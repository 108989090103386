const React = require('react');
const PropTypes = require('prop-types');
const ReactDnD = require('react-dnd');
// const findDOMNode = require('react-dnd').findDOMNode;
// const DragSource = require('react-dnd').DragSource;
// const DropTarget = require('react-dnd').DropTarget;

const cardSource = {
  beginDrag: function(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },

  canDrag: function(props) {
    return props.allowDnd;
  },

  endDrag: function(props) {
    (props.allowDnd) && props.handleTagDrop();
  },
};

const cardTarget = {

  hover: function(props, monitor) {
    const draggedId = monitor.getItem().id;

    if (draggedId !== props.id) {
      props.swapCards(draggedId, props.id);
    }
  }
};

class Tag extends React.Component {
  static propTypes = {
    handleTagClick: PropTypes.func,
    handleTagDrop: PropTypes.func,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any,
    classes: PropTypes.string.isRequired,
    tagType: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    swapCards: PropTypes.func.isRequired,
  };

  handleTagClick = () => {
    this.props.setSelectedTag(this.props.tag);
    this.props.handleTagClick(this.props.tag);
  };

  render() {
    const isDragging = this.props.isDragging;
    const opacity = isDragging ? 0.3 : 1;

    const tag = this.props.tag;

    return this.props.connectDragSource(this.props.connectDropTarget(
      <li key={tag._id}>
        <a
          onClick={this.handleTagClick}
          style={{ opacity }}
          className={this.props.classes}
          data-tag-id={tag._id}
          data-tag-type={this.props.tagType}
          data-tag-value={tag.displayName}
          data-tag-sort={this.props.sort}>{tag.displayName} <i className="fi-check" />
        </a>
      </li>,
    ));
  }
}

const DragSourceDecorator = ReactDnD.DragSource('card', cardSource,
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    };
  });

const DropTargetDecorator = ReactDnD.DropTarget('card', cardTarget,
  (connect) => {
    return {
      connectDropTarget: connect.dropTarget(),
    };
  });


export default DropTargetDecorator(DragSourceDecorator(Tag));
