import constants from '../constants';
import initialState from '../../initialState';

const mentions = function (state, action) {
  let newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.UPSERT_MENTION:
    newstate = action.mentions;
    return newstate;
  case constants.DELETE_MENTION:
    newstate = action.mentions;
    return newstate;
  case constants.CLEAN_MENTIONS:
    newstate = [];
    return newstate;
  default: return state || initialState.mentions;
  }
};

export default mentions;
