import _ from 'lodash';

import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const subscriptions = (state, action) => {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_SUBSCRIPTIONS:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_SUBSCRIPTIONS:
    newstate.isFetching = false;
    newstate.data = action.subscriptions;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_SUBSCRIPTIONS_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.UPDATE_SUBSCRIPTION_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(newstate.data, action.subscription._id, action.subscription);
    newstate.data = _.orderBy(newstate.data, ['lastVisit'], ['desc']);
    return newstate;
  default:
    return state || initialState.subscriptions;
  }
};

export default subscriptions;
