import Outage from './service/outage';
import InviteLinks from './admin/inviteLinks';
import Locations from './admin/locations';
import PendingUsers from './admin/pendingUsers';

const React = require('react');
const { Router, Route, Redirect, IndexRoute } = require('react-router');
const Main = require('./main/containers/main.jsx').default;
const Auth = require('./auth/containers/auth.jsx').default;
const Vibes = require('./vibes/containers/vibes.jsx').default;
const Privacy = require('./privacy/components/privacy.jsx').default;
const Terms = require('./terms/components/terms.jsx').default;
const NotFound = require('./not-found/components/not-found.jsx').default;
const ErrorPage = require('./oops/containers/errors.jsx').default;
const Admin = require('./admin/containers/admin.jsx').default;
const Event = require('./events/containers/events.jsx').default;
const Login = require('./login/containers/login.jsx').default;
const Profile = require('./profile/containers/profile.jsx').default;
const UserSettings = require('./settings/containers/settings.jsx').default;
const SignUp = require('./registration/containers/signUp.jsx').default;
const OrgCreate = require('./registration/containers/orgCreate.jsx').default;
const OrgConfirmation = require('./registration/containers/orgConfirmation.jsx').default;
const CreateUser = require('./registration/containers/userCreate.jsx').default;
const OrgJoin = require('./registration/containers/orgJoin.jsx').default;
const Vibe = require('./vibes/containers/vibe.jsx').default;
const CreateVibe = require('./vibes/containers/createVibe.jsx').default;
const VibeCalendar = require('./events/containers/events.jsx').default;
const OrgCalendar = require('./events/containers/orgCalendar.jsx').default;
const OrgMembers = require('./members/containers/orgMembers.jsx').default;
const VibeMembers = require('./members/containers/vibeMembers.jsx').default;
const PasswordResetRequest = require('./password-reset/containers/passwordRequest.jsx').default;
const PasswordReset = require('./password-reset/containers/passwordReset.jsx').default;
const Survey = require('./surveys/containers/survey.jsx').default;
const SearchResults = require('./search/containers/searchResults.jsx').default;

const checkToken = require('./auth/components/checkToken.jsx').default;
const checkOrg = require('./auth/components/checkOrg.jsx').default;
const checkView = require('./auth/components/checkView.jsx').default;
const checkTags = require('./auth/components/checkTags.jsx').default;
const checkOrgMembers = require('./auth/components/checkOrgMembers.jsx').default;
const checkVibes = require('./auth/components/checkVibes.jsx').default;
const requiresAuth = require('./auth/components/requiresAuth.jsx').default;
const checkAdminAccess = require('./auth/components/checkAdminAccess.jsx').default;

function routes(history, store) {
  return (
    <Router history={history}>
      {/* These top level pages don't have the top nav bar */}
      <Route path="invite/:id" component={checkOrg(CreateUser)} />
      <Route path="profile-details" component={checkToken(checkOrg(checkTags(OrgJoin)))} />
      <Route path="org-create" component={checkToken(checkOrg(checkTags(OrgCreate)))} />
      <Route path="org-confirmation" component={OrgConfirmation} />
      <Route path="outage" component={Outage} />
      {/* The main component adds the top nav bar with login status */}
      <Route component={checkToken(checkOrg(Main))}>
        <Route path="login" component={Login} />
        <Route path="sign-up" component={SignUp} />
        <Route path="get-started" component={SignUp} />
        <Route path="password-request" component={PasswordResetRequest} />
        <Route path="password-reset/:userId/t/:token" component={PasswordReset} />
        <Route component={checkView(checkOrgMembers(checkVibes(checkTags(Auth))))} path="/">
          <IndexRoute component={Vibes} />
          <Redirect from="vibes/create" to="vibes/create/1" />
          <Route path="vibes/create/:step" component={requiresAuth(CreateVibe)} />
          <Route path="vibes/create/:step/:vibeId" component={requiresAuth(CreateVibe)} />
          <Route path="post/:id" component={Vibes} />

          <Route path="settings" component={requiresAuth(UserSettings)} />

          <Route path="admin" component={requiresAuth(checkAdminAccess(Admin))} />
          <Route path="admin/locations" component={requiresAuth(checkAdminAccess(Locations))} />
          <Route path="admin/invite-links" component={requiresAuth(checkAdminAccess(InviteLinks))} />
          <Route path="admin/pending-users" component={requiresAuth(checkAdminAccess(PendingUsers))} />
          <Route path="event" component={Event} />
          <Route path="privacy" component={Privacy} />
          <Route path="profile/:id" component={Profile} />
          <Route path="terms" component={Terms} />
          <Route path="calendar" component={OrgCalendar} />
          <Route path="members" component={OrgMembers} />
          <Route path="vibes/:name" component={Vibe} />
          {/* Redirect old /d/ route to new /post/ route for old links */}
          <Route path="vibes/:name/d/:id" component={Vibe} />
          <Route path="vibes/:name/post/:id" component={Vibe} />
          <Route path="vibes/:name/calendar" component={VibeCalendar} />
          <Route path="vibes/:name/members" component={VibeMembers} />
          {/* New board routes */}
          <Route path="board/:boardId" component={Vibe} />
          <Route path="board/:boardId/post/:id" component={Vibe} />
          <Route path="board/:boardId/calendar" component={VibeCalendar} />
          <Route path="board/:boardId/members" component={VibeMembers} />
          <Redirect from="board/create" to="board/create/1" />
          <Route path="board/create/:step" component={requiresAuth(CreateVibe)} />
          <Route path="board/create/:step/:parentId" component={requiresAuth(CreateVibe)} />
          <Route path="survey" component={Survey} />
          <Route path="search" component={SearchResults} />
          <Route path="search/post/:id" component={SearchResults} />
          <Route path="oops" component={ErrorPage} />
        </Route>
        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  );
}

export default routes;
