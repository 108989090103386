var React = require('react');
var PropTypes = require('prop-types');

var UserBioSettings = require('./userBioSettings.jsx').default;
//var UserEmailSettings = require('./userEmailSettings.jsx').default;
var UserVibesSettings = require('./userVibesSettings.jsx').default;
var Alert = require('../../components/alert.jsx').default;

class UserSettings extends React.Component {
    state = {
        updateAlert: false
    };

    componentWillReceiveProps(nextProps) {
        if ((this.props.auth.error !== nextProps.auth.error) && this.state.updateAlert === false) {
            (Object.keys(nextProps.auth.error).length !== 0) ? this.showErrorAlert() : this.showUpdateAlert('User profile updated successfully')
        }
    }

    showUpdateAlert = (message) => {
        this.setState({updateAlert: true, updateMessage: message});
    };

    showErrorAlert = () => {
        this.setState({errorAlert: true});
    };

    hideUpdateAlert = () => {
        this.setState({updateAlert: false, errorAlert: false});
    };

    render() {
        var isFetchingVibes = this.props.vibes.isFetching;

        if(this.props.auth && !isFetchingVibes) {
        return (
            <div>
                <br/>

                <Alert 
                    type="success callout text-center" 
                    message={this.state.updateMessage}
                    showAlert={this.state.updateAlert} 
                    handleClose={this.hideUpdateAlert} />
                <Alert 
                    type="alert callout text-center" 
                    message={"Please fix the errors below"}
                    showAlert={this.state.errorAlert} 
                    handleClose={this.hideUpdateAlert} />                    

                <div className="row">
                    <div className="small-12 columns">
                        <UserBioSettings 
                            auth={this.props.auth} 
                            organization={this.props.organization}
                            tags={this.props.tags}
                            editUser={this.props.editUser}
                            removeSocialIdentity={this.props.removeSocialIdentity}
                            updateUserOptions={this.props.updateUserOptions}
                            showUpdateAlert={this.showUpdateAlert}/>
                    </div>
                    <div className="small-12 columns">
                        <UserVibesSettings 
                            auth={this.props.auth} 
                            organization={this.props.organization}
                            vibes={this.props.vibes}
                            leaveVibe={this.props.leaveVibe}/>
                    </div>
                    {/* //Moved email setttings into userBioSettings for now
                    <div className="small-5 columns">
                        <UserEmailSettings 
                            auth={this.props.auth} 
                            organization={this.props.organization}
                            editUser={this.props.editUser}
                            showUpdateAlert={this.showUpdateAlert}/>
                    </div>
                    */}
                </div>
            </div>
        );
        } else {
            return (
                <div>
                    <h1 className="loading">Loading...</h1>
                </div>
            );
        }
    }
}

UserSettings.propTypes = {
    editUser: PropTypes.func.isRequired,
    leaveVibe: PropTypes.func.isRequired,
    removeSocialIdentity: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default UserSettings;
