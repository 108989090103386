import constants from '../constants';
import api from '../../../utils/api';

const Tags = (function () {
  const _requestTags = function () {
    return {
      type: constants.REQUEST_TAGS,
    };
  };

  const _receiveTags = function (data) {
    return {
      type: constants.RECEIVE_TAGS,
      tags: data.tags,
    };
  };

  const _fetchTagsError = function (error) {
    return {
      type: constants.REQUEST_TAGS_ERROR,
      errors: error.data,
    };
  };

  const _cleanTagsRequest = function () {
    return {
      type: constants.CLEAN_TAGS,
    };
  };

  const _fetchTags = function (orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestTags());
      api.get(`/proxy/v1/organizations/${orgId}/tags`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveTags(res));
        })
        .catch((res) => {
          dispatch(_fetchTagsError(res));
        });
    };
  };

  const _shouldFetchTags = function (state) {
    const tags = state.tags.data;
    if (Object.keys(tags).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchTagsIfNeeded = function (orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchTags(getState())) {
        return dispatch(_fetchTags(orgId, accessToken));
      }
      return false;
    };
  };

  const _saveTagsRequest = function () {
    return {
      type: constants.SAVE_TAGS_REQUEST,
    };
  };

  const _saveTagsSuccess = function (data) {
    return {
      type: constants.SAVE_TAGS_SUCCESS,
      tags: data.tags,
    };
  };

  const _saveTagsError = function (error) {
    return {
      type: constants.SAVE_TAGS_ERROR,
      errors: error.data,
    };
  };

  const _saveTags = function (tags, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_saveTagsRequest());
      api.put(`/proxy/v1/organizations/${orgId}/tags`, {
        tags,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_saveTagsSuccess(res));
        })
        .catch((res) => {
          dispatch(_saveTagsError(res));
        });
    };
  };

  const _sortTags = function (tags) {
    return {
      type: constants.SORT_TAGS,
      tags,
    };
  };

  const _cleanTags = function () {
    return function (dispatch) {
      dispatch(_cleanTagsRequest());
    };
  };

  const _setSelectedTag = function (tag) {
    return {
      type: constants.SET_SELECTED_TAG,
      tag,
    };
  };

  const _clearSelectedTag = function () {
    return {
      type: constants.CLEAR_SELECTED_TAG,
    };
  };

  return {
    fetchTagsIfNeeded: _fetchTagsIfNeeded,
    fetchTags: _fetchTags,
    saveTags: _saveTags,
    sortTags: _sortTags,
    cleanTags: _cleanTags,
    setSelectedTag: _setSelectedTag,
    clearSelectedTag: _clearSelectedTag,
  };
}());

export default Tags;
