import React, { useState } from 'react';
import PropTypes from 'prop-types';
import utils from '../../../utils';

import UserCard from './components/userCard.jsx';
import Pagination from './pagination';

const UserGrid = (props) => {
  const {
    mode, adminView, users, auth, organization,
    tags, selectedVibe,
    boardId } = props;
  const { data: { _id: orgId } } = organization;
  const {
    showUpdateAlert,
    updateUserOrg, removeOrganizationUser,
    editVibeUser, removeVibeUser,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(50);

  const viewerIsOrgAdmin = utils.userIsOrgAdmin(orgId, auth.user.orgs);

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const userList = currentUsers.map((user) => {
    // Defaults
    const isBoardActive = true;
    let isBoardChampion = false;
    let isBoardMember = false;

    // Filter for current org (mode === 'org')
    const userOrg = (user.orgs).find((org) => org.id === orgId) || {};
    const { isActive: isOrgActive = false, isAdmin: isOrgAdmin = false } = userOrg;

    if (mode === 'board') {
      // Filter for current board (mode==='board')
      const userBoard = (user.vibes).find((board) => board.vibe === boardId) || {};
      // Default of isBoardActive is true to handle tag driven members
      ({ isActive: isBoardMember, isChampion: isBoardChampion } = userBoard);
    }

    return (
      <UserCard
        mode={mode}
        adminView={adminView}
        updateUserOrg={updateUserOrg}
        removeOrganizationUser={removeOrganizationUser}
        editVibeUser={editVibeUser}
        removeVibeUser={removeVibeUser}
        showUpdateAlert={showUpdateAlert}
        key={user._id}
        user={user}
        userTitle={userOrg.title}
        userExternalOrg={userOrg.externalOrg}
        userIsOrgAdmin={isOrgAdmin}
        userIsOrgActive={isOrgActive}
        userIsBoardMember={isBoardMember}
        userIsVibeChampion={isBoardChampion}
        userIsVibeActive={isBoardActive}
        auth={auth}
        organization={organization}
        tags={tags}
        selectedVibe={selectedVibe}
        userOrg={userOrg} />
    );
  });

  return (
    <div>
      <div className="margin-top-5 margin-bottom-15">
        <Pagination
          currentPage={currentPage}
          postsPerPage={usersPerPage}
          totalPosts={users.length}
          paginate={paginate}
        />
      </div>
      <div className="row small-up-1 medium-up-3 large-up-5">
        {userList.length > 0
          ? userList
          :
          <div>
            <br />
            <h5 className="text-center">No members to display</h5>
            <br />
            <br />
            <br />
          </div>}
      </div>
    </div>
  );
};

UserGrid.propTypes = {
  mode: PropTypes.string.isRequired,
  boardId: PropTypes.string,
  users: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  tags: PropTypes.object,
};

UserGrid.defaultProps = {
  boardId: '',
  tags: {},
};

export default UserGrid;
