import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const selectedVibe = function (state = initialState.selectedVibe, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_VIBE:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_VIBE:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.updated = false;
    return newstate;
  case constants.RECEIVE_BOARD_BY_ID:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.updated = false;
    return newstate;
  case constants.RECEIVE_BOARD_FROM_STATE:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.updated = false;
    return newstate;
  case constants.RECEIVE_ORG_VIBE:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.updated = false;
    return newstate;
  case constants.REQUEST_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.UPDATE_VIBE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.UPDATE_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.vibe;
    newstate.updated = true;
    return newstate;
  case constants.UPDATE_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_VIBE_SUCCESS:
    newstate.data = action.vibe;
    return newstate;
  case constants.CREATE_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.REQUEST_MEMBERS:
    newstate.members.isFetching = true;
    return newstate;
  case constants.RECEIVE_DIRECT_MEMBERS:
    newstate.members.isFetching = false;
    newstate.members.direct = action.members;
    newstate.members.vibe = action.boardId;
    return newstate;
  case constants.RECEIVE_INHERITED_MEMBERS:
    newstate.members.isFetching = false;
    newstate.members.inherited = action.members;
    newstate.members.parent = action.boardId;
    return newstate;
  case constants.REQUEST_MEMBERS_ERROR:
    newstate.members.isFetching = false;
    newstate.members.direct = [];
    newstate.members.vibe = null;
    newstate.error = action.errors;
    return newstate;
  case constants.REMOVE_VIBE_USER_SUCCESS:
    newstate.isFetching = false;
    newstate.members.direct = utils.remove(state.members.direct, action.userId);
    return newstate;
  case constants.APPROVE_VIBE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.APPROVE_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.selectedVibe;
    newstate.approved = true;
    return newstate;
  case constants.APPROVE_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.REJECT_VIBE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.REJECT_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.updated = true;
    newstate.data = {};
    // newstate.data = action.vibe.vibe;
    return newstate;
  case constants.REJECT_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.DELETE_VIBE_SUCCESS:
    return initialState.selectedVibe;
  case constants.EDIT_VIBE_USER_REQUEST:
    // newstate.isFetching = true;
    return newstate;
  case constants.EDIT_VIBE_USER_SUCCESS:
    newstate.isFetching = false;
    newstate.members.direct = utils.upsert(state.members.direct, action.user._id, action.user);
    return newstate;
  case constants.EDIT_VIBE_USER_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.ADD_VIBE_CHAMPION:
    newstate.isFetching = false;
    newstate.data = action.vibe;
    return newstate;
  case constants.REMOVE_VIBE_CHAMPION:
    newstate.isFetching = false;
    newstate.data = action.vibe;
    return newstate;
  case constants.REQUEST_CALLOUT_REMOVE:
    newstate.updated = false;
    newstate.approved = false;
    return newstate;
  case constants.SET_SELECTED_VIBE:
    newstate.isFetching = false;
    newstate.data = action.vibeItem;
    return newstate;
  case constants.CLEAR_SELECTED_VIBE:
    // There must be a way to reset this to initialState.selectedVibe
    // But initialState seems to retain the state data
    // If you add this action to the root reducer, it does work with initialState
    newstate.data = {};
    newstate.error = {};
    newstate.children = [];
    newstate.members = {
      isFetching: false,
      vibe: null,
      direct: [],
      inherited: [],
      parent: null,
      error: {},
      message: {},
    };
    return newstate;
  case constants.CLEAR_SELECTED_VIBE_MESSAGE:
    newstate.isFetching = false;
    newstate.members.message = {};
    return newstate;
  case constants.CLEAR_VIBE_ALERT:
    newstate.updated = false;
    newstate.approved = false;
    return newstate;
  case constants.ADD_VIBE_USERS_SUCCESS:
    newstate.isFetching = false;
    newstate.members.direct = action.mergedVibeMembers;
    newstate.members.message = action.newUserFullNames;
    return newstate;
  case constants.RECEIVE_VIBE_CHILDREN:
    newstate.children = action.data;
    return newstate;
  case constants.APPROVE_ORG_VIBE:
    newstate.isFetching = false;
    newstate.data = action.selectedVibe;
    newstate.approved = true;
    return newstate;
  default: return state;
  }
};

export default selectedVibe;
