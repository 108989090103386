import constants from '../constants';
import api from '../../../utils/api';
import mentionActions from '../../mentions/actions/mentions';
import visitVibeActions from '../../auth/actions/visitVibe';
import vibesActions from '../../vibes/actions/vibes';
import discussionsActions from './discussions';

const CreateDiscussion = (function () {
  const _createPostRequest = function () {
    return {
      type: constants.CREATE_POST_REQUEST,
    };
  };

  const _createPostSuccess = function (data) {
    return {
      type: constants.CREATE_POST_SUCCESS,
      post: data.discussion,
    };
  };

  const _createCrossPostSuccess = function (data) {
    return {
      type: constants.CREATE_CROSS_POST_SUCCESS,
      post: data.discussion,
    };
  };

  const _createPostError = function (error) {
    return {
      type: constants.CREATE_POST_ERROR,
      errors: error.data,
    };
  };

  const _createDiscussion = function (postItem, userId, orgId, selectedVibeId, mentions, accessToken) {
    return function (dispatch) {
      dispatch(_createPostRequest());
      api.post('/proxy/v1/discussions/', {
        postType: postItem.data.postType,
        title: postItem.data.title,
        content: postItem.data.content,
        featuredImage: postItem.data.featuredImage,
        author: userId,
        vibe: postItem.data.vibe,
        orgId,
        mentions,
        isPinned: postItem.data.isPinned,
        isFeatured: postItem.data.isFeatured,
        callToAction: postItem.data.callToAction,
        attachments: postItem.data.attachments,
      }, accessToken)
        .then((res) => {
          dispatch(discussionsActions.clearPost());
          if (postItem.data.vibe === selectedVibeId) {
            dispatch(_createPostSuccess(res));
            dispatch(visitVibeActions.visitVibe(selectedVibeId, userId, orgId, accessToken));
            dispatch(vibesActions.setActivityFlag(true));
          } else {
            dispatch(_createCrossPostSuccess(res));
            dispatch(visitVibeActions.visitVibe(postItem.data.vibe, userId, orgId, accessToken));
            dispatch(vibesActions.setActivityFlag(true));
          }

          dispatch(mentionActions.cleanMentions());
        })
        .catch((res) => {
          dispatch(_createPostError(res));
          dispatch(mentionActions.cleanMentions());
        });
    };
  };

  return {
    createDiscussion: _createDiscussion,
  };
}());

export default CreateDiscussion;
