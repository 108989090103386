const React = require('react');
const PropTypes = require('prop-types');

const RadioSet = props => (
  <div>
    {props.radios.map((radio, i) => (
      <div className="field" key={radio.value}>
        <input
          className="is-checkradio"
          id={`option${i}`}
          type="radio"
          name={props.group}
          value={radio.value}
          onChange={props.onChange}
          checked={radio.checked} />
        <label htmlFor={`option${i}`}>
          {(props.boldHeaders) ? <strong>{radio.label}</strong> : radio.label }
          <br />
          {props.subText && radio.text}
        </label>
      </div>
    ))}
  </div>
);

RadioSet.propTypes = {
  group: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subText: PropTypes.bool,
  boldHeaders: PropTypes.bool,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      text: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
};

RadioSet.defaultProps = {
  subText: '',
  boldHeaders: '',
  radios: [],
};

export default RadioSet;
