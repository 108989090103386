import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import activeEventFilterReducer from './events/reducers/activeEventFilter';
import activeVibeFilterReducer from './vibes/reducers/activeVibeFilter';
import alertsReducer from './alerts/reducers';
import authReducer from './auth/reducers/auth';
import boardReducer from './boards/reducers';
import discussionReducer from './discussions/reducers/discussion';
import discussionsReducer from './discussions/reducers/discussions';
import eventsReducer from './events/reducers/events';
import inviteReducer from './invitations/reducers/invitation';
import invitationsReducer from './invitations/reducers/invitations';
import mentionsRouteReducer from './mentions/reducers/mentions';
import orgReducer from './organizations/reducers/organization';
import postReducer from './discussions/reducers/post';
import previousRouteReducer from './auth/reducers/previousRoute';
import regReducer from './registration/reducers/registration';

import selectedEventReducer from './events/reducers/selectedEvent';
import selectedTagReducer from './tags/reducers/selectedTag';
import selectedVibeReducer from './vibes/reducers/selectedVibe';
import subscriptionsReducer from './subscriptions/reducers/subscriptions';
import tagsReducer from './tags/reducers/tags';
import userReducer from './users/reducers/user';
import userFilterReducer from './users/reducers/userFilter';
import vibeReducer from './vibes/reducers/vibes';
import vibesPublicReducer from './vibes/reducers/vibesPublic';

const appReducer = combineReducers({
  activeEventFilter: activeEventFilterReducer,
  activeVibeFilter: activeVibeFilterReducer,
  alerts: alertsReducer,
  auth: authReducer,
  board: boardReducer,
  discussion: discussionReducer,
  discussions: discussionsReducer,
  events: eventsReducer,
  invitation: inviteReducer,
  invitations: invitationsReducer,
  mentions: mentionsRouteReducer,
  organization: orgReducer,
  post: postReducer,
  previousRoute: previousRouteReducer,
  registration: regReducer,
  routing: routerReducer,
  selectedEvent: selectedEventReducer,
  selectedTag: selectedTagReducer,
  selectedVibe: selectedVibeReducer,
  subscriptions: subscriptionsReducer,
  tags: tagsReducer,
  user: userReducer,
  userFilter: userFilterReducer,
  vibes: vibeReducer,
  vibesPublic: vibesPublicReducer,
});

const rootReducer = function (state, action) {
  if (action.type === 'AUTH_LOGOUT_SUCCESS') {
    // activeEventFilter
    // activeVibeFilter
    state.alerts = undefined;
    // auth
    state.board = undefined;
    state.discussion = undefined;
    state.discussions = undefined;
    state.events = undefined;
    state.invitation = undefined;
    state.invitations = undefined;
    state.mentions = undefined;
    // organization
    state.post = undefined;
    state.previousRoute = undefined;
    // registration
    // routing
    state.selectedEvent = undefined;
    state.selectedTag = undefined;
    state.selectedVibe = undefined;
    state.subscriptions = undefined;
    state.tags = undefined;
    state.user = undefined;
    // userFilter
    state.vibes = undefined;
    state.vibesPublic = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
