const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const _ = require('lodash');
const TagSelectModal = require('../../components/tags/containers/tagsSelectModal.jsx').default;
const EmailUserModal = require('../../components/users/containers/emailUserModal.jsx').default;
const ProfileHeaderEditor = require('./profileHeaderEditor.jsx').default;
const utils = require('../../../../common/utils').default;
const Alert = require('../../components/alert.jsx').default;

class ProfileHeader extends React.Component {
  state = {
    tagsModalIsOpen: false,
    emailModalIsOpen: false,
    editProfile: false,
  };

  handleEditProfile = () => {
    this.toggleEditProfile(true);
  };

  toggleEditProfile = (val) => {
    this.setState({ editProfile: val });
  };

  openTagsModal = (mode, type) => {
    this.setState({ tagsModalIsOpen: true });
  };

  closeTagsModal = () => {
    this.setState({ tagsModalIsOpen: false });
  };

  openEmailModal = (mode, type) => {
    this.setState({ emailModalIsOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ emailModalIsOpen: false });
  };

  render() {
    const { editProfile } = this.state;
    const { auth, userTags, nonLocationTags, userCurrentOrg, viewingUserIsOrgAdmin } = this.props;
    const { user: { _id: viewingUserId } } = auth;
    const organization = this.props.organization.data;
    const user = this.props.user.data;
    let phoneSingle;

    // Single number for now but DB able to support multiple numbers array (w/type)
    if (organization.preferences.showPhoneNumbers && user.phoneNumbers && user.phoneNumbers[0]) {
      phoneSingle = user.phoneNumbers[0].number;
    }

    // User title
    let userTitle = userCurrentOrg.title || '';

    let externalOrgName = '';
    let externalOrgUrl = '';
    if (organization.preferences.showExternalOrg && userCurrentOrg.externalOrg) {
      if (userTitle.length > 0 && userCurrentOrg.externalOrg.orgName.length > 0) { userTitle = `${userTitle}, `; }
      externalOrgUrl = userCurrentOrg.externalOrg.orgUrl;
      externalOrgName = userCurrentOrg.externalOrg.orgName;
    }

    const nonLocationTagsList = _.map(nonLocationTags, (tag) => {
      // const baseStyle = 'button round hollow';
      const style = 'tag is-medium is-primary is-light margin-right-5';
    
      // Don't think users need to know it's a private tag, only admins
      // if (tag.isPrivate) {baseStyle = "button round hollow warning"}

      return (
        <span key={tag._id} className={style}>

          {tag.displayName}
          &nbsp;
        </span>
      );
    });

    return (
      <div className="row">
        <Alert
          type="success callout text-center"
          showAlert={this.props.user.emailSent}
          closeButton={false}
          handleClose={this.props.clearGroupEmail} >
          <h5>Your email has been delivered.<br />We also sent you a copy for reference.</h5>
        </Alert>
        <div className="large-12 small-12 columns">
          <TagSelectModal
            viewingUserId={viewingUserId}
            userCurrentOrg={userCurrentOrg}
            tags={this.props.tags}
            userTags={this.props.userTags}
            viewingUserIsOrgAdmin={viewingUserIsOrgAdmin}
            tagsModalIsOpen={this.state.tagsModalIsOpen}
            closeTagsModal={this.closeTagsModal} />

          <EmailUserModal
            organization={this.props.organization}
            auth={this.props.auth}
            targetUsers={[user]}
            sendGroupEmail={this.props.sendGroupEmail}
            emailModalIsOpen={this.state.emailModalIsOpen}
            closeEmailModal={this.closeEmailModal} />

          <div className="profile-header-card">

            {(editProfile) ?
              <ProfileHeaderEditor
                toggleEditProfile={this.toggleEditProfile}
                auth={this.props.auth}
                organization={this.props.organization}
                user={this.props.user}
                tags={this.props.tags}
                userTags={this.props.userTags}
                userCurrentOrg={userCurrentOrg}
                externalOrg={this.props.externalOrg}
                phoneSingle={phoneSingle}
                location={this.props.location}
                openTagsModal={this.openTagsModal}
                setUser={this.props.setUser}
                editUserFull={this.props.editUserFull} />
              :
              <div className="media-object expanded stack-for-small">
                <div className="media-object-section">
                  <img className="profile-image" src={user.photoUrl} alt={user.firstName} onError={utils.showDefaultImage} />
                </div>
                <div className="media-object-section profile-header-text">
                  {(
                    (this.props.viewingUserIsOrgAdmin ||
                        this.props.viewingUserIsTagAdmin ||
                        (this.props.auth.user._id === user._id)) && !editProfile) &&
                      <span>
                        <button
                          type="button"
                          className="button hollow margin-left-15 float-right"
                          title="Edit profile"
                          onClick={this.handleEditProfile}><i className="fa fa-edit" /> Edit profile
                        </button>
                        <button
                          type="button"
                          className="button hollow margin-left-15 float-right"
                          title="Add/edit teams"
                          onClick={this.openTagsModal}><i className="fa fa-tags" /> Edit tags
                        </button>
                      </span>
                  }
                  <button
                    type="button"
                    className="button hollow float-right"
                    title={`Email ${user.firstName}`}
                    onClick={this.openEmailModal}><i className="fa fa-envelope" /> {`Email ${user.firstName}`}
                  </button>
                  <h2>
                    <strong>{user.firstName} {user.lastName}</strong>
                    {user.linkedIn && user.linkedIn.profileUrl
                      ?
                      <a
                        href={user.linkedIn.profileUrl}
                        className="social-icon"
                        target="_ext"
                        title={`Visit ${user.firstName}'s LinkedIn`}>&nbsp;<i className="fi-social-linkedin" />
                      </a>
                      : null}
                  </h2>
                  <h5>
                    {userTitle}
                    {(externalOrgUrl)
                      ? <a href={externalOrgUrl} target="external">{externalOrgName}</a>
                      : externalOrgName
                    }
                  </h5>
                  <h5>{this.props.location}</h5>
                  {(phoneSingle) && <h5>{phoneSingle}</h5>}
                  {this.props.auth.token &&
                      <div className="tags">{nonLocationTagsList}</div>
                  }
                  <div className="float-right user-stats-box hide-for-small-only hide">
                    <ul className="menu">
                      <li><div className="stat">{this.props.selectedUserVibeCount}</div>Vibes</li>
                      <li className="hide"><div className="stat">-</div>posts</li>
                      <li className="hide"><div className="stat">-</div>interactions</li>
                    </ul>
                  </div>
                  <div className="float-left">
                    <ul className="social-icon-list">
                      {user.facebook ? <li><button type="button" target="_ext"><i className="fi-social-facebook" /></button></li> : null}
                      {user.twitter ? <li><button type="button" target="_ext"><i className="fi-social-twitter" /></button></li> : null}
                    </ul>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ProfileHeader.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default ProfileHeader;
