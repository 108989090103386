const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const RouterActions = require('react-router-redux').routerActions;
const PropTypes = require('prop-types');
const utils = require('../../../../common/utils').default;
const PreviousRouteActions = require('../../../modules/auth/actions/previousRoute').default;

const checkView = function (Component) {
  class CheckViewComponent extends React.Component {
    state = { allowRender: false };

    componentDidMount() {
      const { auth } = this.props;
      const organization = this.props.organization.data;
      const previousRoute = this.props.location.search ? this.props.location.pathname + this.props.location.search : this.props.location.pathname;

      this.checkUserView(auth, organization, previousRoute);
    }

    componentWillReceiveProps(nextProps) {
      // Re-check view if user logs in or out
      if (!nextProps.auth.isFetching && (nextProps.auth !== this.props.auth)) {
        const { auth } = nextProps;
        const organization = nextProps.organization.data;
        const previousRoute = nextProps.location.search ? nextProps.location.pathname + nextProps.location.search : nextProps.location.pathname;

        this.checkUserView(auth, organization, previousRoute);
      }
    }

    checkUserView = (auth, organization, previousRoute) => {
      const publicView = this.checkPublicOrgAccess(organization);

      if (publicView) {
        this.setState({ allowRender: true });
      } else if (Object.keys(auth.user).length !== 0) {
        const userIsOrgActive = this.checkUserIsOrgActive(auth, organization);

        if (userIsOrgActive) {
          this.setState({ allowRender: true });
        } else {
          this.props.setPreviousRoute(previousRoute);
          this.props.push('/login?err=7');
        }
      } else if (auth.isFetching && auth.token && (auth.token !== undefined)) {
        // Catch case where login right after logout
        // Don't redirect in this case
      } else {
        this.props.setPreviousRoute(previousRoute);
        this.props.push('/login');
      }
    };

    checkUserIsOrgActive = (auth, organization) => {
      if (Object.keys(auth.user).length !== 0) {
        return utils.userIsOrgActive(organization._id, auth.user.orgs);
      }

      return false;
    };

    checkPublicOrgAccess = (organization) => {
      if (undefined !== organization.preferences.publicView) {
        return organization.preferences.publicView;
      }

      return false;
    };

    render() {
      return (
        <div className="authenticated">
          {
            (this.state.allowRender === true)
              ? <Component {...this.props} />
              : null
          }

        </div>
      );
    }
  }

  // const WrappedViewComponent = withRouter(CheckViewComponent);

  CheckViewComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setPreviousRoute: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, RouterActions, PreviousRouteActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CheckViewComponent);
};

export default checkView;
