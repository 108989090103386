//import 'foundation-sites/dist/foundation.min.css';
import 'react-app-polyfill/ie11';
// import jquery from 'jquery';
import './css/app.css';
import 'react-table/react-table.css';
import React from 'react';
import ReactDOM from 'react-dom';

import { browserHistory} from 'react-router';
//import { Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { Provider } from 'react-redux';
import { configureStore } from './common/modules/store';

// const $ = (typeof window !== 'undefined') ? window.$ = window.jQuery = jquery : {}; // eslint-disable-line
require('fullcalendar');
require('fullcalendar/dist/gcal');
//require('foundation-sites');
require('foundation-sites/dist/js/foundation');

const routes = require('./common/routes/routes.jsx').default;

//var prevLocation = {};
// browserHistory.listen(function (location) {
//     if (location.pathname !== prevLocation.pathname) {
//       if (typeof window !== 'undefined') {
//         var dataLayer = window.dataLayer;
//         dataLayer.push({
//             'event':'VirtualPageview',
//             'virtualPageUrl': location.pathname
//         });
//       }
//       prevLocation = location;
//     }
// });



const initialState = window.__STATE__;
const store = configureStore(initialState, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);

//$(document).foundation();

ReactDOM.render(
<Provider store={store}>
	{ routes(history, store) }
</Provider>,
document.getElementById("app")
);
