const React = require('react');
const { Helmet } = require('react-helmet');
const { Link, withRouter } = require('react-router');
const PropTypes = require('prop-types');

const LoginForm = require('../../components/loginForm.jsx').default;
const SocialButtonList = require('../../components/socialButtonList.jsx').default;
const Alert = require('../../components/alert.jsx').default;

class Login extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if ((nextProps !== this.props) && nextProps.location.query.err) {
      nextProps.router.push(nextProps.location.pathname);
    }
  }

  handleBasicLogin = (email, password) => {
    this.props.login(email, password, this.props.previousRoute, this.props.organization.data.teamDomain);
  };

  render() {
    const organization = this.props.organization.data;
    const { isFetching } = this.props.organization;

    // Custom onboard imagery
    const { imagery: { onboard1 = 'https://res.cloudinary.com/teamvibe/image/upload/v1470114542/product/header-celebrate.jpg' } = {} } = organization;

    const previousRoute = this.props.previousRoute ? this.props.previousRoute : '/';
    // const emailDomain = (organization.emailDomains.length > 0) ? '@' + organization.emailDomains[0]: '@' + organization.teamDomain + '.com';
    const authError = this.props.auth.error || {};
    let errorMessage = '';
    let errorHtml = '';
    const alertOptions = {
      type: 'alert callout text-center',
      autoClose: true,
      closeButton: false,
    };

    if (this.props.location.query.err) {
      switch (this.props.location.query.err) {
      case '1':
        errorMessage = 'User not found.';
        break;
      case '2':
        errorMessage = 'Not authorized for this organization. If you are new, click "Sign up" at the top right to create an account.';
        break;
      case '3':
        errorMessage = 'Your session has timed out, please login again.';
        break;
      case '4':
        errorMessage = 'LinkedIn is currently unavailable. Please try again in a few minutes or use email login.';
        break;
      case '5':
        alertOptions.type = 'success callout text-center';
        errorMessage = 'Your password has been reset';
        break;
      case '6':
        errorMessage = 'You must be logged into see this page.';
        break;
      case '7':
        errorMessage = 'You are logged in but not authorized for this org.';
        break;
      case '8':
        errorMessage = 'We were unable to access your LinkedIn data. Please try username/password or contact support@teamvibe.com.';
        break;
      default:
        errorMessage = 'An error ocurred in your request. Please try again.';
        break;
      }
    }

    if (Object.keys(authError).length !== 0) {
      const { error, error_description } = authError;

      if (error === 'no_password') {
        alertOptions.autoClose = false;
        alertOptions.closeButton = true;
        errorHtml = <span>No password on file. You can login with LinkedIn or <Link to="/password-request">set one up here</Link>.</span>;
      } else if (error_description) {
        errorMessage = error_description;
      }
    }

    const emailError = ((Object.keys(authError).length !== 0) && ('email' in authError)) ? authError.email.message : null;
    const passwordError = ((Object.keys(authError).length !== 0) && ('password' in authError)) ? authError.password.message : null;

    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    return (
      <div>
        <Helmet title="Login" />
        <Alert
          name="alert_error"
          type={alertOptions.type}
          message={errorMessage}
          showAlert={(Object.keys(errorMessage).length !== 0) || (Object.keys(errorHtml).length !== 0)}
          handleClose={this.props.clearAuthError}
          closeButton={alertOptions.closeButton}
          autoClose={alertOptions.autoClose}>
          {errorHtml && errorHtml}
        </Alert>

        <div className="full-screen full-height">
          <div className="fs-panel">
            <br />

            <div className="row align-center">
              <div className="medium-10 medium-offset-1 columns end">
                <h3 className="text-center"><strong>{`Sign in to ${organization.name}`}</strong></h3>
                <p className="text-center">You can sign in with LinkedIn or your email and password</p>
                <br />
                <SocialButtonList
                  type="login"
                  org={organization}
                  previousRoute={previousRoute}
                  inviteType="none" />
                <br className="is-hidden-mobile" />
                <h3 className="text-center">-- OR --</h3>
                <br className="is-hidden-mobile" />
                <form>
                  <LoginForm
                    emailPlaceholder="you@example.com"
                    emailHelp={emailError}
                    passwordPlaceholder="password"
                    passwordHelp={passwordError}
                    loginUser={this.handleBasicLogin}
                    buttonText="Sign in with email address"
                    rememberMe="hide" />
                  <p className="text-center">
                    If you have trouble logging in,
                    <br />
                    <Link to="/password-request">reset your password</Link>
                    &nbsp;or&nbsp;
                    <a href="mailto:support@teamvibe.com?subject=Login Help">email us for help</a>
                  </p>
                  <br />
                  <p className="text-center">If you work at {organization.name}, you can <Link to="/sign-up">create an account here</Link></p>
                </form>
                <br />
              </div>
            </div>
          </div>
          <div
            className="hide-for-small-only fs-panel"
            style={{ background: `#1dadda url(${onboard1}) center center/cover` }}>
            {/* This div is left empty since it is filled by the background image */}
          </div>
        </div>


        <br />

      </div>
    );
  }
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  previousRoute: PropTypes.string,
};

Login.defaultProps = {
  previousRoute: '',
};

export default withRouter(Login);
