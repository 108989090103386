const React = require('react');
const { withRouter } = require('react-router');
const PropTypes = require('prop-types');
// const GoogleLogin = require('react-google-login').default;
const moment = require('moment');
const _ = require('lodash');
const ReactTimeout = require('react-timeout');
const EventsList = require('./eventsList.jsx').default;
const EventInputModal = require('../../components/events/containers/eventInputContainer.jsx').default;
const Calendar = require('../../components/events/components/calendarWidget.jsx').default;
const GoogleSyncModal = require('../../components/events/components/googleSyncModal.jsx').default;
const utils = require('../../../utils').default;
const Alert = require('../../components/alert.jsx').default;

class OrgCalendar extends React.PureComponent {
  state = { inputModalIsOpen: false, eventModalIsOpen: false, googleModalIsOpen: false, eventInputMode: 'create' };

  componentDidMount() {
    this.props.fetchOrgEvents(this.props.organization.data._id, this.props.auth.token);
  }

  componentDidUpdate() {
    const { selectedEvent: { closeModal = false } } = this.props;
    if (closeModal === true) {
      this.closeInputModal();
    }

    // Show confirmation alert on Google sync
    if (this.props.location.query.confirm === 'y') {
      this.props.router.push(this.props.location.pathname);
      this.props.setTimeout(this.showUpdateAlert, 500);
    }
  }

  openEventInputModal = (mode) => {
    const eventItem = this.props.selectedEvent;
    if (mode === 'create') {
      this.props.setSelectedEvent(eventItem.data);
    }
    this.setState({ inputModalIsOpen: true, eventInputMode: mode });
  };

  closeInputModal = () => {
    this.props.clearSelectedEvent();
    this.setState({ inputModalIsOpen: false });
  };

  openGoogleModal = () => {
    this.setState({ googleModalIsOpen: true });
  };

  closeGoogleModal = () => {
    this.setState({ googleModalIsOpen: false });
  };

  showUpdateAlert = () => {
    this.setState({ updateAlert: true });
  };

  showUnsyncAlert = () => {
    this.setState({ unsyncAlert: true });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false, unsyncAlert: false });
  };

  handleMonthChange = (title, intervalStart, intervalEnd) => {
    this.props.setActiveEventFilter(intervalStart, intervalEnd);
  };

  handleChangeRSVP = (eventId, rsvpStatus) => {
    this.props.changeRSVP(eventId, this.props.auth.user._id, rsvpStatus, this.props.organization.data._id, this.props.auth.token);
  };

  handleAllEventsChange = () => {
    this.props.setActiveEventTypeFilter('all');
  };

  handleVibesEventsChange = () => {
    this.props.setActiveEventTypeFilter('vibes');
  };

  render() {
    const { activeEventFilter, auth, events, organization } = this.props;
    const { isFetching, data: eventsData } = events;
    const { vibes } = auth.user;

    let filteredEvents = eventsData;
    let filteredCalEvents = eventsData;

    const isGoogleSynced = (this.props.auth.user.orgs) ? utils.userhasOrgGoogleTokens(this.props.organization.data._id, this.props.auth.user.orgs) : false;

    if (!isFetching) {
      if (this.props.auth.user.orgs && activeEventFilter.filterType && activeEventFilter.filterType !== 'all') {
        filteredCalEvents = _.filter(eventsData, (event) => {
          return utils.userIsVibeMember(auth.user.vibes, event.vibe._id);
        });

        filteredEvents = _.filter(filteredCalEvents, (event) => {
          return moment(event.startDate).isBetween(activeEventFilter.intervalStart,activeEventFilter.intervalEnd);
        });
      } else {
        filteredEvents = _.filter(eventsData, (event) => {
          return moment(event.startDate).isBetween(activeEventFilter.intervalStart,activeEventFilter.intervalEnd);
        });
      }
    }

    return (
      <div>
        <GoogleSyncModal
          googleModalIsOpen={this.state.googleModalIsOpen}
          closeGoogleModal={this.closeGoogleModal}
          organization={this.props.organization}
          auth={this.props.auth}
          removeSocialIdentity={this.props.removeSocialIdentity}
          showUnsyncAlert={this.showUnsyncAlert}
          isGoogleSynced={isGoogleSynced} />
        <Alert
          name="alert_success"
          type="success callout text-center"
          message="Your RSVPs are now synced to your Google calendar"
          showAlert={this.state.updateAlert}
          handleClose={this.hideUpdateAlert} />

        <Alert
          name="alert_unsync"
          type="success callout text-center"
          message="Your calendar is un-synced. You can re-sync again in the future."
          showAlert={this.state.unsyncAlert}
          handleClose={this.hideUpdateAlert} />

        <div className="vibe-header-panel" style={{ backgroundImage: 'url()' }} />
        {/* ## BEGIN content area */}
        {!isFetching &&
          <div className="row column">
            <div className="callout vibe-canvas">
              <div className="row">
                <div className="small-12 medium-10 columns">
                  <div className="post-container event no-border">
                    <Calendar
                      events={filteredEvents}
                      selectedEvent={this.props.selectedEvent}
                      auth={auth}
                      organization={organization}
                      joinVibe={this.props.joinVibe}
                      RSVPclick={this.handleChangeRSVP}
                      handleMonthChange={this.handleMonthChange}
                      setSelectedEvent={this.props.setSelectedEvent}
                      openEventInputModal={this.openEventInputModal}
                      clearSelectedEvent={this.props.clearSelectedEvent} />
                  </div>
                  <EventsList
                    mode="user"
                    events={filteredEvents}
                    auth={auth}
                    organization={organization}
                    joinVibe={this.props.joinVibe}
                    RSVPclick={this.handleChangeRSVP}
                    setSelectedEvent={this.props.setSelectedEvent}
                    openEventInputModal={this.openEventInputModal} />

                  <EventInputModal
                    mode={this.state.eventInputMode}
                    inputModalIsOpen={this.state.inputModalIsOpen}
                    closeInputModal={this.closeInputModal} />
                </div>
                <div className="medium-2 columns hide-for-small-only">
                  {/* ## Actions sidebar */}
                  <br />
                  <br />
                  <br />
                  <br />
                  {this.props.auth.user.orgs ? (vibes.length > 0 && <div>
                    <button
                      type="button"
                      className="button expanded hollow"
                      onClick={this.openEventInputModal.bind(this, 'create')}>
                      <i className="fa fa-plus" />
                      <span> New event</span>
                    </button>
                  </div>) : null}
                  {/*this.props.auth.user.orgs ?
                    <button
                      type="button"
                      className="button expanded hollow"
                      onClick={this.openGoogleModal}>
                      <i className="fa fa-refresh" />
                      {isGoogleSynced ? ' Unsync Google' : ' Sync to Google'}
                    </button> : null*/}
                  <p><strong>Set view:</strong></p>
                  <div className="button-group vertical-toggle text-center" data-toggle="buttons-radio">
                    <input
                      type="radio"
                      id="cal-all"
                      name="cal-options"
                      data-toggle="button"
                      defaultChecked={(activeEventFilter.filterType === 'all')}
                      onClick={this.handleAllEventsChange} />
                    <label className="button hollow expanded" htmlFor="cal-all">All events</label>
                    <input
                      type="radio"
                      id="cal-my"
                      name="cal-options"
                      data-toggle="button"
                      defaultChecked={(activeEventFilter.filterType === 'vibes')}
                      onClick={this.handleVibesEventsChange} />
                    {this.props.auth.user.orgs ? (<label className="button hollow expanded" htmlFor="cal-my">My boards only</label>) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {isFetching &&
            <h1 className="loading">Loading...</h1>
        }
      </div>
    );
  }
}

OrgCalendar.propTypes = {
  fetchOrgEvents: PropTypes.func.isRequired,
  changeRSVP: PropTypes.func.isRequired,
  addSocialIdentity: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  activeEventFilter: PropTypes.object.isRequired,
};

export default withRouter(ReactTimeout(OrgCalendar));
