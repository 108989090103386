import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import _ from 'lodash';
import utils from '../../../utils';

const formatGroupLabel = data => (
  <div>
    <span>{data.label}</span> <span>({data.options.length})</span>
  </div>
);

const TagsSelector = (props) => {
  // Create REFs for menus
  // const filterMenu = useRef(null);
  // const [tagType, setTagType] = useState('');

  const { placeholder, userIsOrgAdmin, addTag } = props;
  // Import state vars
  const { auth, selectedVibe, tags } = useSelector(
    state => ({
      auth: state.auth,
      selectedVibe: state.selectedVibe,
      tags: state.tags,
    }),
  );

  const { user: { _id: userId } } = auth;
  const { data: tagsData } = tags;
  const { data: selectedVibeData } = selectedVibe;
  const { orgOptions = {} } = selectedVibeData;
  const boardTags = (orgOptions && 'tags' in orgOptions) ? orgOptions.tags : [];

  // Filter tags based on isActive, tagAdmin
  const filteredTags = utils.filterTagsByAdmin(tagsData, userId, userIsOrgAdmin);

  const handleTagAdd = (option) => {
    // Add new tag
    if (option) {
      addTag(option.value);
    }
  };

  const locationsList = filteredTags.filter(
    tag => tag.tagType === 'location' && tag.isActive,
  ).map(tag => (
    {
      value: tag._id,
      label: tag.displayName,
      isDisabled: _.includes(boardTags, tag._id),
    }
  ));

  const teamsList = filteredTags.filter(
    tag => tag.tagType === 'team' && tag.isActive,
  ).map(tag => (
    {
      value: tag._id,
      label: tag.displayName,
      isDisabled: _.includes(boardTags, tag._id),
    }
  ));

  const industriesList = filteredTags.filter(
    tag => tag.tagType === 'industry' && tag.isActive,
  ).map(tag => (
    {
      value: tag._id,
      label: tag.displayName,
      isDisabled: _.includes(boardTags, tag._id),
    }
  ));

  const groupedOptions = [
    {
      label: 'Locations',
      options: locationsList,
    },
    {
      label: 'Teams',
      options: teamsList,
    },
    {
      label: 'Industries',
      options: industriesList,
    },
  ];

  return (
    <ReactSelect
      placeholder={placeholder}
      classNamePrefix="react-select"
      isMulti={false}
      value={null}
      options={groupedOptions}
      noOptionsMessage={() => 'You do not have admin rights for any tags'}
      formatGroupLabel={formatGroupLabel}
      onChange={option => handleTagAdd(option)}
    />
  );
};

TagsSelector.propTypes = {
  placeholder: PropTypes.string,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  addTag: PropTypes.func.isRequired,
};

TagsSelector.defaultProps = {
  placeholder: '',
};

export default TagsSelector;
