var React = require('react');
var { Helmet } = require('react-helmet');
var PropTypes = require('prop-types');
var indicative = require("indicative");

var Alert = require('../../components/alert.jsx').default;

var Indicative = new indicative();

class RequestForm extends React.Component {
    componentDidMount() {
        this.refs.emailAddress.focus();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.handleClick(this.refs.emailAddress.value.toLowerCase());
    };

    render() {
    return (
        <form onSubmit={this.handleSubmit}>
            <Helmet title="Password reset request" />
            <h3 className="text-center"><strong>Reset your password</strong></h3>
            <p className="text-center">Enter the email address you use to login to {this.props.orgName}:</p>
            <input type="text" className="input" placeholder="you@example.com" ref="emailAddress" />
            <br /><br />
            <p><button type="submit" className="button expanded">Send reset request</button></p>
            <br />
        </form>
    );
    }
}

class Confirmation extends React.Component {
    render() {
    return (
        <div>
            <h3 className="text-center"><strong>Password reset sent</strong></h3>
            <p className="text-center">Check your inbox for instructions on how to reset your password.</p>
            <br />
            <hr />
            <br />
            <p className="text-center">Email didn't arrive? <a href="/password-request">Click here</a> to try again</p>
            <br />
        </div>
    );
    }
}

class PasswordResetRequest extends React.PureComponent {
    state = { confirmation: false, updateAlert: false };

    componentDidMount() {
        if(this.props.location.query.err) {
            this.showUpdateAlert("Your reset link is expired or already used. Please request a new link below.");
        }
    }

    showUpdateAlert = (message) => {
        this.setState({
            updateAlert: true,
            updateMessage: message
        });
    };

    hideUpdateAlert = () => {
        this.setState({updateAlert: false});
    };

    handleRequestPasswordReset = (email) => {
        var emailAddress = email;

        if(Indicative.is.email(emailAddress)) {
            this.props.passwordResetRequest(emailAddress, this.props.organization.data);
            this.setState({confirmation: true});
        } else {
            this.showUpdateAlert("Invalid email format. Please check the email and try again.");
        }        
    };

    render() {
        var organization = this.props.organization.data;
        var isFetching = this.props.organization.isFetching;

        return (
            <div>
                <Alert 
                    name="alert_invalid"
                    type="alert callout text-center" 
                    message={this.state.updateMessage}
                    showAlert={this.state.updateAlert}
                    handleClose={this.hideUpdateAlert} />             
                <br />
                <br />
                <div className="row">
                    <div className="medium-10 medium-centered large-7 large-centered columns">
                        <div className="login-card">
                            <div className="medium-8 medium-centered columns">                     
                            {organization && !isFetching &&
                                this.state.confirmation 
                                ? <Confirmation /> 
                                : <RequestForm 
                                    handleClick={this.handleRequestPasswordReset} 
                                    orgName={organization.name} />
                            }
                            </div>
                        </div>                        
                        {isFetching &&
                            <h1 className="loading">Loading...</h1>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

PasswordResetRequest.propTypes = {
    passwordResetRequest: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired
};

export default PasswordResetRequest;
