import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import discussionsActions from './discussions';

const DeleteDiscussion = (function () {
  const _deleteDiscussionRequest = function () {
    return {
      type: constants.DELETE_DISCUSSION_REQUEST,
    };
  };

  const _deleteDiscussionSuccess = function (data) {
    return {
      type: constants.DELETE_DISCUSSION_SUCCESS,
      discussion: data,
    };
  };

  const _deleteDiscussionError = function (error) {
    return {
      type: constants.DELETE_DISCUSSION_ERROR,
      errors: error.data,
    };
  };

  const _deleteDiscussion = function (postItem, orgId, boardId, accessToken) {
    return function (dispatch) {
      dispatch(_deleteDiscussionRequest());
      api.delete(`/proxy/v1/discussions/${postItem.data._id}`, {
        orgId,
      }, accessToken)
        .then(() => {
          dispatch(discussionsActions.clearPost());
          dispatch(_deleteDiscussionSuccess(postItem.data._id));
          dispatch(routerActions.push(`/board/${boardId}`));
        })
        .catch((res) => {
          dispatch(_deleteDiscussionError(res));
        });
    };
  };

  return {
    deleteDiscussion: _deleteDiscussion,
  };
}());

export default DeleteDiscussion;
