import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateVibeActions from '../../../modules/vibes/actions/vibe-create';
import TagsActions from '../../../modules/tags/actions/tags';
import CreateVibe from '../components/create/createVibe.jsx';
import VibeActions from '../../../modules/vibes/actions/vibe';

    var mapStateToProps = function(state) {
        return {
            auth: state.auth,
            organization: state.organization,
            discussions: state.discussions,
            tags: state.tags,
            selectedVibe: state.selectedVibe,
            vibes: state.vibes,
        };
    };

    var mapDispatchToProps = function(dispatch) {
        return bindActionCreators(Object.assign({}, CreateVibeActions, VibeActions, TagsActions), dispatch);
    };

export default connect(mapStateToProps, mapDispatchToProps)(CreateVibe);
