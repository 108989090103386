import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgActions from '../../../modules/organizations/actions/organization';
import AuthActions from '../../../modules/auth/actions/auth-profile';
import PreviousRouteActions from '../../../modules/auth/actions/previousRoute';
import Auth from '../components/auth.jsx';

  var mapStateToProps = function(state){
	  return {
	    auth: state.auth,
      previousRoute: state.previousRoute,
	    organization: state.organization
	  };
  }

  var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, OrgActions, AuthActions, PreviousRouteActions), dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
