import { routerActions } from 'react-router-redux';
import cookie from 'react-cookie';
import tldjs from 'tldjs';
import constants from '../constants';

const tld = tldjs.fromUserSettings({
  validHosts: ['localhost'],
});

const Logout = (function () {
  const _logoutSuccess = function (data) {
    const domain = tld.getDomain(document.location.hostname);
    if (domain === 'localhost') {
      cookie.remove('tv_at', { path: '/' });
      cookie.remove('tv_rt', { path: '/' });
    } else {
      cookie.remove('tv_at', { domain: '.teamvibe.com', path: '/' });
      cookie.remove('tv_rt', { domain: '.teamvibe.com', path: '/' });
    }
    return {
      type: constants.AUTH_LOGOUT_SUCCESS,
    };
  };

  const _logout = function () {
    return function (dispatch) {
      dispatch(_logoutSuccess());
      dispatch(routerActions.push('/login'));
    };
  };

  return {
    logout: _logout,
  };
}());

export default Logout;
