import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import organizationActions from '../../organizations/actions/organization';

const UpdateVibe = (function () {
  const _updateVibeRequest = function () {
    return {
      type: constants.UPDATE_VIBE_REQUEST,
    };
  };

  const _updateVibeSuccess = function (data) {
    return {
      type: constants.UPDATE_VIBE_SUCCESS,
      vibe: data.vibe,
    };
  };

  const _updateVibeError = function (error) {
    return {
      type: constants.UPDATE_VIBE_ERROR,
      errors: error.data,
    };
  };

  const _updateVibe = function (vibe, vibeId, userId, organization, accessToken) {
    return function (dispatch) {
      dispatch(_updateVibeRequest());
      api.put(`/proxy/v1/vibes/${vibeId}`, {
        vibe,
        orgId: organization._id,
        orgVibes: organization.vibes,
        teamDomain: organization.teamDomain,
      }, accessToken)
        .then((res) => {
          dispatch(_updateVibeSuccess(res));

          if (res.organization) {
            // May be redundant with new checkUser version
            dispatch(organizationActions.updateOrganizationState(res.organization, 'nonPublic'));
          }

          // This shouldn't be needed once vibes list is pulled from orgs.vibes
          // Now sorting in the UPDATE_VIBE_SUCCESS action
          // dispatch(vibesActions.fetchVibes(res.organization, accessToken));

          // push in case friendlyUrl has changed on edit
          // Push no longer needed after going to boardId
          // dispatch(routerActions.push(`/board/${vibeId}#admin-settings`));
        })
        .catch((res) => {
          dispatch(_updateVibeError(res));
        });
    };
  };

  return {
    updateVibe: _updateVibe,
  };
}());

export default UpdateVibe;
