const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const _ = require('lodash');
const TagsActions = require('../../../modules/tags/actions/tags').default;

const checkTags = function (Component) {
  class CheckTagsComponent extends React.Component {
    componentDidMount() {
      this.setTags();
    }

    setTags = () => {
      const organization = this.props.organization.data;
      const { token } = this.props.auth;

      this.props.fetchTagsIfNeeded(organization._id, token);
    };

    render() {
      return (
        <div className="tag-data">
          {_.isArray(this.props.tags.data) ? <Component {...this.props} /> : null}
        </div>
      );
    }
  }

  CheckTagsComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
    fetchTagsIfNeeded: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
      tags: state.tags,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, TagsActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CheckTagsComponent);
};

export default checkTags;
