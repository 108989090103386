export default {
  REQUEST_VIBES: 'REQUEST_VIBES',
  RECEIVE_VIBES: 'RECEIVE_VIBES',
  REQUEST_VIBES_ERROR: 'REQUEST_VIBES_ERROR',
  REQUEST_VIBES_PUBLIC: 'REQUEST_VIBES_PUBLIC',
  RECEIVE_VIBES_PUBLIC: 'RECEIVE_VIBES_PUBLIC',
  REQUEST_VIBES_PUBLIC_ERROR: 'REQUEST_VIBES_PUBLIC_ERROR',
  REQUEST_VIBE: 'REQUEST_VIBE',
  RECEIVE_VIBE: 'RECEIVE_VIBE',
  RECEIVE_ORG_VIBE: 'RECEIVE_ORG_VIBE',
  REQUEST_VIBE_ERROR: 'REQUEST_VIBE_ERROR',
  SET_ACTIVE_VIBE_FILTER: 'SET_ACTIVE_VIBE_FILTER',
  REQUEST_MEMBERS: 'REQUEST_MEMBERS',
  RECEIVE_DIRECT_MEMBERS: 'RECEIVE_DIRECT_MEMBERS',
  RECEIVE_INHERITED_MEMBERS: 'RECEIVE_INHERITED_MEMBERS',
  REQUEST_MEMBERS_ERROR: 'REQUEST_MEMBERS_ERROR',
  CREATE_VIBE_REQUEST: 'CREATE_VIBE_REQUEST',
  CREATE_VIBE_SUCCESS: 'CREATE_VIBE_SUCCESS',
  CREATE_VIBE_ERROR: 'CREATE_VIBE_ERROR',
  UPDATE_VIBE_REQUEST: 'UPDATE_VIBE_REQUEST',
  UPDATE_VIBE_SUCCESS: 'UPDATE_VIBE_SUCCESS',
  UPDATE_VIBES_SUCCESS: 'UPDATE_VIBES_SUCCESS',
  UPDATE_VIBE_ERROR: 'UPDATE_VIBE_ERROR',
  APPROVE_VIBE_REQUEST: 'APPROVE_VIBE_REQUEST',
  APPROVE_VIBE_SUCCESS: 'APPROVE_VIBE_SUCCESS',
  APPROVE_VIBE_ERROR: 'APPROVE_VIBE_ERROR',
  REJECT_VIBE_REQUEST: 'REJECT_VIBE_REQUEST',
  REJECT_VIBE_SUCCESS: 'REJECT_VIBE_SUCCESS',
  REJECT_VIBE_ERROR: 'REJECT_VIBE_ERROR',
  VIBE_FOLLOW_REQUEST: 'VIBE_FOLLOW_REQUEST',
  VIBE_FOLLOW_SUCCESS: 'VIBE_FOLLOW_SUCCESS',
  VIBE_FOLLOW_ERROR: 'VIBE_FOLLOW_ERROR',
  VIBE_UNFOLLOW_REQUEST: 'VIBE_UNFOLLOW_REQUEST',
  VIBE_UNFOLLOW_SUCCESS: 'VIBE_UNFOLLOW_SUCCESS',
  VIBE_UNFOLLOW_ERROR: 'VIBE_UNFOLLOW_ERROR',
  DELETE_VIBE_REQUEST: 'DELETE_VIBE_REQUEST',
  DELETE_VIBE_SUCCESS: 'DELETE_VIBE_SUCCESS',
  DELETE_VIBE_ERROR: 'DELETE_VIBE_ERROR',
  EDIT_VIBE_USER_REQUEST: 'EDIT_VIBE_USER_REQUEST',
  EDIT_VIBE_USER_SUCCESS: 'EDIT_VIBE_USER_SUCCESS',
  EDIT_VIBE_USER_ERROR: 'EDIT_VIBE_USER_ERROR',
  REMOVE_VIBE_USER_REQUEST: 'REMOVE_VIBE_USER_REQUEST',
  REMOVE_VIBE_USER_SUCCESS: 'REMOVE_VIBE_USER_SUCCESS',
  REMOVE_VIBE_USER_ERROR: 'REMOVE_VIBE_USER_ERROR',
  REQUEST_CALLOUT_REMOVE: 'REQUEST_CALLOUT_REMOVE',
  ADD_VIBE_CHAMPION: 'ADD_VIBE_CHAMPION',
  REMOVE_VIBE_CHAMPION: 'REMOVE_VIBE_CHAMPION',
  SET_SELECTED_VIBE: 'SET_SELECTED_VIBE',
  CLEAR_SELECTED_VIBE: 'CLEAR_SELECTED_VIBE',
  ADD_VIBE_USERS_REQUEST: 'ADD_VIBE_USERS_REQUEST',
  ADD_VIBE_USERS_SUCCESS: 'ADD_VIBE_USERS_SUCCESS',
  ADD_VIBE_USERS_ERROR: 'ADD_VIBE_USERS_ERROR',
  CLEAR_SELECTED_VIBE_MESSAGE: 'CLEAR_SELECTED_VIBE_MESSAGE',
  CLEAR_VIBE_ALERT: 'CLEAR_VIBE_ALERT',
  UPDATE_VIBE_OVERVIEW_REQUEST: 'UPDATE_VIBE_OVERVIEW_REQUEST',
  UPDATE_VIBE_OVERVIEW_SUCCESS: 'UPDATE_VIBE_OVERVIEW_SUCCESS',
  UPDATE_VIBE_OVERVIEW_ERROR: 'UPDATE_VIBE_OVERVIEW_ERROR',
  SET_VIBES_ACTIVITY: 'SET_VIBES_ACTIVITY',
  REQUEST_VIBE_CHILDREN: 'REQUEST_VIBE_CHILDREN',
  RECEIVE_VIBE_CHILDREN: 'RECEIVE_VIBE_CHILDREN',
  REQUEST_VIBE_CHILDREN_ERROR: 'REQUEST_VIBE_CHILDREN_ERROR',
  APPROVE_ORG_VIBE: 'APPROVE_ORG_VIBE',
  RECEIVE_BOARD_BY_ID: 'RECEIVE_BOARD_BY_ID',
  RECEIVE_BOARD_FROM_STATE: 'RECEIVE_BOARD_FROM_STATE',
};
