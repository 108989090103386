const React = require('react');
const PropTypes = require('prop-types');

const CleanMarkdown = require('../../components/cleanMarkdown.jsx').default;

class MemberBio extends React.Component {
  state = {
    editBio: false,
    errors: {},
  };

  handleEditBio = () => {
    this.toggleEditBio(true);
  };

  handleSaveBio = () => {
    const { user } = this.props;
    this.props.editUserFull(user.data, this.props.organization.data._id, this.props.auth.token);
    this.toggleEditBio(false);
  };

  toggleEditBio = (val) => {
    this.setState({ editBio: val });
  };

  handleChange = (event) => {
    const { user } = this.props;

    if (this.state.errors[event.target.name]) {
      const currentState = this.state;
      delete currentState.errors[event.target.name];
      this.setState(currentState);
    }

    if (event.target) {
      user.data[event.target.name] = event.target.value;
    }
    this.props.setUser(user.data);
  };

  render() {
    const { user, viewingUserIsOrgAdmin, viewingUserIsTagAdmin } = this.props;
    const { data: { firstName = '', about = '' } } = user;
    const { editBio } = this.state;

    return (
      <div>
        {(editBio)
          ?
          <div>
            <button
              type="button"
              className="button float-right"
              title="Save bio"
              onClick={this.handleSaveBio}>
              <i className="fa fa-edit" /> Save bio
            </button>
            <h3 className="lead"><strong>{`About ${firstName}`}</strong></h3>
            <textarea className="textarea" name="about" rows="14" defaultValue={user.data.about} onChange={this.handleChange} />
          </div>
          :
          <div>
            {(viewingUserIsOrgAdmin || viewingUserIsTagAdmin ||
              (this.props.auth.user._id === user.data._id)) &&
              <button
                type="button"
                className="button hollow float-right"
                title="Edit bio"
                onClick={this.handleEditBio}>
                <i className="fa fa-edit" /> Edit bio
              </button>
            }
            <h3 className="lead"><strong>{`About ${firstName}`}</strong></h3>
            <p>
              {(user.data.about)
                ? <CleanMarkdown payload={about} />
                : <span><br />No user bio yet<br /></span>}
            </p>
          </div>}
        <br />
      </div>
    );
  }
}

MemberBio.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  viewingUserIsOrgAdmin: PropTypes.bool.isRequired,
  viewingUserIsTagAdmin: PropTypes.bool.isRequired,
  editUserFull: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default MemberBio;
