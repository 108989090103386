/**
 * NOTE: These actions are unused
 */
 
import constants from '../constants';
import utils from '../../../utils';

const Mentions = (function () {
  const _upsertMentionRequest = function (mentions) {
    return {
      type: constants.UPSERT_MENTION,
      mentions,
    };
  };

  const _deleteMentionRequest = function (mentions) {
    return {
      type: constants.DELETE_MENTION,
      mentions,
    };
  };

  const _cleanMentionsRequest = function () {
    return {
      type: constants.CLEAN_MENTIONS,
    };
  };

  const _upsertMention = function (mention) {
    return function (dispatch, getState) {
      let { mentions } = getState();
      mentions = utils.upsert(mentions, mention._id, mention);
      return dispatch(_upsertMentionRequest(mentions));
    };
  };

  const _deleteMention = function (mention) {
    return function (dispatch, getState) {
      let { mentions } = getState();
      mentions = utils.remove(mentions, mention._id);
      return dispatch(_deleteMentionRequest(mentions));
    };
  };

  const _cleanMentions = function () {
    return function (dispatch) {
      dispatch(_cleanMentionsRequest());
    };
  };

  return {
    upsertMention: _upsertMention,
    deleteMention: _deleteMention,
    cleanMentions: _cleanMentions,
  };
}());

export default Mentions;
