import _ from 'lodash';

import constants from '../constants';

const VibeChildren = (function () {
  const _requestVibeChildren = function () {
    return {
      type: constants.REQUEST_VIBE_CHILDREN,
    };
  };

  const _receiveVibeChildren = function (data) {
    return {
      type: constants.RECEIVE_VIBE_CHILDREN,
      data,
    };
  };

  // 'Fetching' each time since there is no API call involved
  const _fetchVibeChildren = function (vibes, parentId) {
    return function (dispatch) {
      dispatch(_requestVibeChildren());

      const childVibes = _.filter(
        vibes,
        child => child.orgOptions && child.orgOptions.isChild && child.orgOptions.parent === parentId,
      );
      if (childVibes) {
        dispatch(_receiveVibeChildren(childVibes));
      }
    };
  };

  return {
    fetchVibeChildren: _fetchVibeChildren,
  };
}());

export default VibeChildren;
