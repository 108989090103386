import constants from '../constants';
import utils from '../../../utils';

const initialState = {
  isFetching: true,
  closeModal: false,
  data: {},
  error: {},
};

const organization = function (state = initialState, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_ORGANIZATION:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_ORGANIZATION:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.viewMode = action.viewMode;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_ORGANIZATION_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_ORGANIZATION_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.CREATE_ORGANIZATION_SUCCESS:
    // Need to clear org state on create so invite can re-fetch new org
    return {
      ...state,
      isFetching: false,
      data: {},
      error: {},
    };
  case constants.CREATE_ORGANIZATION_ERROR:
    return {
      ...state,
      isFetching: false,
      error: action.errors,
    };
  case constants.EDIT_ORGANIZATION_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.organization.organization;
    newstate.error = {};
    return newstate;
  case constants.REMOVE_ORGANIZATION_USER_SUCCESS:
    newstate.members.isFetching = false;
    newstate.members.data = utils.remove(state.members.data, action.user._id);
    return newstate;
  case constants.REMOVE_ORGANIZATION_USER_ERROR:
    newstate.members.isFetching = false;
    newstate.members.error = action.errors;
    return newstate;
  case constants.EDIT_ORGANIZATION_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.organization;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_ORGANIZATION_MEMBERS:
    newstate.members.isFetching = true;
    return newstate;
  case constants.RECEIVE_ORGANIZATION_MEMBERS:
    newstate.members.isFetching = false;
    newstate.members.data = action.data;
    return newstate;
  case constants.REQUEST_ORGANIZATION_MEMBERS_ERROR:
    newstate.members.isFetching = false;
    newstate.members.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.REMOVE_ORGANIZATION_VIBE:
    newstate.isFetching = false;
    newstate.data.vibes = utils.remove(state.data.vibes, action.vibeId);
    newstate.error = {};
    return newstate;
  case constants.RECEIVE_ORGANIZATION_VIBES:
    newstate.isFetching = false;
    newstate.data.vibes = action.data;
    newstate.error = {};
    return newstate;
  case constants.CREATE_ORGANIZATION_VIBE:
    newstate.isFetching = false;
    newstate.data.vibes = utils.unshift(state.data.vibes, action.vibe);
    return newstate;
  case constants.UPDATE_USER_ORG_SUCCESS:
    newstate.isFetching = false;
    newstate.members.data = utils.upsert(newstate.members.data, action.user._id, action.user);
    newstate.user = action.user;
    newstate.error = {};
    return newstate;
  case constants.UPDATE_USER_ORG_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.APPROVE_ORGVIBE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.APPROVE_ORGVIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.organization;
    return newstate;
  case constants.APPROVE_ORGVIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.REJECT_ORGVIBE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.REJECT_ORGVIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data.vibes = utils.remove(state.data.vibes, action.vibeId);
    return newstate;
  case constants.REJECT_ORGVIBE_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  default: return state || initialState.organization;
  }
};

export default organization;
