import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const invitations = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_INVITATIONS:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_INVITATIONS:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_INVITATIONS_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.DELETE_INVITATION_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.invitationId);
    newstate.closeInputModal = true;
    return newstate;
  default:
    return state || initialState.invitations;
  }
};

export default invitations;
