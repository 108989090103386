import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MembersActions from '../../../../modules/vibes/actions/vibe-members';
import userWidget from '../components/userWidget.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        selectedVibe: state.selectedVibe,
        invitation: state.invitation
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, MembersActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(userWidget);
