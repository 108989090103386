import constants from '../constants';
import api from '../../../utils/api';
import vibeChampionActions from './vibe-champions';

const RemoveVibeUser = (function () {
  const _removeVibeUserRequest = function () {
    return {
      type: constants.REMOVE_VIBE_USER_REQUEST,
    };
  };

  const _removeVibeUserSuccess = function (data) {
    return {
      type: constants.REMOVE_VIBE_USER_SUCCESS,
      userId: data.user._id,
    };
  };

  const _removeVibeUserError = function (error) {
    return {
      type: constants.REMOVE_VIBE_USER_ERROR,
      errors: error.data,
    };
  };

  const _removeVibeUser = function (userId, vibeId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_removeVibeUserRequest());
      api.put(`/proxy/v1/users/${userId}/vibes/${vibeId}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(vibeChampionActions.removeChampionFromVibe(userId, vibeId));
          dispatch(_removeVibeUserSuccess(res));
        })
        .catch((res) => {
          dispatch(_removeVibeUserError(res));
        });
    };
  };

  const _removeUserFromSelectedVibe = function (userId) {
    return {
      type: constants.REMOVE_VIBE_USER_SUCCESS,
      userId,
    };
  };

  return {
    removeVibeUser: _removeVibeUser,
    removeUserFromSelectedVibe: _removeUserFromSelectedVibe,
  };
}());

export default RemoveVibeUser;
