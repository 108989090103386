import { routerActions } from 'react-router-redux';
import _ from 'lodash';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const Events = (function () {
  const _requestEvents = function () {
    return {
      type: constants.REQUEST_EVENTS,
    };
  };

  const _receiveEvents = function (data) {
    return {
      type: constants.RECEIVE_EVENTS,
      events: data.events,
    };
  };

  const _fetchEventsError = function (error) {
    return {
      type: constants.REQUEST_EVENTS_ERROR,
      errors: error.data,
    };
  };

  const _setFilteredEvents = function (data) {
    return {
      type: constants.RECEIVE_EVENTS,
      events: data,
    };
  };

  const _filterEventsByUserVibes = function (events, vibes) {
    return function (dispatch) {
      const lookup = _.indexBy(vibes, vibe => vibe._id._id);

      const filteredEvents = _.filter(events, event => lookup[event.vibe._id] !== undefined);
      return dispatch(_setFilteredEvents(filteredEvents));
    };
  };

  const _fetchEvents = function (vibeId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestEvents());
      api.get(`/proxy/v1/vibes/${vibeId}/events`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveEvents(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchEventsError(res));
          }
        });
    };
  };

  const _fetchUpcomingEvents = function (vibeId, limit, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestEvents());
      api.get(`/proxy/v1/vibes/${vibeId}/events`, {
        limit,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveEvents(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchEventsError(res));
          }
        });
    };
  };

  const _fetchOrgEvents = function (orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestEvents());
      api.get(`/proxy/v1/organization/${orgId}/events`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveEvents(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchEventsError(res));
          }
        });
    };
  };

  const _fetchUpcomingOrgEvents = function (orgId, limit, accessToken) {
    return function (dispatch) {
      dispatch(_requestEvents());
      api.get(`/proxy/v1/organization/${orgId}/events/upcoming`, {
        limit,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveEvents(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchEventsError(res));
          }
        });
    };
  };

  const _shouldFetchEvents = function (vibeId, state) {
    const events = state.events.data;
    if (Object.keys(events).length === 0) {
      return true;
    } else if (events[0].vibe._id !== vibeId) {
      return true;
    }
    return false;
  };

  const _fetchEventsIfNeeded = function (vibeId, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchEvents(vibeId, getState())) {
        return dispatch(_fetchEvents(vibeId, orgId, accessToken));
      }
      return false;
    };
  };

  const _fetchUpcomingEventsIfNeeded = function (vibeId, limit, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchEvents(vibeId, getState())) {
        return dispatch(_fetchUpcomingEvents(vibeId, limit, orgId, accessToken));
      }
      return false;
    };
  };

  const _setSelectedEvent = function (event) {
    return {
      type: constants.SET_SELECTED_EVENT,
      event,
    };
  };

  const _clearSelectedEvent = function () {
    return {
      type: constants.CLEAR_SELECTED_EVENT,
    };
  };

  const _setActiveEventFilter = function (intervalStart, intervalEnd) {
    return {
      type: constants.SET_ACTIVE_EVENT_FILTER,
      intervalStart,
      intervalEnd,
    };
  };

  const _setActiveEventTypeFilter = function (filterType) {
    return {
      type: constants.SET_ACTIVE_EVENT_TYPE_FILTER,
      filterType,
    };
  };

  return {
    fetchEvents: _fetchEvents,
    fetchEventsIfNeeded: _fetchEventsIfNeeded,
    fetchUpcomingEvents: _fetchUpcomingEvents,
    fetchUpcomingEventsIfNeeded: _fetchUpcomingEventsIfNeeded,
    fetchOrgEvents: _fetchOrgEvents,
    fetchUpcomingOrgEvents: _fetchUpcomingOrgEvents,
    setSelectedEvent: _setSelectedEvent,
    clearSelectedEvent: _clearSelectedEvent,
    setActiveEventFilter: _setActiveEventFilter,
    setActiveEventTypeFilter: _setActiveEventTypeFilter,
    filterEventsByUserVibes: _filterEventsByUserVibes,
  };
}());

export default Events;
