import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VibeCalendar from '../components/vibeCalendar.jsx';
import EventsList from '../components/eventsList.jsx';
import EventsActions from '../../../modules/events/actions/events';
import ChangeRSVPActions from '../../../modules/events/actions/changeRsvp';
import VibeActions from '../../../modules/vibes/actions/vibe';
import JoinVibeActions from '../../../modules/auth/actions/joinVibe';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        events: state.events,
        selectedEvent: state.selectedEvent,
        selectedVibe: state.selectedVibe,
        activeEventFilter: state.activeEventFilter,
        vibes: state.vibes,
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, EventsActions, ChangeRSVPActions, VibeActions, JoinVibeActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VibeCalendar, EventsList);
