const React = require('react');
const PropTypes = require('prop-types');
const { withRouter, Link } = require('react-router');
const Tour = require('reactour').default;
const Emoji = require('a11y-react-emoji').default;
const Masonry = require('react-masonry-component');
const moment = require('moment');
const _ = require('lodash');

const PostTileItem = require('./postTileItem.jsx').default;
const DiscussionInputModal = require('../../../components/discussions/containers/discussionInputContainer.jsx').default;
const EventInputModal = require('../../../components/events/containers/eventInputContainer.jsx').default;
const PostModal = require('../containers/postModalContainer.jsx').default;
const EventsWidgetItem = require('../../../components/events/components/eventsWidgetItem.jsx').default;
const utils = require('../../../../../common/utils').default;

const masonryOptions = {
  itemSelector: '.item',
  columnWidth: '.post-sizer',
  gutter: 15,
  // horizontalOrder: true,
  // transitionDuration: '0.4s',
  stagger: '0.03s',
  stamp: '.stamp',
  // isFitWidth: true
};

class PostTileList extends React.PureComponent {
  state = {
    inputModalIsOpen: false,
    eventInputModalIsOpen: false,
    postModalIsOpen: false,
    floatMenuIsOpen: false,
    eventInputMode: 'create',
    inputMode: 'create',
    inputType: 'discussion',
    isTourOpen: false,
  };

  componentDidMount() {
    const { organization, auth, selectedVibe } = this.props;
    const { data: { _id: orgId } } = organization;
    const { token } = auth;
    const { data: { _id: boardId } } = selectedVibe;
    this.props.fetchDiscussionsIfNeeded(
      boardId, orgId, token,
    );
    this.props.fetchUpcomingEventsIfNeeded(
      boardId, 10, orgId, token,
    );

    // if(this.masonry) {
    // }

    if (this.props.postId) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }

    if (this.props.location && this.props.location.query.new === 'true') {
      this.setState({ isTourOpen: true });
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedVibe.data._id !== this.props.selectedVibe.data._id) {
      nextProps.fetchDiscussionsIfNeeded(nextProps.selectedVibe.data._id, nextProps.organization.data._id, nextProps.auth.token);
      nextProps.fetchUpcomingEventsIfNeeded(
        nextProps.selectedVibe.data._id,
        5, nextProps.organization.data._id,
        nextProps.auth.token,
      );
    }

    if (nextProps.postId) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }
  }

  componentDidUpdate() {
    const { selectedEvent } = this.props;
    const { closeModal: closeEventModal } = selectedEvent;
    if (closeEventModal === true) {
      this.closeEventInputModal();
    }
  }

  componentWillUnmount() {
    // Need to clearSelectedVibe for stamp (eventsList) to show when switching vibes
    // this.props.clearSelectedVibe();
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleLayoutComplete = () => {
    // console.log("layoutComplete");
  };

  handleImagesLoaded = (laidOutItems) => {
    // console.log("HERE");
  };

  handleClickOutside = (event) => {
    if (this.state.floatMenuIsOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ floatMenuIsOpen: false });
    }
  };

  openInputModal = (inputMode, inputType) => {
    this.setState({
      floatMenuIsOpen: false,
      inputModalIsOpen: true,
      inputMode,
      inputType,
    });
  };

  closeInputModal = () => {
    this.setState({ inputModalIsOpen: false });
  };

  openEventInputModal = (mode) => {
    if (mode === 'create') { this.props.clearSelectedEvent(); }
    const eventItem = this.props.selectedEvent;

    // Set vibe to current vibe
    eventItem.data.vibe = this.props.selectedVibe.data;

    this.props.setSelectedEvent(eventItem.data);
    this.setState({
      floatMenuIsOpen: false,
      eventInputModalIsOpen: true,
      eventInputMode: mode,
    });
  };

  closeEventInputModal = () => {
    this.props.clearSelectedEvent();
    this.setState({ eventInputModalIsOpen: false });
  };

  openPostModal = () => {
    this.setState({ postModalIsOpen: true });
  };

  closePostModal = () => {
    const { selectedVibe: { data: { _id: boardId } } } = this.props;
    this.setState({ postModalIsOpen: false });
    this.props.router.push(`/board/${boardId}`);
  };

  toggleFloatMenu = () => {
    const { floatMenuIsOpen } = this.state;
    this.setState({ floatMenuIsOpen: !floatMenuIsOpen, isTourOpen: false });
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  }

  openTour = () => {
    this.setState({ isTourOpen: true });
  }

  handleJoinVibeClick = () => {
    const userIsVibeMember = utils.userIsVibeMember(
      this.props.auth.user.vibes,
      this.props.selectedVibe.data._id,
    );

    if (!userIsVibeMember) {
      this.props.joinVibe(
        this.props.auth.user._id, this.props.selectedVibe.data._id, false,
        this.props.organization.data._id, this.props.auth.token,
      );
    }
  };

  render() {
    const { organization, selectedVibe, tags, subscriptions } = this.props;
    const { data: organizationData } = organization;
    const { data: boardData } = selectedVibe;
    const { isFetching: boardIsFetching, data: { _id: boardId, orgOptions = {}, memberPosting } } = selectedVibe;
    const { preferences: { allowBoardCreate = true } } = organizationData;
    const { data: tagsData } = tags;
    const { data: subscriptionsData } = subscriptions;

    const discussions = this.props.discussions.data || [];
    const postsIsFetching = this.props.discussions.isFetching;
    const events = this.props.events.data || [];
    const eventsIsFetching = this.props.events.isFetching;

    let eventsList = [];

    const userIsVibeChampion = utils.userIsVibeChampion(this.props.auth.user.vibes, boardId);
    const userIsOrgAdmin = utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs);
    const userIsTagAdmin = utils.userIsTagAdmin(this.props.auth.user._id, orgOptions.tags, tagsData);

    const tourSteps = [
      {
        selector: '#float-menu-toggle',
        style: { maxWidth: 'initial', width: '400px' },
        content: function DemoHelperComponent() {
          return (
            <div>
              <Emoji symbol="🚀" style={{ fontSize: '44px' }} />
              <Emoji symbol="🚀" style={{ fontSize: '44px' }} />
              <Emoji symbol="🚀" style={{ fontSize: '44px' }} />
              <h5>Your new {(orgOptions && orgOptions.isChild) && 'sub-'}board has been created!</h5>
              <p>Now it{"'"}s time to add some content by clicking on the blue &quot;plus&quot; menu.</p>
              <br />
            </div>
          );
        },
      },
    ];

    // Loading
    if (boardIsFetching || postsIsFetching || eventsIsFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    // Post list
    const postList = _.map(discussions, (discussion) => {
      const alert = utils.checkSubscriptions('post', discussion, subscriptionsData);
      return (
        <PostTileItem
          key={discussion._id}
          organization={this.props.organization}
          auth={this.props.auth}
          selectedVibe={selectedVibe}
          userIsVibeChampion={userIsVibeChampion}
          userIsOrgAdmin={userIsOrgAdmin}
          discussion={discussion}
          alert={alert}
          createdAt={(discussion.meta && discussion.meta.latestPost) ? discussion.meta.latestPost : discussion.createdAt}
          openPostModal={this.openPostModal}
          editDiscussion={this.props.editDiscussion} />
      );
    });

    // Events list
    if (!eventsIsFetching) {
      let pastEvent = null;
      eventsList = _.filter(events, event =>
        moment().isSameOrBefore(moment(event.startDate), 'day')).map((event, i) => {
        let sameDate = false;
        const startDate = moment(event.startDate).format('DD/MM/YYYY');
        if (pastEvent === startDate) {
          sameDate = true;
        }
        pastEvent = moment(event.startDate).format('DD/MM/YYYY');

        return (<EventsWidgetItem
          key={event._id}
          itemKey={i}
          eventItem={event}
          selectedEvent={this.props.selectedEvent}
          sameDate={sameDate}
          vibe={this.props.vibe}
          auth={this.props.auth}
          organization={this.props.organization}
          joinVibe={this.props.joinVibe}
          changeRSVP={this.props.changeRSVP}
          setSelectedEvent={this.props.setSelectedEvent}
          clearSelectedEvent={this.props.clearSelectedEvent}
          openEventInputModal={this.openEventInputModal} />);
      });
    }

    // var masonryClass = ((discussions).length >= 4) ? "float-center" : "";
    const masonryClass = 'float-center';

    return (
      <div className="row columns">
        <PostModal
          postId={this.props.postId}
          postModalIsOpen={this.state.postModalIsOpen}
          closePostModal={this.closePostModal}
          openInputModal={this.openInputModal}
          closeInputModal={this.closeInputModal} />

        {/* Tour */}
        <Tour
          steps={tourSteps}
          isOpen={this.state.isTourOpen}
          rounded={5}
          showNumber={false}
          showButtons={false}
          showNavigation={false}
          onRequestClose={this.closeTour} />

        {/* <Editor /> */}

        {/* For editing posts/events via postTileList */}
        {(userIsVibeChampion || userIsOrgAdmin || userIsTagAdmin ||
          memberPosting) ?
          <div>
            <DiscussionInputModal
              type={this.state.inputType}
              mode={this.state.inputMode}
              auth={this.props.auth}
              organization={this.props.organization}
              selectedVibe={selectedVibe}
              modalIsOpen={this.state.inputModalIsOpen}
              closeModal={this.closeInputModal} />

            <EventInputModal
              mode={this.state.eventInputMode}
              inputModalIsOpen={this.state.eventInputModalIsOpen}
              closeInputModal={this.closeEventInputModal}
              selectedVibe={selectedVibe} />
          </div>
          : null}

        <div className="row column">
          <Masonry
            // ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={masonryClass}
            options={masonryOptions}
            // onImagesLoaded={this.handleImagesLoaded}
            // onLayoutComplete={function(laidOutItems) {return this.handleLayoutComplete(laidOutItems)}.bind(this)}
          >
            {/* .grid-sizer empty element, only used for element sizing */}
            <div className="post-sizer" />

            {/* .tile-events for upcoming events widget */}
            {(Object.keys(eventsList).length !== 0) &&
            <div className="tile-events item tile-widget">

              <div className="tile-events-header">
                <span>Upcoming events</span>
                <img
                  className="float-right"
                  src="https://res.cloudinary.com/teamvibe/image/upload/v1532932610/product/icon-events.gif"
                  alt="Events" />
              </div>
              <div className="post-tile-content">
                {eventsList}
              </div>
            </div>}

            {(Object.keys(discussions).length !== 0) ?
              postList
              :
              <div className="post-tile no-posts item">
                <br />
                <br />
                <p className="text-center">No current posts</p>
                {((userIsVibeChampion || userIsOrgAdmin) || memberPosting) &&
                  <p className="text-center">
                    <span>Click the blue button in the bottom right to add something</span>
                  </p>}
                <br />
                <br />
              </div>}
          </Masonry>
        </div>
      </div>
    );
  }
}

PostTileList.propTypes = {
  router: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  discussions: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchDiscussionsIfNeeded: PropTypes.func.isRequired,
  fetchUpcomingEventsIfNeeded: PropTypes.func.isRequired,
  changeRSVP: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  postId: PropTypes.string,
};

export default withRouter(PostTileList);
