
export default {
  REGISTRATION_ERROR: 'REGISTRATION_ERROR',
  CLEAR_REGISTRATION_ERROR: 'CLEAR_REGISTRATION_ERROR',
  REQUEST_PASSWORD_RESET_REQUEST: 'REQUEST_PASSWORD_RESET_REQUEST',
  REQUEST_PASSWORD_RESET_SUCCESS: 'REQUEST_PASSWORD_RESET_SUCCESS',
  REQUEST_PASSWORD_RESET_ERROR: 'REQUEST_PASSWORD_RESET_ERROR',
  PASSWORD_RESET_VALIDATION_REQUEST: 'PASSWORD_RESET_VALIDATION_REQUEST',
  PASSWORD_RESET_VALIDATION_SUCCESS: 'PASSWORD_RESET_VALIDATION_SUCCESS',
  PASSWORD_RESET_VALIDATION_ERROR: 'PASSWORD_RESET_VALIDATION_ERROR',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR: 'PASSWORD_RESET_ERROR',
};
