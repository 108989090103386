import constants from '../constants';
import api from '../../../utils/api';
import vibeActions from '../../vibes/actions/vibe';

const ApproveOrgVibe = (function () {
  const _approveOrgVibeRequest = function () {
    return { type: constants.APPROVE_ORGVIBE_REQUEST };
  };

  const _approveOrgVibeSuccess = function (data) {
    return {
      type: constants.APPROVE_ORGVIBE_SUCCESS,
      organization: data.organization,
    };
  };

  const _approveOrgVibeError = function (error) {
    return {
      type: constants.APPROVE_ORGVIBE_ERROR,
      errors: error.data,
    };
  };

  const _approveOrgVibe = function (selectedVibe, orgId, accessToken) {
    const vibeId = selectedVibe._id;
    return function (dispatch, getState) {
      dispatch(_approveOrgVibeRequest());
      api.put(`/proxy/v1/organizations/${orgId}/vibes/${vibeId}/approval`, {
        isApproved: true,
        orgId,
        title: selectedVibe.title,
        champion: selectedVibe.champion,
      }, accessToken)
        .then((res) => {
          const state = getState();

          // Update selectedVibe state with approval
          const selectedVibe = state.selectedVibe.data;
          selectedVibe.orgOptions.isApproved = true;
          dispatch(vibeActions.approveVibe(selectedVibe));

          // Update org
          dispatch(_approveOrgVibeSuccess(res));
        })
        .catch((res) => {
          dispatch(_approveOrgVibeError(res));
        });
    };
  };

  return { approveOrgVibe: _approveOrgVibe };
}());

export default ApproveOrgVibe;
