import TagSettings from '../tagsSettings';

const React = require('react');
const PropTypes = require('prop-types');
const { Helmet } = require('react-helmet');

const utils = require('../../../utils').default;
const Alert = require('../../components/alert.jsx').default;

class PlatformSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({ updateAlert: false });
  }

  handleHover = () => {
    this.setState({
      logoHover: !this.state.logoHover,
    });
  };

  showUpdateAlert = () => {
    this.setState({ updateAlert: true });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  handleUploadCompanyClick = (event) => {
    event.preventDefault();

    const options = {
      folder: this.props.organization.data.teamDomain,
    };

    utils.uploadImage(options, (results) => {
      if (results) {
        this.refs.orgLogo.src = results.secure_url;
        this.refs.companyLogo.value = results.secure_url;
      }
    });
  };

  handleUploadExecutiveClick = (event) => {
    event.preventDefault();

    const options = {
      folder: this.props.organization.data.teamDomain,
    };

    utils.uploadImage(options, (results) => {
      if (results) {
        this.refs.execImage.src = utils.formatImage(results.secure_url, 'ctaPhoto');
        this.refs.ctaImage.value = results.secure_url;
      }
    });
  };

  handleSaveSettings = () => {
    const organization = this.props.organization.data;

    const updatedOrg = {};
    // Add check to prevent blank logo URLs
    if (this.refs.companyLogo.value) { updatedOrg.logo = this.refs.companyLogo.value; }

    updatedOrg.name = this.refs.name.value;
    updatedOrg.contactInfo = {
      email: this.refs.email.value,
    };

    // Set preferences
    const preferences = (organization.preferences) ? organization.preferences : {};
    preferences.allowBoardCreate = this.refs.switch_allowBoardCreate.checked;
    preferences.digestGlobalEnabled = this.refs.switch_digest.checked;
    preferences.notifyPostReplies = this.refs.switch_notifyPostReplies.checked;

    preferences.publicView = this.refs.switch_publicView.checked;
    preferences.allowAnyEmail = this.refs.switch_allowAnyEmail.checked;
    // preferences.showVibeHeaderImage = this.refs.switch_showVibeHeaderImage.checked;

    updatedOrg.preferences = preferences;

    // Set call to action (all vars are present so can use complete object)
    const callToAction = {
      title: this.refs.title.value,
      message: this.refs.message.value,
      image: this.refs.ctaImage.value,
      linkText: this.refs.linkText.value,
      linkUrl: this.refs.linkUrl.value,
      isActive: this.refs.switch_announcement.checked,
    };
    updatedOrg.callToAction = callToAction;

    this.props.editOrganization(
      updatedOrg,
      organization._id,
      this.props.auth.token,
    );

    // Old tags save. Now saving on create/edit or drop
    // var tags = [];
    // $('.vibe-tags').each(function(i, items_list){
    //     $(items_list).find('li a').each(function(j, li){
    //         var tag = {
    //             type: $(this).attr('data-tag-type'),
    //             displayName: $(this).attr('data-tag-value'),
    //         };
    //         if($(this).hasClass("tag-on")) {
    //             tag.isActive = true;
    //         } else {
    //             tag.isActive = false;
    //         }
    //         if($(this).attr('data-tag-id')) {
    //             var id = $(this).attr('data-tag-id');
    //             if(id.indexOf('new') === -1) {
    //                 tag._id = $(this).attr('data-tag-id');
    //             }
    //         }
    //         if($(this).attr('data-tag-sort')) {
    //             tag.sort = $(this).attr('data-tag-sort');
    //         }
    //         tags.push(tag);
    //     });
    // });

    // if(tags.length > 0) {
    //     this.props.saveTags(tags, organization._id, this.props.auth.token);
    // }
    this.showUpdateAlert();
  };

  render() {
    const organization = this.props.organization.data;
    const { isFetching: isFetchingTags } = this.props.tags;

    // Loading
    if (isFetchingTags) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    const logoClass = this.state.logoHover ? 'hover' : '';

    return (
      <div className="row column">
        <Helmet title="Platform settings" />
        <Alert
          type="success callout text-center"
          message="Org settings updated successfully"
          showAlert={this.state.updateAlert}
          handleClose={this.hideUpdateAlert} />

        <div className="callout hide">
          <div className="row">
            <div className="large-8 columns">
              {/* Featured announcement */}
              <div className="clearfix">
                <div className="float-left"><h4><strong>Featured announcement</strong></h4></div>
                <div className="switch float-left margin-left-15">
                  {(organization.callToAction && organization.callToAction.isActive)
                    ? <input className="switch-input" id="switch-announcement" ref="switch_announcement" type="checkbox" defaultChecked />
                    : <input className="switch-input" id="switch-announcement" ref="switch_announcement" type="checkbox" />}
                  <label className="switch-paddle" htmlFor="switch-announcement">
                    <span className="switch-active" aria-hidden="true">On</span>
                    <span className="switch-inactive" aria-hidden="true">Off</span>
                  </label>
                </div>
              </div>
              <label><strong>Message title</strong> <small>(max 100 characters)</small>
                <input ref="title" className="input" type="text" defaultValue={organization.callToAction ? organization.callToAction.title : ''} />
              </label>
              <label><strong>Message body</strong> <small>(max 300 characters)</small>
                <textarea id="message" className="textarea" ref="message" rows="5" defaultValue={organization.callToAction ? organization.callToAction.message : ''} />
              </label>
              <div className="row">
                <div className="large-6 columns">
                  <label><strong>Link text</strong>
                    <input ref="linkText" className="input" type="text" defaultValue={organization.callToAction ? organization.callToAction.linkText : ''} />
                  </label>
                </div>
                <div className="large-6 columns">
                  <label><strong>Link URL</strong> <small>(external links must start with http or https)</small>
                    <input ref="linkUrl" className="input" type="url" defaultValue={organization.callToAction ? organization.callToAction.linkUrl : ''} />
                  </label>
                </div>
              </div>
            </div>
            <div className="large-4 columns">
              {/* Featured image */}
              <h4>&nbsp;</h4>
              <label><strong>Featured image</strong></label>
              <div className={`upload-img ${logoClass}`} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                {(organization.callToAction && organization.callToAction.image) ?
                  <img ref="execImage" src={utils.formatImage(organization.callToAction.image, 'ctaPhoto')} alt={organization.name} className="image-feature" /> :
                  <img ref="execImage" src="//placehold.it/200x200" alt="placeholder" className="image-feature" />}
                <div className="overlay">
                  <a href={null} className="expand" onClick={this.handleUploadExecutiveClick}>Upload a featured image <i className="fi-upload" /></a>
                </div>
              </div>
              <input type="hidden" ref="ctaImage" defaultValue={organization.callToAction ? organization.callToAction.image : ''} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="large-8 columns">
            {/* Company name, email, & logo */}
            <h4><strong>Company details</strong></h4>
            <div className="callout">
              <div className="row">
                <div className="large-8 columns">
                  {/* Company details */}
                  <label>
                    <strong>Company name</strong> <small>(max 100 characters)</small>
                    <input ref="name" className="input" type="text" defaultValue={organization.name} />
                  </label>
                  <br /><br />
                  <label>
                    <strong>Main contact email</strong>
                    <input ref="email" className="input" type="email" defaultValue={organization.contactInfo ? organization.contactInfo.email : ''} />
                  </label>
                </div>
                <div className="large-4 columns">
                  {/* Company images */}
                  <h4>&nbsp;</h4>
                  <label><strong>Company logo</strong></label>
                  <div className={`upload-img ${logoClass}`} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    {organization.logo ?
                      <img ref="orgLogo" src={organization.logo} alt={organization.name} className="global-settings-logo" /> :
                      <img ref="orgLogo" src="//placehold.it/300x200" alt="placeholder" className="global-settings-logo" />}
                    <div className="overlay">
                      <a href={null} className="expand" onClick={this.handleUploadCompanyClick}>Upload a logo <i className="fi-upload" /></a>
                    </div>
                  </div>
                  <input type="hidden" ref="companyLogo" defaultValue={organization.logo}/>
                </div>
              </div>
            </div>

            <TagSettings />
          </div>
          <div className="large-4 columns">

            {/* Org options */}
            <h4><strong>Org options</strong></h4>
            <div className="callout">
              <div className="row column">
                {/* Remove once setting is live */}
                <input
                  type="hidden"
                  ref="showPublicVibes"
                  value={organization.preferences.hasOwnProperty("showPublicVibes") ? organization.preferences.showPublicVibes : false} />

                <table>
                  <tbody>
                    {/* Allow board create */}
                    <tr>
                      <td width="200"><label><strong>Users can create boards</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-allowBoardCreate"
                            ref="switch_allowBoardCreate"
                            type="checkbox"
                            defaultChecked={organization.preferences.hasOwnProperty("allowBoardCreate") ? organization.preferences.allowBoardCreate : true} />
                          <label className="switch-paddle" htmlFor="switch-allowBoardCreate">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    {/* Email digests (global) */}
                    <tr>
                      <td width="200"><label><strong>Email digests</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-digest"
                            ref="switch_digest"
                            type="checkbox"
                            defaultChecked={(organization.preferences.hasOwnProperty("digestGlobalEnabled")) ? organization.preferences.digestGlobalEnabled : true} />
                          <label className="switch-paddle" htmlFor="switch-digest">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    {/* Notify post replies (global) */}
                    <tr>
                      <td width="200"><label><strong>Reply notifications</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-notifyPostReplies"
                            ref="switch_notifyPostReplies"
                            type="checkbox"
                            defaultChecked={organization.preferences.hasOwnProperty("notifyPostReplies") ? organization.preferences.notifyPostReplies : false} />
                          <label className="switch-paddle" htmlFor="switch-notifyPostReplies">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table>
                  <tbody>
                    {/* Enable public view */}
                    <tr>
                      <td width="200"><label><strong>Enable public view</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-publicView"
                            ref="switch_publicView"
                            type="checkbox"
                            defaultChecked={organization.preferences.hasOwnProperty("publicView") ? organization.preferences.publicView : false} />
                          <label className="switch-paddle" htmlFor="switch-publicView">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    {/* Show public vibes */}
                    {/* <tr>
                      <td width="200"><label><strong>Show public vibes</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input className="switch-input" id="switch-showPublicVibes" ref="switch_showPublicVibes" type="checkbox" defaultChecked={organization.preferences.hasOwnProperty("showPublicVibes") ? organization.preferences.showPublicVibes : false}/>
                          <label className="switch-paddle" htmlFor="switch-showPublicVibes">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    */}
                    {/* Allow any email on invites */}
                    <tr>
                      <td width="200"><label><strong>Allow any email domain</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-allowAnyEmail"
                            ref="switch_allowAnyEmail"
                            type="checkbox"
                            defaultChecked={organization.preferences.hasOwnProperty("allowAnyEmail") ? organization.preferences.allowAnyEmail : false} />
                          <label className="switch-paddle" htmlFor="switch-allowAnyEmail">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    {/* Vibe header images */}
                    {/* <tr>
                      <td width="200"><label><strong>Vibe header images</strong></label></td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-showVibeHeaderImage"
                            ref="switch_showVibeHeaderImage"
                            type="checkbox"
                            defaultChecked={organization.preferences.hasOwnProperty("showVibeHeaderImage") ? organization.preferences.showVibeHeaderImage : true} />
                          <label className="switch-paddle" htmlFor="switch-showVibeHeaderImage">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Update platform settings button */}
        <div className="row columns">
          <br />
          <div className="text-center">
            <button onClick={this.handleSaveSettings} className="button large">Save settings</button>
          </div>
        </div>
      </div>
    );
  }
}

PlatformSettings.propTypes = {
  fetchTagsIfNeeded: PropTypes.func.isRequired,
  editOrganization: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  addTag: PropTypes.func.isRequired,
  saveTags: PropTypes.func.isRequired,
  sortTags: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default PlatformSettings;
