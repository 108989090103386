import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrganizationActions from '../../../modules/organizations/actions/organization-create';
import InviteActions from '../../../modules/invitations/actions/get-invitation';
import OrgCreate from '../components/orgCreate.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        invitation: state.invitation
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, OrganizationActions, InviteActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgCreate);
