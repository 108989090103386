import constants from '../constants';
import utils from '../../../utils';

const initialState = {
  isFetching: true,
  closeModal: false,
  data: {},
  error: {},
};

const discussion = function (state = initialState, action) {
  let newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_REPLIES:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_REPLIES:
    newstate.isFetching = false;
    newstate.data = action.discussion;
    newstate.data.replies = action.replies;
    newstate.clearEditor = false;
    return newstate;
  case constants.REQUEST_REPLIES_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_REPLY_SUCCESS:
    newstate.isFetching = false;
    newstate.data.replies = state.data.replies.concat(action.reply.discussion);
    newstate.clearEditor = true;
    return newstate;
  case constants.CREATE_POST_SUCCESS:
    return {
      ...state,
      isFetching: false,
      closeModal: true,
    };
  case constants.CREATE_CROSS_POST_SUCCESS:
    newstate.isFetching = false;
    return initialState.discussion;
  case constants.CREATE_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.EDIT_POST_SUCCESS:
    newstate.isFetching = false;
    return newstate;
  case constants.MOVE_POST_SUCCESS:
    newstate.isFetching = false;
    newstate.closeModal = true;
    return newstate;
  case constants.EDIT_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.EDIT_REPLY_SUCCESS:
    newstate.isFetching = false;
    newstate.data.replies = utils.upsert(state.data.replies, action.reply.discussion._id, action.reply.discussion);
    newstate.closeModal = true;
    return newstate;
  case constants.EDIT_REPLY_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.DELETE_DISCUSSION_SUCCESS:
    return { ...state, ...initialState };
  case constants.DELETE_REPLY_SUCCESS:
    newstate.isFetching = false;
    newstate.data.replies = utils.remove(state.data.replies, action.reply._id);
    newstate.closeModal = true;
    return newstate;
  case constants.SET_SELECTED_DISCUSSION:
    newstate.isFetching = false;
    newstate.data = action.discussion;
    return newstate;
  case constants.REACT_DISCUSSION_SUCCESS:
    newstate.data = action.discussion.discussion;
    newstate.data.replies = state.data.replies;
    return newstate;
  case constants.REACT_REPLY_SUCCESS:
    newstate.data.replies = utils.upsert(state.data.replies, action.reply._id, action.reply);
    return newstate;
  case constants.CLEAR_DISCUSSION:
    newstate.data = {};
    newstate.error = {};
    return newstate;
  case constants.CLEAR_POST:
    return { ...state, ...initialState };
  default: return state || initialState.discussion;
  }
};

export default discussion;
