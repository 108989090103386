
export default {
  AUTH_LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  LOGIN_COOKIE_REQUEST: 'LOGIN_COOKIE_REQUEST',
  LOGIN_COOKIE_SUCCESS: 'LOGIN_COOKIE_SUCCESS',
  LOGIN_COOKIE_ERROR: 'LOGIN_COOKIE_ERROR',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
  SET_AUTH_COOKIE: 'SET_AUTH_COOKIE',
  AUTH_CREATE_PROFILE_REQUEST: 'AUTH_CREATE_PROFILE_REQUEST',
  AUTH_CREATE_PROFILE_SUCCESS: 'AUTH_CREATE_PROFILE_SUCCESS',
  AUTH_CREATE_PROFILE_ERROR: 'AUTH_CREATE_PROFILE_ERROR',
  JOIN_ORG_REQUEST: 'JOIN_ORG_REQUEST',
  JOIN_ORG_SUCCESS: 'JOIN_ORG_SUCCESS',
  JOIN_ORG_ERROR: 'JOIN_ORG_ERROR',
  REQUEST_CURRENT_USER: 'REQUEST_CURRENT_USER',
  RECEIVE_CURRENT_USER: 'RECEIVE_CURRENT_USER',
  REQUEST_CURRENT_USER_ERROR: 'REQUEST_CURRENT_USER_ERROR',
  CLEAR_AUTH_LOGIN_ERROR: 'CLEAR_AUTH_LOGIN_ERROR',
  CLEAR_CREATE_PROFILE_ERROR: 'CLEAR_CREATE_PROFILE_ERROR',
  JOIN_VIBE_REQUEST: 'JOIN_VIBE_REQUEST',
  JOIN_VIBE_SUCCESS: 'JOIN_VIBE_SUCCESS',
  JOIN_VIBE_ERROR: 'JOIN_VIBE_ERROR',
  LEAVE_VIBE_REQUEST: 'LEAVE_VIBE_REQUEST',
  LEAVE_VIBE_SUCCESS: 'LEAVE_VIBE_SUCCESS',
  LEAVE_VIBE_ERROR: 'LEAVE_VIBE_ERROR',
  VISIT_VIBE_REQUEST: 'VISIT_VIBE_REQUEST',
  VISIT_VIBE_SUCCESS: 'VISIT_VIBE_SUCCESS',
  VISIT_VIBE_ERROR: 'VISIT_VIBE_ERROR',
  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_OTHER_USER_SUCCESS: 'EDIT_OTHER_USER_SUCCESS',
  EDIT_USER_ERROR: 'EDIT_USER_ERROR',
  UPDATE_USER_OPTIONS_SUCCESS: 'UPDATE_USER_OPTIONS_SUCCESS',
  UPDATE_USER_OPTIONS_ERROR: 'UPDATE_USER_OPTIONS_ERROR',
  PREVIOUS_ROUTE_SET: 'PREVIOUS_ROUTE_SET',
  ADD_SOCIAL_IDENTITY_REQUEST: 'ADD_SOCIAL_IDENTITY_REQUEST',
  ADD_SOCIAL_IDENTITY_SUCCESS: 'ADD_SOCIAL_IDENTITY_SUCCESS',
  ADD_SOCIAL_IDENTITY_FAILURE: 'ADD_SOCIAL_IDENTITY_FAILURE',
  REMOVE_SOCIAL_IDENTITY_REQUEST: 'REMOVE_SOCIAL_IDENTITY_REQUEST',
  REMOVE_SOCIAL_IDENTITY_SUCCESS: 'REMOVE_SOCIAL_IDENTITY_SUCCESS',
  REMOVE_SOCIAL_IDENTITY_FAILURE: 'REMOVE_SOCIAL_IDENTITY_FAILURE',
};
