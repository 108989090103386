import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VibeActions from '../../../../modules/vibes/actions/vibe';
import AddVibeUsersActions from '../../../../modules/vibes/actions/addVibeUsers';
import emailUserModal from '../components/emailUserModal.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        selectedVibe: state.selectedVibe
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, VibeActions, AddVibeUsersActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(emailUserModal);
