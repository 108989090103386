import constants from '../constants';
import api from '../../../utils/api';

const RemoveOrganizationUser = (function () {
  const _removeOrganizationUserRequest = function () {
    return {
      type: constants.REMOVE_ORGANIZATION_USER_REQUEST,
    };
  };

  const _removeOrganizationUserSuccess = function (data) {
    return {
      type: constants.REMOVE_ORGANIZATION_USER_SUCCESS,
      user: data.user,
    };
  };

  const _removeOrganizationUserError = function (error) {
    return {
      type: constants.REMOVE_ORGANIZATION_USER_ERROR,
      errors: error.data,
    };
  };

  const _removeOrganizationUser = function (userId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_removeOrganizationUserRequest());
      api.delete(`/proxy/v1/users/${userId}/org/${orgId}`, { orgId }, accessToken)
        .then((res) => {
          dispatch(_removeOrganizationUserSuccess(res));
        })
        .catch((res) => {
          dispatch(_removeOrganizationUserError(res));
        });
    };
  };

  return {
    removeOrganizationUser: _removeOrganizationUser,
  };
}());

export default RemoveOrganizationUser;
