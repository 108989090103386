var React = require('react');
var { Helmet } = require('react-helmet');

class NotFound extends React.Component {
  render() {
    return (
        <div className="wrapper">
            <Helmet title="Page Not Found" />
            <div>
               Bummer - we couldnt find that.
            </div>
        </div>
    )
  }
}

export default NotFound;