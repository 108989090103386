import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorPage from '../components/errorPage.jsx';

  var mapStateToProps = function(state){
    return {
      auth: state.auth,
      organization: state.organization,
      vibes: state.organization
    };
  }

  var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}), dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
