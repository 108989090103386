import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgCalendar from '../components/orgCalendar.jsx';
import EventsActions from '../../../modules/events/actions/events';
import ChangeRSVPActions from '../../../modules/events/actions/changeRsvp';
import EventsList from '../../events/components/eventsList.jsx';
import VibeActions from '../../../modules/vibes/actions/vibe';
import JoinVibeActions from '../../../modules/auth/actions/joinVibe';
import SocialIdentityActions from '../../../modules/auth/actions/auth-social-identity';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        events: state.events,
        selectedEvent: state.selectedEvent,
        activeEventFilter: state.activeEventFilter
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, EventsActions, ChangeRSVPActions, VibeActions, JoinVibeActions, SocialIdentityActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgCalendar, EventsList);
