export default {
  REQUEST_DISCUSSIONS: 'REQUEST_DISCUSSIONS',
  RECEIVE_DISCUSSIONS: 'RECEIVE_DISCUSSIONS',
  APPEND_DISCUSSIONS: 'APPEND_DISCUSSIONS',
  REQUEST_DISCUSSIONS_ERROR: 'REQUEST_DISCUSSIONS_ERROR',
  REQUEST_REPLIES: 'REQUEST_REPLIES',
  RECEIVE_REPLIES: 'RECEIVE_REPLIES',
  REQUEST_REPLIES_ERROR: 'REQUEST_REPLIES_ERROR',
  CREATE_REPLY_REQUEST: 'CREATE_REPLY_REQUEST',
  CREATE_REPLY_SUCCESS: 'CREATE_REPLY_SUCCESS',
  CREATE_REPLY_ERROR: 'CREATE_REPLY_ERROR',
  CREATE_POST_REQUEST: 'CREATE_POST_REQUEST',
  CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  CREATE_CROSS_POST_SUCCESS: 'CREATE_CROSS_POST_SUCCESS',
  CREATE_POST_ERROR: 'CREATE_POST_ERROR',
  CLEAR_POST_ERROR: 'CLEAR_POST_ERROR',
  EDIT_POST_REQUEST: 'EDIT_POST_REQUEST',
  EDIT_POST_SUCCESS: 'EDIT_POST_SUCCESS',
  MOVE_POST_SUCCESS: 'MOVE_POST_SUCCESS',
  EDIT_POST_ERROR: 'EDIT_POST_ERROR',
  DELETE_DISCUSSION_REQUEST: 'DELETE_DISCUSSION_REQUEST',
  DELETE_DISCUSSION_SUCCESS: 'DELETE_DISCUSSION_SUCCESS',
  DELETE_DISCUSSION_ERROR: 'DELETE_DISCUSSION_ERROR',
  SET_SELECTED_DISCUSSION: 'SET_SELECTED_DISCUSSION',
  EDIT_REPLY_REQUEST: 'EDIT_REPLY_REQUEST',
  EDIT_REPLY_SUCCESS: 'EDIT_REPLY_SUCCESS',
  EDIT_REPLY_ERROR: 'EDIT_REPLY_ERROR',
  DELETE_REPLY_REQUEST: 'DELETE_REPLY_REQUEST',
  DELETE_REPLY_SUCCESS: 'DELETE_REPLY_SUCCESS',
  DELETE_REPLY_ERROR: 'DELETE_REPLY_ERROR',
  REACT_DISCUSSION_REQUEST: 'REACT_DISCUSSION_REQUEST',
  REACT_DISCUSSION_SUCCESS: 'REACT_DISCUSSION_SUCCESS',
  REACT_DISCUSSION_ERROR: 'REACT_DISCUSSION_ERROR',
  REACT_REPLY_REQUEST: 'REACT_REPLY_REQUEST',
  REACT_REPLY_SUCCESS: 'REACT_REPLY_SUCCESS',
  REACT_REPLY_ERROR: 'REACT_REPLY_ERROR',
  SET_POST: 'SET_POST',
  CLEAR_POST: 'CLEAR_POST',
  SET_POST_ERROR: 'SET_POST_ERROR',
  CLEAR_DISCUSSION: 'CLEAR_DISCUSSION',
};
