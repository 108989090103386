import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthActions from '../../../modules/auth/actions/auth-login';
import PreviousRouteActions from '../../../modules/auth/actions/previousRoute';
import Login from '../components/login.jsx';

  var mapStateToProps = function(state){
    return {
      auth: state.auth,
      previousRoute: state.previousRoute,
      organization: state.organization
    };
  }

  var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, AuthActions, PreviousRouteActions), dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Login);
