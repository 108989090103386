import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Survey from '../components/survey.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    selectedVibe: state.selectedVibe,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    // SurveyActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
