import constants from '../constants';
import api from '../../../utils/api';

const DeleteEvent = (function () {
  const _deleteEventRequest = function () {
    return {
      type: constants.DELETE_EVENT_REQUEST,
    };
  };

  const _deleteEventSuccess = function (data) {
    return {
      type: constants.DELETE_EVENT_SUCCESS,
      event: data,
    };
  };

  const _deleteEventError = function (error) {
    return {
      type: constants.DELETE_EVENT_ERROR,
      errors: error.data,
    };
  };

  const _deleteEvent = function (event, userId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_deleteEventRequest());
      api.delete(`/proxy/v1/events/${event._id}`, {
        userId,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_deleteEventSuccess(event._id));
        })
        .catch((res) => {
          dispatch(_deleteEventError(res));
        });
    };
  };

  return {
    deleteEvent: _deleteEvent,
  };
}());

export default DeleteEvent;
