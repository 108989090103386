var React = require('react');
var PropTypes = require('prop-types');

class SocialButton extends React.Component {
  render() {
    return ( 
      <a href={this.props.redirect} className={"large expanded button social " + this.props.name.toLowerCase() + " " + this.props.visibility}>{this.props.text}<i className={"fi-social-" + this.props.name.toLowerCase()} /></a>
    )
  }
}

SocialButton.propTypes = {
  name: PropTypes.string.isRequired,
  visibility: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default SocialButton;