const React = require('react');
const { withRouter, Link } = require('react-router');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const { DateTime } = require('luxon');
const _ = require('lodash');

const utils = require('../../../utils').default;
const Alert = require('../../components/alert.jsx').default;
const ProfileForm = require('../../components/profileForm.jsx').default;

class OrgJoin extends React.PureComponent {
  componentDidMount() {
    const orgId = this.props.organization.data._id;

    // currentUser should always be fetched in checkToken HOC
    // this.props.fetchCurrentUserIfNeeded(this.props.organization.data._id, this.props.auth.token);

    // tags should always be fetched in checkTags HOC
    // this.props.fetchTagsIfNeeded(this.props.organization.data._id, this.props.auth.token);

    // LinkedIn login will clear this.props.invitation. Need to re-fetch invite.
    this.props.fetchInvitationIfNeeded(this.props.location.query.invite);

    // If org is lost, don't let them create an account on 'start'
    if (this.props.organization.data.name === 'Start') {
      this.props.router.push(`/invite/${this.props.location.query.invite}`);
    }

    // If user is already a member of the org, let them right in
    // Removed this to not redirect pre-registered members
    // Might be able to check for isPending here though
    /*
    const { user } = this.props.auth || {};
    if (user) {
      const userOrgIds = _.map(user.orgs, 'id');
      if (_.includes(userOrgIds, orgId)) {
        this.props.router.push('/');
      }
    } */
  }

  handleCreateProfile = (userData, userOrgData) => {
    const { data: organizationData } = this.props.organization;
    const { user } = this.props.auth;
    const { data: invitation } = this.props.invitation;
    const { presetTeams, presetVibes, sender = null } = invitation;
    const timeZone = (DateTime.local()).zoneName;
    userData.timeZone = timeZone;

    // Add preset vibes to top-level user
    if (presetVibes.length > 0) {
      const formatedVibesList = _.map(presetVibes, vibe => ({ vibe }));
      userData.vibes = _.unionBy(userData.vibes, formatedVibesList, 'vibe');
    }

    // Assemble userOrg
    userOrgData.id = organizationData._id;
    userOrgData.teamDomain = organizationData.teamDomain;
    userOrgData.orgName = organizationData.name;
    userOrgData.invitedBy = sender;

    // Add preset teams to userOrgData tags
    if (presetTeams.length > 0) {
      // No need to unionBy since userOrg object will always be new
      userOrgData.tags = _.union(userOrgData.tags, presetTeams);
    }

    this.props.joinOrg(
      user._id, organizationData._id, organizationData.logo,
      userData, userOrgData,
      this.props.location.query.invite, this.props.invitation.data.inviteType,
      this.props.auth.token,
    );

    // Old way of adding user/org
    // this.props.createProfile(
    //   newUser, this.props.auth.token,
    //   this.props.organization.data, externalOrg,
    //   this.props.invitation.data,
    // );
  };

  render() {
    const { data: organization } = this.props.organization;
    const { _id: orgId } = organization;
    const { isFetching: isFetchingUser, user: authUser, error: authError } = this.props.auth;
    const { isFetching: isFetchingTags, data: orgTags } = this.props.tags;
    const { isFetching: isFetchingInvitation, data: invitation } = this.props.invitation;
    // socialProfile feeds title & externalOrg; firstName, lastName, & photoUrl are set on userCreate
    const socialProfile = authUser.linkedIn;

    // Loading
    if (isFetchingUser || isFetchingTags || isFetchingInvitation) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    // Check for existing user/org
    const { orgs = [] } = authUser;
    const currentOrg = orgs.find((userOrg) => userOrg.id === orgId) || {};

    // Find if pre-set user had location tag already (need to merge userTags with orgTags)
    const userTags = utils.getUserTags(currentOrg, orgTags);
    const locationTag = userTags.find((tag) => tag.tagType === 'location');
    const presetLocationsArray = [];

    if (locationTag) {
      presetLocationsArray.push(locationTag._id);
    } else {
      const { presetLocations } = invitation;
      presetLocationsArray.push(...presetLocations);
    }

    // Custom onboard imagery
    const { imagery: { onboard2 = 'https://res.cloudinary.com/teamvibe/image/upload/v1470114452/product/header-quicken.jpg' } = {} } = organization;

    return (
      <div>
        <Helmet title="Profile Details" />
        <Alert
          name="alert_error"
          type="alert callout text-center"
          message={authError.message}
          showAlert={(Object.keys(authError).length !== 0)}
          handleClose={this.props.clearAuthError} />

        <div className="full-screen full-height">
          <div className="fs-panel">
            <br />
            { organization.hasOwnProperty('logo') && <img className="logo-feature" src={organization.logo} alt="Company logo" /> }
            <div className="row align-center">
              <div className="medium-10 medium-offset-1 columns end">
                { (Object.keys(invitation).length > 0) ?
                  <div>
                    { (organization._id === invitation.orgId) ?
                      <div>
                        <br /><br />
                        <h2>Complete your profile for <strong>{organization.name}</strong></h2>
                        <ProfileForm
                          createProfile={this.handleCreateProfile}
                          buttonText={`Join ${organization.name}`}
                          imageFolder={organization.teamDomain}
                          organizationData={organization}
                          tags={orgTags}
                          presetLocations={presetLocationsArray}
                          socialProfile={socialProfile}
                          authUser={authUser} />
                      </div>
                      :
                      <div>
                        <br /><br />
                        <h5>It looks like you using an invitation intended for another organization.</h5>
                        <br />
                        <h5>Try the link in your email invitation again or contact your administrator for a fresh invite.</h5>
                      </div>
                    }
                  </div>
                  :
                  <div>
                    <br /><br />
                    <h5>Unfortunately it looks like you have an invalid or expired invitation.</h5>
                    <br />
                    <h5>
                      You may need to <Link to="/sign-up">request a new invite</Link>
                      &nbsp;using your organization email address or contact
                      &nbsp;your administrator for a fresh invite.
                    </h5>
                    <br />
                    <h5>If you are creating a new organization, you can <Link to="/get-started">start over here</Link>.</h5>
                  </div>
                }                  
              </div>
            </div>

            <br /><br /><br /><br />
          </div>
          <div
            className="hide-for-small-only fs-panel"
            style={{ background: `#1dadda url(${onboard2}) center center/cover` }}>
            {/* This div is left empty since it is filled by the background image */}
          </div>
        </div>
      </div>
    );
  }
}

OrgJoin.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchCurrentUserIfNeeded: PropTypes.func.isRequired,
  fetchTagsIfNeeded: PropTypes.func.isRequired,
  fetchInvitationIfNeeded: PropTypes.func.isRequired,
  // editUserFull: PropTypes.func.isRequired,
  // createUserOrg: PropTypes.func.isRequired,
  joinOrg: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
};

export default withRouter(OrgJoin);
