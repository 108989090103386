import { routerActions } from 'react-router-redux';
import cookie from 'react-cookie';
import tldjs from 'tldjs';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from './auth-logout';
import previousRouteActions from './previousRoute';
import loginUtils from '../utils/LoginUtils';

const tld = tldjs.fromUserSettings({
  validHosts: ['localhost'],
});

const Login = (function () {
  const _setCookie = function (data) {
    let domain = '';
    if (typeof document !== 'undefined') {
      domain = tld.getDomain(document.location.hostname);
    }

    // const now = new Date();
    // const expires = now.setSeconds(now.getSeconds() + data.expires_in);
    const atExpiration = 86400; // 1 day
    const rtExpiration = 31536000; // 1 year

    if (domain === 'localhost') {
      cookie.save('tv_at', { accessToken: data.access_token }, { path: '/', maxAge: atExpiration });
      cookie.save('tv_rt', { refreshToken: data.refresh_token }, { path: '/', maxAge: rtExpiration });
    } else {
      cookie.save('tv_at', { accessToken: data.access_token }, { domain: '.teamvibe.com', path: '/', maxAge: atExpiration });
      cookie.save('tv_rt', { refreshToken: data.refresh_token }, { domain: '.teamvibe.com', path: '/', maxAge: rtExpiration });
    }

    return {
      type: constants.SET_AUTH_COOKIE,
    };
  };

  const _loginRequest = function () {
    return {
      type: constants.AUTH_LOGIN_REQUEST,
    };
  };

  const _loginSuccess = function (data) {
    const now = new Date();
    const expires = now.setSeconds(now.getSeconds() + data.expires_in);

    return {
      type: constants.AUTH_LOGIN_SUCCESS,
      token: data.access_token,
      expires,
    };
  };

  const _loginError = function (error) {
    return {
      type: constants.AUTH_LOGIN_FAILURE,
      errors: error.data,
    };
  };

  const _loginCookieRequest = function () {
    return {
      type: constants.LOGIN_COOKIE_REQUEST,
    };
  };

  const _loginCookieSuccess = function (accessToken) {
    return {
      type: constants.LOGIN_COOKIE_SUCCESS,
      token: accessToken,
    };
  };

  // const _loginCookieError = function (error) {
  //   return {
  //     type: constants.LOGIN_COOKIE_ERROR,
  //     errors: error.data,
  //   };
  // };

  const _refreshTokenRequest = function () {
    return {
      type: constants.REFRESH_TOKEN_REQUEST,
    };
  };

  const _refreshTokenSuccess = function (data) {
    const now = new Date();
    const expires = now.setSeconds(now.getSeconds() + data.expires_in);

    return {
      type: constants.REFRESH_TOKEN_SUCCESS,
      token: data.access_token,
      expires,
    };
  };

  const _refreshTokenError = function (error) {
    return {
      type: constants.REFRESH_TOKEN_ERROR,
      errors: error,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.AUTH_LOGIN_FAILURE,
      errors,
    };
  };

  const _clearAuthError = function () {
    return {
      type: constants.CLEAR_AUTH_LOGIN_ERROR,
    };
  };

  const _login = function (email, password, previousRoute, teamDomain) {
    return function (dispatch) {
      loginUtils.validate(email, password)
        .then((result) => {
          if (result === true) {
            dispatch(_loginRequest());
            api.post('/proxy/oauth/token', {
              username: email,
              password,
              teamDomain,
              // orgId: org._id,
              grant_type: 'password',
            })
              .then((data) => {
                dispatch(_setCookie(data));
                dispatch(_loginSuccess(data));

                if (previousRoute) {
                  dispatch(previousRouteActions.setPreviousRoute(null));
                  dispatch(routerActions.push(previousRoute));
                } else {
                  dispatch(routerActions.push('/'));
                }
              })
              .catch((res) => {
                dispatch(_loginError(res.response));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  const _loginViaCookie = function (accessToken) {
    return function (dispatch) {
      dispatch(_loginCookieRequest());
      dispatch(_loginCookieSuccess(accessToken));
    };
  };

  const _refreshToken = function (refreshToken) {
    return function (dispatch) {
      dispatch(_refreshTokenRequest());
      api.post('/proxy/oauth/token', {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      })
        .then((data) => {
          dispatch(_setCookie(data));
          dispatch(_refreshTokenSuccess(data));
        })
        .catch((res) => {
          let err = {};
          if (res.response) {
            err = res.response;
          } else {
            err.error_description = res;
          }
          dispatch(_refreshTokenError(err));
          dispatch(authLogout.logout());
        });
    };
  };

  return {
    login: _login,
    loginViaCookie: _loginViaCookie,
    refreshToken: _refreshToken,
    loginSuccess: _loginSuccess,
    loginError: _loginError,
    setCookie: _setCookie,
    clearAuthError: _clearAuthError,
  };
}());

export default Login;
