export default {
  REQUEST_USER_DETAILS: 'REQUEST_USER_DETAILS',
  RECEIVE_USER_DETAILS: 'RECEIVE_USER_DETAILS',
  REQUEST_USER_DETAILS_ERROR: 'REQUEST_USER_DETAILS_ERROR',
  SET_USER: 'SET_USER',
  SET_USER_FILTER: 'SET_USER_FILTER',
  CLEAR_USER_FILTER: 'CLEAR_USER_FILTER',
  REQUEST_GROUP_EMAIL: 'REQUEST_GROUP_EMAIL',
  GROUP_EMAIL_SUCCESS: 'GROUP_EMAIL_SUCCESS',
  GROUP_EMAIL_ERROR: 'GROUP_EMAIL_ERROR',
  CLEAR_GROUP_EMAIL: 'CLEAR_GROUP_EMAIL',
};
