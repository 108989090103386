import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscussionsActions from '../../../../modules/discussions/actions/discussions';
import DiscussionsList from '../components/discussionsList.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        discussions: state.discussions,
        discussion: state.discussion,
        post: state.post
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, DiscussionsActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionsList);
