import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgMembers from '../components/orgMembers.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        selectedVibe: state.selectedVibe
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembers);