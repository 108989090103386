import UserGrid from '../userGrid';

const React = require('react');
const PropTypes = require('prop-types');
const { Link } = require('react-router');
const _ = require('lodash');
const UserCard = require('./userCard.jsx').default;
const UserInvite = require('../containers/userInvite.jsx').default;
const VibeInviteModal = require('../containers/vibeInviteModal.jsx').default;
const Alert = require('../../alert.jsx').default;
const TagFilters = require('../../tags/components/tagFilters.jsx').default;
const utils = require('../../../../../common/utils').default;

class UserList extends React.Component {
  state = {
    updateAlert: false,
    modalIsOpen: false,
    vibeModalIsOpen: false,
    showFilters: false,
  };

  componentDidMount() {
    const { mode, selectedVibe, auth } = this.props;
    const { topParentBoard, fetchTagMembers } = this.props;
    const { data: { _id: boardId, orgOptions = {} } } = selectedVibe;
    const { accessLevel, tags = [] } = orgOptions;
    if (mode === 'org') {
      this.props.fetchOrganizationMembers(this.props.organization.data._id, this.props.auth.token);
    } else if (mode === 'vibe') {
      const { _id: topParentId, tags: topParentTags = [] } = topParentBoard;
      const dynamicTags = (accessLevel === 'inherit') ? topParentTags : tags;

      // Get direct members first
      this.props.fetchMembersIfNeeded(
        boardId, this.props.organization.data._id, 'direct', auth.token,
      );

      // Get inherited members (if inherit)
      if (accessLevel === 'inherit') {
        this.props.fetchMembersIfNeeded(
          topParentId, this.props.organization.data._id, 'inherit', auth.token,
        );
      }

      // Get tag members
      fetchTagMembers(
        dynamicTags, auth.token,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedVibe, mode, auth } = this.props;
    const { topParentBoard, fetchTagMembers } = this.props;
    const { data: { _id: boardId, orgOptions = {} } = {} } = selectedVibe;
    const { accessLevel, tags = [] } = orgOptions;

    if (prevProps.selectedVibe.data._id !== boardId) {
      if (mode === 'org') {
        this.props.fetchOrganizationMembers(this.props.organization.data._id, this.props.auth.token);
      } else if (mode === 'vibe') {
        const { _id: topParentId, tags: topParentTags = [] } = topParentBoard;
        const dynamicTags = (accessLevel === 'inherit') ? topParentTags : tags;

        // Get direct members first
        this.props.fetchMembersIfNeeded(
          boardId, this.props.organization.data._id, 'direct', auth.token,
        );

        // Get inherited members (if inherit)
        if (topParentId && accessLevel === 'inherit') {
          this.props.fetchMembersIfNeeded(
            topParentId, this.props.organization.data._id, 'inherit', auth.token,
          );
        }

        // Get tag members
        fetchTagMembers(dynamicTags, auth.token);
      }
    }
  }

  showUpdateAlert = (message) => {
    this.setState({
      updateAlert: true,
      calloutMessage: message,
    });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openVibeModal = () => {
    this.setState({ vibeModalIsOpen: true });
  };

  closeVibeModal = () => {
    this.setState({ vibeModalIsOpen: false });
  };

  toggleFilters = () => {
    if (this.state.showFilters) {
      this.props.clearUserFilter();
      this.setState({ showFilters: false });
    } else {
      this.setState({ showFilters: true });
    }
  };

  render() {
    const userId = this.props.auth.user._id;
    const organization = this.props.organization.data;
    const orgId = this.props.organization.data._id;
    const vibes = this.props.vibes.data;
    const tags = this.props.tags.data;
    const { mode, userFilter, selectedVibe, board } = this.props;
    const { user } = this.props.auth;

    const { data: { title: boardTitle } } = selectedVibe;

    if (mode === 'org') {
      let users = this.props.organization.members.data;
      const { isFetching } = this.props.organization.members;
      const { isFetching: isFetchingBoard } = board;

      // Loading
      if (isFetching || isFetchingBoard) {
        return (
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        );
      }

      const viewerIsOrgAdmin = utils.userIsOrgAdmin(organization._id, this.props.auth.user.orgs);

      // Pass full tags list to see if user is a tag admin of any orgTag
      const tagIds = _.map(tags, '_id');
      const viewerIsTagAdmin = utils.userIsTagAdmin(this.props.auth.user._id, tagIds, tags);

      // Filter lists for globalAdmin or tagAdmin
      const teamsList = _.filter(tags, { tagType: 'team', isActive: true });
      let locationsList = [];
      let boardList = [];
      if (viewerIsOrgAdmin) {
        boardList = _.orderBy(vibes, 'title');
        locationsList = _.filter(tags, { tagType: 'location', isActive: true });
      } else {
        // List of boards filtered by admin & champion
        boardList = _.orderBy(utils.filterBoardsByAdmin(vibes, user, organization, tags), 'title') || [];

        locationsList = _.filter(tags, tag => (
          tag.tagType === 'location' &&
          tag.isActive === true &&
          _.includes(tag.tagAdmin, userId)
        ));
      }

      // Check if use can add members
      const userCanInviteMembers = (viewerIsOrgAdmin || (viewerIsTagAdmin && locationsList.length > 0)) || false;

      // Removed disabled users from user array if not admin
      if (viewerIsOrgAdmin === false) {
        users = users.filter(user => utils.userIsOrgActive(this.props.organization.data._id, user.orgs));
      }

      if (userFilter.tags && userFilter.tags.length > 0) {
        users = _.filter(users, (user) => {
          // Filter for current org
          const userOrg = _.find(user.orgs, { id: orgId }) || {};
          const combinedTags = _.union(userOrg.tags, userOrg.teams);

          if (userFilter.filterType === 'ALL') {
            return (_.difference(userFilter.tags, combinedTags).length === 0);
          }

          return (_.intersection(userFilter.tags, combinedTags).length !== 0);
        });
      }

      return (
        <div className="row column">
          {/* Alert for user widget changes */}
          <Alert
            type="success callout text-center"
            message={this.state.calloutMessage}
            showAlert={this.state.updateAlert}
            handleClose={this.hideUpdateAlert} />

          {(userCanInviteMembers && mode === 'org') &&
          <UserInvite
            organization={this.props.organization}
            auth={this.props.auth}
            locationsList={locationsList}
            teamsList={teamsList}
            vibesList={boardList}
            viewerIsOrgAdmin={viewerIsOrgAdmin}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={this.closeModal} />
          }

          <div className="callout">
            <div className="row">
              <div className="small-12 medium-8 columns">
                <h4><strong>{this.props.organization.data.name} members</strong> <small>({users.length})</small></h4>
              </div>
              <div className="small-12 medium-4 columns text-right">
                <button
                  type="button"
                  className={(this.state.showFilters) ? 'button hollow' : 'button'}
                  data-open="user-add"
                  onClick={this.toggleFilters}><i className="fa fa-filter" /> Filters
                </button>
                {(userCanInviteMembers && mode === 'org') &&
                  <span>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="button"
                      data-open="user-add"
                      onClick={this.openModal}><i className="fi-plus" /> Add users
                    </button>
                  </span>
                }
              </div>
            </div>
            { this.state.showFilters ?
              <TagFilters
                tags={tags}
                userFilter={this.props.userFilter}
                setUserFilter={this.props.setUserFilter} />
              : null }
            <UserGrid
              mode="org"
              adminView={viewerIsOrgAdmin}
              users={users}
              auth={this.props.auth}
              organization={this.props.organization}
              tags={this.props.tags} // Expecting object here
              showUpdateAlert={this.showUpdateAlert}
              updateUserOrg={this.props.updateUserOrg}
              removeOrganizationUser={this.props.removeOrganizationUser} />
          </div>
        </div>
      );
    }

    if (mode === 'vibe') {
      const { members: { isFetching: isFetchingMembers } } = selectedVibe;
      const { data: { _id: boardId } } = selectedVibe;
      let { members: { direct: directMembers = [], inherited: inheritedMembers = [], parent } } = selectedVibe;
      const { data: { orgOptions = {} } } = selectedVibe;
      const { accessLevel } = orgOptions;
      let { tagMembers = [] } = board;
      const { isFetching: isFetchingBoard } = board;

      // Loading
      if (isFetchingMembers || isFetchingBoard) {
        return (
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        );
      }

      // Remove disabled users from user array
      directMembers = _.filter(directMembers, (user) => utils.userIsOrgActive(this.props.organization.data._id, user.orgs));

      // Filter direct members out of inherited and tag members
      const parentBoard = vibes.find((board) => board._id === parent) || {};
      const { title: parentBoardTitle = '' } = parentBoard;

      const directMemberIds = directMembers.map((item) => item._id);
      const tagMemberIds = tagMembers.map((item) => item._id);
      inheritedMembers = inheritedMembers.filter((member) => !directMemberIds.includes(member._id) && !tagMemberIds.includes(member._id));

      // Filter out direct members from tag members
      tagMembers = tagMembers.filter((member) => !directMemberIds.includes(member._id));

      // Combine regular members and tag members into one list
      // If inherited, add tag users to inherited list. Otherwise, add them to direct list
      if (tagMembers) {
        if (accessLevel === 'inherit') {
          inheritedMembers = [...inheritedMembers, ...tagMembers];
        } else {
          directMembers = [...directMembers, ...tagMembers];
        }
      }

      // Count total members
      const directMembersCount = directMembers.length;
      const inheritedMembersCount = inheritedMembers.length;
      const totalMemberCount = (directMembersCount + inheritedMembersCount);

      // Pass adminView
      const adminView = (
        this.props.userIsOrgAdmin ||
        this.props.userIsTagAdmin ||
        this.props.userIsVibeChampion
      );

      const internalMembers = [];
      const externalMembers = [];
      let memberDetails = {};

/*      for (let i = 0; i < members.length; i += 1) {
        // If in currentOrg, use that. If not, use first available org (until we allow selection)
        const currentOrg = _.find(members[i].orgs, { id: orgId });
        const org = currentOrg ? currentOrg : members[i].orgs[0];
        const vibeRecord = _.find(members[i].vibes, { vibe: vibeId });
        if (vibeRecord) {
          memberDetails = {
            _id: members[i]._id,
            firstName: members[i].firstName,
            lastName: members[i].lastName,
            photoUrl: members[i].photoUrl,
            orgId: org.id,
            title: org.title,
            orgName: org.orgName,
            externalOrg: org.externalOrg,
            vibeDetails: vibeRecord,
          };

          if (currentOrg) {
            internalMembers.push(memberDetails);
          } else {
            externalMembers.push(memberDetails);
          }
        }
      } */

      const directMembersList = directMembers.map((user) => {
        const userBoard = _.find(user.vibes, { vibe: boardId }) || {};

        return (
          <UserCard
            key={user._id}
            mode="vibe"
            adminView={adminView}
            selectedVibe={this.props.selectedVibe}
            editVibeUser={this.props.editVibeUser}
            removeVibeUser={this.props.removeVibeUser}
            showUpdateAlert={this.showUpdateAlert}
            user={user}
            userTitle={user.title}
            userIsBoardMember={userBoard.isActive || false}
            userIsVibeChampion={userBoard.isChampion || false}
            userIsVibeActive={userBoard.isActive || true}
            auth={this.props.auth}
            tags={this.props.tags}
            organization={this.props.organization} />
        );
      });

      const inheritedMembersList = inheritedMembers.map((user) => {
        const userBoard = _.find(user.vibes, { vibe: boardId }) || {};

        return (
          <UserCard
            key={user._id}
            mode="vibe"
            adminView={adminView}
            selectedVibe={this.props.selectedVibe}
            editVibeUser={this.props.editVibeUser}
            removeVibeUser={this.props.removeVibeUser}
            showUpdateAlert={this.showUpdateAlert}
            user={user}
            userTitle={user.title}
            userIsBoardMember={userBoard.isActive || false}
            userIsVibeChampion={userBoard.isChampion || false}
            userIsVibeActive={userBoard.isActive || true}
            auth={this.props.auth}
            tags={this.props.tags}
            organization={this.props.organization} />
        );
      });

      // The next 2 are the old internal/external lists. Replaced by above.
      const internalUserList = internalMembers.map(user => (
        <UserCard
          key={user._id}
          mode="vibe"
          adminView={adminView}
          selectedVibe={this.props.selectedVibe}
          editVibeUser={this.props.editVibeUser}
          removeVibeUser={this.props.removeVibeUser}
          showUpdateAlert={this.showUpdateAlert}
          user={user}
          userTitle={user.title}
          userIsVibeChampion={user.vibeDetails.isChampion}
          userIsVibeActive={user.vibeDetails.isActive}
          auth={this.props.auth}
          organization={this.props.organization} />
      ));

      const externalUserList = externalMembers.map(user => (
        <UserCard
          key={user._id}
          mode="vibe"
          admin={adminView}
          selectedVibe={this.props.selectedVibe}
          editVibeUser={this.props.editVibeUser}
          removeVibeUser={this.props.removeVibeUser}
          showUpdateAlert={this.showUpdateAlert}
          user={user}
          userTitle={user.title}
          userIsVibeChampion={user.vibeDetails.isChampion}
          userIsVibeActive={user.vibeDetails.isActive}
          auth={this.props.auth}
          organization={this.props.organization} />
      ));

      return (
        <div className="row column">
          <Alert
            type="success callout text-center"
            message={this.state.calloutMessage}
            closeButton={false}
            showAlert={this.state.updateAlert}
            handleClose={this.hideUpdateAlert} />

          {(adminView === true && mode === 'vibe') &&
          <VibeInviteModal
            organization={this.props.organization}
            auth={this.props.auth}
            tags={this.props.tags}
            vibeModalIsOpen={this.state.vibeModalIsOpen}
            closeVibeModal={this.closeVibeModal} /> }

          <div className={(mode === 'vibe' && this.props.page === true) ? 'callout vibe-canvas' : 'callout'}>
            {(mode === 'vibe' && this.props.page === true) &&
              <div className="row columns">
                <br />
                {/* Close buttons */}
                <Link to={`/board/${this.props.selectedVibe.data._id}`}><i className="fi-arrow-left back-arrow" /></Link>
                <Link className="close-button" to={`/board/${this.props.selectedVibe.data._id}`}>
                  <span aria-hidden="true"><small>&times;</small></span>
                </Link>
              </div>}

            <div className="row">
              <div className="small-12 medium-10 columns">
                <h4>
                  <strong>{`${boardTitle} members`}</strong>
                  <small>{` (${totalMemberCount})`}</small>
                </h4>
              </div>
              <div className="small-12 medium-2 columns text-right">
                {(adminView === true && mode === 'vibe') &&
                  <button
                    type="button"
                    className="button"
                    data-open="user-add"
                    onClick={this.openVibeModal}><i className="fi-plus" /> Add members
                  </button>
                }
              </div>
            </div>
            { (inheritedMembersCount > 0) &&
              <p className="title is-size-5">
                <strong>Direct members</strong>
                <small className="subtitle">{` (${directMembersCount})`}</small>
              </p>
            }
            <UserGrid
              mode="board"
              boardId={boardId}
              adminView={adminView}
              users={directMembers}
              auth={this.props.auth}
              organization={this.props.organization}
              tags={this.props.tags}
              selectedVibe={this.props.selectedVibe}
              showUpdateAlert={this.showUpdateAlert}
              updateUserOrg={this.props.updateUserOrg}
              removeOrganizationUser={this.props.removeOrganizationUser}
              editVibeUser={this.props.editVibeUser}
              removeVibeUser={this.props.removeVibeUser} />
            { (inheritedMembersCount > 0) &&
            <div>
              <p className="title is-size-5">
                <strong>{`Members inheritied from ${parentBoardTitle}`}</strong>
                <small className="subtitle">{` (${inheritedMembersCount})`}</small>
              </p>
              <UserGrid
                mode="board"
                boardId={boardId}
                adminView={adminView}
                users={inheritedMembers}
                auth={this.props.auth}
                organization={this.props.organization}
                tags={this.props.tags}
                selectedVibe={this.props.selectedVibe}
                showUpdateAlert={this.showUpdateAlert}
                updateUserOrg={this.props.updateUserOrg}
                removeOrganizationUser={this.props.removeOrganizationUser}
                editVibeUser={this.props.editVibeUser}
                removeVibeUser={this.props.removeVibeUser} />
            </div> }
          </div>
        </div>
      );
    }

    return false;
  }
}

UserList.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  fetchOrganizationMembers: PropTypes.func,
  fetchMembers: PropTypes.func,
  updateUserOrg: PropTypes.func.isRequired,
  removeOrganizationUser: PropTypes.func,
  editVibeUser: PropTypes.func,
  removeVibeUser: PropTypes.func,
  leaveVibe: PropTypes.func,
  selectedVibe: PropTypes.object,
  mode: PropTypes.string.isRequired,
  page: PropTypes.bool,
  admin: PropTypes.bool,
  invitation: PropTypes.object,
  board: PropTypes.object,
};

export default UserList;
