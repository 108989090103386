const React = require('react');
const PropTypes = require('prop-types');
const { withRouter } = require('react-router');
const Draft = require('draft-js');
const Modal = require('react-modal');

const PostItem = require('./postItem.jsx').default;
const PostReplies = require('./postReplies.jsx').default;
// Should be moved to a common comment/reply component
const ReplyInput = require('../../../discussions/components/layout/replyInput.jsx').default;
const utils = require('../../../../utils').default;

class PostModal extends React.PureComponent {
  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (this.props.friendlyUrl) {
      this.props.fetchVibeIfNeeded(
        this.props.friendlyUrl, this.props.organization,
        this.props.vibes,
        this.props.auth, this.props.auth.token,
      );
    }

    if (this.props.postId) {
      this.props.fetchDiscussionDataIfNeeded(
        this.props.postId, this.props.organization.data._id,
        this.props.auth.token,
      );

      if (Object.keys(user).length !== 0) {
        this.props.updateSubscription(
          this.props.auth.user._id, this.props.organization.data._id,
          'Post', this.props.postId,
          this.props.auth.token,
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps.auth;

    if (nextProps.friendlyUrl && (nextProps.friendlyUrl !== this.props.selectedVibe.data.friendlyUrl)) {
      nextProps.fetchVibeIfNeeded(
        nextProps.friendlyUrl, nextProps.organization,
        nextProps.auth, nextProps.auth.token,
      );
    }

    if (nextProps.postId && (nextProps.postId !== this.props.postId)) {
      nextProps.fetchDiscussionDataIfNeeded(
        nextProps.postId, nextProps.organization.data._id,
        nextProps.auth.token,
      );

      if (Object.keys(user).length !== 0) {
        nextProps.updateSubscription(
          nextProps.auth.user._id, nextProps.organization.data._id,
          'post', nextProps.postId,
          nextProps.auth.token,
        );
      }
    }
  }

  openDiscussionInputModal = (mode, type, post) => {
    const postItem = post;
    if (postItem.content && postItem.content.raw) {
      const contentState = Draft.convertFromRaw(JSON.parse(postItem.content.raw));
      postItem.contentState = contentState;
    }

    this.props.setPost(postItem, false);
    this.props.openInputModal('edit', type);
  };

  handleJoinVibeClick = () => {
    const userIsVibeMember = utils.userIsVibeMember(
      this.props.auth.user.vibes,
      this.props.selectedVibe.data._id,
    );

    if (!userIsVibeMember) {
      this.props.joinVibe(
        this.props.auth.user._id, this.props.selectedVibe.data._id, false,
        this.props.organization.data._id, this.props.auth.token,
      );
    }
  };

  render() {
    const discussion = this.props.discussion.data;
    const replies = this.props.discussion.data.replies || {};
    const selectedVibe = this.props.selectedVibe.data;
    const orgOptions = selectedVibe.orgOptions || {};
    const selectedVibeTags = orgOptions.tags || [];

    const userIsVibeMember = utils.userIsVibeMember(
      this.props.auth.user.vibes,
      this.props.discussion.data.vibe,
    );

    const userIsVibeChampion = utils.userIsVibeChampion(
      this.props.auth.user.vibes,
      this.props.discussion.data.vibe,
    );

    const userIsOrgAdmin = utils.userIsOrgAdmin(
      this.props.organization.data._id,
      this.props.auth.user.orgs,
    );

    const userIsTagAdmin = utils.userIsTagAdmin(
      this.props.auth.user._id,
      selectedVibeTags, this.props.tags.data,
    );

    const postType = ('postType' in discussion) ? discussion.postType : 'discussion';
    const modalClass = (postType === 'promo') ? 'modal small' : 'modal';

    return (
      <div>
        <Modal
          isOpen={this.props.postModalIsOpen}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.props.closePostModal}
          className={modalClass}
          overlayClassName="modal-overlay"
          contentLabel="Post modal">

          {!this.props.discussion.isFetching
            && Object.keys(this.props.discussion.data).length !== 0
            && (
              <div>
                <PostItem
                  organization={this.props.organization}
                  auth={this.props.auth}
                  item={this.props.discussion.data}
                  vibeLayout={selectedVibe.vibeLayout}
                  modalLocation={this.props.modalLocation}
                  userIsVibeMember={userIsVibeMember}
                  userIsVibeChampion={userIsVibeChampion}
                  userIsOrgAdmin={userIsOrgAdmin}
                  userIsTagAdmin={userIsTagAdmin}
                  openDiscussionInputModal={this.openDiscussionInputModal}
                  reactPost={this.props.reactDiscussion} />

                <PostReplies
                  organization={this.props.organization}
                  auth={this.props.auth}
                  items={replies}
                  modalLocation={this.props.modalLocation}
                  userIsVibeMember={userIsVibeMember}
                  userIsVibeChampion={userIsVibeChampion}
                  userIsOrgAdmin={userIsOrgAdmin}
                  userIsTagAdmin={userIsTagAdmin}
                  openDiscussionInputModal={this.openDiscussionInputModal}
                  reactPost={this.props.reactReply} />

                {(postType !== 'promo') &&
                (
                  <ReplyInput
                    organization={this.props.organization}
                    auth={this.props.auth}
                    discussion={this.props.discussion}
                    discussionId={this.props.discussion.data._id}
                    selectedVibe={this.props.selectedVibe}
                    post={this.props.post}
                    setPost={this.props.setPost}
                    mentions={this.props.mentions}
                    createReply={this.props.createReply} />
                )
                }
              </div>)
          }

          <button className="close-button" onClick={this.props.closePostModal} aria-label="Close reveal" type="button">
            <span aria-hidden="true"><small>&times;</small></span>
          </button>
        </Modal>
      </div>
    );
  }
}

PostModal.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  discussion: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  postId: PropTypes.string,
  friendlyUrl: PropTypes.string,
  fetchVibeIfNeeded: PropTypes.func.isRequired,
  fetchDiscussionDataIfNeeded: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  setPost: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
  openInputModal: PropTypes.func,
  postModalIsOpen: PropTypes.bool.isRequired,
  closePostModal: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  createReply: PropTypes.func.isRequired,
  reactDiscussion: PropTypes.func.isRequired,
  reactReply: PropTypes.func.isRequired,
};

PostModal.defaultProps = {
  postId: '',
  friendlyUrl: '',
};

export default withRouter(PostModal);
