const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const cookie = require('react-cookie');
const { Link } = require('react-router');

const Alert = require('../../components/alert.jsx').default;
const LoginForm = require('../../components/loginForm.jsx').default;
const SocialButtonList = require('../../components/socialButtonList.jsx').default;
const TermsModal = require('./termsModal.jsx').default;
const PrivacyModal = require('./privacyModal.jsx').default;

class UserCreate extends React.PureComponent {
  state = {
    termsModalIsOpen: false,
    privacyModalIsOpen: false,
  };

  componentDidMount() {
    cookie.remove('tv_at', { path: '/' });
    cookie.remove('tv_at', { domain: '.teamvibe.com', path: '/' });
    this.props.fetchInvitationIfNeeded(this.props.params.id);
  }

  openTermsModal = () => {
    this.setState({ termsModalIsOpen: true });
  };

  openPrivacyModal = () => {
    this.setState({ privacyModalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ termsModalIsOpen: false, privacyModalIsOpen: false });
  };

  handleCreateBasicAccount = (email, password) => {
    const { invitation, organization } = this.props;
    const { data: organizationData } = organization;
    const { data: invitationData } = invitation;

    this.props.loginOrRegister(
      email, password,
      invitationData, organizationData,
    );

/*    if (accountExists && !isPending) {
      this.props.loginWithInvitation(
        email, password,
        this.props.invitation.data, this.props.organization.data.teamDomain,
      );
    } else {
      this.props.signUp(
        email, password,
        this.props.invitation.data, this.props.organization.data,
      );
    }*/
  };

  render() {
    const { isFetching: isFetchingOrg, data: organization } = this.props.organization;
    const { isFetching, data: invite } = this.props.invitation;
    const { inviteType, recipient } = invite;
    const regError = this.props.registration.error || {};
    // const inviteError = this.props.invitation.error || {};
    const authError = this.props.auth.error || {};

    // Custom onboard imagery
    const { imagery: { onboard1 = 'https://res.cloudinary.com/teamvibe/image/upload/v1470114542/product/header-celebrate.jpg' } = {} } = organization;

    let emailError = '';
    let passwordError = '';
    if (Object.keys(authError).length !== 0) {
      passwordError = ((Object.keys(authError).length !== 0) && ('password' in authError)) ? authError.password.message : null;
    } else if ((Object.keys(regError).length !== 0)) {
      emailError = ((Object.keys(regError).length !== 0) && ('email' in regError)) ? regError.email.message : null;
      passwordError = ((Object.keys(regError).length !== 0) && ('password' in regError)) ? regError.password.message : null;
    }

    // const linkedInType = invite.accountExists ? 'login' : 'signup';

    return (
      <div>
        <Helmet title="Accept invite" />
        <Alert
          name="alert_reg_error"
          type="alert callout text-center"
          message={regError.message}
          showAlert={((Object.keys(regError).length !== 0) && ('message' in regError))}
          handleClose={this.props.clearRegError} />
        <Alert
          name="alert_auth_error"
          type="alert callout text-center"
          message={authError.error_description}
          showAlert={((Object.keys(authError).length !== 0) && ('error' in authError))}
          autoClose={false}
          closeButton
          handleClose={this.props.clearAuthError} />

        <TermsModal
          modalIsOpen={this.state.termsModalIsOpen}
          closeModal={this.closeModal} />

        <PrivacyModal
          modalIsOpen={this.state.privacyModalIsOpen}
          closeModal={this.closeModal} />

        <div className="full-screen full-height">
          <div className="fs-panel">
            <br />
            {invite.inviteType !== 'createOrg' && organization.hasOwnProperty('logo') && <img className="logo-feature" src={organization.logo} alt="Company logo" />}
            {organization && invite && !isFetching &&
            <div className="row align-center">
              <div className="medium-10 medium-offset-1 columns end">
                {(Object.keys(invite).length > 0) ?
                  <div>
                    {invite.inviteType === 'createOrg' ?
                      <div>
                        <h2>Welcome to Teamvibe</h2>
                        <h4>First let&#39;s create your user account...</h4>
                      </div>
                      :
                      <div>
                        <br /><br />
                        <h2>Welcome to Teamvibe {invite.inviteType !== 'createOrg' && <span>for <strong>{organization.name}</strong></span>}</h2>
                      </div>
                    }

                    <p>To make login easy, we recommend using your LinkedIn account. Or you can set a password the old-fashioned way if you like.</p>
                    <br />
                    <div className="text-center">
                      <SocialButtonList
                        type="signup"
                        org={organization}
                        token={this.props.params.id}
                        inviteType={this.props.invitation.data.inviteType} />
                    </div>

                    <br />
                    <h2 className="text-center">-- OR --</h2>
                    <br />

                    <form>
                      {(inviteType !== 'magicLink') &&
                        <p>Choose a password for sign in (your email is already set).</p>}
                      <LoginForm
                        emailLabel="Your email"
                        emailValue={(inviteType !== 'magicLink') ? recipient : null}
                        emailHelp={emailError}
                        passwordLabel="Password"
                        passwordPlaceholder="Choose a password (at least 8 characters)"
                        passwordHelp={passwordError}
                        disabledEmail={(inviteType !== 'magicLink')}
                        loginUser={this.handleCreateBasicAccount}
                        buttonText="Sign up the old-fashioned way"
                        rememberMe="hide" />
                    </form>
                    <br />
                    <h6 className="subheader text-center">By creating an account and using Teamvibe,<br />you agree to our&nbsp;
                      <button type="button" className="link" onClick={this.openTermsModal}>Terms of Use</button> and&nbsp;
                      <button type="button" className="link" onClick={this.openPrivacyModal}>Privacy Policy</button>.
                    </h6>
                  </div>
                  :
                  <div>
                    <br /><br />
                    <h5>Unfortunately it looks like you have an invalid or expired invitation.</h5>
                    <br />
                    <h5>You may need to <Link to="/sign-up">request a new invite</Link> using your organization email address or contact your administrator for a fresh invite.</h5>
                    <br />
                    <h5>If you are creating a new organization, you can <Link to="/get-started">start over here</Link>.</h5>
                  </div>
                }
              </div>
            </div>
            }
            {isFetching &&
              <h1 className="loading">Loading...</h1>
            }

            <br /><br /><br /><br />
          </div>
          <div
            className="hide-for-small-only fs-panel"
            style={{ background: `#1dadda url(${onboard1}) center center/cover` }}>
            {/* This div is left empty since it is filled by the background image */}
          </div>
        </div>
        {isFetchingOrg &&
            <h1 className="loading">Loading...</h1>
        }
      </div>
    );
  }
}

UserCreate.propTypes = {
  fetchInvitationIfNeeded: PropTypes.func.isRequired,
  loginOrRegister: PropTypes.func.isRequired,
  loginWithInvitation: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
  clearInviteError: PropTypes.func.isRequired,
  clearRegError: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

export default UserCreate;
