import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PasswordResetRequest from '../components/passwordResetRequest.jsx';
import PasswordResetActions from '../../../modules/registration/actions/password-reset';

var mapStateToProps = function(state){
    return {
        organization: state.organization
    };
}

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, PasswordResetActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetRequest);
