import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Admin from '../components/admin.jsx';
import TagsActions from '../../../modules/tags/actions/tags';
import AddTagActions from '../../../modules/tags/actions/addTag';
import EditOrganizationActions from '../../../modules/organizations/actions/editOrganization';
import OrganizationMembersActions from '../../../modules/organizations/actions/organization-members';
import GetInvitationsActions from '../../../modules/invitations/actions/getInvitations';
import DeleteInvitationActions from '../../../modules/invitations/actions/deleteInvitation';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    tags: state.tags,
    invitation: state.invitation,
    invitations: state.invitations,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    TagsActions, AddTagActions, EditOrganizationActions, OrganizationMembersActions,
    GetInvitationsActions, DeleteInvitationActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
