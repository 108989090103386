const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const RouterActions = require('react-router-redux').routerActions;
const utils = require('../../../../common/utils').default;

const checkAdminAccess = function (Component) {
  class AdminComponent extends React.Component {
    componentWillMount() {
      this.checkAndRedirect();
    }

    checkAndRedirect = () => {
      const { auth } = this.props;
      const organization = this.props.organization.data;

      const userIsOrgAdmin = utils.userIsOrgAdmin(organization._id, auth.user.orgs);

      if (!userIsOrgAdmin) {
        this.props.push('/');
      }
    };

    render() {
      return (
        <div className="authenticated">
          {Object.keys(this.props.auth.user).length !== 0 && this.props.auth.token
            ? <Component {...this.props} />
            : null}
        </div>
      );
    }
  }

  AdminComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, RouterActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(AdminComponent);
};

export default checkAdminAccess;
