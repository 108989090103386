const React = require('react');
const PropTypes = require('prop-types');
const { withRouter, Link } = require('react-router');
const _ = require('lodash');
const utils = require('../../../../../common/utils').default;
const Alert = require('../../../components/alert.jsx').default;

class UserCard extends React.Component {
  state = { removeOrgAlert: false, showAdminMenu: false };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleAdminWidgetClick = () => {
    const { showAdminMenu } = this.state;
    this.setState({ showAdminMenu: !showAdminMenu });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showAdminMenu: false });
    }
  };

  handleAdminClick = () => {
    const { user, organization, userOrg } = this.props;

    // Toggle isAdmin
    userOrg.isAdmin = !userOrg.isAdmin;
    this.props.updateUserOrg(user._id, organization.data._id, userOrg, this.props.auth.token);
    this.setState({ showAdminMenu: false });

    const fullName = `${this.props.user.firstName} ${this.props.user.lastName}`;
    if (userOrg.isAdmin) {
      this.props.showUpdateAlert(`${fullName} is now platform admin`);
    } else {
      this.props.showUpdateAlert(`${fullName} is no longer platform admin`);
    }
  };

  handleChampionClick = () => {
    let { userIsVibeChampion } = this.props;
    const { organization: { data: organizationData } } = this.props;
    const adminFullName = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;

    // Toggle userIsVibeChampion
    userIsVibeChampion = !userIsVibeChampion;
    this.props.editVibeUser(
      this.props.user._id, this.props.selectedVibe.data, this.props.organization.data._id,
      userIsVibeChampion, this.props.userIsVibeActive, organizationData, adminFullName, this.props.auth.token,
    );
    this.setState({ showAdminMenu: false });

    const fullName = `${this.props.user.firstName} ${this.props.user.lastName}`;
    if (userIsVibeChampion) {
      this.props.showUpdateAlert(`${fullName} is now a champion`);
    } else {
      this.props.showUpdateAlert(`${fullName} is no longer a champion`);
    }
  };

  handleEnableClick = () => {
    const { user, organization, userOrg } = this.props;

    // Toggle isActive
    userOrg.isActive = !userOrg.isActive;
    this.props.updateUserOrg(user._id, organization.data._id, userOrg, this.props.auth.token);
    this.setState({ showAdminMenu: false });

    const fullName = `${this.props.user.firstName} ${this.props.user.lastName}`;
    if (userOrg.isActive) {
      this.props.showUpdateAlert(`${fullName} is now enabled`);
    } else {
      this.props.showUpdateAlert(`${fullName} is now disabled`);
    }
  };

  handleRemoveVibeClick = () => {
    this.props.removeVibeUser(
      this.props.user._id, this.props.selectedVibe.data._id, this.props.organization.data._id,
      this.props.auth.token,
    );
    this.setState({ showAdminMenu: false });

    const fullName = `${this.props.user.firstName} ${this.props.user.lastName}`;
    this.props.showUpdateAlert(`${fullName} has been removed from the board`);
  };

  handleRemoveOrgClick = () => {
    this.setState({ removeOrgAlert: true, showAdminMenu: false });
  };

  handleRemoveOrgConfirm = () => {
    const fullName = `${this.props.user.firstName} ${this.props.user.lastName}`;

    this.hideAlerts();
    this.props.removeOrganizationUser(
      this.props.user._id, this.props.organization.data._id,
      this.props.auth.token,
    );
    this.props.showUpdateAlert(`${fullName} has been removed from the organization`);
  };

  hideAlerts = () => {
    this.setState({ removeOrgAlert: false });
    // this.props.removeVibeAlerts();
  };

  render() {
    const { user, mode, userExternalOrg = {} } = this.props;
    const { adminView, userIsBoardMember, userIsVibeChampion, userIsOrgAdmin } = this.props;
    const { orgName: userExternalOrgName } = userExternalOrg;
    const organization = this.props.organization.data;
    const { _id: orgId, preferences: orgPreferences } = organization;
    const { showExternalOrg = false, showLocation = false } = orgPreferences;
    const { firstName, lastName } = user;

    // Only needed when board user list
    const { selectedVibe = {}, tags = {} } = this.props;
    const userCurrentOrg = (user.orgs).find((org) => org.id === orgId) || {};
    const { tags: userTags = [] } = userCurrentOrg;
    const { data: selectedVibeData = {} } = selectedVibe;
    const { orgOptions: { tags: boardTags = [] } = {} } = selectedVibeData;
    const { data: orgTags = [] } = tags;

    const userTagsInBoard = userTags.filter((tag) => _.includes(boardTags, tag)) || [];
    const userTagsArray = _(orgTags).keyBy('_id').at(userTagsInBoard).value() || [];

    // Get user location tag/displayName
    const userTagsData = utils.getUserTags(userCurrentOrg, orgTags);
    const locationTag = userTagsData.find((tag) => tag.tagType === 'location') || {};
    const { displayName: locationName = '' } = locationTag;

    const classes = ((mode === 'org' && this.props.userIsOrgActive) || (mode === 'board' && this.props.userIsVibeActive))
      ? 'user-card'
      : 'user-card card-disabled';

    return (
      <div className="column">
        {/* 'Are you sure' alert */}
        <Alert
          name="alert_pending"
          type="alert callout text-center"
          autoClose={false}
          showAlert={this.state.removeOrgAlert}
          handleClose={this.hideAlerts}>
          <h4 className="alert">Warning: Permanent action</h4>
          <p>{`${user.firstName} ${user.lastName} will be removed from your organization`}</p>
          <div className="margin-top-15">
            <button type="button" className="alert button" onClick={this.handleRemoveOrgConfirm}>Confirm removal</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="success button" onClick={this.hideAlerts}>Keep user</button>
          </div>
        </Alert>

        <div className={classes}>
          <Link to={`/profile/${this.props.user._id}`}>
            <img
              src={utils.formatImage(this.props.user.photoUrl, 'userPhoto')}
              className="user-image"
              alt={`${this.props.user.firstName} ${this.props.user.lastName}`}
              onError={utils.showDefaultImage} />
          </Link>
          {(userIsOrgAdmin) && <div className="ribbon-wrapper"><div className="ribbon-green">Admin</div></div>}
          {userIsVibeChampion && <div className="ribbon-wrapper"><div className="ribbon-green">Champion</div></div>}
          {((mode === 'org' && !this.props.userIsOrgActive) || (mode === 'board' && !this.props.userIsVibeActive)) &&
            <div className="ribbon-wrapper"><div className="ribbon-green">Disabled</div></div>}
          <div className="card-body" ref={this.setWrapperRef}>
            {(adminView || userIsVibeChampion) &&
              <button type="button" className="icon" onClick={this.handleAdminWidgetClick}><i className="fa fa-cog" /></button>}
            <span className="name">{`${firstName} ${lastName}`}</span>
            <span className="sub-title">{this.props.userTitle}</span>
            {(this.props.user.orgId !== this.props.organization.data._id) && <span className="sub-title">{this.props.user.orgName}</span>}
            <span className="sub-title">{showExternalOrg && userExternalOrgName}</span>
            {(showLocation && locationName) && <div className="sub-title">{locationName}</div>}
            {this.state.showAdminMenu &&
              <div className="user-admin-menu">
                <div className="menu-header clearfix">
                  <div className="float-right">
                    <img
                      src={utils.formatImage(this.props.user.photoUrl, 'userThumb')}
                      className="image-thumb"
                      alt=""
                      onError={utils.showDefaultImage} />
                  </div>
                  <div>
                    <strong>{`${firstName} ${lastName}`}</strong>
                    <br />{this.props.userTitle}
                  </div>
                  {userIsBoardMember
                    ? <div className="tag is-primary is-small is-rounded">Member</div>
                    : userTagsArray.map((tag) => <div className="tag is-primary is-small is-rounded" key={tag._id}>{tag.displayName}</div>)}
                </div>
                <div className="menu-body">
                  <ul className="menu vertical">
                    <li><Link to={`/profile/${this.props.user._id}`}>View full profile</Link></li>
                    {(mode === 'org') &&
                    <li>
                      <button type="button" className="link" onClick={this.handleEnableClick}>
                        {this.props.userIsOrgActive ? <span>Disable user</span> : <span>Enable user</span>}
                      </button>
                    </li>}
                    {(mode === 'board' && userIsBoardMember) &&
                    <li>
                      <button type="button" className="link" onClick={this.handleRemoveVibeClick}><span>Remove user from board</span></button>
                    </li>}
                    {(mode === 'org') &&
                    <li>
                      <button type="button" className="link" onClick={this.handleAdminClick}>
                        {!userIsOrgAdmin ? <span>Make platform admin</span> : <span>Remove platform admin</span>}
                      </button>
                    </li>}
                    {(mode === 'board' && userIsBoardMember) &&
                    <li>
                      <button type="button" className="link" onClick={this.handleChampionClick}>
                        {!userIsVibeChampion ? <span>Make board champion</span> : <span>Remove board champion</span>}
                      </button>
                    </li>}
                    {(mode === 'org') &&
                    <li>
                      <button type="button" className="link" onClick={this.handleRemoveOrgClick}><span>Remove user from org</span></button>
                    </li>}
                  </ul>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

UserCard.propTypes = {
  mode: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  updateUserOrg: PropTypes.func,
  editVibeUser: PropTypes.func,
  removeVibeUser: PropTypes.func,
  removeOrganizationUser: PropTypes.func,
  showUpdateAlert: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userIsOrgAdmin: PropTypes.bool,
  userIsOrgActive: PropTypes.bool,
  userIsVibeChampion: PropTypes.bool,
  userIsVibeActive: PropTypes.bool,
  userExternalOrg: PropTypes.object,
  selectedVibe: PropTypes.object,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  userOrg: PropTypes.object,
  tags: PropTypes.object,
};

export default withRouter(UserCard);
