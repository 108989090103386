import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import surveyInput from '../components/surveyModal.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    // SurveyActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(surveyInput);
