var React = require('react');
var { Helmet } = require('react-helmet');
var PropTypes = require('prop-types');

class ErrorPage extends React.Component {
    render() {
        var error = this.props.vibes.error;

        return (
            <div className="wrapper">
                <Helmet title="Vibe Not Found" />
                <div>
                    Oops - {Object.keys(error) > 0 ? error : null}
                </div>
            </div>
        )
    }
}

ErrorPage.propTypes = {
    vibes: PropTypes.object,
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default ErrorPage;
