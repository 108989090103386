import constants from '../constants';
import api from '../../../utils/api';
import discussionsActions from './discussions';

const DeleteReply = (function () {
  const _deleteReplyRequest = function () {
    return {
      type: constants.DELETE_REPLY_REQUEST,
    };
  };

  const _deleteReplySuccess = function (data, replyId) {
    const reply = data;
    reply._id = replyId;
    return {
      type: constants.DELETE_REPLY_SUCCESS,
      reply: data.reply,
    };
  };

  const _deleteReplyError = function (error) {
    return {
      type: constants.DELETE_REPLY_ERROR,
      errors: error.data,
    };
  };

  const _deleteReply = function (postItem, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_deleteReplyRequest());
      api.delete(`/proxy/v1/discussions/${postItem.data.discussion}/replies/${postItem.data._id}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_deleteReplySuccess(res, postItem.data._id));
        })
        .catch((res) => {
          dispatch(_deleteReplyError(res));
        });
    };
  };

  return {
    deleteReply: _deleteReply,
  };
}());

export default DeleteReply;
