import constants from '../constants';
import api from '../../../utils/api';

const OrganizationVibes = (function () {
  const _requestOrgVibes = function () {
    return {
      type: constants.REQUEST_ORGANIZATION_VIBES,
    };
  };

  const _receiveOrgVibes = function (data) {
    return {
      type: constants.RECEIVE_ORGANIZATION_VIBES,
      data: data.vibes,
    };
  };

  const _fetchOrgVibesError = function (error) {
    return {
      type: constants.REQUEST_ORGANIZATION_VIBES_ERROR,
      errors: error.data,
    };
  };

  const _fetchOrgVibes = function (orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestOrgVibes());
      api.get(`/proxy/v1/organizations/${orgId}/vibes`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveOrgVibes(res));
        })
        .catch((res) => {
          dispatch(_fetchOrgVibesError(res));
        });
    };
  };

  const _createOrgVibe = function (vibe) {
    return {
      type: constants.CREATE_ORGANIZATION_VIBE,
      vibe,
    };
  };

  const _removeOrgVibe = function (vibeId) {
    return {
      type: constants.REMOVE_ORGANIZATION_VIBE,
      vibeId,
    };
  };

  return {
    fetchOrgVibes: _fetchOrgVibes,
    createOrgVibe: _createOrgVibe,
    removeOrgVibe: _removeOrgVibe,
  };
}());

export default OrganizationVibes;
