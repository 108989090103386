export default {
  REQUEST_EVENTS: 'REQUEST_EVENTS',
  RECEIVE_EVENTS: 'RECEIVE_EVENTS',
  REQUEST_EVENTS_ERROR: 'REQUEST_EVENTS_ERROR',
  CHANGE_RSVP_REQUEST: 'CHANGE_RSVP_REQUEST',
  CHANGE_RSVP_SUCCESS: 'CHANGE_RSVP_SUCCESS',
  CHANGE_RSVP_ERROR: 'CHANGE_RSVP_ERROR',
  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_ERROR: 'CREATE_EVENT_ERROR',
  EDIT_EVENT_REQUEST: 'EDIT_EVENT_REQUEST',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_ERROR: 'EDIT_EVENT_ERROR',
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',
  CLEAR_EVENT_ERROR: 'CLEAR_EVENT_ERROR',
  SET_SELECTED_EVENT: 'SET_SELECTED_EVENT',
  CLEAR_SELECTED_EVENT: 'CLEAR_SELECTED_EVENT',
  SET_ACTIVE_EVENT_FILTER: 'SET_ACTIVE_EVENT_FILTER',
  SET_ACTIVE_EVENT_TYPE_FILTER: 'SET_ACTIVE_EVENT_TYPE_FILTER',
};
