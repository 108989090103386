import constants from '../constants';
import api from '../../../utils/api';

const GetInvitation = (function () {
  const _requestInvitation = function () {
    return {
      type: constants.REQUEST_INVITATION,
    };
  };

  const _receiveInvitation = function (data) {
    return {
      type: constants.RECEIVE_INVITATION,
      data: data.response,
    };
  };

  const _fetchInvitationError = function (error) {
    return {
      type: constants.REQUEST_INVITATION_ERROR,
      errors: error.response.data,
    };
  };

  const _clearInviteError = function () {
    return {
      type: constants.CLEAR_GET_INVITE_ERROR,
    };
  };

  const _fetchInvitation = function (token) {
    return function (dispatch) {
      dispatch(_requestInvitation());
      api.get(`/proxy/v1/invitations/${token}`)
        .then((res) => {
          dispatch(_receiveInvitation(res));
        })
        .catch((res) => {
          dispatch(_fetchInvitationError(res));
        });
    };
  };

  const _shouldFetchInvitation = function (state) {
    const invitation = state.invitation.data;
    if (Object.keys(invitation).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchInvitationIfNeeded = function (token) {
    return function (dispatch, getState) {
      if (_shouldFetchInvitation(getState(), token)) {
        return dispatch(_fetchInvitation(token));
      }
      return false;
    };
  };

  return {
    fetchInvitationIfNeeded: _fetchInvitationIfNeeded,
    clearInviteError: _clearInviteError,
  };
}());

export default GetInvitation;
