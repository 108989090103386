import constants from '../constants';

const initialState = {
  isFetching: true,
  closeModal: false,
  data: {},
  error: {},
};

const selectedEvent = function (state = initialState, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_EVENT:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_EVENT:
    newstate.isFetching = false;
    newstate.data = action.data;
    return newstate;
  case constants.CREATE_EVENT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      closeModal: true,
    };
  case constants.CREATE_EVENT_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_EVENT_ERROR:
    newstate.isFetching = false;
    newstate.error = {};
    return newstate;
  case constants.SET_SELECTED_EVENT:
    newstate.isFetching = false;
    newstate.data = action.event;
    return newstate;
  case constants.CLEAR_SELECTED_EVENT:
    return initialState;
  case constants.EDIT_EVENT_SUCCESS:
    // May be able to close model in function via try/catch
    return {
      ...state,
      isFetching: false,
      data: {},
      closeModal: true,
    };
  case constants.EDIT_EVENT_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.DELETE_EVENT_SUCCESS:
    // May be able to close model in function via try/catch
    return {
      ...state,
      isFetching: false,
      data: {},
      closeModal: true,
    };
  default: return state;
  }
};

export default selectedEvent;
