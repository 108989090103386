import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ currentPage, postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i += 1) {
    pageNumbers.push(i);
  }

  // Get number of pages
  const numOfPages = pageNumbers.length;

  // Dynamic slicing of middle pages (only if > 10 pages)
  let startSlice = 1;
  let endSlice = 6;
  if (numOfPages <= 10) {
    // If 10 pages or less, show all pages in pagination list
    endSlice = 10;
  } else {
    // If > 10 pages, use dynamic middle pages
    if (currentPage === 2) {
      startSlice = (currentPage - 1);
    } else if (currentPage === 3) {
      startSlice = (currentPage - 2);
    } else if (currentPage > 3) {
      startSlice = (currentPage - 3);
      endSlice = (currentPage + 2);
    }

    if ((numOfPages - currentPage) <= 2) {
      startSlice = (numOfPages - 6);
      endSlice = (numOfPages - 1);
    }
  }

  return (
    <div>
      {
        // Hide pagination if only 1 page
        (numOfPages > 1) &&
      <nav className="pagination is-small is-centered" role="navigation" aria-label="pagination">
        <button
          type="button"
          className="pagination-previous"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}>
          Previous Page
        </button>
        <button
          type="button"
          className="pagination-next"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === numOfPages}>
          Next Page
        </button>

        <ul className="pagination-list">
          {/* First page marker */}
          <li key="1">
            <button
              type="button"
              className={`pagination-link ${(currentPage === 1) && 'is-current'}`}
              onClick={() => paginate(1)}>
              1
            </button>
          </li>

          {
            (numOfPages > 10) &&
            <li><span className="pagination-ellipsis">&hellip;</span></li>
          }

          {/* Dynamic extra page markers */}
          {pageNumbers.slice(startSlice, endSlice).map((number) => {
            const style = `pagination-link ${(number === currentPage) && 'is-current'}`;

            return (
              <li key={number}>
                <button
                  type="button"
                  className={style}
                  onClick={() => paginate(number)}>
                  {number}
                </button>
              </li>
            );
          })}
          {(numOfPages > 10) &&
            <>
              <li><span className="pagination-ellipsis">&hellip;</span></li>
              <li key={numOfPages}>
                <button
                  type="button"
                  className={`pagination-link ${(currentPage === numOfPages) && 'is-current'}`}
                  onClick={() => paginate(numOfPages)}>
                  {numOfPages}
                </button>
              </li>
            </>}
        </ul>
      </nav>
      }
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  postsPerPage: PropTypes.number.isRequired,
  totalPosts: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
};

export default Pagination;
