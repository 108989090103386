import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router';

import DiscussionInputModal from './discussions/containers/discussionInputContainer.jsx';
import EventInputModal from './events/containers/eventInputContainer.jsx';

import EventsActions from '../../modules/events/actions/events';

const { setSelectedEvent, clearSelectedEvent } = EventsActions;

const FloatMenu = (props) => {
  // Wrapper ref
  const wrapperRef = useRef(null);

  // Local state vars
  const [inputModalIsOpen, setInputModalIsOpen] = useState(false);
  const [eventInputModalIsOpen, setEventInputModalIsOpen] = useState(false);
  const [floatMenuIsOpen, setFloatMenuIsOpen] = useState(false);
  const [inputMode, setInputMode] = useState('create');
  const [inputType, setInputType] = useState('discussion');

  // Import state vars
  const { auth, discussion, organization, selectedVibe, selectedEvent } = useSelector(
    (state) => ({
      auth: state.auth,
      discussion: state.discussion,
      organization: state.organization,
      selectedEvent: state.selectedEvent,
      selectedVibe: state.selectedVibe,
    }),
  );

  const { closeModal: closePostModal } = discussion;
  const { closeModal: closeEventModal } = selectedEvent;
  const { data: selectedVibeData } = selectedVibe;
  const { _id: boardId } = selectedVibeData;

  // Get vars from props
  const { userCanAdmin } = props;

  const handleClickOutside = (event) => {
    if (floatMenuIsOpen && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setFloatMenuIsOpen(false);
    }
  };

  // Listener for clicks outside of modal
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  // Close modal after submit (probably should be tied to post/event actions)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  // Close post modal if closeModal: closePostModal === true
  useEffect(() => {
    if (closePostModal) {
      setInputModalIsOpen(false);
    }
  }, [closePostModal]);

  // Close event input modal if closeModal: closeEventModal === true
  useEffect(() => {
    if (closeEventModal) {
      setEventInputModalIsOpen(false);
    }
  }, [closeEventModal]);

  const toggleFloatMenu = () => {
    setFloatMenuIsOpen(!floatMenuIsOpen);
  };

  const openInputModal = (inputMode, inputType) => {
    setFloatMenuIsOpen(false);
    setInputModalIsOpen(true);
    setInputMode(inputMode);
    setInputType(inputType);
  };

  const closeInputModal = () => {
    setInputModalIsOpen(false);
  };

  const openEventInputModal = (mode) => {
    if (mode === 'create') { clearSelectedEvent(); }
    const eventItem = selectedEvent;

    // Set vibe to current vibe
    eventItem.data.vibe = selectedVibeData;

    setSelectedEvent(eventItem.data);

    setFloatMenuIsOpen(false);
    setEventInputModalIsOpen(true);
    setInputMode(mode);
  };

  const closeEventInputModal = () => {
    clearSelectedEvent();
    setEventInputModalIsOpen(false);
  };

  return (
    <div>
      <div ref={wrapperRef}>
        <div id="float-menu" className={!floatMenuIsOpen ? 'hide' : null}>
          <ul className="menu vertical text-left">
            <li>
              <button type="button" onClick={openInputModal.bind(null, 'create', 'discussion')}>
                <i className="fa fa-file-text-o" />
                <span>Add post</span>
              </button>
            </li>
            <li>
              <button type="button" onClick={openEventInputModal.bind(null, 'create')}>
                <i className="fa fa-calendar" />
                <span>Add event</span>
              </button>
            </li>
            {(userCanAdmin) &&
            <li>
              <Link className="common-link" to={`/board/create/1/${boardId}`}>
                <i className="fa fa-file-o" />
                <span>Add sub-board</span>
              </Link>
            </li>
            }
            {(userCanAdmin) &&
            <li className="hide">
              <button type="button" onClick={openInputModal.bind(null, 'create', 'promo')}>
                <i className="fa fa-usd" />
                <span>Add promo</span>
              </button>
            </li>
            }
          </ul>
        </div>
        <button
          type="button"
          id="float-menu-toggle"
          className="button large"
          onClick={toggleFloatMenu}>
          <i className="fi-plus" />
        </button>
      </div>

      <DiscussionInputModal
        type={inputType}
        mode={inputMode}
        auth={auth}
        organization={organization}
        selectedVibe={selectedVibe}
        modalIsOpen={inputModalIsOpen}
        closeModal={closeInputModal} />

      <EventInputModal
        selectedVibe={selectedVibe}
        selectedEvent={selectedEvent}
        mode={inputMode}
        inputModalIsOpen={eventInputModalIsOpen}
        closeInputModal={closeEventInputModal} />
    </div>
  );
};

FloatMenu.propTypes = {
  userCanAdmin: PropTypes.bool.isRequired,
};

export default withRouter(FloatMenu);
