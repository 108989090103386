const React = require('react');
const PropTypes = require('prop-types');
const ReactDnD = require('react-dnd');
// const DragDropContext = require('react-dnd').DragDropContext;
const HTML5Backend = require('react-dnd-html5-backend');
const _ = require('lodash');

const Tag = require('./tag.jsx').default;

class TagsContainer extends React.Component {
  compareCards = (card1, card2) => card1.sort - card2.sort;

  swapCards = (id1, id2) => {
    // Update the sort field of cards being swapped
    let cards = this.props.tags;
    const card1 = _.find(cards, { _id: id1 });
    const card2 = _.find(cards, { _id: id2 });

    const card1Sort = card1.sort;
    card1.sort = card2.sort;
    card2.sort = card1Sort;

    // Merge updated cards back into orginal
    cards = _.merge(cards, card1, card2);

    // cards.sort(this.compareCards);
    cards = _.sortBy(cards, ['sort']);
    this.props.sortTags(cards);
  };

  render() {
    const { tagType, mode } = this.props;
    // Filter tags by type and mode
    let typeTags;
    if (mode === 'select') {
      typeTags = _.filter(this.props.tags, { tagType, isActive: true });
    } else {
      typeTags = _.filter(this.props.tags, { tagType });
    }

    if (typeTags.length === 0) {
      return (
        <p className="subheader text-center">
          {`No viewable ${tagType} tags`}
          <br />
          <br />
        </p>
      );
    }

    const tagsDnd = typeTags.map(function (tag, i) {
      let baseStyle = 'button round';
      if (tag.isPrivate) { baseStyle = 'button round warning'; }

      // Filter tags by userTags or isActive and set display classes
      let classes;
      if (this.props.userTags) {
        const result = _.some(this.props.userTags, { _id: tag._id });
        classes = (result) ? `${baseStyle} tag-on` : `${baseStyle} hollow tag-off`;
      } else {
        classes = tag.isActive ? `${baseStyle} tag-on` : `${baseStyle} hollow tag-off`;
      }

      const sort = (tag.sort) ? tag.sort : i;
      return (
        <Tag
          key={tag._id}
          index={i}
          id={tag._id}
          tag={tag}
          classes={classes}
          tagType={this.props.tagType}
          sort={sort}
          allowDnd={this.props.allowDnd}
          swapCards={this.swapCards}
          handleTagClick={this.props.handleTagClick}
          handleTagDrop={this.props.handleTagDrop}
          setSelectedTag={this.props.setSelectedTag}
        />
      );
    }, this);

    return (
      <div>
        <ul className="vibe-tags">
          {tagsDnd && tagsDnd}
        </ul>
      </div>
    );
  }
}

TagsContainer.propTypes = {
  handleTagClick: PropTypes.func,
  tags: PropTypes.array.isRequired,
  tagType: PropTypes.string.isRequired,
  mode: PropTypes.string,
};

TagsContainer.defaultProps = {
  mode: 'create',
};

export default ReactDnD.DragDropContext(HTML5Backend)(TagsContainer);
