import constants from '../constants';
import api from '../../../utils/api';
import eventUtils from '../utils/eventUtils';

const EditEvent = (function () {
  const _editEventRequest = function () {
    return {
      type: constants.EDIT_EVENT_REQUEST,
    };
  };

  const _editEventSuccess = function (data) {
    return {
      type: constants.EDIT_EVENT_SUCCESS,
      event: data,
    };
  };

  const _editEventError = function (error) {
    return {
      type: constants.EDIT_EVENT_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.EDIT_EVENT_ERROR,
      errors,
    };
  };

    // var _clearError = function() {
    //    return {
    //        type: constants.CLEAR_EVENT_ERROR
    //    };
    // };

  const _editEvent = function (event, userId, vibeId, orgId, accessToken) {
    return function (dispatch) {
      eventUtils.validateEvent(event, vibeId)
        .then((result) => {
          if (result === true) {
            const eventItem = event;
            eventItem.vibe = vibeId;
            dispatch(_editEventRequest());
            api.put('/proxy/v1/events/', {
              event: eventItem,
              orgId,
            }, accessToken)
              .then((res) => {
                dispatch(_editEventSuccess(res));
              })
              .catch((res) => {
                dispatch(_editEventError(res.response));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    editEvent: _editEvent,
  };
}());

export default EditEvent;
