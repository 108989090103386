import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Toaster from '../components/toaster';
import TagsActions from '../../modules/tags/actions/tags';

import { alertOperations } from '../../modules/alerts';

const { clearAlert } = alertOperations;

const { clearSelectedTag, saveTags, setSelectedTag, sortTags } = TagsActions;

const TagsContainer = require('../components/tagsContainer.jsx').default;
const TagInputModal = require('../components/tags/containers/tagsInputModal.jsx').default;

const TagsSettings = () => {
  // Create REFs for menus
  // const filterMenu = useRef(null);

  const [inputModalIsOpen, setInputModalIsOpen] = useState(false);
  const [tagInputMode, setTagInputMode] = useState('edit');
  const [tagType, setTagType] = useState('');

  const dispatch = useDispatch();

  // Import state vars
  const { alerts, auth, organization, tags } = useSelector(
    (state) => ({
      alerts: state.alerts,
      auth: state.auth,
      organization: state.organization,
      tags: state.tags,
    }),
  );

  const { token } = auth;
  const { data: tagsData } = tags;
  const { isFetching: isFetchingTags } = tagsData;
  const { data: orgData } = organization;
  const { type: orgType } = orgData;
  const { showAlert, alertLabel } = alerts;

  // Check for updated state in selectedTag
  useEffect(() => {
    if (showAlert === true) {
      dispatch(clearAlert());
    }
  }, [showAlert, dispatch]);

  const openInputModal = (mode, tagType) => {
    setInputModalIsOpen(true);
    setTagInputMode(mode);
    setTagType(tagType);
  };

  const closeInputModal = () => {
    dispatch(clearSelectedTag());
    setInputModalIsOpen(false);
  };

  const handleTagClick = () => {
    openInputModal('edit');
  };

  /*  const handleAddTagClick = (tags, tagType) => {
    const newCategory = this.refs.newCategory.value;
    const newLocation = this.refs.newLocation.value;
    const tagTitle = (tagType === 'location') ? newLocation : newCategory;

    this.props.addTag({
      _id: `new_${parseInt((Math.random() * 10000), 10)}`,
      displayName: tagTitle,
      isActive: true,
      orgId: this.props.organization.data._id,
      tagType,
      value: tagTitle,
      sort: (tags.length + 1),
    });
    sortTags(tags);
    this.refs.newCategory.value = '';
    this.refs.newLocation.value = '';
  }; */

  const handleTagDrop = () => {
    const { data: { _id: orgId } } = organization;

    if (tagsData.length > 0) {
      dispatch(saveTags(tagsData, orgId, token));
    }
  };

  const handleSetSelectedTag = (tag) => {
    // Temp until refactoring of components
    dispatch(setSelectedTag(tag));
  };

  // Loading
  if (isFetchingTags) {
    return (
      <div>
        <h1 className="loading">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="row column">
      <Toaster
        message={(alertLabel) ? `${alertLabel} updated successfully` : 'Tag updated successfully'}
        showAlert={showAlert} />

      <TagInputModal
        mode={tagInputMode}
        tagType={tagType}
        inputModalIsOpen={inputModalIsOpen}
        closeInputModal={closeInputModal} />

      {/* Org locations */}
      <div className="callout">
        <div className="row column">
          <button
            type="button"
            className="button small hollow float-right"
            onClick={() => openInputModal('create', 'location')}>
            <i className="fi-plus" /> Add location
          </button>
          <h4><strong>Locations</strong></h4>
          <p className="subheader margin-bottom-15">Click to edit a tag or drag/drop to reorder.</p>
          <TagsContainer
            tags={tagsData}
            tagType="location"
            sortTags={sortTags}
            allowDnd
            handleTagClick={handleTagClick}
            handleTagDrop={handleTagDrop}
            setSelectedTag={handleSetSelectedTag} />
        </div>
      </div>

      {/* Teams */}
      <div className="callout">
        <div className="row column">
          <button
            type="button"
            className="button small hollow float-right"
            onClick={() => openInputModal('create', 'team')}>
            <i className="fi-plus" /> Add team
          </button>
          <h4><strong>Teams</strong></h4>
          <p className="subheader margin-bottom-15">Click to edit a tag or drag/drop to reorder.</p>
          <TagsContainer
            tags={tagsData}
            tagType="team"
            sortTags={sortTags}
            allowDnd
            handleTagClick={handleTagClick}
            handleTagDrop={handleTagDrop}
            setSelectedTag={handleSetSelectedTag} />
        </div>
      </div>

      {/* Industry */}
      { (orgType === 'org') &&
      <div className="callout">
        <div className="row column">
          <button
            type="button"
            className="button small hollow float-right"
            onClick={() => openInputModal('create', 'industry')}>
            <i className="fi-plus" /> Add industry
          </button>
          <h4 className="margin-bottom-15"><strong>Industries</strong></h4>
          <TagsContainer
            tags={tagsData}
            tagType="industry"
            sortTags={sortTags}
            allowDnd
            handleTagClick={handleTagClick}
            handleTagDrop={handleTagDrop}
            setSelectedTag={handleSetSelectedTag} />
        </div>
      </div>}

    </div>
  );
};


TagsSettings.propTypes = {

};

export default TagsSettings;
