import constants from '../constants';
import api from '../../../utils/api';

const GetInvitations = (function () {
  const _requestInvitations = function () {
    return {
      type: constants.REQUEST_INVITATIONS,
    };
  };

  const _receiveInvitations = function (data) {
    return {
      type: constants.RECEIVE_INVITATIONS,
      data: data.invitations,
    };
  };

  const _fetchInvitationsError = function (error) {
    return {
      type: constants.REQUEST_INVITATIONS_ERROR,
      errors: error.data,
    };
  };

  const _fetchInvitations = function (orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestInvitations());
      api.get(`/proxy/v1/organizations/${orgId}/invitations`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveInvitations(res));
        })
        .catch((res) => {
          dispatch(_fetchInvitationsError(res.response));
        });
    };
  };

  const _shouldFetchInvitations = function (state) {
    const invitation = state.invitation.data;
    if (Object.keys(invitation).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchInvitationsIfNeeded = function (orgId, token) {
    return function (dispatch, getState) {
      if (_shouldFetchInvitations(getState(), token)) {
        return dispatch(_fetchInvitations(orgId, token));
      }
      return false;
    };
  };

  return {
    fetchInvitationsIfNeeded: _fetchInvitationsIfNeeded,
  };
}());

export default GetInvitations;
