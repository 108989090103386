const _ = require('lodash');
const clip = require('text-clipper');
const linkifyHtml = require('linkifyjs/html');
const moment = require('moment');
// const tldjs = require('tldjs');

// const tld = tldjs.fromUserSettings({
//  validHosts: ['localhost'],
// });

const utils = (function () {
  const _splitName = function (fullName) {
    const names = fullName.split(' ');
    return {
      first: names.shift().trim(),
      last: names.shift().trim() || '',
    };
  };

  const _splitLocation = function (location) {
    const formattedLocation = location.split(',');
    return {
      city: formattedLocation.shift().trim(),
      state: formattedLocation.shift().trim() || '',
    };
  };

  const _splitOrg = function (url, suffix) {
    const urlSplit = url.split(suffix);
    return {
      id: urlSplit.shift().trim(),
      teamDomain: urlSplit.shift().trim() || '',
    };
  };

  const _upsert = function (arr, key, newval) {
    const match = _.find(arr, { _id: key });
    if (match) {
      const index = _.indexOf(arr, _.find(arr, { _id: key }));
      arr.splice(index, 1, newval);
    } else {
      arr.push(newval);
    }

    return arr;
  };

  const _unshift = function (arr, newval) {
    arr.unshift(newval);
    return arr;
  };

  const _remove = function (arr, key) {
    const match = _.find(arr, { _id: key });

    if (match) {
      const index = _.indexOf(arr, _.find(arr, { _id: key }));
      arr.splice(index, 1);
    }

    return arr.filter(Boolean);
  };

    var _upsertByField = function (arr, field, key, newval) {
        var match = _.find(arr, {field:key});
        if(match){
            var index = _.indexOf(arr, _.find(arr, {field:key}));
            arr.splice(index, 1);
        }
        return arr.filter(Boolean);
    };

  const _removeByField = function (arr, key, field) {
    const match = _.find(arr, item => _.isEqual(item[field], key));

    if (match) {
      const index = _.indexOf(arr, _.find(arr, { field: key }));
      arr.splice(index, 1);
    }
    return arr.filter(Boolean);
  };

    var _find = function (arr, key) {
        var match = _.find(arr, {"_id":key});
        if(match){
            return true;
        }
        return false;
    };

    var _getOrgVibe = function(orgVibes, vibeId) {
    const orgVibe = _.find(orgVibes, { vibe: vibeId });
    return orgVibe;
    };

    var _findVibeTag = function(vibes, vibeId, tagId) {
        var tags = vibes.filter(function(vibe) {
            return vibe.vibe === vibeId;
        }).map(function(vibe) {
            if(vibe.tags) {
                return vibe.tags;
            } else {
                return false;
            }
        });
        var found = false;
        if(tags.length > 0) {
            Object.keys(tags[0]).forEach(function (key) {
                if(undefined !== tags[0][key] && tags[0][key] === tagId) {
                    found =  true;
                }
            });
        }
        return found;
    };

    var _get = function(arr, key) {
        var object = {};
        var match = _.find(arr, {"_id":key});
        if(match) {
            var index = _.indexOf(arr, _.find(arr, {"_id":key}));
            object = arr[index];
        }
        return object;
    };

  /* Permission Checks */
  const _userIsVibeChampion = (userVibes, vibeId) => {
    const match = _.find(userVibes, { vibe: vibeId });
    if (match) {
      const index = _.indexOf(userVibes, _.find(userVibes, { vibe: vibeId }));
      const vibe = userVibes[index];
      return vibe.isChampion;
    }

    return false;
  };

  const _userIsVibeMember = function (userVibes, vibeId) {
    const vibeMatch = _.some(userVibes, { vibe: vibeId });

    return vibeMatch;
  };

  // Not currently used - need to refactor for combined tags list
  const _userIsVibeTeamMember = function (organizationId, user, vibeTeams) {
    //console.log(organizationId);
    //console.log(user);
    //console.log(vibeTeams);
    const userCurrentOrg = _.find(user.orgs, { id: organizationId });
    const teamMatch = (_.intersection(userCurrentOrg.teams, vibeTeams).length !== 0);

    //console.log(teamMatch);
    return teamMatch;
  };

    var _userIsVibeActive = function(userVibes, vibeId) {
        /*var orgId = organization.data._id;
        if(!userOrganizations) {
            return false;
        }
        var orgs = userOrganizations.filter(function(org) {
            return org.id === orgId;
        }).
        map(function(org) {
            return org.vibes;
        });

        if(orgs.length > 0) {
            var vibe = null;
            Object.keys(orgs[0]).forEach(function (key) {
                if(orgs[0][key]._id === vibeId) {
                    vibe = orgs[0][key];
                }
            });
            if(null !== vibe) {
                return vibe.isActive;
            }
        }
        return false;*/
        var vibe = {};
        var match = _.find(userVibes, {"vibe": vibeId});
        if(match) {
            var index = _.indexOf(userVibes, _.find(userVibes, {"vibe": vibeId}));
            vibe = userVibes[index];
            return vibe.isActive;
        }
        return false;
    };

  const _userIsTagAdmin = (userId, tagList, orgTags) => {
    const tags = _(orgTags).keyBy('_id').at(tagList).value();
    const filteredTags = _.filter(tags, { isActive: true });
    const tagAdminIds = _.compact(_.flatMap(filteredTags, 'tagAdmin'));

    return _.includes(tagAdminIds, userId);
  };

  const _userIsOrgAdmin = (organizationId, userOrganizations) => {
    if (!userOrganizations) {
      return false;
    }
    const orgs = userOrganizations.filter(org => org.id === organizationId);
    if (orgs.length > 0) {
      return orgs[0].isAdmin;
    }
    return false;
  };

  const _userIsOrgActive = (organizationId, userOrganizations) => {
    if (!userOrganizations) {
      return false;
    }
    const orgs = userOrganizations.filter(org => org.id === organizationId);
    if (orgs.length > 0) {
      return orgs[0].isActive;
    }
    return false;
  };

  const _filterBoardsByAdmin = (boards, user, organization, orgTags) => {
    const { _id: orgId } = organization;
    const { _id: userId, orgs: userOrgs } = user;

    // First check for admin
    if (_userIsOrgAdmin(orgId, userOrgs)) {
      return boards;
    }

    // Second check for tagAdmin or champion
    const filteredBoards = _.filter(boards, (board) => {
      const { orgOptions: { tags: boardTags = [] } = {} } = board;
      const isTagAdmin = _userIsTagAdmin(userId, boardTags, orgTags);
      const championIds = _.map(board.champion, '_id');
      return isTagAdmin || _.includes(championIds, userId);
    });

    return filteredBoards;
  };

  const _filterTagsByAdmin = (tags, userId, userIsOrgAdmin) => {
  // Filter tags based on isActive, tagAdmin
    const filteredTags = tags.filter((tag) => {
      const isTagAdmin = _.includes(tag.tagAdmin, userId);
      return tag.isActive && (userIsOrgAdmin || isTagAdmin);
    });

    return filteredTags;
  };

  const _filterMembersByAdmin = (user, orgMembers, orgId, orgTags) => {
    const { _id: userId, orgs: userOrgs } = user;

    // First check for admin
    if (_userIsOrgAdmin(orgId, userOrgs)) {
      return orgMembers;
    }

    // Get ids of tags user can manage
    const filteredTags = _.filter(orgTags, tag => tag.isActive && _.includes(tag.tagAdmin, userId));
    const tagIds = _.compact(_.flatMap(filteredTags, '_id'));

    // Filter orgMembers with matching tags
    const filteredMembers = _.filter(orgMembers, (member) => {
      const userCurrentOrg = _.find(member.orgs, { id: orgId });
      return _.intersection(tagIds, userCurrentOrg.tags).length !== 0;
    });

    return filteredMembers;
  };

  const _findTopParent = (orgBoards = [], board = {}) => {
    const { accessLevel, isChild, parent } = board;

    // Board is not child or inherited
    if (!isChild || !accessLevel === 'inherit') {
      return board;
    }

    const parentBoard = orgBoards.find((o) => o._id === parent);
    if (parentBoard && parentBoard.accessLevel !== 'inherit') {
      return parentBoard;
    }

    return _findTopParent(orgBoards, parentBoard);
  };

    var _userhasOrgGoogleTokens = function(organizationId, userOrganizations) {
        var orgs = userOrganizations.filter(function(org) {
            return org.id === organizationId;
        });
        if(orgs.length > 0) {
            if(orgs[0].google !== undefined && Object.keys(orgs[0].google).length !== 0) {
                return true;
            }
        }
        return false;
    };

  const _userGetLastVibeVisit = function (userVibes, vibeId) {
    const match = _.find(userVibes, { vibe: vibeId });
    if (match) {
      return _.find(userVibes, { vibe: vibeId }).lastVisit;
    }

    // This will show new activity to non-vibe members. But can't
    // use until vibe visits are logged via subscriptions.
    // return moment().utc().add(-1, 'days').toISOString();
    return false;
  };

    var _checkPublicOrgAccess = function(organization) {
        if(undefined !== organization.preferences.publicView) {
            return organization.preferences.publicView;
        } else {
            return false;
        }
    };

    var _getUserLastVisit = function(organizationId, userOrganizations) {
        var orgs = userOrganizations.filter(function(org) {
            return org.id === organizationId;
        });
        if(orgs.length > 0) {
            return orgs[0].lastLogin;
        }
        return false;
    };

    var _truncateUrl = function(value) {
        return value.length > 30 ? value.slice(0, 30) + '…' : value
    };

  const _getEnvironment = (location) => {
    let server = 'production';
    if (_.includes(location, 'staging.')) {
      server = 'staging';
    } else if (_.includes(location, 'localhost')) {
      server = 'development';
    }

    return server;
  };

    var _buildUrlByEnvironment = function(orgDomain, parameters, environment) {
        if('development' === environment) {
            return 'http://'+orgDomain+'.localhost:8081/'+parameters;
        } else if('staging' === environment) {
            return 'https://'+orgDomain+'.staging.teamvibe.com/'+parameters;
        } else {
            return 'https://'+orgDomain+'.teamvibe.com/'+parameters;
        }
    };

    var _buildGoogleUrlByEnviroment = function(orgDomain, parameters, environment) {
        if('development' === environment) {
            return 'http://127.0.0.1:8081/'+parameters;
        } else if('staging' === environment) {
            return 'https://'+orgDomain+'.staging.teamvibe.com/'+parameters;
        } else {
            return 'https://'+orgDomain+'.teamvibe.com/'+parameters;
        }
    };



  const _mergeVibesWithOrgVibes = function (vibes, orgVibes) {
    // Assign orgVibe options to vibe.orgOptions
    const mergedVibes = _.map(vibes, obj => (
      _.assign(
        obj,
        {
          orgOptions:
          _.pick(
            _.find(orgVibes, { vibe: obj._id }),
            ['tags', 'teams', 'accessLevel',
              'isActive', 'isApproved', 'approvedDate',
              'isPinned', 'allUser', 'isChild', 'parent'],
          ),
        },
      )
    ));

    return mergedVibes;
  };

  const _sortVibes = function (vibes) {
    // Sort vibe list by isPinned, sort, lastActivity, approvedDate
    let sortedVibes = vibes;
    sortedVibes = _.orderBy(
      sortedVibes,
      [function (o) { return (o.orgOptions && o.orgOptions.isPinned) || false; },
        function (o) { return (o.orgOptions && o.orgOptions.sort) || 999; },
        function (o) {
          if (o.lastActivity) {
            return moment(o.lastActivity.activityDate);
          }
          return moment().subtract(1, 'years');
        },
        'orgOptions.approvedDate'],
      ['desc', 'asc', 'desc', 'desc'],
    );

    return sortedVibes;
  };

  const _getUserVibes = function (userVibes, orgVibes) {
    const userOrgVibes = userVibes.map(userVibe => userVibe.vibe);

    let vibeList = _(orgVibes).keyBy('_id').at(userOrgVibes).value();

    vibeList = vibeList.filter(Boolean);
    return vibeList;
  };

  const _getUserVibesCanPost = function (userVibes, orgVibes, isAdmin) {
    let vibeList = [];

    if (isAdmin) {
      // Admins can post in any vibe
      vibeList = orgVibes;
    } else {
      vibeList = orgVibes.filter((vibe) => {
        const isChampion = userVibes.find((userVibe) => (userVibe.vibe === vibe._id && userVibe.isChampion));
        return (vibe.memberPosting === true || isChampion);
      });
    }

    return vibeList;
  };

  const _checkSubscriptions = function (type, content, subscriptions) {
    const subscription = _.find(subscriptions, { ref: content._id });
    let alertText;
    switch (type) {
    case 'post': if (content.meta.replyCount > 0) { alertText = 'New reply'; } break;
    case 'event': alertText = 'New RSVP'; break;
    default: alertText = 'New reply';
    }

    const lastVisit = (subscription && subscription.lastVisit)
      ? subscription.lastVisit
      : moment().utc().add(-7, 'days');

    if (moment(content.meta.latestPost).isAfter(moment(lastVisit))) {
      return alertText;
    }
    return '';
  };

    var _getUserTags = function(userCurrentOrg, orgTags) {
		// Key org tags list against user.org.tags and re-sort
		var userTags = (userCurrentOrg && userCurrentOrg.hasOwnProperty("tags")) ? userCurrentOrg.tags : [];
		userTags = _(orgTags).keyBy('_id').at(userTags).value();
		userTags = userTags.filter( Boolean );
		userTags = _.sortBy(userTags, ['sort']);

		return userTags;
    };

  const _getVibeTags = function (vibe, orgTags) {
    let vibeTags = (vibe && vibe.orgOptions && ('tags' in vibe.orgOptions)) ? vibe.orgOptions.tags : [];
    vibeTags = _(orgTags).keyBy('_id').at(vibeTags).value();
    vibeTags = vibeTags.filter(Boolean);
    vibeTags = _.sortBy(vibeTags, ['sort']);

    return vibeTags;
  };

  // Not currently used - need to refactor for combined tags list
    var _getUserTeams = function(userCurrentOrg, orgTags) {
        // Key org teams list against user.org.teams and re-sort
        var userTeams = (userCurrentOrg && userCurrentOrg.hasOwnProperty("teams")) ? userCurrentOrg.teams : [];
        userTeams = _(orgTags).keyBy('_id').at(userTeams).value();
        userTeams = userTeams.filter( Boolean );
        userTeams = _.sortBy(userTeams, ['sort']);

        return userTeams;
    };

  /* Image Manipulation */
  const _formatImage = function (imageUrl, type) {
    let formattedImage;

    if (imageUrl && imageUrl.includes('cloudinary.com')) {
      const baseUrl = 'https://res.cloudinary.com/teamvibe/image/upload';
      const imageFile = imageUrl.split('/').reverse()[0];
      let params;
      let folder;
      let version;
      let pathFile;

      // Check for /uploads directory
      if (imageUrl.includes('uploads')) {
        folder = imageUrl.split('/').reverse()[2];
        folder = folder + '/uploads';

        /* Check for presense of version (v+10numbers) */
        if (/^[v][0-9]{10}/.test(imageUrl.split('/').reverse()[3])) {
          version = imageUrl.split('/').reverse()[3];
        }
      } else {
        folder = imageUrl.split('/').reverse()[1];

        /* Check for presense of version (v+10numbers) */
        if (/^[v][0-9]{10}/.test(imageUrl.split('/').reverse()[2])) {
          version = imageUrl.split('/').reverse()[2];
        }
      }

      // Build back half of image url (pathFile)
      if (version) {
        pathFile = `${version}/${folder}/${imageFile}`;
      } else {
        pathFile = `${folder}/${imageFile}`;
      }

      if (type === 'vibeThumb') {
        params = 'w_200';
        formattedImage = `${baseUrl}/${params}/${pathFile}`;
      } else if (type === 'vibePhoto') {
        // params = 'if_ar_gt_3:4_and_w_gt_300_and_h_gt_200';
        // formattedImage = baseUrl+'/'+params+'/'+version+'/'+folder+'/'+imageFile;
        formattedImage = `${baseUrl}/${pathFile}`;
      } else if (type === 'userPhoto') {
        params = 'w_200,h_200,c_thumb,g_face,z_0.7';
        formattedImage = `${baseUrl}/${params}/${pathFile}`;
      } else if (type === 'userThumb') {
        params = 'w_48,h_48,c_thumb,g_face,z_0.7';
        formattedImage = `${baseUrl}/${params}/${pathFile}`;
      } else if (type === 'ctaPhoto') {
        params = 'w_240,h_240,c_fill';
        formattedImage = `${baseUrl}/${params}/${pathFile}`;
      } else {
        formattedImage = imageUrl;
      }
    } else {
      /* Pass though (LinkedIn or external photo) */
      formattedImage = imageUrl;
    }

    return formattedImage;
  };

  const _uploadImage = function (options, callback) {
    const uploadOptions = options || {};
    // var imagePublicId = (typeof options.publicId !== 'undefined') ? options.publicId : 0;
    // var imageFolder = (typeof options.folder !== 'undefined') ? options.folder : 'core';

    window.cloudinary.openUploadWidget({
      cloud_name: 'teamvibe',
      upload_preset: (uploadOptions.uploadPreset) ? uploadOptions.uploadPreset : 'gfbcov7m',
      multiple: false,
      sources: ['local', 'url', 'facebook', 'instagram'],
      // sources: [ 'local', 'url', 'image_search', 'facebook', 'instagram', 'dropbox'],
      // google_api_key: 'AIzaSyC7KKSIAXF8Xeb74loTvoUMx3MaaRWQY-Q',
      // dropbox_app_key: 'e2xnnxw7evv59nu',
      // search_by_rights: true,
      // Public_id works but overwriting of same file name only works on signed uploads
      // public_id: (uploadOptions.publicId ? uploadOptions.publicId : null),
      folder: (uploadOptions.folder ? uploadOptions.folder : 'core'),
      client_allowed_formats: ['png', 'gif', 'jpeg', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'],
      max_file_size: (uploadOptions.maxFileSize) ? uploadOptions.maxFileSize : null,
      max_image_width: (uploadOptions.maxImageWidth) ? uploadOptions.maxImageWidth : 1000,
      max_image_height: 1000,
      min_image_width: (uploadOptions.minImageWidth) ? uploadOptions.minImageWidth : null,
      theme: 'white',
      show_powered_by: false,
    }, (error, result) => {

      if (result.event === 'success') {
        callback(result.info);
      }

      if (error) {
        callback(null, error);
      }
    });
  };

  const _validateImageUrl = function (url) {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  };

  const _showDefaultImage = function (ev) {
    ev.target.src = 'https://res.cloudinary.com/teamvibe/image/upload/product/blank-profile-picture.png';
  };

  const _showDefaultVibeImage = function (ev) {
    ev.target.src = 'https://res.cloudinary.com/teamvibe/image/upload/v1473058129/product/placeholder-image.png';
  };

  const _getFileThumbnail = function (fileType) {
    let thumbnail;
    switch (fileType) {
    case 'doc':
    case 'docx':
      thumbnail = 'https://res.cloudinary.com/teamvibe/image/upload/product/document-doc.png';
      break;
    case 'pdf':
      thumbnail = 'https://res.cloudinary.com/teamvibe/image/upload/product/document-pdf.png';
      break;
    case 'ppt':
    case 'pptx':
      thumbnail = 'https://res.cloudinary.com/teamvibe/image/upload/product/document-ppt.png';
      break;
    case 'xls':
    case 'xlsx':
      thumbnail = 'https://res.cloudinary.com/teamvibe/image/upload/product/document-xls.png';
      break;
    default: thumbnail = '';
    }

    return thumbnail;
  };

  /* Text manipulation */
  const _formatTime = (date) => {
    const leadingZero = num => `0${num}`.slice(-2);
    return [date.getHours(), date.getMinutes()].map(leadingZero).join(':');
  };

  const _formatPreview = function (html) {
    let elements;
    let thumbnail;
    const div = document.createElement('div');
    div.innerHTML = html;

    // Look for images in content and make 1st one featured image (need to happen before scrub below)
    const images = div.getElementsByTagName('img');
    const imageCount = images.length;
    if (images[0] && (!thumbnail && _validateImageUrl(images[0].src))) { thumbnail = images[0].src; }

    while (images[0]) images[0].parentNode.removeChild(images[0]);

    const videos = div.getElementsByTagName('iframe');
    while (videos[0]) videos[0].parentNode.removeChild(videos[0]);

    // Clear <br> tags caused by extra lines around images in draft-js
    elements = div.getElementsByTagName('br');
    while (elements[0]) elements[0].parentNode.removeChild(elements[0]);

    // Clear <figure> tags
    elements = div.getElementsByTagName('figure');
    while (elements[0]) elements[0].parentNode.removeChild(elements[0]);

    // Truncate urls and format into span tags w/onclick
    const options = {
      tagName: 'span',
      className: 'link',
      format: _truncateUrl,
      attributes: { onclick: "window.open(event.target.getAttribute('href'), 'external'); return false" },
    };
    const content = linkifyHtml(div.innerHTML, options);

    // Trim final content
    const shortPreview = clip(content, 200);
    const longPreview = clip(content, 500);

    return { preview: shortPreview, longPreview, imageCount, featuredImage: thumbnail };
  };

    var _contentTruncate = function( n, useWordBoundary ){
        var isTooLong = this.length > n,
            s_ = isTooLong ? this.substr(0,n-1) : this;
            s_ = (useWordBoundary && isTooLong) ? s_.substr(0,s_.lastIndexOf(' ')) : s_;
        return  isTooLong ? s_ + '...' : s_;
    };

    var _getMentionsFromContent = function(rawContent) {
        var mentions = [];
        var raw = rawContent ? JSON.parse(rawContent) : {};
        for (var key in raw.entityMap) {
            if (raw.entityMap.hasOwnProperty(key)) {
                var obj = raw.entityMap[key];
                if("mention" === obj.type) {
                    mentions.push(obj.data.mention);
                }
            }
        }
        return mentions;
    };

    var _userReactedPost = function(userId, reactions) {
        for(var i = 0;i < reactions.length;i++) {
            if(reactions[i].user === userId) {
                return true;
            }
        }
        return false;
    };

    var _humanizeReactCounter = function(reactions) {
        var sentence = '';
        reactions = _.sortBy(reactions, function(reaction) {
            return reaction.reactedOn;
        });

        if(reactions.length > 2) {
            sentence = reactions[0].fullName+' and '+reactions[1].fullName+' and '+(reactions.length-2)+' people like this';
        } else if(reactions.length === 2) {
            sentence = reactions[0].fullName+' and '+reactions[1].fullName+' like this';
        } else if(reactions.length === 1) {
            sentence = reactions[0].fullName+' likes this';
        } else {
            sentence = '\xa0';
        }
        return sentence;
    };

  return {
    splitName: _splitName,
    splitLocation: _splitLocation,
    splitOrg: _splitOrg,
    upsert: _upsert,
    upsertByField: _upsertByField,
    removeByField: _removeByField,
    unshift: _unshift,
    remove: _remove,
    find: _find,
    findVibeTag: _findVibeTag,
    getOrgVibe: _getOrgVibe,
    get: _get,
    userIsVibeChampion: _userIsVibeChampion,
    userIsVibeMember: _userIsVibeMember,
    userIsVibeTeamMember: _userIsVibeTeamMember,
    userIsVibeActive: _userIsVibeActive,
    userIsTagAdmin: _userIsTagAdmin,
    userIsOrgAdmin: _userIsOrgAdmin,
    userIsOrgActive: _userIsOrgActive,
    filterBoardsByAdmin: _filterBoardsByAdmin,
    filterTagsByAdmin: _filterTagsByAdmin,
    filterMembersByAdmin: _filterMembersByAdmin,
    findTopParent: _findTopParent,
    userhasOrgGoogleTokens: _userhasOrgGoogleTokens,
    userGetLastVibeVisit: _userGetLastVibeVisit,
    checkPublicOrgAccess: _checkPublicOrgAccess,
    getUserLastVisit: _getUserLastVisit,
    truncateUrl: _truncateUrl,
    getEnvironment: _getEnvironment,
    buildUrlByEnvironment: _buildUrlByEnvironment,
    buildGoogleUrlByEnviroment: _buildGoogleUrlByEnviroment,
    mergeVibesWithOrgVibes: _mergeVibesWithOrgVibes,
    sortVibes: _sortVibes,
    getUserVibes: _getUserVibes,
    getUserVibesCanPost: _getUserVibesCanPost,
    checkSubscriptions: _checkSubscriptions,
    getUserTags: _getUserTags,
    getVibeTags: _getVibeTags,
    getUserTeams: _getUserTeams,
    formatImage: _formatImage,
    uploadImage: _uploadImage,
    validateImageUrl: _validateImageUrl,
    showDefaultImage: _showDefaultImage,
    showDefaultVibeImage: _showDefaultVibeImage,
    getFileThumbnail: _getFileThumbnail,
    formatTime: _formatTime,
    formatPreview: _formatPreview,
    contentTruncate: _contentTruncate,
    getMentionsFromContent: _getMentionsFromContent,
    userReactedPost: _userReactedPost,
    humanizeReactCounter: _humanizeReactCounter,
  };
}());

export default utils;
