
export default {
  CREATE_INVITATION_REQUEST: 'CREATE_INVITATION_REQUEST',
  CREATE_INVITATION_SUCCESS: 'CREATE_INVITATION_SUCCESS',
  CREATE_INVITATION_ERROR: 'CREATE_INVITATION_ERROR',
  CREATE_INVITATIONS_REQUEST: 'CREATE_INVITATIONS_REQUEST',
  CREATE_INVITATIONS_SUCCESS: 'CREATE_INVITATIONS_SUCCESS',
  CREATE_INVITATIONS_ERROR: 'CREATE_INVITATIONS_ERROR',
  REQUEST_INVITATION: 'REQUEST_INVITATION',
  RECEIVE_INVITATION: 'RECEIVE_INVITATION',
  REQUEST_INVITATION_ERROR: 'REQUEST_INVITATION_ERROR',
  REQUEST_INVITATIONS: 'REQUEST_INVITATIONS',
  RECEIVE_INVITATIONS: 'RECEIVE_INVITATIONS',
  REQUEST_INVITATIONS_ERROR: 'REQUEST_INVITATIONS_ERROR',
  DELETE_INVITATION_REQUEST: 'DELETE_INVITATION_REQUEST',
  DELETE_INVITATION_SUCCESS: 'DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_ERROR: 'DELETE_INVITATION_ERROR',
  CLEAR_INVITE: 'CLEAR_INVITE',
  CLEAR_GET_INVITE_ERROR: 'CLEAR_GET_INVITE_ERROR',
};
