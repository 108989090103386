const React = require('react');
const { Link, withRouter } = require('react-router');
const PropTypes = require('prop-types');
const SurveyModal = require('../../components/surveys/containers/surveyModal.jsx').default;
const utils = require('../../../../common/utils').default;

class Survey extends React.PureComponent {
    state = { modalIsOpen: true };

    componentDidMount() {
      // this.props.fetchVibeIfNeeded(this.props.params.name, this.props.organization, this.props.auth, this.props.auth.token);
    }

    openModal = () => {
      this.setState({ modalIsOpen: true });
    };

    openDiscussionInputModal = (type, post) => {
      this.props.setPost(post, false);
      this.setState({ modalIsOpen: true, type });
    };

    closeModal = () => {
      this.setState({ modalIsOpen: false });
    };

    render() {
      const survey = {
        _id: '56e349f6f436f83738566f14',
        title: 'All-staff Vibe',
        description: 'All-company events and announcements.',
        post: '5716a19b755c0b8c123dfa48',
        orgId: '56958544240f46eeaf4d58cc',
        created: new Date(),
        closed: new Date(),
        isActive: true,
        isRequired: true,
        creator: '56e5fb53f436f83738566f26',
        questions: [
          {
            title: 'Question 1',
            description: 'This is question 1',
            mediaUrl: 'http://p3cdn4static.sharpschool.com/UserFiles/Servers/Server_1051846/Image/News/survey.jpg',
            survey: '56e349f6f436f83738566f14',
            isActive: true,
            isRequired: true,
            isMulti: false,
            choices: ['Easy', 'Medium', 'Hard'],
          },
          {
            title: 'Question 2',
            description: 'This is question 2',
            mediaUrl: 'http://p3cdn4static.sharpschool.com/UserFiles/Servers/Server_1051846/Image/News/survey.jpg',
            survey: '56e349f6f436f83738566f14',
            isActive: true,
            isRequired: true,
            isMulti: false,
            choices: ['Easy', 'Medium', 'Hard'],
          },
        ],
        responses: [
          {
            responder: '56e5fbbaf436f83738566f2e',
            survey: '56e349f6f436f83738566f14',
            submitted: new Date(),
            edited: new Date(),
          },
        ],
      };
      const surveyIsFetching = false;
      // Loading
      if (surveyIsFetching) {
        return (
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        );
      }

      return (
        <div>
          <h1>HELLO WORLD</h1>

          <SurveyModal
            modalIsOpen={this.state.modalIsOpen}
            closeModal={this.closeModal}
            survey={survey} />
        </div>
      );
    }
}

Survey.propTypes = {

};

export default withRouter(Survey);
