var React = require('react');
var { Helmet } = require('react-helmet');

class Privacy extends React.Component {
    render() {
      return (
          <div>
              <Helmet title="Privacy Policy "/>
              <div className="row">
                  <div>
                      <h2><strong>Teamvibe Privacy Policy</strong></h2>
                  </div>
              </div>
              
          </div>
      )
    }
}

export default Privacy;