import constants from '../constants';

const initialState = {
  isFetching: true,
  updated: false,
  data: {
    digest: {},
  },
  error: {},

};

const selectedTag = function (state = initialState, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.CREATE_TAG_SUCCESS:
    newstate.isFetching = false;
    newstate.data = {};
    return newstate;
  case constants.CREATE_TAG_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.UPDATE_TAG_SUCCESS:
    return {
      ...state,
      isFetching: false,
      updated: true,
      data: {},
    };
  case constants.UPDATE_TAG_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_TAG_ERROR:
    newstate.isFetching = false;
    newstate.error = {};
    return newstate;
  case constants.SET_SELECTED_TAG:
    return {
      ...state,
      isFetching: false,
      data: { ...state.data, ...action.tag },
    };
  case constants.CLEAR_SELECTED_TAG:
    return {
      isFetching: false,
      updated: false,
      data: {},
      error: {},
    };
  default: return state || initialState.selectedTag;
  }
};

export default selectedTag;
