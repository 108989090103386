import constants from '../constants';
import api from '../../../utils/api';
import eventsActions from './events';

const ChangeRSVP = (function () {
  const _changeRSVPRequest = function () {
    return {
      type: constants.CHANGE_RSVP_REQUEST,
    };
  };

  const _changeRSVPSuccess = function (data) {
    return {
      type: constants.CHANGE_RSVP_SUCCESS,
      event: data,
    };
  };

  const _changeRSVPError = function (error) {
    return {
      type: constants.CHANGE_RSVP_ERROR,
      errors: error.data,
    };
  };

  const _changeRSVP = function (eventId, userId, status, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_changeRSVPRequest());
      api.put(`/proxy/v1/events/${eventId}`, {
        userId,
        status,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(eventsActions.setSelectedEvent(res.event));
          dispatch(_changeRSVPSuccess(res, eventId, userId, status));
        })
        .catch((res) => {
          dispatch(_changeRSVPError(res));
        });
    };
  };

  return {
    changeRSVP: _changeRSVP,
  };
}());

export default ChangeRSVP;
