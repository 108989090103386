import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MembersActions from '../../../../modules/vibes/actions/vibe-members';
import CreateInvitationsActions from '../../../../modules/invitations/actions/create-invitations';
import userInvite from '../components/userInvite.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    selectedVibe: state.selectedVibe,
    invitation: state.invitation,
    vibes: state.vibes,
    tags: state.tags,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({}, MembersActions, CreateInvitationsActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(userInvite);
