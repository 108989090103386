import { routerActions } from 'react-router-redux';
import _ from 'lodash';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const Discussions = (function () {
  const _requestDiscussions = function () {
    return {
      type: constants.REQUEST_DISCUSSIONS,
    };
  };

  const _receiveDiscussions = function (data) {
    return {
      type: constants.RECEIVE_DISCUSSIONS,
      discussions: data.discussions,
    };
  };

  const _appendDiscussions = function (data) {
    return {
      type: constants.APPEND_DISCUSSIONS,
      discussions: data.discussions,
    };
  };

  const _fetchDiscussionsError = function (error) {
    return {
      type: constants.REQUEST_DISCUSSIONS_ERROR,
      errors: error.data,
    };
  };

  const _setFilteredDiscussions = function (data) {
    return {
      type: constants.RECEIVE_DISCUSSIONS,
      discussions: data,
    };
  };

  const _filterDiscussionsByUserVibes = function (discussions, vibes) {
    return function (dispatch) {
      const lookup = _.indexBy(vibes, vibe => vibe._id._id);

      const filteredDiscussions = _.filter(discussions, discussion =>
        lookup[discussion.vibe._id] !== undefined);
      return dispatch(_setFilteredDiscussions(filteredDiscussions));
    };
  };

  const _fetchDiscussions = function (vibeId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestDiscussions());
      api.get(`/proxy/v1/vibes/${vibeId}/discussions`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveDiscussions(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchDiscussionsError(res));
          }
        });
    };
  };

  const _fetchNewOrgDiscussions = function (orgId, startDate, endDate, append, accessToken) {
    return function (dispatch) {
      dispatch(_requestDiscussions());
      api.get(`/proxy/v1/organization/${orgId}/discussions/new`, {
        orgId,
        startDate,
        endDate,
      }, accessToken)
        .then((res) => {
          if (append) {
            dispatch(_appendDiscussions(res));
          } else {
            dispatch(_receiveDiscussions(res));
          }
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchDiscussionsError(res));
          }
        });
    };
  };

  const _shouldFetchDiscussions = function (vibeId, state) {
    const discussions = state.discussions.data;
    if (Object.keys(discussions).length === 0) {
      return true;
    } else if (discussions[0].vibe !== vibeId) {
      return true;
    }
    return false;
  };

  const _fetchDiscussionsIfNeeded = function (vibeId, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchDiscussions(vibeId, getState())) {
        return dispatch(_fetchDiscussions(vibeId, orgId, accessToken));
      }
      return false;
    };
  };

  const _setDiscussion = function (discussion) {
    return {
      type: constants.SET_SELECTED_DISCUSSION,
      discussion,
    };
  };

  const _setPost = function (post, unsaved) {
    return {
      type: constants.SET_POST,
      post,
      unsaved,
    };
  };

  const _clearPost = function () {
    return {
      type: constants.CLEAR_POST,
    };
  };

  const _setPostError = function (errorObj) {
    return {
      type: constants.SET_POST_ERROR,
      errorObj,
    };
  };

  return {
    fetchDiscussionsIfNeeded: _fetchDiscussionsIfNeeded,
    fetchDiscussions: _fetchDiscussions,
    fetchNewOrgDiscussions: _fetchNewOrgDiscussions,
    setDiscussion: _setDiscussion,
    setPost: _setPost,
    clearPost: _clearPost,
    setPostError: _setPostError,
    filterDiscussionsByUserVibes: _filterDiscussionsByUserVibes,
  };
}());

export default Discussions;
