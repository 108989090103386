import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserEditActions from '../../../modules/auth/actions/editUser';
import LeaveVibeActions from '../../../modules/auth/actions/leaveVibe';
import AuthSocialIdentityActions from '../../../modules/auth/actions/auth-social-identity';
import UserSettings from '../components/userSettings.jsx';

var mapStateToProps = function(state) {
    return {
        auth: state.auth,
        organization: state.organization,
        user: state.user,
        tags: state.tags,
        vibes: state.vibes
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, UserEditActions, LeaveVibeActions, AuthSocialIdentityActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
