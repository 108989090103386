import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VibeActions from '../../../modules/vibes/actions/vibe';
import CreateVibeActions from '../../../modules/vibes/actions/vibe-create';
import UpdateVibeActions from '../../../modules/vibes/actions/vibe-update';
import DeleteVibeActions from '../../../modules/vibes/actions/vibe-delete';
import JoinVibeActions from '../../../modules/auth/actions/joinVibe';
import LeaveVibeActions from '../../../modules/auth/actions/leaveVibe';
import ApproveOrgVibeActions from '../../../modules/organizations/actions/approveOrgVibe';
import RejectOrgVibeActions from '../../../modules/organizations/actions/rejectOrgVibe';
import Vibe from '../components/vibe.jsx';
import MembersActions from '../../../modules/vibes/actions/vibe-members';
import EditVibeUserActions from '../../../modules/vibes/actions/vibe-edit-user';
import RemoveVibeUserActions from '../../../modules/vibes/actions/vibe-remove-user';
import TagsActions from '../../../modules/tags/actions/tags';
import MentionsActions from '../../../modules/mentions/actions/mentions';
import DiscussionsActions from '../../../modules/discussions/actions/discussions';
import UpdateVibeOverviewActions from '../../../modules/vibes/actions/updateVibeOverview';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        vibes: state.vibes,
        selectedVibe: state.selectedVibe,
        discussions: state.discussions,
        tags: state.tags,
        mentions: state.mentions,
        post: state.post
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({},
    	VibeActions, JoinVibeActions, LeaveVibeActions,
    	MembersActions, TagsActions, UpdateVibeActions, CreateVibeActions, DeleteVibeActions, ApproveOrgVibeActions, RejectOrgVibeActions, EditVibeUserActions, RemoveVibeUserActions, 
    	MentionsActions, DiscussionsActions, UpdateVibeOverviewActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Vibe);
