const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const cookie = require('react-cookie');

class OrgConfirmation extends React.PureComponent {
  render() {
    // Get tokens from cookie for redirect to new subdomain
    const tvCookieAt = cookie.load('tv_at');
    const tvCookieRt = cookie.load('tv_rt');
    const accessToken = (tvCookieAt) ? tvCookieAt.accessToken : undefined;
    const refreshToken = (tvCookieRt) ? tvCookieRt.refreshToken : undefined;

    const organization = this.props.organization.data;
    const redirect = `/oauth/org/redirect?teamdomain=${organization.teamDomain}&accessToken=${accessToken}&refreshToken=${refreshToken}`;
    return (
      <div className="full-screen full-height">
        <Helmet title="Organization created" />
        <div className="fs-panel">
          <br />
          {(organization.logo) && <img className="logo-feature" src={organization.logo} alt="Company logo" /> }
          <div className="row align-center">
            <div className="medium-10 medium-offset-1 columns end">
              <h2>Organization created</h2>
              <p>Your shiny new organization is all setup and ready to go!</p>
              <p>Next we will send you to your new hompage where you can start adding boards.</p>
              <br /><br />
              <h3 className="text-center">
                <a href={redirect}>Click here to visit your new homepage</a>
              </h3>
              <br /><br />
              <br /><br />
              <br /><br />
            </div>
          </div>
        </div>
        <div
          className="hide-for-small-only fs-panel"
          style={{ background: '#1dadda url(https://res.cloudinary.com/teamvibe/image/upload/v1492187554/product/header-office-people.jpg) center center/cover' }}>
          {/* This div is left empty since it is filled by the background image */}
        </div>
      </div>
    );
  }
}

OrgConfirmation.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default OrgConfirmation;
