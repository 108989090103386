const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const _ = require('lodash');
const ProfileHeader = require('./profile-header.jsx').default;
const MemberVibes = require('./memberVibes.jsx').default;
// const MemberInterests = require('./memberInterests.jsx').default;
const MemberBio = require('./memberBio.jsx').default;
const utils = require('../../../utils').default;

class Profile extends React.PureComponent {
  componentDidMount() {
    this.props.fetchUserDetailsIfNeeded(this.props.params.id, this.props.organization.data._id, this.props.auth.token);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.id !== this.props.params.id) {
      this.props.fetchUserDetailsIfNeeded(this.props.params.id, this.props.organization.data._id, this.props.auth.token);
    }
  }

  render() {
    const { user: { data: userData = {}, isFetching } } = this.props;
    const orgId = this.props.organization.data._id;
    const viewingUser = this.props.auth.user;
    const orgVibes = this.props.vibes.data;

    const orgTags = this.props.tags.data;

    if (!isFetching) {
      const selectedUserCurrentOrg = _.find(userData.orgs, { id: orgId }) || {};
      const selectedUserTags = selectedUserCurrentOrg.tags;

      // Build user vibes list
      const selectedUserVibes = utils.getUserVibes(userData.vibes, orgVibes);

      const selectedUserIsOrgActive = utils.userIsOrgActive(this.props.organization.data._id, userData.orgs);

      // These are for the viewing (admin) user, not the displayed user
      const viewingUserIsOrgAdmin = (this.props.auth.token) ? utils.userIsOrgAdmin(orgId, viewingUser.orgs) : false;
      const viewingUserIsTagAdmin = utils.userIsTagAdmin(viewingUser._id, selectedUserTags, orgTags);

      // Get user tags and teams for current org
      const userTags = utils.getUserTags(selectedUserCurrentOrg, orgTags);
      // const userTeams = utils.getUserTeams(selectedUserCurrentOrg, orgTags);

      // Get location tag (there should only be one - will use first instance)
      const locationTag = _.find(userTags, { tagType: 'location' });

      // Get non-location tags
      const nonLocationTags = _.filter(userTags, (tag) => tag.isActive &&
        (tag.tagType === 'team' || tag.tagType === 'industry'));

      return (
        <div>
          {userData && <div>
            <Helmet title={userData.firstName + ' ' + userData.lastName} />
            <br />
            <ProfileHeader
              user={this.props.user}
              userCurrentOrg={selectedUserCurrentOrg}
              auth={this.props.auth}
              organization={this.props.organization}
              tags={this.props.tags}
              userTags={userTags}
              userTeams={userTags}
              location={locationTag ? locationTag.displayName : ''}
              selectedUserVibeCount={selectedUserVibes.length}
              nonLocationTags={nonLocationTags}
              viewingUserIsOrgAdmin={viewingUserIsOrgAdmin}
              viewingUserIsTagAdmin={viewingUserIsTagAdmin}
              setUser={this.props.setUser}
              editUserFull={this.props.editUserFull}
              sendGroupEmail={this.props.sendGroupEmail}
              clearGroupEmail={this.props.clearGroupEmail} />
            <div className="row">
              <div className="large-8 small-12 columns">
                <div className="callout">
                  <MemberBio
                    auth={this.props.auth}
                    user={this.props.user}
                    organization={this.props.organization}
                    viewingUserIsOrgAdmin={viewingUserIsOrgAdmin}
                    viewingUserIsTagAdmin={viewingUserIsTagAdmin}
                    setUser={this.props.setUser}
                    editUserFull={this.props.editUserFull} />
                </div>
              </div>
              <div className="large-4 small-12 columns">
                {selectedUserIsOrgActive && <MemberVibes selectedUserVibes={selectedUserVibes} userFirstName={userData.firstName} />}
              </div>
            </div>
          </div>
          }
          {isFetching &&
              <h1 className="loading">Loading...</h1>
          }
        </div>
      )
    } else {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }
  }
}

Profile.propTypes = {
  fetchUserDetailsIfNeeded: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default Profile;
