import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TagsActions from '../../../../modules/tags/actions/tags';
import UpdateUserOrganizationActions from '../../../../modules/organizations/actions/updateUserOrganization';
import tagsSelect from '../components/tagsSelectModal.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    user: state.user,
    organization: state.organization,
    tags: state.tags,
    selectedTag: state.selectedTag,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({}, TagsActions, UpdateUserOrganizationActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(tagsSelect);
