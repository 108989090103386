const React = require('react');
const PropTypes = require('prop-types');
const CSSTransitionGroup = require('react-transition-group/CSSTransitionGroup');
const ReactTimeout = require('react-timeout');

class Alert extends React.Component {
  closeAlert = () => {
    this.props.handleClose();
  };

  render() {
    const { showAlert = false, closeButton = false, autoClose = true } = this.props;
    let { message = '' } = this.props;

    if (showAlert) {
      if (autoClose) {
        // Auto-close after wait time
        this.props.setTimeout(this.closeAlert, 3800);
      }
    }
    let key = showAlert ? "ON" : "OFF"; // eslint-disable-line
    key = (this.props.name === true) ? `${this.props.name}_${key}` : key;

    // Parse new lines
    if (message) {
      message = message.split('\n').map(item => (
        <span key={key}>
          {item}
          <br />
        </span>
      ));
    }

    return (
      <CSSTransitionGroup
        transitionName="slide"
        transitionEnter={true}
        transitionEnterTimeout={0}
        transitionLeave={true}
        transitionLeaveTimeout={0}>

        {showAlert &&
        <div id="alert" key={key}>
          <div className={this.props.type} data-closable>
            {message && <h5>{message}</h5>}
            {this.props.children}
            {closeButton &&
              <button className="close-button" aria-label="Dismiss alert" type="button" onClick={this.closeAlert}>
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

Alert.propTypes = {
  showAlert: PropTypes.bool,
  type: PropTypes.string,
  ref: PropTypes.string,
  message: PropTypes.string,
  autoClose: PropTypes.bool,
  closeButton: PropTypes.bool,
  handleClose: PropTypes.func,

};

export default ReactTimeout(Alert);
