import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import utils from '../../../utils';
import organizationUtils from '../utils/organizationUtils';

const nodeEnv = process.env.NODE_ENV || 'development';

const CreateOrganization = (function () {
  const _createOrganizationRequest = function () {
    return {
      type: constants.CREATE_ORGANIZATION_REQUEST,
    };
  };

  const _createOrganizationSuccess = function (data) {
    return {
      type: constants.CREATE_ORGANIZATION_SUCCESS,
      data: data.organization,
    };
  };

  const _createOrganizationError = function (error) {
    return {
      type: constants.CREATE_ORGANIZATION_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.CREATE_ORGANIZATION_ERROR,
      errors,
    };
  };

  const _createOrganization = function (type, name, teamDomain, logo, invitation, initialLocation, accessToken) {
    return function (dispatch) {
      organizationUtils.validateOrg(name, teamDomain, initialLocation)
        .then((result) => {
          if (result === true) {
            dispatch(_createOrganizationRequest());
            api.post('/proxy/v1/organizations/', {
              type,
              name,
              teamDomain,
              logo,
              invitation,
              initialLocation,
            }, accessToken)
              .then((res) => {
                dispatch(_createOrganizationSuccess(res));
                const redirect = utils.buildUrlByEnvironment(teamDomain, '', nodeEnv);
                window.location.assign(redirect);
              })
              .catch((res) => {
                dispatch(_createOrganizationError(res));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  const _validateOrganizationUniqueTeamDomain = function (teamDomain, callback) {
    return function () {
      api.get(`/proxy/v1/organizations?teamdomain=${teamDomain}&validate=true`)
        .then((res) => {
          if (res.organization) {
            return callback({ teamDomain: { message: 'Team domain already exists. Please try another name.' } }, false);
          }
          return callback(null, true);
        })
        .catch(() => callback(null, false));
    };
  };

  return {
    createOrganization: _createOrganization,
    validateOrganizationUniqueTeamDomain: _validateOrganizationUniqueTeamDomain,
    validationError: _validationError,
  };
}());

export default CreateOrganization;
