import constants from '../constants';
import api from '../../../utils/api';

const VibeMembers = (function () {
  const _requestMembers = function () {
    return {
      type: constants.REQUEST_MEMBERS,
    };
  };

  const _receiveDirectMembers = function (data, boardId) {
    return {
      type: constants.RECEIVE_DIRECT_MEMBERS,
      members: data.users,
      boardId,
    };
  };

  const _receiveInheritedMembers = function (data, boardId) {
    return {
      type: constants.RECEIVE_INHERITED_MEMBERS,
      members: data.users,
      boardId,
    };
  };

  const _fetchMembersError = function (error) {
    return {
      type: constants.REQUEST_MEMBERS_ERROR,
      errors: error.data,
    };
  };

  const _fetchMembers = function (vibeId, orgId, type, accessToken) {
    return function (dispatch) {
      dispatch(_requestMembers());
      api.get(`/proxy/v1/vibes/${vibeId}/users`, {
        orgId,
      }, accessToken)
        .then((res) => {
          if (type === 'inherit') {
            dispatch(_receiveInheritedMembers(res, vibeId));
          } else {
            dispatch(_receiveDirectMembers(res, vibeId));
          }
        })
        .catch((res) => {
          dispatch(_fetchMembersError(res));
        });
    };
  };

  const _shouldFetchMembers = function (vibeId, type, state) {
    const stateVibe = state.selectedVibe.members.vibe;
    const parentBoard = state.selectedVibe.members.parent;
    if (stateVibe !== vibeId) {
      return true;
    }

    if (type === 'inherit' && (parentBoard !== vibeId)) {
      return true;
    }

    return false;
  };

  const _fetchMembersIfNeeded = function (vibeId, orgId, type, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchMembers(vibeId, type, getState())) {
        return dispatch(_fetchMembers(vibeId, orgId, type, accessToken));
      }
      return false;
    };
  };

  return {
    fetchMembersIfNeeded: _fetchMembersIfNeeded,
    fetchMembers: _fetchMembers,
  };
}());

export default VibeMembers;
