var React = require('react');
var PropTypes = require('prop-types');
var Draft = require('draft-js');
var StateToHTML = require('draft-js-export-html');

var Editor = require('../../../components/discussions/components/draftEditor.jsx').default;

class VibeInfo extends React.PureComponent {
    state = {
        editMode: false
    };

    changeContent = (contentState) => {
        var postItem = this.props.post;
        postItem.data['contentState'] = contentState;
        delete postItem.error['content'];

        this.props.setPost(postItem.data, false);
    };

    handleEdit = () => {
		this.setState({ editMode: true })
		this.refs.editor.focus();
	};

    handleCancel = () => {
		this.setState({ editMode: false })
		this.props.clearPost();
	};

    handleSave = () => {
		var postItem = this.props.post;

        // Send editing state to actions
        postItem.data.content = {
            raw: '',
            rich: ''
        };

        postItem.data.content.raw = JSON.stringify(Draft.convertToRaw(postItem.data.contentState));
        postItem.data.content.rich = StateToHTML.stateToHTML(postItem.data.contentState);

		this.props.updateVibeOverview(this.props.selectedVibe.data._id, postItem.data.content, this.props.organization.data._id, this.props.auth.token);
		this.setState({ editMode: false })
		this.props.clearPost();
		// Some kind of alert goes here
	};

    render() {
        var selectedVibe = this.props.selectedVibe.data;
    	var isFetching = this.props.selectedVibe.members.isFetching;

    	var postContent = (selectedVibe.overview) ? selectedVibe.overview : null;

        var placeholder = (!this.state.editMode) 
        	? "Use this area to showcase information about your organization. Click edit below to add images, links, and rich formatting."
        	: null;

    	if(!isFetching) {
			return (
				<div className="callout"> 
					{(selectedVibe.sponsor && selectedVibe.sponsor.sponsorLogo) && 
						<img style={{'maxHeight': '200px'}} className="float-center" src={selectedVibe.sponsor.sponsorLogo} alt="Sponsor" />
					}
					<Editor 
						className={(this.state.editMode) ? 'post-info-box edit-mode' : 'post-info-box'}
						ref={'editor'}
						placeholder={placeholder}
						changeContent={this.changeContent} 
						content={postContent}
						organization={this.props.organization}
						//users={this.props.organization.members.data}
						readOnly={!this.state.editMode}/>	
					{(this.state.editMode) 
						?
						<div className="clearfix">
							<span className="float-right">
								<button className="button alert" onClick={this.handleCancel}>Cancel</button>&nbsp;&nbsp;&nbsp;
								<button className="button" onClick={this.handleSave}>Save changes</button>
							</span>
						</div>	
						:
						<div className="clearfix">
							<span className="float-right">
								{(this.props.userIsVibeChampion || this.props.userIsOrgAdmin) &&
									<button className="button hollow" onClick={this.handleEdit}>Edit</button>
								}
							</span>
						</div>
					}
				</div> 
			);

        } else {
            return (
                <div>
                    <h1 className="loading">Loading...</h1>
                </div>
            );
        }
    }
}

VibeInfo.propTypes = {
	auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    selectedVibe: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    updateVibeOverview: PropTypes.func.isRequired
};

export default VibeInfo;
