var React = require('react');
var PropTypes = require('prop-types');
var ImageUploadComponent = require('./imageUploadComponent.jsx').default;
var DropdownList = require('react-widgets/lib/DropdownList');
var utils = require('../../../utils').default;
var _ = require('lodash');
const { DateTime } = require('luxon');

class UserBioSettings extends React.Component {
    state = {
        userTags: []
    };

    changeProfilePictureUrl = (value) => {
        this.refs.photoUrl.defaultValue = value;
    };

    updateTimeZone = () => {
        var timeZone = (DateTime.local()).zoneName;
        var user = {
            _id: this.props.auth.user._id,
            timeZone: timeZone
        };
        this.props.updateUserOptions(user, this.props.organization.data._id, this.props.auth.token);
        this.props.showUpdateAlert('Your time zone has been set to: ' + timeZone);
    };

    handleUnsyncGoogleClick = () => {
        this.props.removeSocialIdentity(this.props.auth.user._id, this.props.organization.data._id, 'google', this.props.auth.token);
    };

    handleLocationChange = (selectedValue) => {
        var organization = this.props.organization.data;
        var user = this.props.auth.user;
        var tags = this.props.tags.data;
        var userCurrentOrg = _.find(user.orgs, {'id': organization._id});

        // Filter out any location tags and add selected location
        var userTags = utils.getUserTags(userCurrentOrg, tags);        
        userTags = _.reject(userTags, {'tagType': 'location'});
        userTags.push(selectedValue);

        this.setState({userTags: userTags});
    };

    handleOnImageUpload = () => {
        this.handleSubmit();
    };

    handleSubmit = () => {
        // #################################################
        // Should refactor this using state instead of refs
        // #################################################

        var organization = this.props.organization.data;

        var firstName = _.upperFirst(_.toLower(this.refs.firstName.value));
        var lastName = _.upperFirst(_.toLower(this.refs.lastName.value));
 
        var about = this.refs.about.value;
        var title = this.refs.title.value;
        var photoUrl = this.refs.photoUrl.value;
        var emailOptIn = this.refs.switch_emailDigest.checked;
        var timeZone = this.refs.timeZone.value;

        var userTags = this.state.userTags;
        userTags = _.map(userTags, '_id');

        var userUpdate = {
            firstName: firstName,
            lastName: lastName,
            about: about,
            title: title,
            photoUrl: photoUrl,
            timeZone: timeZone,
            tags: userTags,
            preferences: {
                emailOptIn: emailOptIn
            }
        };

        if (organization.preferences.showPhoneNumbers) {
            var phoneNumber = {
                type: 'office',
                number: this.refs.phoneSingle.value,
                isActive: true
            };
            var phoneNumbers = [];
            phoneNumbers.push(phoneNumber);
            userUpdate.phoneNumbers = phoneNumbers;
        }

        if (organization.preferences.showExternalOrg) {
            userUpdate.externalOrg = {};
            userUpdate.externalOrg.orgName = this.refs.externalOrg.value;
            userUpdate.externalOrg.orgUrl = this.refs.externalOrgUrl.value;
        }

        this.props.editUser(userUpdate, this.props.organization.data._id, this.props.auth.token);
    };

    render() {
        var organization = this.props.organization.data;
        var user = this.props.auth.user;
        var userCurrentOrg = _.find(user.orgs, {'id': organization._id});
        var tags = this.props.tags.data;
        
        // Get user tags for current org
        var userTags = utils.getUserTags(userCurrentOrg, tags);
        //console.log(userCurrentOrg);
        //console.log(tags);
        //console.log(userTags)

        // Get user location tag (there should only be one - will use first instance)
        var userlocationTag = _.find(userTags, {'tagType': 'location'});
        //console.log(userlocationTag)

        // Full location list for org
        var locationTags = tags.filter(function(tag) {
            return tag.tagType === 'location' && tag.isActive;
        });

        var externalOrg = userCurrentOrg.externalOrg ? userCurrentOrg.externalOrg.orgName : null;
        var externalOrgUrl = userCurrentOrg.externalOrg ? userCurrentOrg.externalOrg.orgUrl : null;

        // Single number for now but DB able to support multiple numbers array (w/type)
        var phoneSingle = user.phoneNumbers[0] ? user.phoneNumbers[0].number : null;

        var authError = this.props.auth.error;
        var titleError = ((Object.keys(authError).length !== 0) && ('title' in  authError)) ? authError.title.message : null;
        var externalOrgError = ((Object.keys(authError).length !== 0) && ('externalOrgName' in  authError)) ? authError.externalOrgName.message : null;
        var externalOrgUrlError = ((Object.keys(authError).length !== 0) && ('externalOrgUrl' in  authError)) ? authError.externalOrgUrl.message : null;
        var phoneError = ((Object.keys(authError).length !== 0) && ('phone' in  authError)) ? authError.phone.message : null;

        return (
            <div className="callout">
                <div className="row">
                    <div className="large-8 columns">
                        {/* User profile */}
                        <h4><strong>Your profile</strong></h4>
                        <div className="row">
                            <div className="small-6 columns">
                                <label><p><strong>Your name</strong></p>
                                    <input ref="firstName" className="input" defaultValue={user.firstName} type="text"/>
                                </label>
                            </div>
                            <div className="small-6 columns">
                                <label><p>&nbsp;</p>
                                    <input ref="lastName" className="input" defaultValue={user.lastName} type="text"/>
                                </label>    
                            </div>
                        </div>
                        <label className="hide"><p><strong>Primary email</strong></p>
                            <input ref="username" className="input" defaultValue={user.username} type="text"/>
                        </label>
                        {(locationTags && locationTags.length > 1) &&
                            <div>
                                <label><p><strong>Location</strong></p></label>
                                <DropdownList
                                    ref='location'
                                    defaultValue={userlocationTag}
                                    className={locationTags.length > 1 ? 'full-input margin-bottom-15' : 'hide'}                        
                                    data={locationTags}
                                    valueField='_id'
                                    textField='displayName'
                                    placeholder='Select your location'
                                    onSelect={this.handleLocationChange} />
                                <span className="input-error"></span> 
                            </div>                           
                        }
                        <label><p><strong>Job title</strong></p>
                            <input ref="title" className="input" defaultValue={userCurrentOrg.title} type="text"/>
                            <span className="input-error">{titleError}</span>
                        </label>
                        
                        {(organization.preferences && organization.preferences.showPhoneNumbers) &&
                        <label><p><strong>Phone</strong></p>
                            <input ref="phoneSingle" className="input" defaultValue={phoneSingle} type="tel"/>
                            <span className="input-error">{phoneError}</span>
                        </label>
                        }

                        {(organization.preferences && organization.preferences.showExternalOrg) &&
                        <div className="row">
                            <div className="small-6 columns">
                                <label><p><strong>Organization</strong></p>
                                    <input ref="externalOrg" className="input" defaultValue={externalOrg} type="text"/>
                                    <span className="input-error">{externalOrgError}</span>
                                </label>
                            </div>
                            <div className="small-6 columns">
                                <label><p><strong>Organization URL</strong> <small>(optional)</small></p>
                                    <input ref="externalOrgUrl" className="input" defaultValue={externalOrgUrl} type="text"/>
                                    <span className="input-error">{externalOrgUrlError}</span>
                                </label>    
                            </div>
                        </div>
                        }
                        
                        <label><p><strong>Bio</strong></p>
                            <textarea ref="about" className="textarea" rows="8" defaultValue={user.about}></textarea>
                        </label>

                        <input type="hidden" ref="photoUrl" defaultValue={user.photoUrl} />
                    </div>
                    <div className="large-4 columns">
                        <h4>&nbsp;</h4>
                        <label><strong>Your photo</strong></label>                    
                        <ImageUploadComponent
                            user={this.props.auth.user}
                            organization={this.props.organization}
                            changeProfilePictureUrl={this.changeProfilePictureUrl}
                            handleOnImageUpload={this.handleOnImageUpload} />
                        <br />
                        <input type="hidden" ref="photoUrl" defaultValue={user.photoUrl} />
                        <div className="callout">                           
                            <div className="row">
                                <div className="small-9 columns">
                                    <p><strong>Email Digest</strong><br />
                                    Get updates from your boards</p>
                                </div>
                                <div className="small-3 columns text-right">
                                    <div className="switch">
                                        <input className="switch-input" id="switch-emailDigest" ref="switch_emailDigest" type="checkbox" defaultChecked={userCurrentOrg.preferences ? userCurrentOrg.preferences.emailOptIn : false}/>
                                        <label className="switch-paddle" htmlFor="switch-emailDigest">
                                            <span className="switch-active" aria-hidden="true">On</span>
                                            <span className="switch-inactive" aria-hidden="true">Off</span>
                                        </label>
                                    </div> 
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="small-9 columns">
                                    <p><strong>Your time zone</strong>
                                    <br />
                                    {user.timeZone ? user.timeZone : 'Not set'}</p>
                                    <input type="hidden" ref="timeZone" value={user.timeZone} />
                                </div>  
                                <div className="small-3 columns text-right">
                                    <small><a onClick={this.updateTimeZone}>Refresh</a></small>
                                </div>                                                           
                            </div>
                        </div>
                    </div>
                </div>
                {/* Update vibe button */}
                <div className="row columns">
                    <br/>
                    <div className="text-center">
                        <button type="button" className="button large" onClick={this.handleSubmit}>Update user data</button>
                    </div>
                </div>
                
            </div>
        );
    }
}

UserBioSettings.propTypes = {
    editUser: PropTypes.func.isRequired,
    removeSocialIdentity: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default UserBioSettings;
