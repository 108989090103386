const React = require('react');
const PropTypes = require('prop-types');

class SurveyQuestion extends React.Component {
  state = {

  };

  render() {
    const { questionTitle, questionText } = this.props;

    return (
      <div className="row">
        <div className="small-4 columns">
          <h4 id="questionTitle">{questionTitle}</h4>
        </div>
        <div className="small-8 columns">
          <h6 id="text">{questionText}</h6>
        </div>
      </div>
    );
  }
}

SurveyQuestion.propTypes = {

};

export default SurveyQuestion;
