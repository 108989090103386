import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import deleteVibeActions from '../../vibes/actions/vibe-delete';

const RejectOrgVibe = (function () {
  const _rejectOrgVibeRequest = function () {
    return { type: constants.REJECT_ORGVIBE_REQUEST };
  };

  const _rejectOrgVibeSuccess = function (vibeId) {
    return {
      type: constants.REJECT_ORGVIBE_SUCCESS,
      vibeId,
    };
  };

  const _rejectOrgVibeError = function (error, vibeId) {
    return {
      type: constants.REJECT_ORGVIBE_ERROR,
      errors: error.data,
      vibe: vibeId,
    };
  };

  const _rejectOrgVibe = function (selectedVibe, organization, reason, accessToken) {
    const vibeId = selectedVibe._id;
    const orgId = organization._id;
    return function (dispatch) {
      dispatch(_rejectOrgVibeRequest());
      api.put(`/proxy/v1/organizations/${orgId}/vibes/${vibeId}/approval`, {
        message: reason,
        isApproved: false,
        orgId,
        title: selectedVibe.title,
        champion: selectedVibe.champion,
      }, accessToken)
        .then(() => {
          // We should just delete the vibe first and then send a notification
          // It seems this hits orgs/vibes/approval to send the email, and then deletes
          // Updating approval shouldn't be needed for the delete case
          dispatch(deleteVibeActions.deleteVibe(selectedVibe, organization, accessToken));

          // Remove orgVibe from organization state
          dispatch(_rejectOrgVibeSuccess(vibeId));
          dispatch(routerActions.push('/'));
        })
        .catch((res) => {
          dispatch(_rejectOrgVibeError(res, vibeId));
          dispatch(routerActions.push('/oops'));
        });
    };
  };

  return { rejectOrgVibe: _rejectOrgVibe };
}());

export default RejectOrgVibe;
