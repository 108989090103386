import _ from 'lodash';
import Moment from 'moment';

import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const events = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_EVENTS:
    newstate.isFetching = true;
    newstate.rsvp = '';
    return newstate;
  case constants.RECEIVE_EVENTS:
    newstate.isFetching = false;
    newstate.data = action.events;
    newstate.rsvp = '';
    return newstate;
  case constants.REQUEST_EVENTS_ERROR:
    newstate.isFetching = false;
    newstate.data = [];
    newstate.rsvp = '';
    newstate.error = action.errors;
    return newstate;
  case constants.CHANGE_RSVP_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(newstate.data, action.event.event._id, action.event.event);
    return newstate;
  case constants.CREATE_EVENT_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.events;
    newstate.closeInputModal = true;
    return newstate;
  case constants.EDIT_EVENT_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(newstate.data, action.event.event._id, action.event.event);
    newstate.data = _.sortBy(newstate.data, (o) => (new Moment(o.startDate)));
    newstate.closeInputModal = true;
    return newstate;
  case constants.DELETE_EVENT_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.event);
    newstate.closeInputModal = true;
    return newstate;
  default: return state || initialState.events;
  }
};

export default events;
