import constants from '../constants';
import initialState from '../../initialState';

const post = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.SET_POST:
    newstate.isFetching = false;
    newstate.data = action.post;
    newstate.unsaved = action.unsaved;
    return newstate;
  case constants.CLEAR_POST:
    newstate.data = {};
    newstate.error = {};
    newstate.unsaved = false;
    return newstate;
  case constants.CREATE_POST_SUCCESS:
    newstate.isFetching = false;
    newstate.unsaved = false;
    return newstate;
  case constants.CREATE_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.EDIT_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.SET_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errorObj;
    return newstate;
  default: return state || initialState.post;
  }
};

export default post;
