const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const _ = require('lodash');
const VibeActions = require('../../../modules/vibes/actions/vibes').default;

const checkVibes = function (Component) {
  class CheckVibesComponent extends React.Component {
    componentDidMount() {
      this.setVibes();
    }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.vibes.isFetching && Object.keys(nextProps.vibes.data).length !== 0) {
        // Re-fetch vibes if there is new activity
        if (nextProps.vibes.activity === true) {
          this.setVibes();
        }
      }
    }

    setVibes = () => {
      const organization = this.props.organization.data;
      const { token } = this.props.auth;
      const isFetchingVibes = this.props.vibes.isFetching;

      if (!isFetchingVibes) {
        this.props.fetchVibesIfNeeded(organization, token);
      }
    };

    render() {
      return (
        <div className="vibe">
          {_.isArray(this.props.vibes.data) ? <Component {...this.props} /> : null}
        </div>
      );
    }
  }

  CheckVibesComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    vibes: PropTypes.object.isRequired,
    fetchVibesIfNeeded: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
      vibes: state.vibes,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, VibeActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CheckVibesComponent);
};

export default checkVibes;
