const React = require('react');
const PropTypes = require('prop-types');
const { withRouter } = require('react-router');
// const { ReactiveBase, DataSearch, ReactiveComponent } = require('@appbaseio/reactivesearch');
const _ = require('lodash');

const utils = require('../../../common/utils').default;
// const SearchSentry = require('./searchSentry.jsx').default;

class SearchBox extends React.Component {
  searchRedirectByType = (val, cause, source) => {
    if (cause === 'SUGGESTION_SELECT') {
      // Only hot link if they select a search item
      switch (source.searchType) {
      case 'user':
      case 'vibe':
        this.props.router.push(`${source.link}`);
        break;
      case 'discussion':
      default:
        this.props.router.push(`/search?q="${val}"`);
        break;
      }
    } else {
      // If they press enter or anything else, send them to search results page
      this.props.router.push(`/search?q="${val}"`);
    }
  };

  getSuggestionTemplate = (suggestion) => {
    // console.log(suggestion);
    const userOrg = _.find(suggestion.source.orgs, { id: this.props.organization.data._id });
    switch (suggestion.source.searchType) {
    case 'user':
      return (
        <div style={{ width: '100%' }}>
          <img
            src={utils.formatImage(suggestion.source.imageUrl, 'userThumb')}
            alt={suggestion.value}
            onError={utils.showDefaultImage}
            className="image-thumb float-right"
            style={{ width: '50px' }} />
          <div>{suggestion.value}</div>
          <div>{userOrg.title}</div>
        </div>
      );
    case 'vibe':
      return (
        <div style={{ width: '100%' }}>
          <img
            src={utils.formatImage(suggestion.source.imageUrl, 'vibeThumb')}
            alt={suggestion.source.title}
            onError={utils.showDefaultImage}
            className="image-thumb float-right"
            style={{ width: '80px' }} />
          <div><strong>{suggestion.source.title}</strong></div>
          <div>{suggestion.value}</div>
        </div>
      );
    case 'discussion':
    default:
      return (
        <div>
          {`(${suggestion.source.searchType}) ${suggestion.value}`}
        </div>
      );
    }
  }

  renderSuggestion = (suggestion) => {
    // console.log(suggestion);
    // const suggestionTemplate = this.getSuggestionTemplate(suggestion);
    let titleLine;
    let secondLine;
    let formattedLabel;

    if (suggestion.source.searchType === 'vibe') {
      if (suggestion.value !== suggestion.source.title) {
        formattedLabel = <div>{suggestion.value}<br /><span className="subheader">found in {suggestion.source.title}</span></div>;
      } else {
        formattedLabel = suggestion.value;
      }
    } else if (suggestion.source.searchType === 'user') {
      const userOrg = (suggestion.source.orgs) ? _.find(suggestion.source.orgs, { id: this.props.organization.data._id }) : null;
      titleLine = suggestion.value;
      secondLine = userOrg.title;
      formattedLabel = `${titleLine}<br /><span className="subheader">${secondLine}</span>`;
    } else {
      formattedLabel = <div>{suggestion.value}<br /><span className="subheader">found in </span></div>;
      // formattedLabel = suggestion.value;
    }
    return (
      {
        // title: 'Test',
        label: formattedLabel,
        // label: <div>{`(${suggestion.source.searchType}) ${suggestion.value}`}</div>,
        value: suggestion.value,
        source: suggestion.source, // for onValueSelected to work with renderSuggestion
      });
  }

  redirectSearch = (event) => {
    const searchTerm = event.target.value;
    if (event.key === 'Enter' || (this.props.autoRedirect && searchTerm && searchTerm.length > 2)) {
      if (this.props.toggleHamburger) { this.props.toggleHamburger(); }
      this.props.router.push(`/search?q="${searchTerm}"`);
    }
  }

  render() {
    const searchTerm = (this.props.location.query.q && this.props.location.query.q.replace(/[\W_]+/g, '')) || '';

    // const { auth, organization } = this.props;
    // const server = utils.getEnvironment(document.location.hostname);
    // const searchProxy = `${utils.buildUrlByEnvironment(organization.data.teamDomain, '', server)}searchAPI`;

    return (
      <div className={this.props.className}>
        <div className="search-input-wrapper">
          <input
            className="input has-icons-left search-bar"
            defaultValue={searchTerm}
            onKeyUp={this.redirectSearch}
            id="search"
            name="search"
            placeholder="Search posts, boards, & people"
            autoComplete="off"
            type="text" />
          <span className="icon is-small is-left">
            <i className="fa fa-search search-input-icon" />
          </span>
        </div>

{/*        <ReactiveBase
          url={searchProxy}
          app={`${server}-discussions,${server}-users`}>

          <SearchSentry
            componentId="searchSentry"
            auth={auth}
            organization={organization} />

          <DataSearch
            componentId="search"
            // renderSuggestion={this.renderSuggestion}
            // onValueSelected={val => this.props.router.push(`/search?q="${val}"`)}
            onValueChange={val => this.props.router.push(`/search?q="${val}"`)}
            defaultValue={searchTerm}
            autosuggest={false}
            // showClear={true}
            dataField={[
              'title',
              'title.autosuggest',
              'title.keyword',
              'description',
              'description.autosuggest',
              'description.keyword',
              'fullName',
            ]}
            fieldWeights={[
              2,
              2,
              2,
              2,
              1,
              2,
              1,
            ]}
            highlightField={[
              'title',
              'fullName',
            ]}
            react={{
              and: ['searchSentry'],
            }}
            queryFormat="or"
            style={{ marginTop: '0.5rem' }}
            innerClass={{
              input: 'search-global-input',
            }}
          />
        </ReactiveBase> */}
      </div>
    );
  }
}

SearchBox.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleHamburger: PropTypes.func,
};

export default withRouter(SearchBox);
