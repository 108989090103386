const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');

const EventsItem = require('./eventsItem.jsx').default;

class EventsModal extends React.PureComponent {
  componentWillMount() {
    Modal.setAppElement('body');
  }

  render() {
    return (
      <Modal
        isOpen={this.props.eventModalIsOpen}
        onAfterOpen={this.handleOnAfterOpenModal}
        onRequestClose={this.props.closeEventModal}
        className="modal event"
        overlayClassName="modal-overlay"
        contentLabel="Events modal">

        <EventsItem
          mode={this.props.mode}
          view="modal"
          eventItem={this.props.selectedEvent.data}
          auth={this.props.auth}
          organization={this.props.organization}
          RSVPclick={this.props.RSVPclick}
          setSelectedEvent={this.props.setSelectedEvent}
          openEventInputModal={this.props.openEventInputModal}
          closeEventModal={this.props.closeEventModal}
          joinVibe={this.props.joinVibe} />

        <button className="close-button" onClick={this.props.closeEventModal} aria-label="Close reveal" type="button">
          <span aria-hidden="true"><small>&times;</small></span>
        </button>
      </Modal>
    );
  }
}

EventsModal.propTypes = {
  RSVPclick: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  openEventInputModal: PropTypes.func.isRequired,
  closeEventModal: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  mode: PropTypes.string,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};

export default EventsModal;
