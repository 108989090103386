import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PasswordReset from '../components/passwordReset.jsx';
import PasswordResetValidateActions from '../../../modules/registration/actions/password-reset-validate';
import PasswordResetActions from '../../../modules/registration/actions/password-reset';

var mapStateToProps = function(state){
    return {
        organization: state.organization,
		registration: state.registration        
    };
}

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, PasswordResetValidateActions, PasswordResetActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
