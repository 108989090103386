const React = require('react');
const Masonry = require('react-masonry-component');
const PropTypes = require('prop-types');
const Tile = require('./tile.jsx').default;
// const utils = require('../../../../../common/utils').default;

const masonryOptions = {
  itemSelector: '.item',
  columnWidth: '.grid-sizer',
  gutter: 10,
  horizontalOrder: true,
  // transitionDuration: '0.4s',
  stagger: '0.03s',
  isFitWidth: true,
};

class TileList extends React.PureComponent {
  render() {
    const { isOrgAdmin } = this.props;
    // var checkPublicOrgAccess = utils.checkPublicOrgAccess(organization);

    const vibes = this.props.vibes.map(vibe => (
      <Tile
        key={vibe._id}
        auth={this.props.auth}
        isOrgAdmin={isOrgAdmin}
        vibe={vibe}
        tags={this.props.tags} />
    ));

    const masonryClass = ((this.props.vibes).length >= 4) ? 'float-center' : '';
    // var masonryClass = "float-center";
    return (
      <Masonry
        className={masonryClass}
        options={masonryOptions}>
        {/* .grid-sizer empty element, only used for element sizing */}
        <div className="grid-sizer" />
        {vibes}
      </Masonry>
    );
  }
}

TileList.propTypes = {
  auth: PropTypes.object.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
  vibes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default TileList;
