const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const Linkify = require('linkifyjs/react');
const { browserHistory } = require('react-router');
const utils = require('../../../../common/utils').default;

class VibeOverview extends React.Component {
  state = { modalIsOpen: false };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  truncateUrl = value => (value.length > 50 ? `${value.slice(0, 50)}…` : value);

  handleVibeButtonClick = () => {
    const selectedVibe = this.props.selectedVibe.data;
    if (!this.props.auth.token) {
      browserHistory.push('/login');
    }
    const vibeStatus = utils.userIsVibeMember(
      this.props.auth.user.vibes, selectedVibe._id,
    );

    if (vibeStatus) {
      this.props.leaveVibe(this.props.auth.user._id, selectedVibe._id,
        this.props.organization.data._id, this.props.auth.token);
      this.closeModal();
    } else {
      this.props.joinVibe(this.props.auth.user._id, selectedVibe._id, false,
        this.props.organization.data._id, this.props.auth.token);
    }
  };

  render() {
    let championText = '';
    let champions = '';
    const selectedVibe = this.props.selectedVibe.data;
    const vibeAllUser = (selectedVibe.orgOptions && 'allUser' in selectedVibe.orgOptions) ? selectedVibe.orgOptions.allUser : false;
    const userIsVibeMember = utils.userIsVibeMember(
      this.props.auth.user.vibes, selectedVibe._id,
    );

    if (selectedVibe.champion && selectedVibe.champion.length > 0) {
      championText = (selectedVibe.champion.length > 1) ? 'Champions:' : 'Champion:';
      champions = selectedVibe.champion.map((champ, i) => (
        <span key={champ._id}>{champ.firstName} {champ.lastName}{i + 1 < (selectedVibe.champion.length) ? <span>, </span> : null}</span>
      ));
    }

    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className="reveal"
          overlayClassName="modal-overlay"
          contentLabel="Leave vibe modal">
          <div className="text-center" id="leave-vibe" data-reveal>
            <h2 className="text-left">Are you sure?</h2>
            <p>You will no longer receive notfications related to this Board&#39;s activity.</p>
            <p className="text-center"><button type="button" className="button wide" onClick={this.handleVibeButtonClick}>Unfollow board</button></p>
            <button className="close-button" onClick={this.closeModal} aria-label="Close reveal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal>

        <div className="row column margin-top-15">
          {/* ## Mobile vibe overview */}
          <div className="vibe-overview small show-for-small-only">
            <div className="media-object no-margin">
              <div className="media-object-section">
                <img src={selectedVibe.imageUrl} alt="Vibe" onError={utils.showDefaultVibeImage} />
              </div>
              <div className="media-object-section middle">
                <h4 className="no-margin"><strong>{selectedVibe.title}</strong></h4>
                {(champions) && <span className="vibe-champions">{championText} {champions}</span>}
                {(vibeAllUser) ?
                  <div className="subheader"><small><i>Auto-following</i></small></div>
                  :
                  <div>
                    <small>
                      {(userIsVibeMember) ?
                        <button
                          type="button"
                          className="link"
                          onClick={this.openModal}><span>Unfollow board</span>
                        </button>
                        :
                        <button
                          type="button"
                          className="link"
                          onClick={this.handleVibeButtonClick}><span>Follow board</span>
                        </button>
                      }
                    </small>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* ## END Mobile vibe overview */}

          {/* ## Web and tablet vibe overview */}
          <div className="vibe-overview show-for-medium">
            <div className="float-right margin-left-15">
              {(vibeAllUser) ?
                <h5 className="button-alt-text"><small><i>Auto-following</i></small></h5>
                :
                <div>
                  {(userIsVibeMember) ?
                    <button
                      type="button"
                      className="button hollow join-vibe hide-for-medium-only"
                      onClick={this.openModal}><span>Unfollow board</span>
                    </button>
                    :
                    <button
                      type="button"
                      className="button wide join-vibe hide-for-medium-only"
                      onClick={this.handleVibeButtonClick}><span>Follow board</span>
                    </button>
                  }
                  {(userIsVibeMember) ?
                    <button
                      type="button"
                      className="button hollow join-vibe show-for-medium-only"
                      onClick={this.openModal}><span>Unfollow board</span>
                    </button>
                    :
                    <button
                      type="button"
                      className="button join-vibe show-for-medium-only"
                      onClick={this.handleVibeButtonClick}><span>Follow board</span>
                    </button>
                  }
                </div>
              }
            </div>

            <div className="media-object no-margin">
              <div className="media-object-section middle">
                <img className="vibe-thumb" src={selectedVibe.imageUrl} alt="Vibe" onError={utils.showDefaultVibeImage} />
              </div>
              <div className="media-object-section middle">
                <h4 className="no-margin"><strong>{selectedVibe.title}</strong></h4>
                <span><Linkify options={{ format: this.truncateUrl }}>{selectedVibe.description}</Linkify></span>
                <br />
                {(champions) && <span className="vibe-champions">{championText} {champions}</span>}
              </div>
            </div>
          </div>
          {/* ## END Web and tablet vibe overview */}
        </div>
      </div>
    );
  }
}

VibeOverview.propTypes = {
  joinVibe: PropTypes.func.isRequired,
  leaveVibe: PropTypes.func.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default VibeOverview;
