var React = require('react');
var { Helmet } = require('react-helmet');

class Terms extends React.Component {
    render() {
      return (
          <div>
              <Helmet title="Terms and Conditions"/>
              <div className="row">
                  <div>
                      <h2><strong>Welcome to Teamvibe</strong></h2>
                      <h5>- and thank you for visiting. We hope you enjoy the experience!</h5>
                  </div>
              </div> 
          </div>
      )
    }
}

export default Terms;