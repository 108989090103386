const indicative = require('indicative');

const Indicative = new indicative();

const eventUtils = (function () {
  const _validateEvent = function (event, vibeId) {
    const data = {
      vibe: vibeId,
      title: event.title,
      location: event.location,
      startDate: event.startDate,
      description: event.description,
    };

    const rules = {
      // Now allowing special characters. SpeakingUrl will sanitize friendly Urls when added.
      vibe: 'required',
      title: 'required|min:3|max:100',
      location: 'max:100',
      startDate: 'required',
    };

    const messages = {
      'vibe.required': 'Please select a board for this event',
      'title.required': 'A title is required',
      'title.min': 'Your title must be at least 3 characters',
      'title.max': 'Your title must be less than 100 characters',
      'location.max': 'Your location must be less than 100 characters',
      'startDate.required': 'A valid date is required',
    };

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => true)
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }
        return errorMessage;
      });
  };

  return {
    validateEvent: _validateEvent,
  };
}());

export default eventUtils;
