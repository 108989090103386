var React = require('react');
var PropTypes = require('prop-types');
var _ = require('lodash');
var { Multiselect } = require('react-widgets');

// var TagsContainer = require('../../../components/tagsContainer.jsx').default;

class ListItem extends React.Component {
    render() {
        var tag = this.props.item;

        return (
            <div className="clearfix">
                {tag.displayName}
            </div>
        );
    }
}

class TagItem extends React.Component {
    render() {
        var tag = this.props.item;

        return (
            <span>
                {tag.displayName}
            </span>
        );
    }
}

class GroupItem extends React.Component {
    render() {
		return (
			<span>
				{_.startCase(_.toLower(this.props.item))}
			</span>
		);
	}
}

class TagFilters extends React.PureComponent {
    state = {
        openSelect: false,
    };

    updateFilterList = (tagArray) => {
		var filterArray = _.map(tagArray, function(tag) {
			return tag._id;
		});
		var filterType = this.props.userFilter.filterType;
		this.props.setUserFilter(filterArray, filterType);
		this.setState({ openSelect: false });
    };

    handleToggleSelect = () => {
        this.setState({ openSelect: !this.state.openSelect });
    };

    toggleFilterType = () => {
		var filterArray = this.props.userFilter.tags;
		var filterType = (this.props.userFilter.filterType === 'ALL') ? 'ANY' : 'ALL' ;
		this.props.setUserFilter(filterArray, filterType);
    };

    render() {
        var tags = this.props.tags;
        var filterTags = this.props.userFilter.tags || [];

        tags = _.reject(tags, {'tagType': 'category'});

        var filterTypeMessage = (this.props.userFilter.filterType === 'ANY') ? 'ANY' : 'ALL';
    	//const { openSelect } = this.state;
    	const openSelect = this.state.openSelect;

		return (
			<div className="row">
				<div className="medium-8 medium-centered columns">
					<Multiselect
						valueField='_id' 
						textField={function(item){return item.displayName}}
                        open={openSelect}
                        onToggle={this.handleToggleSelect}
						data={tags}
						defaultValue={filterTags}
						itemComponent={ListItem}
						tagComponent={TagItem}
						groupBy='tagType'
						groupComponent={GroupItem}
						filter='contains'
						placeholder='Select tags to filter users'
						onChange={this.updateFilterList} />
						{filterTags.length > 1 &&
							<p>Users shown have <a onClick={this.toggleFilterType}>{filterTypeMessage} of the teams</a> listed</p>
						}
					<br />
				</div>
			</div>
		);
    }
}

TagFilters.propTypes = {
	tags: PropTypes.array.isRequired
};

export default TagFilters;
