import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const FetchDiscussion = (function () {
  const _requestDiscussionData = function () {
    return {
      type: constants.REQUEST_REPLIES,
    };
  };

  const _receiveDiscussionData = function (data) {
    return {
      type: constants.RECEIVE_REPLIES,
      replies: data.results.replies,
      discussion: data.results.discussion,
    };
  };

  const _fetchDiscussionDataError = function (error) {
    return {
      type: constants.REQUEST_REPLIES_ERROR,
      errors: error.response.data,
    };
  };

  const _fetchDiscussionData = function (discussionId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestDiscussionData());
      api.get(`/proxy/v1/discussions/${discussionId}/replies`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveDiscussionData(res));
        })
        .catch((res) => {
          const response = res.response || {};
          const { status } = response;
          if (status === 401) {
            // No auth
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else if (status === 404) {
            // No discussion found
            dispatch(_fetchDiscussionDataError(res));
            dispatch(routerActions.push('/?err=p1'));
          } else if (res.response) {
            dispatch(_fetchDiscussionDataError(res));
            dispatch(routerActions.push('/?err=p2'));
          } else {
            dispatch(routerActions.push('/?err=p2'));
          }
        });
    };
  };

  const _shouldFetchDiscussionData = function (discussionId, state) {
    const discussion = state.discussion.data;
    if (Object.keys(discussion).length === 0) {
      return true;
    } else if (discussion._id !== discussionId) {
      return true;
    }
    return false;
  };

  const _fetchDiscussionDataIfNeeded = function (discussionId, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchDiscussionData(discussionId, getState())) {
        return dispatch(_fetchDiscussionData(discussionId, orgId, accessToken));
      }
      return false;
    };
  };

  const _clearDiscussion = function () {
    return {
      type: constants.CLEAR_DISCUSSION,
    };
  };

  return {
    fetchDiscussionDataIfNeeded: _fetchDiscussionDataIfNeeded,
    fetchDiscussionData: _fetchDiscussionData,
    clearDiscussion: _clearDiscussion,
  };
}());

export default FetchDiscussion;
