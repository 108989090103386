import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import loginUtils from '../utils/LoginUtils';
import authLogout from './auth-logout';

const EditUser = (function () {
  const _editUserRequest = function () {
    return {
      type: constants.EDIT_USER_REQUEST,
    };
  };

  const _editUserSuccess = function (state, data) {
    const { user } = state.auth;
    if (user._id === data.user._id) {
      return {
        type: constants.EDIT_USER_SUCCESS,
        user: data,
      };
    }

    return {
      type: constants.EDIT_OTHER_USER_SUCCESS,
      user: data,
    };
  };

  const _editUserError = function (error) {
    return {
      type: constants.EDIT_USER_ERROR,
      errors: error.data,
    };
  };

  const _updateUserOptionsSuccess = function (data) {
    return {
      type: constants.UPDATE_USER_OPTIONS_SUCCESS,
      user: data,
    };
  };

  const _updateUserOptionsError = function (error) {
    return {
      type: constants.UPDATE_USER_OPTIONS_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.AUTH_CREATE_PROFILE_ERROR,
      errors,
    };
  };

  const _clearAuthError = function () {
    return {
      type: constants.CLEAR_CREATE_PROFILE_ERROR,
    };
  };

  const _editUser = function (user, orgId, accessToken) {
    return function (dispatch, getState) {
      loginUtils.validateEditProfile(user)
        .then((result) => {
          if (result === true) {
            dispatch(_editUserRequest());
            const updateData = {
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              about: user.about,
              title: user.title,
              photoUrl: user.photoUrl,
              timeZone: user.timeZone,
              orgId,
              externalOrg: user.externalOrg,
              phoneNumbers: user.phoneNumbers,
              tags: user.tags,
            };
            if (user.preferences) {
              updateData.preferences = user.preferences;
            }
            api.put('/proxy/v1/users/', updateData, accessToken)
              .then((res) => {
                dispatch(_editUserSuccess(getState(), res));
              })
              .catch((res) => {
                if (res.status === 401) {
                  dispatch(authLogout.logout());
                  dispatch(routerActions.push('/login?err=3'));
                } else {
                  dispatch(_editUserError(res));
                }
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  // Sends the entire user object for updating
  // Should refactor editUser to this or update parts like user.org, user.preferences
  const _editUserFull = function (userFull, orgId, accessToken) {
    return function (dispatch, getState) {
      loginUtils.validateEditProfile(userFull)
        .then((result) => {
          if (result === true) {
            dispatch(_editUserRequest());
            api.put(`/proxy/v1/users/${userFull._id}/full/`, {
              orgId,
              userFull,
            }, accessToken)
              .then((res) => {
                dispatch(_editUserSuccess(getState(), res));
              })
              .catch((res) => {
                if (res.response.status === 401) {
                  dispatch(authLogout.logout());
                  dispatch(routerActions.push('/login?err=3'));
                } else {
                  dispatch(_editUserError(res.response));
                }
              });
          } else {
            dispatch(_validationError(result.response));
          }
        });
    };
  };

  const _updateUserOptions = function (user, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_editUserRequest());
      api.put(`/proxy/v1/users/${user._id}/options`, {
        orgId,
        timeZone: user.timeZone,
      }, accessToken)
        .then((res) => {
          dispatch(_updateUserOptionsSuccess(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_updateUserOptionsError(res));
          }
        });
    };
  };

  return {
    editUser: _editUser,
    editUserFull: _editUserFull,
    updateUserOptions: _updateUserOptions,
    clearAuthError: _clearAuthError,
  };
}());

export default EditUser;
