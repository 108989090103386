import { routerActions } from 'react-router-redux';
import getSlug from 'speakingurl';

import constants from '../constants';
import api from '../../../utils/api';
import joinVibeActions from '../../auth/actions/joinVibe';
import organizationActions from '../../organizations/actions/organization';
import vibeActions from './vibe';
import vibeValidations from '../utils/vibeValidations';

const CreateVibe = (function () {
  const _createVibeRequest = function () {
    return {
      type: constants.CREATE_VIBE_REQUEST,
    };
  };

  const _createVibeSuccess = function (data) {
    return {
      type: constants.CREATE_VIBE_SUCCESS,
      vibe: data.vibe,
    };
  };

  const _createVibeError = function (error) {
    return {
      type: constants.CREATE_VIBE_ERROR,
      errors: error.data,
    };
  };

  // var _validationError = function(errors) {
  //     return {
  //         type: constants.CREATE_VIBE_ERROR,
  //         errors: errors
  //     };
  // };

  const _validateVibe = function (title, description, vibeLayout, callback) {
    return function () {
      vibeValidations.validateVibe(title, description, vibeLayout)
        .then((result) => {
          if (result === true) {
            return callback(true);
          }
          return callback(result);
        });
    };
  };

  const _validateVibeUniqueTitle = function (title, organization, accessToken, callback) {
    const org = organization.data;
    return function () {
      let friendlyUrl = getSlug(title, { uric: false, mark: false });
      friendlyUrl = `${org.teamDomain}-${friendlyUrl}`;
      vibeActions.validateVibeByFriendlyUrl(friendlyUrl, org._id, accessToken, (res) => {
        // Will return 'true' if name is unique, else 'false'
        return callback(res, friendlyUrl);
      });
    };
  };

  const _createVibe = function (vibe, userId, orgId, teamDomain, redirect, autoApprove, accessToken) {
    return function (dispatch) {
      dispatch(_createVibeRequest());
      api.post('/proxy/v1/vibes/', {
        vibe,
        orgId,
        teamDomain,
        autoApprove,
      }, accessToken)
        .then((res) => {
          // var orgVibe = _.find(res.organization.vibes, function(orgVibe) { return orgVibe.vibe === res.vibe._id; });

          dispatch(joinVibeActions.joinVibe(userId, res.vibe._id, true, orgId, accessToken));
          // Correctly adds vibe to state (should be in org or org-vibes though)
          // Should move vibe creating to org branch in the future
          // dispatch(organizationVibeActions.createOrgVibe(res.vibe));

          // May be redundant with new checkUser HOC version
          dispatch(organizationActions.updateOrganizationState(res.organization, 'nonPublic'));

          dispatch(_createVibeSuccess(res));
          if (redirect) {
            dispatch(routerActions.push(`/board/${res.vibe._id}?new=true`));
          }
        })
        .catch((res) => {
          dispatch(_createVibeError(res));
        });
    };
  };

  const _setCreateVibeStep = function (step) {
    return function (dispatch) {
      dispatch(routerActions.push(`/board/create/${step}`));
    };
  };

  return {
    createVibe: _createVibe,
    validateVibe: _validateVibe,
    validateVibeUniqueTitle: _validateVibeUniqueTitle,
    setCreateVibeStep: _setCreateVibeStep,
  };
}());

export default CreateVibe;
