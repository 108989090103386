var React = require('react');
var withRouter = require('react-router').withRouter;
var PropTypes = require('prop-types');

var UserList = require('../../components/users/containers/userList.jsx').default;

class OrgMembers extends React.Component {
    render() {
        return (
            <div className="row column">
                <br/>
                <UserList 
                    mode={'org'} 
                    query={this.props.location.query} />
    	    </div>
        );
    }
}

OrgMembers.propTypes = {
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default withRouter(OrgMembers);
