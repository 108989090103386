const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const moment = require('moment');
const Linkify = require('linkifyjs/react');
const _ = require('lodash');

const RSVPButtons = require('./rsvpButtons.jsx').default;
const utils = require('../../../../utils').default;

class EventsItem extends React.Component {
  handleEditClick = (eventItem) => {
    if (eventItem.startDate) {
      eventItem.startDateOnly = eventItem.startDate;
      eventItem.startTimeOnly = eventItem.startDate;
    }
    if (eventItem.endDate) {
      eventItem.endDateOnly = eventItem.endDate;
      eventItem.endTimeOnly = eventItem.endDate;
    }

    if (this.props.view === 'modal') {
      this.props.closeEventModal();
    }

    this.props.setSelectedEvent(eventItem);
    this.props.openEventInputModal('edit');
  };

  handleJoinVibeClick = () => {
    const userIsVibeMember = utils.userIsVibeMember(
      this.props.auth.user.vibes, this.props.eventItem.vibe._id,
    );

    if (!userIsVibeMember) {
      this.props.joinVibe(
        this.props.auth.user._id, this.props.eventItem.vibe._id, false,
        this.props.organization.data._id, this.props.auth.token,
      );
    }
  };

  userIsEventOrganizer = (eventItem) => {
    if (eventItem.organizer === this.props.auth.user._id) {
      return true;
    }

    return false;
  };

  render() {
    const { eventItem = {}, view } = this.props;
    const { _id: eventId, vibe: eventVibe = {} } = eventItem;
    const { _id: eventVibeId } = eventVibe;

    let dateStyles = [];
    let containerStyle = 'post-container event';
    const organization = this.props.organization.data;

    if (view === 'modal') {
      containerStyle = `${containerStyle} no-border no-padding`;
      dateStyles = { marginRight: '2rem' };
    }

    let isVibeMember = false;

    // This typeof check was needed because the RSVP findOneAndUpdate didn't populate the vibe object
    // It has been fixed on the backed and the check is no longer needed
    if (eventItem.vibe) {
      if (typeof eventItem.vibe === 'string') {
        isVibeMember = utils.userIsVibeMember(this.props.auth.user.vibes, eventItem.vibe);
      } else {
        isVibeMember = utils.userIsVibeMember(this.props.auth.user.vibes, eventItem.vibe._id);
      }
    }

    const userId = this.props.auth.user._id;
    const rsvp = _.find(eventItem.attendees, attendee => attendee.user._id === userId && attendee.orgId === organization._id);

    let eventMeta;
    let eventDay = moment(eventItem.startDate).format('D');
    let eventTime = moment(eventItem.startDate).format('h:mm A');
    const eventAllDay = eventItem.allDay;

    // If endDate <> endDate, add end date to display
    if (moment(eventItem.endDate).isAfter(moment(eventItem.startDate), 'day')) {
      eventDay = `${eventDay} - ${moment(eventItem.endDate).format('D')}`;
    }

    // If endTime <> startTime, add end time to display
    if (moment(eventItem.endDate).isAfter(moment(eventItem.startDate), 'minute')) {
      eventTime = `${eventTime} - ${moment(eventItem.endDate).format('h:mm A')}`;
    }

    // Assign to eventMeta and add location if provided
    eventMeta = (eventAllDay === false) ? eventTime : '';
    eventMeta = eventMeta + ((eventAllDay === false && eventItem.location) ? ' - ' : '');
    eventMeta = eventMeta + ((eventItem.location) ? eventItem.location : '');

    const eventShowAttendees = ('showAttendees' in eventItem) ? eventItem.showAttendees : true;
    const eventAllowRSVP = ('allowRSVP' in eventItem) ? eventItem.allowRSVP : true;

    const attendeeList = _.filter(eventItem.attendees, { status: 'going' });
    const attendeeListFormatted = _.map(attendeeList, (attendee) => {
      const name = `${attendee.user.firstName} ${attendee.user.lastName}`;
      return (
        <Link key={attendee.user._id} to={`/profile/${attendee.user._id}`}>
          <img
            src={utils.formatImage(attendee.user.photoUrl, 'userThumb')}
            title={name}
            alt={name}
            onError={utils.showDefaultImage} />
        </Link>
      );
    });

    return (
      <article className={containerStyle}>
        <header className="post-header">
          <div className="event-date" style={dateStyles}>
            <p className="event-month">{moment(eventItem.startDate).format('MMM')}</p>
            <p className="event-day">{eventDay}</p>
          </div>
          <div className="post-meta right"></div>
          <h3 className="post-title">
            {eventItem.title}
            {this.props.mode === 'user' &&
              <span className="post-meta"> in <Link to={`/board/${eventItem.vibe._id}`}>{eventItem.vibe.title}</Link>&nbsp;</span>}
          </h3>
          <p className="event-meta">{eventMeta}</p>
        </header>

        <div className="event-body">
          <Linkify>{eventItem.description}</Linkify>
        </div>

        <div>
          <div className={eventAllowRSVP ? 'event-rsvp' : 'hide'}>
            {(isVibeMember) ?
              <RSVPButtons
                eventId={eventId}
                rsvp={rsvp}
                RSVPclick={this.props.RSVPclick} />
              : (this.props.view === 'modal') ?
                <p className=""><a href={null} onClick={this.handleJoinVibeClick}>Follow this board</a> to RSVP for this event</p>
                : null
            }
          </div>
          <div className={eventShowAttendees ? 'member-thumbnails left' : 'hide'}>
            {attendeeListFormatted}
          </div>
        </div>

        <aside className="post-actions">
          <ul className="menu">
            {(this.userIsEventOrganizer(eventItem) ||
              utils.userIsVibeChampion(this.props.auth.user.vibes, eventVibeId) ||
              utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs))
              ? <li><button type="button" className="link" title="Edit event" onClick={ this.handleEditClick.bind(this, eventItem) }>Edit event</button></li>
              : null}
          </ul>
        </aside>

        <footer className="post-footer">
          <ul>
            <li>&nbsp;</li>
          </ul>
        </footer>
      </article>
    );
  }
}

EventsItem.propTypes = {
  RSVPclick: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  openEventInputModal: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  mode: PropTypes.string,
  view: PropTypes.string,
  eventItem: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};

export default EventsItem;
