import * as types from './types';

const initialState = {
  isFetching: false,
  data: {},
  tagMembers: [],
  members: {
    direct: [],
    inherited: [],
    tag: [],
    parentId: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.REQUEST_TAG_MEMBERS:
    return {
      ...state,
      isFetching: true,
    };
  case types.RECEIVE_TAG_MEMBERS:
    return {
      ...state,
      isFetching: false,
      tagMembers: action.tagMembers,
      members: {
        tag: action.tagMembers,
      },
      error: {},
    };
  case types.REQUEST_TAG_MEMBERS_ERROR:
    return {
      ...state,
      isFetching: false,
      error: action.error,
    };
  default:
    return state;
  }
};

export default reducer;
