import constants from '../constants';

const PreviousRoute = (function () {
  const _setPreviousRouteState = function (route) {
    return {
      type: constants.PREVIOUS_ROUTE_SET,
      route,
    };
  };

  const _setPreviousRoute = function (route) {
    return function (dispatch) {
      dispatch(_setPreviousRouteState(route));
    };
  };

  return {
    setPreviousRoute: _setPreviousRoute,
  };
}());

export default PreviousRoute;
