const React = require('react');
const { Link, withRouter } = require('react-router');
const PropTypes = require('prop-types');
const { ReactiveBase, DataSearch, ReactiveList } = require('@appbaseio/reactivesearch');
const _ = require('lodash');
const moment = require('moment');
const { Markup } = require('interweave');

const utils = require('../../../../common/utils').default;
const SearchSentry = require('../../components/searchSentry.jsx').default;

const PostModal = require('../../components/posts/containers/postModalContainer.jsx').default;

const NoResults = function () {
  // Must use span tags since renderNoResults is wrapped in <p> tags
  return (
    <span className="row columns">
      <br />
      <span style={{ display: 'block', fontSize: '1.7rem' }} className="text-center">No matches found</span>
      <br />
      <span style={{ display: 'block' }} className="text-center">Adjust your search above to try again</span>
    </span>
  );
};

class SearchResults extends React.PureComponent {
  state = {
    inputModalIsOpen: false,
    postModalIsOpen: false,
  };

  componentDidMount() {
    if (this.props.params.id) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.params.id) {
      this.setState({ postModalIsOpen: false });
    }

    if (prevProps.params.id !== this.props.params.id) {
      this.setState({ postModalIsOpen: true });
    }
  }

  openPostModal = () => {
    this.setState({ postModalIsOpen: true });
  };

  closePostModal = () => {
    this.setState({ postModalIsOpen: false });
    this.props.router.push(`/search${this.props.location.search}`);
  };

  closeInputModal = () => {
    this.setState({ inputModalIsOpen: false });
  };

  renderTemplate = (data) => {
    const { vibes: { data: vibesList } } = this.props;
    const vibe = _.find(vibesList, { _id: data.vibe });
    const vibeName = (vibe) ? vibe.title : '';

    const title = data.title || '';
    const description = data.description || '';
    const fullName = data.fullName || '';
    const imageUrl = data.imageUrl || null;
    let created = data.created || null;
    created = (moment(created).year() === moment().year())
      ? moment(created).format('MMM D')
      : moment(created).format('MMM D, YYYY');

    const { meta: { authorName = '' } = {} } = data;

    const rawContent = _.get(data, 'content.rich');
    const content = (rawContent)
      ? utils.formatPreview(rawContent)
      : { preview: '', longPreview: '', imageCount: 0, featuredImage: '' };
    const contentPreview = content.longPreview || '';

    const userOrg = (data.orgs) ? _.find(data.orgs, { id: this.props.organization.data._id }) : null;

    // Generate search result item based on searchType
    switch (data.searchType) {
    case 'event':
      return (
        <div key={data._id}>
          <p><strong>{title}</strong></p>
          <p>{description}</p>
          <p className="subheader">(Type: Event / Board: {data.vibe} / Org: {data.orgId})</p>
          <br /><hr />
        </div>
      );
    case 'user':
      return (
        <div key={data._id}>
          <Link to={data.link} className="common-link">
            <button className="button small float-right search-link-button" type="button">View profile</button>
            <img
              className="search-image float-left"
              alt={fullName}
              src={utils.formatImage(imageUrl, 'vibeThumb')}
              onError={utils.showDefaultImage} />
            <Markup content={`<strong>${fullName}</strong>`} />
            <p>{userOrg.title}</p>
          </Link>
          <br /><hr />
        </div>
      );
    case 'vibe':
      return (
        <div key={data._id}>
          <Link to={data.link} className="common-link">
            <button className="button small float-right search-link-button" type="button">Visit board</button>
            <img
              className="search-image float-left"
              alt={title}
              src={utils.formatImage(imageUrl, 'vibeThumb')}
              onError={utils.showDefaultImage} />
            <Markup content={`<strong>${title}</strong>`} />
            <Markup content={description} />
          </Link>
          <br /><hr />
        </div>
      );
    case 'discussion':
    default:
      return (
        <div key={data._id}>
          <Link to={`search${data.link}${this.props.location.search}`} className="common-link">
            {(imageUrl) &&
            <img
              className="search-image float-right"
              alt="Featured"
              src={utils.formatImage(imageUrl, 'vibeThumb')}
              onError={utils.showDefaultImage} />
            }
            <Markup content={`<strong>${title}</strong>`} />
            <Markup content={contentPreview} />
            <p className="subheader">Posted in {vibeName} on {created} by {authorName}</p>
            <br /><hr />
          </Link>
        </div>
      );
    }
  };

  render() {
    const { auth, organization, vibes, tags } = this.props;
    const server = utils.getEnvironment(document.location.hostname);
    const searchProxy = `${utils.buildUrlByEnvironment(organization.data.teamDomain, '', server)}searchAPI`;
    // const { vibes: { data: vibesData } } = this.props;
    // const vibesFilters = _.map(vibesData, vibe => ({ label: vibe.title, value: vibe._id }));

    // No search term
    const searchQuery = this.props.location.query.q;
    const isSearchTerm = (searchQuery && searchQuery.replace(/[\W_]+/g, '').length > 0) || false;

    if (!isSearchTerm) {
      return (
        <NoResults />
      );
    }


    return (
      <div className="main-container">
        {/* To display posts directly from search results page */}
        <PostModal
          postId={this.props.params.id}
          modalLocation="search"
          postModalIsOpen={this.state.postModalIsOpen}
          closePostModal={this.closePostModal} />

        <ReactiveBase
          url={searchProxy}
          app={`${server}-discussions,${server}-users,${server}-vibes`}>

          <SearchSentry
            componentId="searchSentry"
            auth={auth}
            organization={organization}
            vibes={vibes}
            tags={tags} />

          <DataSearch
            componentId="q"
            dataField={[
              'title',
              'description',
              'content.rich',
              'fullName',
              'orgs.title',
            ]}
            fieldWeights={[
              2,
              2,
              1,
              10,
              2,
            ]}
            highlightField={[
              'title',
              'description',
              'fullName',
            ]}
            react={{
              and: ['searchSentry'],
            }}
            queryFormat="or"
            style={{ display: 'none' }}
          />
          {/* <div className="row columns">
            <MultiDataList
              componentId="searchTypes"
              dataField="searchType"
              data={
                [
                  { label: 'Posts', value: 'discussion' },
                  { label: 'Users', value: 'user' },
                  { label: 'Vibes', value: 'vibe' },
                ]
              }
              defaultValue={['Posts', 'Users', 'Vibes']}
              title="Testing: "
              showSearch={false}
              style={{ padding: '1rem' }}
              innerClass={{
                title: 'search-filter-title',
                list: 'search-filter-list menu',
              }}
            />

            <ToggleButton
              componentId="searchTypes2"
              dataField="searchType"
              data={
                [
                  { label: 'Posts', value: 'discussion' },
                  { label: 'Users', value: 'user' },
                  { label: 'Vibes', value: 'vibe' },
                ]
              }
              defaultValue={['discussion', 'user', 'vibe']}
              multiSelect={true}
              showFilter={true}
              filterLabel="City"
              innerClass={{ button: 'search-filter-button' }}
            />
          </div> */}
          <div className="row">
            <div className="small-12 medium-10 large-8 medium-centered columns">
              <br />
              <ReactiveList
                componentId="results"
                dataField="title"
                pagination={true}
                react={{
                  and: ['q', 'searchSentry'],
                }}
                renderItem={this.renderTemplate}
                renderNoResults={() => <NoResults />}
                innerClass={{ sortOptions: 'search-sort' }}
                sortOptions={
                  [
                    { label: 'Best Match', dataField: '_score', sortBy: 'desc' },
                    { label: 'Date - Newest first', dataField: 'created', sortBy: 'desc' },
                    { label: 'Date - Oldest first', dataField: 'created', sortBy: 'asc' },
                  ]
                }
              //  defaultQuery={() => ({
              //    highlight: {
              //     fields: {
              //        title: {},
              //        description: {},
              //        fullName: {},
              //      },
              //      pre_tags: ['<mark>'],
              //      post_tags: ['</mark>'],
              //    },
              //  })}
              />
            </div>
          </div>
        </ReactiveBase>
      </div>
    );
  }
}

SearchResults.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
};

export default withRouter(SearchResults);
