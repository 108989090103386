const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const OrgMembersActions = require('../../../modules/organizations/actions/organization-members').default;
const SubscriptionsActions = require('../../../modules/subscriptions/actions/subscriptions').default;

const checkOrgMembers = function (Component) {
  class CheckOrgMembersComponent extends React.Component {
    componentDidMount() {
      this.setOrgMembers();
      this.getSubscriptions();
    }

    setOrgMembers = () => {
      const organization = this.props.organization.data;
      const { token } = this.props.auth;

      this.props.fetchOrganizationMembersIfNeeded(organization._id, token);
    };

    getSubscriptions = () => {
      const organization = this.props.organization.data;
      const { user, token } = this.props.auth;

      if (Object.keys(user).length !== 0) {
        this.props.fetchSubscriptionsIfNeeded(user._id, organization._id, token);
      }
    };

    render() {
      const { user } = this.props.auth;
      const isFetchingSubscriptions = (Object.keys(user).length !== 0) && this.props.subscriptions.isFetching;

      return (
        <div className="orgMembers">
          {Object.keys(this.props.organization.members.data).length !== 0 &&
            !isFetchingSubscriptions
            ? <Component {...this.props} />
            : null}
        </div>
      );
    }
  }

  CheckOrgMembersComponent.propTypes = {
    auth: PropTypes.object,
    organization: PropTypes.object,
    subscriptions: PropTypes.object,
    fetchOrganizationMembersIfNeeded: PropTypes.func.isRequired,
    fetchSubscriptionsIfNeeded: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
      subscriptions: state.subscriptions,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, OrgMembersActions, SubscriptionsActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CheckOrgMembersComponent);
};

export default checkOrgMembers;
