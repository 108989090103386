import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const tags = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_TAGS:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_TAGS:
    newstate.isFetching = false;
    newstate.data = action.tags;
    return newstate;
  case constants.REQUEST_TAGS_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.ADD_TAG:
    newstate.data = utils.upsert(newstate.data, action.tag._id, action.tag);
    return newstate;
  case constants.SAVE_TAGS_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.SAVE_TAGS_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.tags;
    return newstate;
  case constants.SAVE_TAGS_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_TAG_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(newstate.data, action.tag._id, action.tag);
    return newstate;
  case constants.UPDATE_TAG_SUCCESS:
    return {
      ...state,
      isFetching: false,
      data: utils.upsert(state.data, action.tag._id, action.tag),
    };
  case constants.SORT_TAGS:
    newstate.isFetching = false;
    newstate.data = action.tags;
    return newstate;
  case constants.CLEAN_TAGS:
    newstate.data = [];
    return newstate;
  default: return state || initialState.tags;
  }
};

export default tags;
