import constants from '../constants';
import api from '../../../utils/api';
import mentionActions from '../../mentions/actions/mentions';
import visitVibeActions from '../../auth/actions/visitVibe';
import vibesActions from '../../vibes/actions/vibes';
import updateSubscriptionsActions from '../../subscriptions/actions/updateSubscription';

const PostReply = (function () {
  const _createReplyRequest = function () {
    return {
      type: constants.CREATE_REPLY_REQUEST,
    };
  };

  const _createReplySuccess = function (data) {
    return {
      type: constants.CREATE_REPLY_SUCCESS,
      reply: data,
    };
  };

  const _createReplyError = function (error) {
    return {
      type: constants.CREATE_REPLY_ERROR,
      errors: error.data,
    };
  };

  const _createReply = function (discussionId, content, author, orgId, vibeId, mentions, attachments, accessToken) {
    return function (dispatch) {
      dispatch(_createReplyRequest());
      api.post(`/proxy/v1/discussions/${discussionId}/replies`, {
        author,
        orgId,
        content,
        vibeId,
        mentions,
        attachments,
      }, accessToken)
        .then((res) => {
          dispatch(_createReplySuccess(res));
          dispatch(mentionActions.cleanMentions());
          dispatch(visitVibeActions.visitVibe(vibeId, author, orgId, accessToken));
          dispatch(updateSubscriptionsActions.updateSubscription(author, orgId, 'post', discussionId, accessToken));
          dispatch(vibesActions.setActivityFlag(true));
        })
        .catch((res) => {
          dispatch(_createReplyError(res));
          dispatch(mentionActions.cleanMentions());
        });
    };
  };

  return {
    createReply: _createReply,
  };
}());

export default PostReply;
