import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const auth = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.AUTH_CREATE_PROFILE_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.AUTH_CREATE_PROFILE_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.data;
    newstate.error = {};
    return newstate;
  case constants.AUTH_CREATE_PROFILE_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.JOIN_ORG_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.JOIN_ORG_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.data;
    newstate.error = {};
    return newstate;
  case constants.JOIN_ORG_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.AUTH_LOGIN_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.AUTH_LOGIN_SUCCESS:
    newstate.isFetching = false;
    newstate.token = action.token;
    newstate.expires = action.expires;
    newstate.error = {};
    return newstate;
  case constants.AUTH_LOGIN_FAILURE:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.AUTH_LOGOUT_SUCCESS:
    newstate.token = null;
    newstate.expires = null;
    newstate.user = {};
    newstate.error = {};
    return newstate;
  case constants.LOGIN_COOKIE_SUCCESS:
    newstate.isFetching = false;
    newstate.token = action.token;
    newstate.error = {};
    return newstate;
  case constants.LOGIN_COOKIE_FAILURE:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.REFRESH_TOKEN_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.REFRESH_TOKEN_SUCCESS:
    newstate.isFetching = false;
    newstate.token = action.token;
    newstate.expires = action.expires;
    newstate.error = {};
    return newstate;
  case constants.REFRESH_TOKEN_ERROR:
    newstate.isFetching = false;
    newstate.token = null;
    newstate.user = {};
    newstate.error = action.errors.error_description;
    return newstate;
  case constants.REQUEST_CURRENT_USER:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_CURRENT_USER:
    newstate.isFetching = false;
    newstate.user = action.data;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_CURRENT_USER_ERROR:
    newstate.isFetching = false;
    newstate.user = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_CREATE_PROFILE_ERROR:
  case constants.CLEAR_AUTH_LOGIN_ERROR:
    newstate.isFetching = false;
    newstate.error = {};
    return newstate;
  case constants.JOIN_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.user;
    newstate.error = {};
    return newstate;
  case constants.LEAVE_VIBE_SUCCESS:
    newstate.isFetching = false;
    // newstate.user = action.user;
    newstate.user.vibes = utils.removeByField(state.user.vibes, action.vibeId, 'vibe');
    newstate.error = {};
    return newstate;
  case constants.VISIT_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.user;
    newstate.error = {};
    return newstate;
  case constants.EDIT_USER_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.user.user;
    newstate.error = {};
    return newstate;
  case constants.EDIT_OTHER_USER_SUCCESS:
    newstate.isFetching = false;
    newstate.error = {};
    return newstate;
  case constants.ADD_SOCIAL_IDENTITY_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.user;
    newstate.error = {};
    return newstate;
  case constants.REMOVE_SOCIAL_IDENTITY_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.data;
    newstate.error = {};
    return newstate;
  case constants.UPDATE_USER_OPTIONS_SUCCESS:
    newstate.isFetching = false;
    newstate.user = action.user.user;
    newstate.error = {};
    return newstate;
  default:
    return state || initialState.auth;
  }
};

export default auth;
