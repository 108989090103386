import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscussionsActions from '../../../../modules/discussions/actions/discussions';
import CreateDiscussionActions from '../../../../modules/discussions/actions/createDiscussion';
import EditDiscussionActions from '../../../../modules/discussions/actions/editDiscussion';
import DeleteDiscussionActions from '../../../../modules/discussions/actions/deleteDiscussion';
import EditReplyActions from '../../../../modules/discussions/actions/editReply';
import DeleteReplyActions from '../../../../modules/discussions/actions/deleteReply';
import DiscussionInputModal from '../components/discussionInputModal.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    discussions: state.discussions,
    discussion: state.discussion,
    post: state.post,
    vibes: state.vibes,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    DiscussionsActions, CreateDiscussionActions, EditDiscussionActions, DeleteDiscussionActions,
    EditReplyActions, DeleteReplyActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionInputModal);
