var React = require('react');
var { Helmet } = require('react-helmet');
var PropTypes = require('prop-types');

class PasswordReset extends React.PureComponent {
    componentDidMount() {
        this.props.passwordResetValidation(this.props.params.userId, this.props.params.token, this.props.organization.data);
    }

    handlePasswordResetUpdate = (event) => {
        event.preventDefault();
        this.props.passwordReset(this.props.params.userId, this.refs.newPassword.value, this.props.params.token, this.props.organization.data);
    };

    render() {
        var organization = this.props.organization.data;
        var isFetching = this.props.organization.isFetching;

        var regError = this.props.registration.error;
        var passwordError = ((Object.keys(regError).length !== 0) && ('password' in regError)) ? regError.password.message : null;

        return (
            <div>
                <Helmet title="Reset password" />
                <br />
                <br />
                <div className="row">
                    <div className="medium-10 medium-centered large-7 large-centered columns">
                        {organization && !isFetching &&
                        <form onSubmit={this.handlePasswordResetUpdate}>
                            <div className="login-card">
                                <div className="medium-8 medium-centered columns"> 
                                    <h3 className="text-center"><strong>Set new password</strong></h3>
                                    <p className="text-center">Enter your new password for {organization.name}</p>
                                    <input type="password" className="input" placeholder="Password" ref="newPassword" />
                                    <span className="input-error">{passwordError}</span>
                                    <br /><br />
                                    <p><button type="submit" className="button expanded">Update password</button></p>
                                </div>
                            </div>
                        </form>
                        }
                        {isFetching &&
                            <h1 className="loading">Loading...</h1>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

PasswordReset.propTypes = {
    passwordReset: PropTypes.func.isRequired,
    passwordResetValidation: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired
};

export default PasswordReset;
