import constants from '../constants';
import initialState from '../../initialState';

const previousRoute = function (state, action) {
  let newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.PREVIOUS_ROUTE_SET:
    newstate = action.route;
    return newstate;
  default:
    return state || initialState.previousRoute;
  }
};

export default previousRoute;
