const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');

class ActivateVibe extends React.PureComponent {
  state = {
    activateClick: false,
  }

  componentDidMount() {
    const { selectedVibe } = this.props;

    if (Object.keys(selectedVibe.data).length === 0) {
      this.props.setCreateVibeStep('1');
    }
  }

  activateClick = () => {
    this.setState({ activateClick: true });
    this.props.handleActivateVibe();
  }

  render() {
    const { activateClick } = this.state;
    const activateText = (activateClick) ? 'Waiting for admin approval' : 'Awaiting your activation';
    const activateTextColor = (activateClick) ? 'green' : 'red';

    return (
      <div className="callout">
        <div className="row">
          <div className="large-8 columns">
            <h2>Activate Board</h2>
            <p>Your shiny new board is all setup and ready for activation!</p>
            <p>Once you activate, a review request will be sent to the company admin (usually HR). When they approve it, you will get an email notifiying you that it&#39;s live and online.</p>
            <div className="text-center">
              <br />
              <h4>Current board status: <span style={{ color: activateTextColor }}>{activateText}</span></h4>
              <br />
              <p>
                <button
                  type="button"
                  className="button"
                  disabled={activateClick}
                  onClick={this.activateClick}>Activate board and send for approval
                </button>
              </p>
              <p>
                <Link
                  to="/"
                  className={(activateClick) ? null : 'hide'}
                  title="Back to home">You may now return to the hompage and explore other boards
                </Link>
              </p>
            </div>
            <br />
          </div>
          <div className="large-4 columns">
            <div className="vibe-tile preview float-center">
              <img id="imageOutput" src={this.props.selectedVibe.data.imageUrl} style={{ width: '100%' }} alt="" />
              <div className="card-body">
                <h3 className="lead"><strong>{this.props.selectedVibe.data.title}</strong></h3>
                <p id="descriptionOutput">{this.props.selectedVibe.data.description}</p>
                <p><button type="button" className="button small hollow expanded disabled">Explore board</button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ActivateVibe.propTypes = {
  handleActivateVibe: PropTypes.func.isRequired,
  setCreateVibeStep: PropTypes.func.isRequired,
  selectedVibe: PropTypes.object.isRequired,
};

export default ActivateVibe;
