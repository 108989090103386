import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import utils from '../../../utils';

const TagItem = (props) => {
  const { tag: { displayName, tagAdmin, _id: tagId }, userIsOrgAdmin, userId, removeTag } = props;
  const isTagAdmin = _.includes(tagAdmin, userId);
  const handleTagRemove = () => {
    // remove tag from list
    removeTag(tagId);
  };

  return (
    <span className="tag is-primary is-medium is-rounded">
      {displayName}
      {(userIsOrgAdmin || isTagAdmin) && <button type="button" className="delete is-small" onClick={handleTagRemove} />}
    </span>
  );
};


const TagsList = (props) => {
  const { removeTag } = props;
  // Import state vars
  const { auth, organization, selectedVibe, tags } = useSelector(
    (state) => ({
      auth: state.auth,
      organization: state.organization,
      selectedVibe: state.selectedVibe,
      tags: state.tags,
    }),
  );

  const { user } = auth;
  const { _id: userId, orgs: userOrgs = {} } = user;
  const { data: { _id: orgId } } = organization;
  const { data: tagsData } = tags;
  const { data: selectedVibeData } = selectedVibe;
  const { orgOptions = {} } = selectedVibeData;
  const boardTags = (orgOptions && 'tags' in orgOptions) ? orgOptions.tags : [];

  const userIsOrgAdmin = utils.userIsOrgAdmin(orgId, userOrgs);

  const tagList = tagsData.map((tag) => {
    if (_.includes(boardTags, tag._id)) {
      return (
        <TagItem
          tag={tag}
          key={tag._id}
          userIsOrgAdmin={userIsOrgAdmin}
          userId={userId}
          removeTag={removeTag} />
      );
    }

    return false;
  });

  return (
    <div className="tag-list">
      {tagList}
    </div>
  );
};

TagItem.propTypes = {
  removeTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

TagsList.propTypes = {
  removeTag: PropTypes.func.isRequired,
};

export default TagsList;
