import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgConfirmation from '../components/orgConfirmation.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgConfirmation);
