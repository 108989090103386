import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import fetchDiscussionActions from './fetchDiscussion';
import discussionsActions from './discussions';

const EditDiscussion = (function () {
  const _editPostRequest = function () {
    return {
      type: constants.EDIT_POST_REQUEST,
    };
  };

  const _editPostSuccess = function (data) {
    return {
      type: constants.EDIT_POST_SUCCESS,
      post: data.discussion,
    };
  };

  const _movePostSuccess = function (data) {
    return {
      type: constants.MOVE_POST_SUCCESS,
      post: data.discussion,
    };
  };

  const _editPostError = function (error) {
    return {
      type: constants.EDIT_POST_ERROR,
      errors: error.data,
    };
  };

  const _editDiscussion = function (postItem, orgId, selectedVibeId, accessToken) {
    return function (dispatch) {
      dispatch(_editPostRequest());
      api.put(`/proxy/v1/discussions/${postItem.data._id}`, {
        vibe: postItem.data.vibe,
        postType: postItem.data.postType,
        title: postItem.data.title,
        content: postItem.data.content,
        featuredImage: postItem.data.featuredImage,
        orgId,
        isPinned: postItem.data.isPinned,
        isFeatured: postItem.data.isFeatured,
        callToAction: postItem.data.callToAction,
        attachments: postItem.data.attachments,
      }, accessToken)
        .then((res) => {
          // Call fetchDiscussionData to re-attach replies to discussion state
          dispatch(fetchDiscussionActions.fetchDiscussionData(postItem.data._id, orgId, accessToken));
          dispatch(discussionsActions.clearPost());
          if (postItem.data.vibe === selectedVibeId) {
            dispatch(_editPostSuccess(res));
          } else {
            dispatch(_movePostSuccess(res));
            dispatch(routerActions.push(`/board/${selectedVibeId}`));
          }
        })
        .catch((res) => {
          dispatch(_editPostError(res));
        });
    };
  };

  return {
    editDiscussion: _editDiscussion,
  };
}());

export default EditDiscussion;
