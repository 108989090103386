
export default {
  REQUEST_ORGANIZATION: 'REQUEST_ORGANIZATION',
  RECEIVE_ORGANIZATION: 'RECEIVE_ORGANIZATION',
  REQUEST_ORGANIZATION_ERROR: 'REQUEST_ORGANIZATION_ERROR',
  CREATE_ORGANIZATION_REQUEST: 'CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_ERROR: 'CREATE_ORGANIZATION_ERROR',
  EDIT_ORGANIZATION_REQUEST: 'EDIT_ORGANIZATION_REQUEST',
  EDIT_ORGANIZATION_SUCCESS: 'EDIT_ORGANIZATION_SUCCESS',
  EDIT_ORGANIZATION_ERROR: 'EDIT_ORGANIZATION_ERROR',
  EDIT_ORGANIZATION_USER_REQUEST: 'EDIT_ORGANIZATION_USER_REQUEST',
  EDIT_ORGANIZATION_USER_SUCCESS: 'EDIT_ORGANIZATION_USER_SUCCESS',
  EDIT_ORGANIZATION_USER_ERROR: 'EDIT_ORGANIZATION_USER_ERROR',
  REMOVE_ORGANIZATION_USER_REQUEST: 'REMOVE_ORGANIZATION_USER_REQUEST',
  REMOVE_ORGANIZATION_USER_SUCCESS: 'REMOVE_ORGANIZATION_USER_SUCCESS',
  REMOVE_ORGANIZATION_USER_ERROR: 'REMOVE_ORGANIZATION_USER_ERROR',
  EDIT_ORGANIZATION_VIBE_REQUEST: 'EDIT_ORGANIZATION_VIBE_REQUEST',
  EDIT_ORGANIZATION_VIBE_SUCCESS: 'EDIT_ORGANIZATION_VIBE_SUCCESS',
  EDIT_ORGANIZATION_VIBE_ERROR: 'EDIT_ORGANIZATION_VIBE_ERROR',
  REQUEST_ORGANIZATION_MEMBERS: 'REQUEST_ORGANIZATION_MEMBERS',
  RECEIVE_ORGANIZATION_MEMBERS: 'RECEIVE_ORGANIZATION_MEMBERS',
  REQUEST_ORGANIZATION_MEMBERS_ERROR: 'REQUEST_ORGANIZATION_MEMBERS_ERROR',
  CREATE_ORGANIZATION_VIBE: 'CREATE_ORGANIZATION_VIBE',
  REMOVE_ORGANIZATION_VIBE: 'REMOVE_ORGANIZATION_VIBE',
  REQUEST_ORGANIZATION_VIBES: 'REQUEST_ORGANIZATION_VIBES',
  RECEIVE_ORGANIZATION_VIBES: 'RECEIVE_ORGANIZATION_VIBES',
  REQUEST_ORGANIZATION_VIBES_ERROR: 'REQUEST_ORGANIZATION_VIBES_ERROR',
  UPDATE_USER_ORG_REQUEST: 'UPDATE_USER_ORG_REQUEST',
  UPDATE_USER_ORG_SUCCESS: 'UPDATE_USER_ORG_SUCCESS',
  UPDATE_USER_ORG_ERROR: 'UPDATE_USER_ORG_ERROR',
  APPROVE_ORGVIBE_REQUEST: 'APPROVE_ORGVIBE_REQUEST',
  APPROVE_ORGVIBE_SUCCESS: 'APPROVE_ORGVIBE_SUCCESS',
  APPROVE_ORGVIBE_ERROR: 'APPROVE_ORGVIBE_ERROR',
  REJECT_ORGVIBE_REQUEST: 'REJECT_ORGVIBE_REQUEST',
  REJECT_ORGVIBE_SUCCESS: 'REJECT_ORGVIBE_SUCCESS',
  REJECT_ORGVIBE_ERROR: 'REJECT_ORGVIBE_ERROR',
};
