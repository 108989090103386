import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const vibes = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_VIBES:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_VIBES:
    newstate.isFetching = false;
    newstate.data = action.vibes;
    return newstate;
  case constants.REQUEST_VIBES_ERROR:
    newstate.isFetching = false;
    newstate.data = [];
    newstate.error = action.errors;
    return newstate;
  case constants.DELETE_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.vibe.vibe._id);
    newstate.callout = action.callout;
    return newstate;
  case constants.REJECT_VIBE_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    newstate.data = utils.remove(state.data, action.vibe);
    return newstate;
  case constants.VIBE_FOLLOW_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.vibes;
    return newstate;
  case constants.VIBE_UNFOLLOW_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.vibes;
    return newstate;
  case constants.UPDATE_VIBES_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.vibes;
    return newstate;
  case constants.UPDATE_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(state.data, action.vibe._id, action.vibe);
    newstate.data = utils.sortVibes(state.data);
    return newstate;
  case constants.REQUEST_CALLOUT_REMOVE:
    newstate.callout = {};
    return newstate;
  case constants.ADD_VIBE_CHAMPION:
    newstate.isFetching = false;
    newstate.data = utils.upsert(state.data, action.vibe._id, action.vibe);
    return newstate;
  case constants.REMOVE_VIBE_CHAMPION:
    newstate.isFetching = false;
    newstate.data = utils.upsert(state.data, action.vibe._id, action.vibe);
    return newstate;
  case constants.CREATE_VIBE_SUCCESS:
    newstate.isFetching = false;
    // Needed since vibes created by non-admins don't return an orgVibe until approved
    newstate.data = (action.vibe) ? utils.unshift(state.data, action.vibe) : state.data;
    return newstate;
  case constants.REJECT_VIBE_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.vibeId);
    return newstate;
  case constants.SET_VIBES_ACTIVITY:
    newstate.isFetching = false;
    newstate.activity = action.flag;
    return newstate;
  default: return state || initialState.vibes;
  }
};

export default vibes;
