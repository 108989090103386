const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const SurveyQuestion = require('./surveyQuestion');
const SurveyChoices = require('./surveyChoices');

class SurveyModal extends React.Component {
  state = {
    currentQuestion: 0,
  };

  afterOpenModal = () => {
    // this.displayName.focus();
  };

  nextQuestion = () => {
    this.setState({ currentQuestion: (this.state.currentQuestion + 1) });
  };

  prevQuestion = () => {
    this.setState({ currentQuestion: (this.state.currentQuestion - 1) });
  };

  render() {
    const { survey } = this.props;
    // Use question number unless < 0 or > length of array
    let { currentQuestion } = this.state;
    if (currentQuestion <= 0) { currentQuestion = 0; }
    if (currentQuestion > survey.questions.length) { currentQuestion = (survey.questions.length - 1); }

    const questionTitle = survey.questions[currentQuestion].title;
    const questionText = survey.questions[currentQuestion].description;

    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.closeModal}
          className="modal"
          overlayClassName="modal-overlay"
          contentLabel="Survey modal">

          {/* Close buttons */}
          <button className="close-button" onClick={this.props.closeModal} aria-label="Close reveal" type="button">
            <span aria-hidden="true"><small>&times;</small></span>
          </button>

          <div className="row">
            <div className="small-12 columns">
              <h1>{survey.title}</h1>
            </div>
          </div>
          <SurveyQuestion
            currentQuestion={this.state.currentQuestion}
            questionTitle={questionTitle}
            questionText={questionText}
          />
          <SurveyChoices
            currentChoices={console.log('hello')}
            choicesText={console.log('HI')}
          />

          <div className="row">
            <div className="small-12 columns text-center" style={{ color: '#2baed8'}}>
              <button onClick={this.prevQuestion}>&lt; Prev</button>
              <button onClick={this.nextQuestion}>Next &gt;</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SurveyModal.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeInputModal: PropTypes.func.isRequired,
};

export default SurveyModal;
