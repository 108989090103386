/* 
###########################################################################
  NEED TO CONSOLIDATE THIS FILE WITH events/components/eventsItem.jsx
###########################################################################   
*/
var React = require('react');
var PropTypes = require('prop-types');
var moment = require('moment');

var EventsModal = require('./eventsModal.jsx').default;

class EventsWidgetItem extends React.PureComponent {
    state = { eventModalIsOpen: false };

    handleClick = (selectedEvent) => {
        this.props.setSelectedEvent(selectedEvent);          
        this.setState({eventModalIsOpen: true});
    };

    handleRSVPclick = (eventId, rsvpStatus) => {
        this.props.changeRSVP(eventId, this.props.auth.user._id, rsvpStatus, this.props.organization.data._id, this.props.auth.token);
    };

    closeEventModal = () => {
        this.props.clearSelectedEvent();
        this.setState({eventModalIsOpen: false});
    };

    render() {
        var eventItem = this.props.eventItem;

        //var userId = this.props.auth.user._id;
        //var currentUserRSVP = eventItem.attendees.filter(function(attendee) {
        //    return attendee.user._id === userId;
        //}).map(function(attendee) {
        //    return attendee.status;
        //});

        let eventMeta;
        eventMeta = (eventItem.allDay === false) ? moment(eventItem.startDate).format('h:mm A') : '';
        eventMeta = eventMeta + ((eventItem.allDay === false && eventItem.location) ? ' - ' : '');
        eventMeta = eventMeta + ((eventItem.location) ? eventItem.location : '');       

        return (
            <div>
                <EventsModal 
                    selectedEvent={this.props.selectedEvent}
                    auth={this.props.auth}
                    organization={this.props.organization}
                    joinVibe={this.props.joinVibe}
                    RSVPclick={this.handleRSVPclick}
                    eventModalIsOpen={this.state.eventModalIsOpen}
                    closeEventModal={this.closeEventModal}
                    setSelectedEvent={this.props.setSelectedEvent}
                    openEventInputModal={this.props.openEventInputModal} />
            
                {(!this.props.sameDate && this.props.itemKey > 0) && <hr />}
                {!this.props.sameDate && <div className="event-date">
                <p className="event-month">{moment(eventItem.startDate).format('MMM')}</p>
                <p className="event-day">{moment(eventItem.startDate).format('D')}</p>
                </div>}
                <div className="event-title">
                    <a href={null} onClick={ this.handleClick.bind(this, eventItem) }>{eventItem.title}</a>
                </div>
                <p className="event-meta">&nbsp;{eventMeta}</p>
            </div>
        )
    }
}

EventsWidgetItem.propTypes = {
    itemKey: PropTypes.number.isRequired,
    eventItem: PropTypes.object.isRequired,
    selectedEvent: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    joinVibe: PropTypes.func.isRequired
};

export default EventsWidgetItem;
