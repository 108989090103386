import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventsActions from '../../../../modules/events/actions/events';
import CreateEventActions from '../../../../modules/events/actions/createEvent';
import EditEventActions from '../../../../modules/events/actions/editEvent';
import DeleteEventActions from '../../../../modules/events/actions/deleteEvent';
import EventInputModal from '../components/eventInputModal.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        events: state.events,
        selectedEvent: state.selectedEvent,
        vibes: state.vibes       
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, EventsActions, EditEventActions, DeleteEventActions, CreateEventActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventInputModal);