import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscussionsActions from '../../../../modules/discussions/actions/discussions';
import EditDiscussionsActions from '../../../../modules/discussions/actions/editDiscussion';
import EventsActions from '../../../../modules/events/actions/events';
import ChangeRSVPActions from '../../../../modules/events/actions/changeRsvp';
import VibeActions from '../../../../modules/vibes/actions/vibe';
import JoinVibeActions from '../../../../modules/auth/actions/joinVibe';
import PostTileList from '../components/postTileList.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    discussions: state.discussions,
    discussion: state.discussion,
    selectedVibe: state.selectedVibe,
    events: state.events,
    selectedEvent: state.selectedEvent,
    post: state.post,
    subscriptions: state.subscriptions,
    tags: state.tags,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    DiscussionsActions, EditDiscussionsActions,
    EventsActions, ChangeRSVPActions,
    VibeActions, JoinVibeActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PostTileList);
