import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';

const JoinOrg = (function () {
  const _joinOrgRequest = function () {
    return {
      type: constants.JOIN_ORG_REQUEST,
    };
  };

  const _joinOrgSuccess = function (data) {
    return {
      type: constants.JOIN_ORG_SUCCESS,
      data: data.user,
    };
  };

  const _joinOrgError = function (error) {
    return {
      type: constants.JOIN_ORG_ERROR,
      errors: error,
    };
  };

  const _joinOrg = function (userId, orgId, orgLogo, userData, userOrg, invitationToken, inviteType, accessToken) {
    return function (dispatch) {
      dispatch(_joinOrgRequest());
      api.post(`/proxy/v1/users/${userId}/org/${orgId}`, {
        orgLogo,
        userData,
        userOrg,
        invitationToken,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_joinOrgSuccess(res));
          if (inviteType === 'joinOrg' || inviteType === 'selfJoin' || inviteType === 'magicLink') {
            dispatch(routerActions.push('/'));
          } else if (inviteType === 'createOrg') {
            dispatch(routerActions.push('/org-confirmation'));
          }
        })
        .catch((err) => {
          if (err && err.response) {
            dispatch(_joinOrgError(err.response.data));
          } else {
            dispatch(_joinOrgError(err));
          }

          if (err.response && err.response.status === 409) {
            dispatch(routerActions.push('/'));
          }
        });
    };
  };

  return {
    joinOrg: _joinOrg,
  };
}());

export default JoinOrg;
