import React from 'react';
import PropTypes from 'prop-types';
import Draft from 'draft-js';

import Editor, { composeDecorators } from '@draft-js-plugins/editor';

import createInlineToolbarPlugin, { Separator } from 'draft-js-inline-toolbar-plugin';

import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import videoUtils from 'draft-js-video-plugin/lib/video/utils';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
// import createAlignmentPlugin from 'draft-js-alignment-plugin';
// import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import createImagePlugin from '@draft-js-plugins/image';

// import createCheckableListPlugin from 'draft-js-checkable-list-plugin';

// const checkableListPlugin = createCheckableListPlugin();
// const { Button } = checkableListPlugin;

const {
  ItalicButton, BoldButton, UnderlineButton,
  UnorderedListButton, OrderedListButton, BlockquoteButton,
} = require('draft-js-buttons');

const linkifyPlugin = createLinkifyPlugin({ target: 'external' });
const videoPlugin = createVideoPlugin();
const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions } = emojiPlugin;
const linkPlugin = createLinkPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();

const { InlineToolbar } = inlineToolbarPlugin;

const focusPlugin = createFocusPlugin();

// const alignmentPlugin = createAlignmentPlugin();
// const AlignmentTool = alignmentPlugin.AlignmentTool;
// const resizeablePlugin = createResizeablePlugin();

const mentionPlugin = createMentionPlugin();
const { MentionSuggestions } = mentionPlugin;

const decorator = composeDecorators(
  // alignmentPlugin.decorator,
  focusPlugin.decorator,
  // resizeablePlugin.decorator,
  // dndPlugin.decorator
);

const imagePlugin = createImagePlugin({ decorator });

const utils = require('../../../../../common/utils').default;

const plugins = [
  linkifyPlugin,
  emojiPlugin,
  inlineToolbarPlugin,
  linkPlugin,
  videoPlugin,
  imagePlugin,
  mentionPlugin,
  focusPlugin,
  // checkableListPlugin,
];

class DraftEditor extends React.Component {
  constructor(props) {
    super(props);
    const suggestions = [];
    this.editorWindow = React.createRef();

    if (props.users) {
      props.users.forEach((user) => {
        suggestions.push({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          link: `/profile/${user._id}`,
          avatar: user.photoUrl,
        });
      });
    }

    if (props.content && props.content.raw) {
      const contentState = Draft.convertFromRaw(JSON.parse(props.content.raw));
      this.state = {
        editorState: Draft.EditorState.createWithContent(contentState),
        suggestions,
      };

      return;
    }
    // This conditional will persist contentState when modal is closed
    // const editorState = (props.postType === 'discussion' && props.post.data.contentState) ?
    //    Draft.EditorState.createWithContent(props.post.data.contentState) :
    //    Draft.EditorState.createEmpty();
    const editorState = Draft.EditorState.createEmpty();
    this.state = {
      editorState,
      suggestions,
    };
  }

  onSearchChange = (value) => {
    if (this.props.users) {
      const suggestions = [];
      this.props.users.forEach((user) => {
        suggestions.push({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.username,
          link: `/profile/${user._id}`,
          avatar: user.photoUrl,
        });
      });
      this.setState({
        suggestions: defaultSuggestionsFilter(value.value, suggestions),
      });
    }
  };

  editorChange = (newEditorState) => {
    // getCurrentContent() will detect content changes; getSelection() will detect clicks, etc.
    const currentContent = this.state.editorState.getCurrentContent();
    const newContent = newEditorState.getCurrentContent();

    // Only update redux if content has changed
    if ((currentContent !== newContent) && this.props.changeContent) {
      this.props.changeContent(newContent);
    }

    this.setState({ editorState: newEditorState });
  };

  clearEditor = () => {
    const { editorState } = this.state;
    const newEditorState = Draft.EditorState.push(
      editorState, Draft.ContentState.createFromText(''),
    );
    this.setState({ editorState: newEditorState });
  };

  handlePastedText = (text) => {
    const { editorState } = this.state;

    if (videoUtils.isYoutube(text) || videoUtils.isVimeo(text)) {
      const newEditorState = videoPlugin.addVideo(editorState, { src: text });

      this.setState({
        editorState: Draft.EditorState.forceSelection(
          newEditorState,
          editorState.getCurrentContent().getSelectionAfter(),
        ),
      });

      this.props.changeContent(newEditorState.getCurrentContent());
      return 'handled';
    }
    return 'not-handled';
  };

  handleKeyCommand = (command) => {
    const { editorState } = this.state;

    const newContent = Draft.RichUtils.handleKeyCommand(editorState, command);
    if (newContent) {
      this.editorChange(newContent);
      return 'handled';
    }

    return 'not-handled';
  };

  focusTextInput = () => {
    // Focuses editor window on a slight delay to fix emoji & mentions loading bug
    // Check for modalIsOpen prevents focus on window close
    setTimeout(() => { this.editorWindow.current.focus(); }, 10);
  };

  handleUploadPhotoClick = (event) => {
    event.preventDefault();

    const options = {
      folder: `${this.props.organization.data.teamDomain}/uploads`,
    };

    utils.uploadImage(options, (results, error) => {
      if (results) {
        // Image format (insert into post)
        if (results.format === 'gif' ||
          results.format === 'jpg' ||
          results.format === 'jpeg' ||
          results.format === 'png') {
          const { editorState } = this.state;

          // Use addImage plug-in instead of manually adding images
          const newEditorState = imagePlugin.addImage(editorState, results.secure_url, '');

          this.setState({
            editorState: Draft.EditorState.forceSelection(
              newEditorState,
              editorState.getCurrentContent().getSelectionAfter(),
            ),
          });
          this.props.changeContent(this.state.editorState.getCurrentContent());
        } else {
          // Document format (add as attachment)
          this.props.addAttachment(results);
        }
      }
    });
  };

  render() {
    const { editorState } = this.state;
    const readOnly = this.props.readOnly ? this.props.readOnly : false;

    return (
      <div>
        {readOnly === false && (
          <span className="image-upload text-right">
            <button type="button" onClick={this.handleUploadPhotoClick}>
              <i className="fa fa-cloud-upload" title="Upload image or file" />
            </button>
          </span>)
        }

        <div className={this.props.className} onClick={this.focusTextInput}>

          <Editor
            ref={this.editorWindow}
            editorState={editorState}
            handlePastedText={this.handlePastedText}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.editorChange}
            plugins={plugins}
            readOnly={readOnly}
            placeholder={this.props.placeholder}
            spellCheck={true} />
          <InlineToolbar>
            {
              externalProps => (
                <div>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                  <Separator {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                  {/* <CodeBlockButton {...externalProps} /> */}
                  {/* <Button {...externalProps} editorState={editorState} /> */}
                </div>
              )
            }
          </InlineToolbar>
          <EmojiSuggestions />
          {/* <AlignmentTool /> */}
          {this.state.suggestions && (
            <MentionSuggestions
              onSearchChange={this.onSearchChange}
              suggestions={this.state.suggestions} />)
          }
        </div>
      </div>
    );
  }
}

DraftEditor.propTypes = {
  organization: PropTypes.object,
  content: PropTypes.object,
  changeContent: PropTypes.func,
  addAttachment: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  users: PropTypes.array,
};

export default DraftEditor;
