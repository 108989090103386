const React = require('react');
//const { PropTypes } = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const { Multiselect } = require('react-widgets');
const _ = require('lodash');
const Alert = require('../../alert.jsx').default;
const utils = require('../../../../../common/utils').default;

class ListItem extends React.Component {
  render() {
    const user = this.props.item;

    return (
      <div className="clearfix">
        <img src={utils.formatImage(user.photoUrl, 'userThumb')} className="thumb-selectlist float-left" style={{ marginRight: '10px' }} alt="profile" onError={utils.showDefaultImage} />
        <strong>{`${user.firstName} ${user.lastName}`}</strong><br />
        {user.orgs[0].title}
        <br />
      </div>
    );
  }
}

class TagItem extends React.Component {
  render() {
    const user = this.props.item;

    return (
      <span>
        {`${user.firstName} ${user.lastName}`}
      </span>
    );
  }
}

class EmailUserModal extends React.PureComponent {
  state = {
    subjectHelp: '',
    bodyHelp: '',
    updateAlert: false,
    openSelect: false,
    calloutMessage: '',
    userList: this.props.targetUsers || {},
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  showUpdateAlert = (message) => {
    this.setState({
      updateAlert: true,
      calloutMessage: message,
    });
  };

  showErrorAlert = (errors) => {
    // The nested error state won't re-ender unless there is a top-level state change (errorAlert)
    this.setState({ errors, errorAlert: true });
  };

  clearError = (event) => {
    const key = `${event.target.name}Help`;
    this.setState({ [key]: '' });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  handleToggleSelect = () => {
    this.setState({ openSelect: !this.state.openSelect });
  };

  updateUserList = (userList) => {
    this.setState({ userList, openSelect: false });
  };

  handleSendMessage = () => {
    const replyAll = this.refs.replyAll.checked;
    // let validation = true;
    var validation = true;

    if (this.refs.subject.value.length < 1) {
      const subjectHelp = 'Please enter a subject';
      this.setState({ subjectHelp });
      validation = false;
    }

    if (this.refs.body.value.length < 1) {
      const bodyHelp = 'Please enter a message';
      this.setState({ bodyHelp });
      validation = false;
    }

    if (!validation) { return false; }

    const email = {
      from: `'${this.props.auth.user.firstName} ${this.props.auth.user.lastName} via Teamvibe <support@teamvibe.com>'`,
      subject: this.refs.subject.value,
      message: this.refs.body.value,
      replyTo: this.props.auth.user.username,
    };

    if (this.state.userList.length > 0) {
      // let recipients = _.map(this.state.userList, 'username');
      var recipients = _.map(this.state.userList, 'username');

      if (replyAll) {
        // Set To: address to sender + all recipients
        recipients.unshift(this.props.auth.user.username);
      } else {
        // Set To: address to sender, BCC all other recipients
        email.bcc = recipients;
        recipients = this.props.auth.user.username;
      }

      this.props.sendGroupEmail(recipients, email, this.props.organization.data, this.props.auth.token);
      this.props.closeEmailModal();
    } else {
      this.showUpdateAlert('No users selected. Please select a user from the list.');
    }

    return true;
  };

  render() {
    // const { user } = this.props.auth;
    const user = this.props.auth.user;
    const orgMembers = this.props.organization.members.data;

    // const { openSelect } = this.state;
    const openSelect = this.state.openSelect;

    return (
      <div id="user-invite">
        <Modal
          isOpen={this.props.emailModalIsOpen}
          onRequestClose={this.props.closeEmailModal}
          className="modal large"
          overlayClassName="modal-overlay"
          contentLabel="Invite users modal" >
          <div id="user-invite">
            <Alert
              type="alert callout text-center"
              message={this.state.calloutMessage}
              showAlert={this.state.updateAlert}
              handleClose={this.hideUpdateAlert} />
            <br />

            <div className="row small-12 medium-11 large-11 columns">
              <h3 className="text-center"><i className="fa fa-envelope" style={{ color: '#8a8a8a' }} />&nbsp;&nbsp;Send direct email</h3>
              <p className="text-center">Send a direct email to people or groups. Any replies will go directly to your email address.</p>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td className="text-right" width="140"><h5>From:</h5></td>
                    <td><h5>{`${user.firstName} ${user.lastName} <${user.username}>`}</h5></td>
                  </tr>
                  <tr>
                    <td className="text-right" width="140"><h5>To:</h5></td>
                    <td>
                      <Multiselect
                        className="margin-bottom-5"
                        valueField="_id"
                        textField={function (item) { return item.firstName + ' ' + item.lastName }}
                        defaultValue={this.props.targetUsers}
                        open={openSelect}
                        onToggle={this.handleToggleSelect}
                        data={orgMembers}
                        itemComponent={ListItem}
                        tagComponent={TagItem}
                        filter="contains"
                        placeholder="Start typing first or last name to select users"
                        onChange={this.updateUserList} />
                    </td>
                  </tr>

                  <tr>
                    <td className="text-right" width="140"><h5>Subject:</h5></td>
                    <td>
                      <label>
                        <input ref="subject" className="input" name="subject" type="text" onChange={this.clearError} placeholder="Enter your message title" />
                        <span className="input-error-no-margin">{this.state.subjectHelp}</span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right" width="140" style={{ verticalAlign: 'top' }}><h5>Message:</h5></td>
                    <td>
                      <textarea ref="body" className="textarea" name="body" rows="7" onChange={this.clearError} placeholder="Enter your message here" />
                      <span className="input-error-no-margin">{this.state.bodyHelp}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right" width="140" style={{ verticalAlign: 'top' }}><h5>Options:</h5></td>
                    <td>
                      <div><input ref="replyAll" type="checkbox" className="no-margin" defaultChecked={true} /> Allow recipients to reply-all</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <span className="float-right"><button href="#" className="button" onClick={this.handleSendMessage}>Send message</button></span>
              <br />
            </div>

            <button className="close-button" onClick={this.props.closeEmailModal} data-close="" aria-label="Close reveal" type="button">
              <span aria-hidden="true"><small>&times;</small></span>
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}


EmailUserModal.propTypes = {
  addVibeUsers: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired
};

export default EmailUserModal;
