import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscussionsActions from '../../../../modules/discussions/actions/discussions';
import FetchDiscussionActions from '../../../../modules/discussions/actions/fetchDiscussion';
import PostReplyActions from '../../../../modules/discussions/actions/postReply';
import ReactPostActions from '../../../../modules/discussions/actions/reactPost';
import VibeActions from '../../../../modules/vibes/actions/vibe';
import JoinVibeActions from '../../../../modules/auth/actions/joinVibe';
import LeaveVibeActions from '../../../../modules/auth/actions/leaveVibe';
import MentionsActions from '../../../../modules/mentions/actions/mentions';
import UpdateSubscriptionActions from '../../../../modules/subscriptions/actions/updateSubscription';
import PostModal from '../components/postModal.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    discussion: state.discussion,
    post: state.post,
    selectedVibe: state.selectedVibe,
    mentions: state.mentions,
    tags: state.tags,
    vibes: state.tags,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({},
    DiscussionsActions, FetchDiscussionActions, PostReplyActions,
    VibeActions, JoinVibeActions, LeaveVibeActions,
    MentionsActions, ReactPostActions, UpdateSubscriptionActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PostModal);
