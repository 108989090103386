import constants from '../constants';
import api from '../../../utils/api';
import fetchDiscussionActions from './fetchDiscussion';

const EditReply = (function () {
  const _editReplyRequest = function () {
    return {
      type: constants.EDIT_REPLY_REQUEST,
    };
  };

  const _editReplySuccess = function (data) {
    return {
      type: constants.EDIT_REPLY_SUCCESS,
      reply: data,
    };
  };

  const _editReplyError = function (error) {
    return {
      type: constants.EDIT_REPLY_ERROR,
      errors: error.data,
    };
  };

  const _editReply = function (postItem, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_editReplyRequest());
      api.put(`/proxy/v1/discussions/${postItem.data.discussion}/replies/${postItem.data._id}`, {
        content: postItem.data.content,
        attachments: postItem.data.attachments,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(fetchDiscussionActions.fetchDiscussionData(postItem.data.discussion, orgId, accessToken));
          dispatch(_editReplySuccess(res));
        })
        .catch((res) => {
          dispatch(_editReplyError(res));
        });
    };
  };

  return {
    editReply: _editReply,
  };
}());

export default EditReply;
