import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InviteActions from '../../../modules/invitations/actions/get-invitation';
import RegActions from '../../../modules/registration/actions/registration';
import AuthActions from '../../../modules/auth/actions/auth-signup-login';
import Invite from '../components/userCreate.jsx';

var mapStateToProps = function(state){
  return {
    auth: state.auth,
    organization: state.organization,
    invitation: state.invitation,
    registration: state.registration
  };
};

var mapDispatchToProps = function(dispatch) {
  return bindActionCreators(Object.assign({}, InviteActions, RegActions, AuthActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
