import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';

const PasswordResetValidate = (function () {
  const _passwordResetValidationRequest = function () {
    return {
      type: constants.PASSWORD_RESET_VALIDATION_REQUEST,
    };
  };

  const _passwordResetValidationSuccess = function (data) {
    return {
      type: constants.PASSWORD_RESET_VALIDATION_SUCCESS,
      data,
    };
  };

  const _passwordResetValidationError = function (error) {
    return {
      type: constants.PASSWORD_RESET_VALIDATION_ERROR,
      errors: error.data,
    };
  };

  const _passwordResetValidation = function (userId, token, organization) {
    return function (dispatch) {
      dispatch(_passwordResetValidationRequest());
      api.get(`/proxy/v1/users/password-reset-validate/${userId}/t/${token}`, {
        orgId: organization._id,
      })
        .then((res) => {
          dispatch(_passwordResetValidationSuccess(res));
        })
        .catch((res) => {
          dispatch(_passwordResetValidationError(res));
          dispatch(routerActions.push('/password-request?err=1'));
        });
    };
  };

  return {
    passwordResetValidation: _passwordResetValidation,
  };
}());

export default PasswordResetValidate;
