const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const _ = require('lodash');
const Geosuggest = require('react-geosuggest').default;
const utils = require('../../../utils').default;

class OrgCreate extends React.PureComponent {
    state = {
      tagName: '',
      location: null,
      // imageUrl: 'https://res.cloudinary.com/teamvibe/image/upload/product/placeholder-image.png'
    };

    componentDidMount() {
      this.props.fetchInvitationIfNeeded(this.props.location.query.invite);
    }

    onSuggestSelect = (suggest) => {
      if (suggest) {
        let tagName;
        _.map(suggest.gmaps.address_components, (item) => {
          _.map(item.types, (type) => {
            if (type === 'locality') {
              tagName = item.long_name;
            }
          });
        });

        const location = {
          label: suggest.label,
          googlePlaceId: suggest.placeId,
          loc: {
            type: 'Point',
            coordinates: [suggest.location.lng, suggest.location.lat],
          },
        };

        this.setState({
          tagName,
          location,
        });

        const errors = this.props.organization.error;

        if (errors.initialLocation) {
          delete errors.initialLocation;
          this.props.validationError(errors);
        }
      }
    };

    handleUploadImageClick = (event) => {
      const options = {
        folder: 'logos',
        minImageWidth: 150,
      };

      utils.uploadImage(options, (results, error) => {
        if (results) {
          this.setState({ imageUrl: results.secure_url });
          this.refs.imageOutput.src = results.secure_url;
        }
      });
    };

    handleChange = (event) => {
      const errors = this.props.organization.error;

      if (errors[event.target.name]) {
        delete errors[event.target.name];
        this.props.validationError(errors);
      }
    };

    handleClick = () => {
      const { auth, invitation, location } = this.props;
      const { query: { accessToken } } = location;
      const { token = accessToken } = auth;
      const { data: invitationData } = invitation;

      // Create first location
      const initialLocation = {
        sort: 0,
        isActive: true,
        tagType: 'location',
      };

      if (this.state.tagName) { initialLocation.displayName = this.state.tagName; }
      if (this.state.location) { initialLocation.location = this.state.location; }

      this.props.validateOrganizationUniqueTeamDomain(this.refs.teamDomain.value, (err, result) => {
        if (result) {
          this.props.createOrganization(
            this.refs.orgType.value, this.refs.orgName.value,
            this.refs.teamDomain.value, this.state.imageUrl,
            invitationData, initialLocation, token,
          );
        } else {
          this.props.validationError(err);
        }
      });
    };

    render() {
      const { invitation } = this.props;
      const { isFetching: isFetchingInvitation, data: invitationData } = invitation;
      const { inviteType } = invitationData;

      const orgError = this.props.organization.error;
      const orgNameError = ((Object.keys(orgError).length !== 0) && ('orgName' in orgError)) ? orgError.orgName.message : null;
      const teamDomainError = ((Object.keys(orgError).length !== 0) && ('teamDomain' in orgError)) ? orgError.teamDomain.message : null;
      const locationError = ((Object.keys(orgError).length !== 0) && ('initialLocation' in orgError)) ? orgError.initialLocation.message : null;

      // Loading
      if (isFetchingInvitation) {
        return (
          <div>
            <h1 className="loading">Loading...</h1>
          </div>
        );
      }

      // Block other inviteTypes
      if (inviteType !== 'createOrg') {
        return (
          <div className="full-screen full-height">
            <Helmet>
              <title>Create organization</title>
            </Helmet>
            <div className="fs-panel" style={{ padding: '7rem' }}>
              <br />
              <h5>It looks like you using an invaild or expired invitation.</h5>
              <br />
              <h5>Try the link in your email invitation again or contact your administrator for a fresh invite.</h5>
            </div>
            <div
              className="hide-for-small-only fs-panel"
              style={{ background: '#1dadda url(https://res.cloudinary.com/teamvibe/image/upload/v1492115809/product/header-meeting.jpg) center center/cover' }}>
              {/* This div is left empty since it is filled by the background image */}
            </div>
          </div>
        );
      }


      return (
        <div className="full-screen full-height">
          <Helmet>
            <title>Create organization</title>
          </Helmet>
          <div className="fs-panel">
            <br />
            <div className="row align-center">
              <div className="medium-8 medium-offset-1 columns end">
                <h2>Create new organization</h2>
                <p>Now let&#39;s setup your organization with some basic info</p>

                <div className="field">
                  <label className="label">Organization type</label>
                  <div className="control">
                    <div className="select">
                      <select ref="orgType" name="orgType" onChange={this.handleChange}>
                        <option value="company">Company - we have employees</option>
                        <option value="npo">Non-profit - employees and volunteers</option>
                        <option value="membership">Membership - networking groups, associations, etc.</option>
                        <option value="community">Community - towns, schools, churches, etc.</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Organization name</label>
                  <div className="control">
                    <input
                      className="input"
                      ref="orgName"
                      name="orgName"
                      type="text"
                      onChange={this.handleChange}
                      placeholder="Organization name" />
                  </div>
                  <span className="input-error">{orgNameError}</span>
                </div>

                <div className="field">
                  <label className="label">Primary location</label>
                  <div className="control">
                    <Geosuggest
                      className="input"
                      onSuggestSelect={this.onSuggestSelect}
                      placeholder="Enter a business address, city, or region"
                      types={['geocode']} />
                  </div>
                  <span className="input-error">{locationError}</span>
                </div>

                <p>
                  <strong>Team URL </strong>
                  <small>(max 15 characters)</small>
                </p>
                <div className="field is-horizontal is-marginless">
                  <div className="field-body">
                    <div className="field">
                      <p className="control">
                        <input
                          className="input text-right"
                          ref="teamDomain"
                          name="teamDomain"
                          type="text"
                          onChange={this.handleChange}
                          placeholder="teamdomain" />
                      </p>
                    </div>
                  </div>
                  <div className="field-label is-normal" style={{ paddingTop: '0.5rem' }}>
                    <span>.teamvibe.com</span>
                  </div>
                </div>
                <span className="input-error">{teamDomainError}</span>

                <p>
                  <strong>Logo </strong>
                  <small>(you can also upload later)</small>
                </p>
                {this.state.imageUrl &&
                  <div id="thumb" className="float-left">
                    <img className="logo-input" ref="imageOutput" src={this.state.imageUrl} alt="logo" />
                    <span>&nbsp;&nbsp;</span>
                  </div>
                }
                <div className="float-left">
                  <button type="button" className="button hollow" onClick={this.handleUploadImageClick}>Upload your logo</button>
                </div>
              </div>
            </div>
            <div className="row columns">
              <br />
              <div className="text-center">
                <button type="button" onClick={this.handleClick} className="button large">Create organization</button>
              </div>
            </div>
            <br /><br /><br /><br />
          </div>
          <div
            className="hide-for-small-only fs-panel"
            style={{ background: '#1dadda url(https://res.cloudinary.com/teamvibe/image/upload/v1492115809/product/header-meeting.jpg) center center/cover' }}>
            {/* This div is left empty since it is filled by the background image */}
          </div>
        </div>
      );
    }
}

OrgCreate.propTypes = {
  location: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  fetchInvitationIfNeeded: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  validateOrganizationUniqueTeamDomain: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default OrgCreate;
