var React = require('react');
var	ReactDOM = require('react-dom');
var PropTypes = require('prop-types');

var jquery = require('jquery');
var $ = (typeof window !== 'undefined') ? window.$ = window.jQuery = jquery : {};

class Callout extends React.Component {
  handleClick = () => {
      var element = ReactDOM.findDOMNode(this.refs.myCallout)
      $(element).fadeOut().trigger('closed.zf')
      this.props.handleClose();
  };

  render() {
    var closeButton = (this.props.closeButton === false) ? false : true;

    return (
      <div className={this.props.type + ' callout'} ref='myCallout' data-closable>
        {this.props.message && <h5>{this.props.message}</h5>}
        {this.props.children}
        {closeButton &&
            <button className="close-button" aria-label="Dismiss alert" type="button"
              onClick={this.handleClick}>
              <span aria-hidden="true">&times;</span>
            </button>
        }
      </div>
    )
  }
}

Callout.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func
};

export default Callout;
