const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');

// const StartNewVibe = require('./startNewVibe.jsx').default;
const CustomizeVibe = require('./customizeVibe.jsx').default;
const ActivateVibe = require('./activateVibe.jsx').default;
const utils = require('../../../../../common/utils').default;

class CreateVibe extends React.Component {
  componentDidMount() {
    const { params } = this.props;
    const { parentId } = params;

    this.props.fetchTagsIfNeeded(this.props.organization.data._id, this.props.auth.token);

    // Pass selectedVibe._id when creating sub-vibe
    if (parentId) {
      const { organization: { data: organizationData } } = this.props;
      const parentBoard = _.find(organizationData.vibes, { vibe: parentId }) || {};
      const { tags = [], accessLevel = 'hidden' } = parentBoard;
      const selectedVibe = { orgOptions: { isChild: true, parent: parentId, tags, accessLevel } };

      this.props.setSelectedVibe(selectedVibe);
    }
  }

  handleClick = (step) => {
    this.props.setCreateVibeStep(step);
  };

  handleActivateVibe = (redirect = false, autoApprove = false) => {
    this.props.createVibe(
      this.props.selectedVibe.data,
      this.props.auth.user._id,
      this.props.organization.data._id,
      this.props.organization.data.teamDomain,
      redirect,
      autoApprove,
      this.props.auth.token,
    );
  };

  renderElement = (step, tags) => {
    const { params } = this.props;
    const { parentId } = params;
    const { organization: { data: organizationData } } = this.props;
    const { auth: { user } } = this.props;

    let parentTags = [];
    if (parentId) {
      const parentBoard = _.find(organizationData.vibes, { vibe: parentId }) || {};
      parentTags = parentBoard.tags;
    }

    const userIsVibeChampion = utils.userIsVibeChampion(user.vibes, parentId);
    const userIsOrgAdmin = utils.userIsOrgAdmin(organizationData._id, user.orgs);
    const userIsTagAdmin = utils.userIsTagAdmin(user._id, parentTags, tags);

    const components = {
      // 1: StartNewVibe,
      1: CustomizeVibe,
      2: ActivateVibe,
    };

    const ItemComponent = components[step];
    return (<ItemComponent
      mode="create"
      auth={this.props.auth}
      organization={this.props.organization}
      discussions={this.props.discussions}
      vibes={this.props.vibes}
      validateVibe={this.props.validateVibe}
      validateVibeUniqueTitle={this.props.validateVibeUniqueTitle}
      setCreateVibeStep={this.props.setCreateVibeStep}
      // handleVibeDetails={this.handleVibeDetails}
      handleActivateVibe={this.handleActivateVibe}
      tags={tags}
      parentTags={parentTags}
      selectedVibe={this.props.selectedVibe}
      setSelectedVibe={this.props.setSelectedVibe}
      clearSelectedVibe={this.props.clearSelectedVibe}
      removeVibeAlerts={this.props.removeVibeAlerts}
      userIsVibeChampion={userIsVibeChampion}
      userIsOrgAdmin={userIsOrgAdmin}
      userIsTagAdmin={userIsTagAdmin} />);
  };

  render() {
    const { tags, params } = this.props;
    const { step } = params;
    const tagsData = tags.data;
    const tagsIsFetching = tags.isFetching;

    // Loading
    if (tagsIsFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    return (
      <div>
        <br />
        <div className="row column">
          <div className="wizard-menu hide-for-small-only hide">
            <ul>
              {/* step === 1 ? <li className="current">Create Board</li> : <li>Create Board</li> */}
              {step === 1 ? <li className="current">Customize Board</li> : <li>Customize Board</li>}
              {step === 2 ? <li className="current">Activate Board</li> : <li>Activate Board</li>}
            </ul>
          </div>
          {step ? this.renderElement(step, tagsData) : this.renderElement('1', tagsData)}
        </div>
      </div>
    );
  }
}

CreateVibe.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  discussions: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  setSelectedVibe: PropTypes.func.isRequired,
  createVibe: PropTypes.func.isRequired,
  validateVibe: PropTypes.func.isRequired,
  validateVibeUniqueTitle: PropTypes.func.isRequired,
  fetchTagsIfNeeded: PropTypes.func.isRequired,
  setCreateVibeStep: PropTypes.func.isRequired,
  clearSelectedVibe: PropTypes.func.isRequired,
  removeVibeAlerts: PropTypes.func.isRequired,
};

export default CreateVibe;
