const React = require('react');
const PropTypes = require('prop-types');
const Draft = require('draft-js');
const StateToHTML = require('draft-js-export-html');
const { Link } = require('react-router');
const utils = require('../../../../../common/utils').default;
const Editor = require('../../../components/discussions/components/draftEditor.jsx').default;
const PostFiles = require('../../../components/posts/components/postFiles').default;

class ReplyInput extends React.PureComponent {
  state = {
    replyHelp: '',
  };

  handlePostReply = () => {
    const postItem = this.props.post;
    const attachments = postItem.data.attachments || [];

    // Send editing state to actions
    postItem.data.content = {
      //raw: '',
      //rich: '',
    };

    // Validate there is some content (either text, image, or attachment)
    const hasContent = (postItem.data.contentState) ? postItem.data.contentState.hasText() : false;
    const hasAttachment = (attachments.length > 0);

    if (hasContent || hasAttachment) {
      // Transform content into raw and rich
      if (postItem.data.contentState) {
        postItem.data.content.raw = JSON.stringify(Draft.convertToRaw(postItem.data.contentState));
        postItem.data.content.rich = StateToHTML.stateToHTML(postItem.data.contentState);
      }

      const mentions = utils.getMentionsFromContent(postItem.data.content.raw);
      this.props.createReply(
        this.props.discussionId, postItem.data.content,
        this.props.auth.user._id, this.props.organization.data._id,
        this.props.selectedVibe.data._id, mentions, postItem.data.attachments,
        this.props.auth.token,
      );
      this.setState({ replyHelp: '' });
    } else {
      this.setState({ replyHelp: 'You must enter a reply before submitting' });
    }
    this.child.clearEditor();
  };

  changeContent = (contentState) => {
    const postItem = this.props.post;
    postItem.data.contentState = contentState;
    // delete postItem.error['content'];
    this.setState({ replyHelp: '' });

    this.props.setPost(postItem.data, false);
  };

  addAttachment = (upload) => {
    const postItem = this.props.post;
    const attachments = postItem.data.attachments || [];

    const fileType = upload.secure_url.split('.').pop();

    if (fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'pdf' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'xls' ||
      fileType === 'xlsx') {
      const uploadItem = {
        fileType,
        fileName: upload.original_filename,
        path: upload.path,
        secureUrl: upload.secure_url,
        pages: upload.pages,
        width: upload.width,
        height: upload.height,
      };

      attachments.push(uploadItem);
      postItem.data.attachments = attachments;

      this.props.setPost(postItem.data, true);
    }
  };

  render() {
    const { replyHelp } = this.state;
    const org = this.props.auth.user.orgs.filter(org => org.id === this.props.organization.data._id);

    const location = org[0].location ? `, ${org[0].location.city}, ${org[0].location.state}` : '';
    const userFullName = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;

    return (
      <article className="post-container discussion no-border">
        <header className="post-header hide">
          <div className="media-object">
            <div className="media-object-section-tight">
              <Link to={`/profile/${this.props.auth.user._id}`}>
                <img
                  className="image-thumb"
                  src={utils.formatImage(this.props.auth.user.photoUrl, 'userThumb')}
                  onError={utils.showDefaultImage}
                  alt={userFullName} />
              </Link>
            </div>
            <div className="media-object-section">
              <span><strong>{userFullName}</strong></span><br />
              <span className="post-meta">{org[0].title}{location}</span>
            </div>
          </div>
        </header>
        <div className="post-body">
          <Editor
            ref={(instance) => { this.child = instance; }}
            className="post-reply-box"
            placeholder="Add your reply here"
            changeContent={this.changeContent}
            // discussion={this.props.discussion}
            organization={this.props.organization}
            addAttachment={this.addAttachment}
            users={this.props.organization.members.data} />
          <PostFiles post={this.props.post.data} setPost={this.props.setPost} mode="edit" />
          <div className="clearfix">
            <span className="input-error">{replyHelp}</span>
            <button className="button hollow float-right" onClick={this.handlePostReply}>Submit reply</button>
          </div>
        </div>
        <aside className="post-actions" />
        <footer className="post-footer" />
      </article>
    );
  }
}

ReplyInput.propTypes = {
  post: PropTypes.object.isRequired,
  discussion: PropTypes.object.isRequired,
  createReply: PropTypes.func.isRequired,
  setPost: PropTypes.func.isRequired,
  discussionId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
};

export default ReplyInput;
