const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const tldjs = require('tldjs');

const tld = tldjs.fromUserSettings({
  validHosts: ['localhost'],
});
const OrgActions = require('../../../modules/organizations/actions/organization').default;

const checkOrg = function (Component) {
  class OrgComponent extends React.Component {
    componentDidMount() {
      const subDomain = this.getSubdomain();
      this.props.fetchOrganizationIfNeeded(subDomain, this.props.auth.token);
    }

    componentWillReceiveProps(nextProps) {
      if (!nextProps.organization.isFetching && Object.keys(nextProps.organization.data).length !== 0) {
        // Re-fetch org if user logs in
        if (nextProps.auth.token !== this.props.auth.token) {
          const subDomain = this.getSubdomain();
          this.props.fetchOrganization(subDomain, nextProps.auth.token);
        }
      }
    }

    getSubdomain = () => {
      const domain = tld.getDomain(document.location.hostname);
      let subDomain = tld.getSubdomain(document.location.hostname);
      const parts = subDomain.split('.');

      if (domain === 'localhost' && subDomain === '') {
        subDomain = 'businessol';
      } else if (parts.length === 2) {
        subDomain = parts[0];
      }

      if (this.props.location.query.teamdomain) {
        subDomain = this.props.location.query.teamdomain;
      }

      return subDomain;
    };

    render() {
      return (
        <div className="org">
          {!this.props.organization.isFetching && Object.keys(this.props.organization.data).length !== 0
            ? <Component {...this.props} />
            : null}
        </div>
      );
    }
  }

  OrgComponent.propTypes = {
    auth: PropTypes.object,
    organization: PropTypes.object,
    location: PropTypes.object,
    fetchOrganizationIfNeeded: PropTypes.func.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      organization: state.organization,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, OrgActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(OrgComponent);
};

export default checkOrg;
