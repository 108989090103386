import * as types from './types';
import api from '../../utils/api';

const requestTagMembers = () => ({
  type: types.REQUEST_TAG_MEMBERS,
});

const receiveTagMembers = (data) => ({
  type: types.RECEIVE_TAG_MEMBERS,
  tagMembers: data.tagMembers,
});

const requestTagMembersError = (errors) => ({
  type: types.REQUEST_TAG_MEMBERS_ERROR,
  errors,
});

export const fetchTagMembers = (tags, token) => async (dispatch) => {
  try {
    dispatch(requestTagMembers());
    const data = await api.get('/proxy/v1/tags/users', { tags }, token);

    dispatch(receiveTagMembers(data));
  } catch (error) {
    dispatch(requestTagMembersError(error));
  }
};

export const somethingElse = () => {};
