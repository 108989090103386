import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventsActions from '../../../../modules/events/actions/events';
import ChangeRSVPActions from '../../../../modules/events/actions/changeRsvp';
import EventsWidget from '../components/eventsWidget.jsx';
import JoinVibeActions from '../../../../modules/auth/actions/joinVibe';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        events: state.events,
        selectedEvent: state.selectedEvent        
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, EventsActions, ChangeRSVPActions, JoinVibeActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsWidget);
