import constants from '../constants';
import api from '../../../utils/api';

const CreateInvitations = (function () {
  const _requestInvites = function () {
    return {
      type: constants.CREATE_INVITATIONS_REQUEST,
    };
  };

  const _createInvitesSuccess = function (data) {
    return {
      type: constants.CREATE_INVITATIONS_SUCCESS,
      data,
    };
  };

  const _createInvitesError = function (error) {
    return {
      type: constants.CREATE_INVITATIONS_ERROR,
      errors: error.response.data,
    };
  };

  const _clearInvite = function () {
    return {
      type: constants.CLEAR_INVITE,
    };
  };

  const _createInvites = function (firstName, lastName, emails, organization, presetLocations, presetTeams, presetVibes, isAdmin, accessToken) {
    return function (dispatch) {
      dispatch(_requestInvites());
      api.post('/proxy/v1/invitations', {
        firstName,
        lastName,
        emails,
        inviteType: 'joinOrg',
        presetLocations,
        presetTeams,
        presetVibes,
        isAdmin,
        orgId: organization._id,
        orgTeamDomain: organization.teamDomain,
        orgName: organization.name,
        orgLogo: organization.logo,
      }, accessToken)
        .then((res) => {
          dispatch(_createInvitesSuccess(res));
        })
        .catch((res) => {
          dispatch(_createInvitesError(res));
        });
    };
  };

  return {
    createInvites: _createInvites,
    clearInvite: _clearInvite,
  };
}());

export default CreateInvitations;
