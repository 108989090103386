const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const jquery = require('jquery');

const $ = (typeof window !== 'undefined') ? window.$ = window.jQuery = jquery : {};

const EventsModal = require('./eventsModal.jsx').default;

class CalendarWidget extends React.Component {
  state = {
    eventModalIsOpen: false,
  };

  componentDidMount() {
    const that = this;

    $('#calendar').fullCalendar({
      weekends: true,
      eventLimit: false,
      editable: true,
      fixedWeekCount: false,
      eventColor: '#1dadda',
      googleCalendarApiKey: 'AIzaSyCaJhauZi8IFWaKVssjON1cqcY2zBfN764',

/* TODO: Temp remove global holidays for Endeavor until we can make dynamic
       eventSources: [
        {
          // US Holidays
          id: 'gcal',
          googleCalendarId: 'en.usa#holiday@group.v.calendar.google.com',
          color: '#B4D71F',
          editable: false,
        },
      ], */

      eventClick: (calEvent, jsEvent, view) => {
        if (calEvent.source.id === 'gcal') {
          return false;
        }

        that.openEventModal(calEvent);
      },

      viewRender: (view, element) => {
        that.props.handleMonthChange(view.title, view.intervalStart, view.intervalEnd);
      },
    });
  }

  componentDidUpdate() {
    const calEventsList = this.buildEventsList(this.props.events);

    // const tv = $('#calendar').fullCalendar( 'getEventSourceById', 'tv' )
    $('#calendar').fullCalendar('removeEventSource', 'tv');

    const tvSource = { id: 'tv', events: calEventsList };
    $('#calendar').fullCalendar('addEventSource', tvSource);
  }

  openEventModal = (calEvent) => {
    let selectedEvent = this.props.selectedEvent.data;
    const eventId = calEvent.id;

    const eventsList = this.props.events;

    eventsList.map((event) => {
      if (event._id === eventId) {
        selectedEvent = event;
        return selectedEvent;
      }
      return null;
    });

    this.props.setSelectedEvent(selectedEvent);
    this.setState({ eventModalIsOpen: true });
  };

  closeEventModal = () => {
    this.props.clearSelectedEvent();
    this.setState({ eventModalIsOpen: false });
  };

  buildEventsList = (events) => {
    const userId = this.props.auth.user._id;

    return events.map((event) => {
      const rsvp = event.attendees.filter((attendee) => {
        return attendee.user._id === userId;
      }).map((attendee) => {
        return attendee.status;
      });

      return (
        {
          id: event._id,
          title: event.title,
          location: event.location,
          description: event.description,
          vibe: event.vibe._id,
          start: moment(event.startDate).format(),
          end: moment(event.endDate).format(),
          allDay: event.allDay,
          attendees: event.attendees,
          rsvp,
          date: moment(event.startDate).format(),
          displayEventTime: true,
        }
      );
    });
  };

  render() {
    return (
      <div>
        <EventsModal
          selectedEvent={this.props.selectedEvent}
          auth={this.props.auth}
          organization={this.props.organization}
          RSVPclick={this.props.RSVPclick}
          eventModalIsOpen={this.state.eventModalIsOpen}
          closeEventModal={this.closeEventModal}
          setSelectedEvent={this.props.setSelectedEvent}
          openEventInputModal={this.props.openEventInputModal}
          joinVibe={this.props.joinVibe} />
        <div id="calendar" />
      </div>
    );
  }
}

CalendarWidget.propTypes = {
  handleMonthChange: PropTypes.func.isRequired,
  RSVPclick: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  clearSelectedEvent: PropTypes.func.isRequired,
  openEventInputModal: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
};

export default CalendarWidget;
