import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import vibeChampionActions from '../../vibes/actions/vibe-champions';
import vibeRemoveUserActions from '../../vibes/actions/vibe-remove-user';
import authLogout from './auth-logout';

const LeaveVibe = (function () {
  const _leaveVibeRequest = function () {
    return {
      type: constants.LEAVE_VIBE_REQUEST,
    };
  };

  const _leaveVibeSuccess = function (data, vibeId) {
    return {
      type: constants.LEAVE_VIBE_SUCCESS,
      user: data.user,
      vibeId,
    };
  };

  const _leaveVibeError = function (error) {
    return {
      type: constants.LEAVE_VIBE_ERROR,
      errors: error,
    };
  };

  const _leaveVibe = function (userId, vibeId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_leaveVibeRequest());
      api.put(`/proxy/v1/users/${userId}/vibes/${vibeId}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          // This must come before other actions are dispatched
          // or UI won't re-render vibe follow button properly
          dispatch(_leaveVibeSuccess(res, vibeId));
          dispatch(vibeChampionActions.removeChampionFromVibe(userId, vibeId));
          dispatch(vibeRemoveUserActions.removeUserFromSelectedVibe(userId));
        })
        .catch((res) => {
          console.log(res);
          if (res.response) {
            if (res.response.status === 401) {
              dispatch(authLogout.logout());
              dispatch(routerActions.push('/login?err=3'));
            } else {
              dispatch(_leaveVibeError(res.response.data));
            }
          } else {
            dispatch(_leaveVibeError(res));
          }
        });
    };
  };

  return {
    leaveVibe: _leaveVibe,
  };
}());

export default LeaveVibe;
