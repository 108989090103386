import constants from '../constants';
import initialState from '../../initialState';

const vibesPublic = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_VIBES_PUBLIC:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_VIBES_PUBLIC:
    newstate.isFetching = false;
    newstate.data = action.vibesPublic;
    return newstate;
  case constants.REQUEST_VIBES_PUBLIC_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  default: return state || initialState.vibesPublic;
  }
};

export default vibesPublic;
