const React = require('react');
const PropTypes = require('prop-types');
const { Link } = require('react-router');

const formatboardList = (boards) => {
  const boardList = boards.map((board) => (
    <Link key={board._id} to={`/board/${board._id}`} className="button">
      {board.title}
      {board.orgOptions.accessLevel === 'hidden' && <i className="fa fa-lock icon-button" />}
    </Link>));

  return boardList;
};

const MemberVibes = (props) => {
  const { userFirstName, selectedUserVibes } = props;

  const boardList = formatboardList(selectedUserVibes);
  return (
    <div>
      <div className="callout">
        <h3 className="lead"><strong>{`${userFirstName}'s boards`}</strong></h3>
        <div className="button-group">
          {boardList.length > 0 ? boardList : <span><br />No boards yet<br /><br /></span>}
        </div>
      </div>
    </div>
  );
};

MemberVibes.propTypes = {
  selectedUserVibes: PropTypes.array.isRequired,
  userFirstName: PropTypes.string.isRequired,
};

export default MemberVibes;
