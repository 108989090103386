import ReactSelect from 'react-select';
import utils from '../../../../utils';

const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const Geosuggest = require('react-geosuggest').default;
const _ = require('lodash');
const { Helmet } = require('react-helmet');

const moment = require('moment-timezone');
const DateTimePicker = require('react-widgets/lib/DateTimePicker');

class TagsInputModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillUnmount() {
    this.props.clearSelectedTag();
  }

  onSuggestSelect = (suggest) => {
    if (suggest) {
      const { selectedTag } = this.props;

      const location = {
        label: suggest.label,
        googlePlaceId: suggest.placeId,
        loc: {
          type: 'Point',
          coordinates: [suggest.location.lng, suggest.location.lat],
        },
      };
      selectedTag.data.location = location;

      this.props.setSelectedTag(selectedTag.data);
    }
  };


  updateSelectedList = (selectedList) => {
    const { selectedTag } = this.props;
    selectedTag.data.tagAdmin = _.map(selectedList, '_id');
    this.props.setSelectedTag(selectedTag.data);
  };

  afterOpenModal = () => {
    this.displayName.focus();
  };

  handleCreateTag = (selectedTag) => {
    // this.props.addTag({'_id': 'new_'+parseInt((Math.random()*10000),10), 'displayName': tagTitle, 'isActive': true, 'orgId': this.props.organization.data._id, 'type': type, 'value': tagTitle, 'sort': (tags.length+1)});
    this.props.createTag(selectedTag, this.props.organization.data._id, this.props.auth.token);
  };

  handleEditTag = (selectedTag) => {
    this.props.updateTag(selectedTag, this.props.organization.data._id, this.props.auth.token);
  };

  handleDeleteTag = (selectedTag) => {
    this.handleCloseAlert();
    // Need function
  };

  handleSubmit = (mode) => {
    const { selectedTag } = this.props;
    const { data: { digest = {} } } = selectedTag;
    selectedTag.data.isActive = ('isActive' in selectedTag.data) ? selectedTag.data.isActive : true;

    // Default digest object
    const defaultDigest = {
      digestEnabled: true,
      digestTitle: 'The Daily Vibe',
      digestSendDays: [1, 2, 3, 4, 5],
      digestSendTime: '09:00',
      digestTimeZone: 'America/Los_Angeles',
    };

    // Use default as base, and then merge any actual data
    const mergedDigest = { ...defaultDigest, ...digest };
    selectedTag.data.digest = mergedDigest;

    if (mode === 'create') {
      selectedTag.data.tagType = this.props.tagType;

      const tagList = _.filter(this.props.tags.data, { tagType: selectedTag.data.tagType });
      const tagListMaxSort = _.maxBy(tagList, function (o) { return o.sort; });
      selectedTag.data.sort = (tagListMaxSort) ? (tagListMaxSort.sort + 1) : 0;
    }

    if (mode === 'edit') {
      this.handleEditTag(selectedTag);
    } else if (mode === 'delete') {
      // No deleting of tags until we can recursively assign vibes/users once deleted
      // this.handleDeleteTag(selectedTag);
    } else {
      this.handleCreateTag(selectedTag);
    }

    this.props.closeInputModal();
  };

  handleChange = (e) => {
    const { selectedTag } = this.props;
    const { data: { digest = {} } } = selectedTag;
    const { target: { name, value, type, checked } } = e;
    const inputValue = (type === 'checkbox') ? checked : value;

    if (name === 'digestSendDays') {
      // Convert string to array
      digest[name] = inputValue.split(',').map(Number);
      selectedTag.data.digest = digest;
    } else if (name.includes('digest')) {
      // Store in digest object
      digest[name] = inputValue;
      selectedTag.data.digest = digest;
    } else {
      selectedTag.data[name] = inputValue;
    }

    this.props.setSelectedTag(selectedTag.data);
  };

  handleTimeChange = (selectedTime) => {
    const { selectedTag } = this.props;
    selectedTag.data.digest.digestSendTime = utils.formatTime(selectedTime);

    this.props.setSelectedTag(selectedTag.data);
  }

  render() {
    const { mode, selectedTag } = this.props;
    const { data: tag } = selectedTag;
    const {
      displayName = '', isActive = true, isPrivate = false,
      location, tagAdmin, digest = {},
    } = tag;
    const {
      digestEnabled = true, digestTitle = 'The Daily Vibe',
      digestTimeZone = 'America/Los_Angeles',
    } = digest;
    let { digestSendDays = [], digestSendTime = '09:00' } = digest;
    digestSendDays = digestSendDays.toString();
    digestSendTime = new Date(`01/01/2000 ${digestSendTime}`);
    const orgMembers = this.props.organization.members.data;

    const tagType = ('tagType' in tag) ? tag.tagType : this.props.tagType;
    let placeholder;
    switch (tagType) {
    case 'location':
      placeholder = 'ie. San Diego Office';
      break;
    case 'team':
      placeholder = 'ie. Sales, marketing, etc.';
      break;
    default:
      placeholder = 'Team description';
    }

    const tagAdminList = (tagAdmin) ? _(orgMembers).keyBy('_id').at(tagAdmin).value() : [];
    const typeLabel = (mode === 'edit') ? 'Update' : 'Create';
    // const tagClass = tag.isActive ? 'button round tag-on' : 'button round tag-off';

    const locationLabel = (location && location.label) ? location.label : undefined;

    return (
      <div>
        <Helmet>
          {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC7KKSIAXF8Xeb74loTvoUMx3MaaRWQY-Q&libraries=places" type="text/javascript" /> */}
        </Helmet>
        <Modal
          isOpen={this.props.inputModalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.props.closeInputModal}
          className="modal medium"
          overlayClassName="modal-overlay"
          contentLabel="Tags input modal">
          <div id="tags-input">

            <br />

            <div className="row small-12 medium-9 small-6 columns">
              <h3 className="text-center">
                <i className="fa fa-tags" style={{ color: '#8a8a8a' }} />
                {` ${typeLabel} ${tagType}`}
              </h3>

              <div className="field">
                <div className="control">
                  <div className="switch">
                    <input
                      type="checkbox"
                      id="switch-isActive"
                      name="isActive"
                      checked={isActive}
                      onChange={this.handleChange}
                      className="switch-input" />
                    <label className="switch-paddle active-disabled" htmlFor="switch-isActive">
                      <span className="switch-active" aria-hidden="true">Active</span>
                      <span className="switch-inactive" aria-hidden="true">Disabled</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Display name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="displayName"
                    ref={(element) => { this.displayName = element; }}
                    placeholder={placeholder}
                    value={displayName}
                    onChange={this.handleChange} />
                </div>
              </div>

              {(tagType === 'location') &&
                <div className="field">
                  <label className="label">Location</label>
                  <div className="control">
                    <Geosuggest
                      inputClassName="input"
                      onSuggestSelect={this.onSuggestSelect}
                      placeholder="Enter a business address, city, or region"
                      types={['geocode']}
                      initialValue={locationLabel}
                      // location={new google.maps.LatLng(32.715738, -117.161084)}
                      // radius="2000"
                    />
                  </div>
                </div>
              }

              <div className="field">
                <label className="label">{`${_.startCase(tagType)} admin(s)`}</label>
                <div className="control">
                  <ReactSelect
                    ref={(e) => { this.industries = e; }}
                    classNamePrefix="react-select"
                    menuPosition="fixed"
                    placeholder="Start typing first or last name to filter users"
                    isMulti
                    options={orgMembers}
                    value={tagAdminList}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    getOptionValue={(option) => option._id}
                    noOptionsMessage={() => 'No users'}
                    onChange={(option) => this.updateSelectedList(option)}
                  />
                </div>
              </div>

              {/* Digest settings */}
              {(tagType === 'location') &&
              <>
                <br />
                <hr />
                <div className="row">
                  <div className="small-3 columns">
                    <div className="field">
                      <label className="label">Send digests</label>
                      <div className="control">
                        <div className="switch">
                          <input
                            className="switch-input"
                            id="switch-digestEnabled"
                            type="checkbox"
                            name="digestEnabled"
                            checked={digestEnabled}
                            onChange={this.handleChange} />

                          <label className="switch-paddle" htmlFor="switch-digestEnabled">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="small-9 columns">
                    <div className="field">
                      <label className="label">Email digest title <small>(max 40 characters)</small></label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="digestTitle"
                          value={digestTitle}
                          onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label">Delivery days & time</label>
                      <div className="control">
                        <div className="select">
                          <select
                            name="digestSendDays"
                            value={digestSendDays}
                            onChange={this.handleChange}>
                            <option value="1,2,3,4,5">Daily emails (Mon-Fri)</option>
                            <option value="1,3,5">Three times a week (Mon, Wed, Fri)</option>
                            <option value="2,4">Twice a week (Tue, Thu)</option>
                            <option value="1">Once a week (Mon)</option>
                            <option value="2">Once a week (Tue)</option>
                            <option value="3">Once a week (Wed)</option>
                            <option value="4">Once a week (Thu)</option>
                            <option value="5">Once a week (Fri)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">&nbsp;</label>
                      <div className="control">
                        <DateTimePicker
                          name="digestSendTime"
                          value={digestSendTime}
                          onChange={this.handleTimeChange}
                          date={false}
                          min={new Date('01/01/2000 06:00')}
                          max={new Date('01/01/2000 19:00')}
                          placeholder="Time" />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">&nbsp;</label>
                      <div className="control">
                        <div className="select">
                          <select
                            name="digestTimeZone"
                            value={digestTimeZone}
                            onChange={this.handleChange}>
                            <option value="Pacific/Honolulu">{`Honolulu (${moment.tz('Pacific/Honolulu').format('z')})`}</option>
                            <option value="America/Anchorage">{`Anchorage (${moment.tz('America/Anchorage').format('z')})`}</option>
                            <option value="America/Los_Angeles">{`Los Angeles (${moment.tz('America/Los_Angeles').format('z')})`}</option>
                            <option value="America/Phoenix">{`Phoenix (${moment.tz('America/Phoenix').format('z')})`}</option>
                            <option value="America/Denver">{`Denver (${moment.tz('America/Denver').format('z')})`}</option>
                            <option value="America/Chicago">{`Chicago (${moment.tz('America/Chicago').format('z')})`}</option>
                            <option value="America/New_York">{`New York (${moment.tz('America/New_York').format('z')})`}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>
              }

              {/* isPrivate */}
              {(tagType === 'team') &&
              <>
                <label><strong>Private {_.startCase(tagType)}?</strong></label>
                <div className="switch">
                  <input
                    type="checkbox"
                    id="switch-isPrivate"
                    name="isPrivate"
                    checked={isPrivate}
                    onChange={this.handleChange}
                    className="switch-input" />
                  <label className="switch-paddle" htmlFor="switch-isPrivate">
                    <span className="switch-active" aria-hidden="true">On</span>
                    <span className="switch-inactive" aria-hidden="true">Off</span>
                  </label>
                </div>
              </>
              }

              {(tagType === 'team') &&
                <div className="help-text">Private teams only show to other members with the same team (or admins)</div>
              }
              <br />
              <div className="clearfix">
                {/* No deleting of tags until we can recursively assign vibes/users once deleted
                {(mode === 'edit') &&
                        <button className="alert button" onClick={this.handleDeleteClick}>Delete event</button>
                }
                */}
                <button
                  type="button"
                  className="button float-right"
                  onClick={this.handleSubmit.bind(this, mode)}>
                  {typeLabel}
                </button>
              </div>

              <br />
            </div>

            <button
              className="close-button"
              onClick={this.props.closeInputModal}
              data-close=""
              aria-label="Close reveal"
              type="button">
              <span aria-hidden="true"><small>&times;</small></span>
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}


TagsInputModal.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedTag: PropTypes.object.isRequired,
  mode: PropTypes.string,
  createTag: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  setSelectedTag: PropTypes.func.isRequired,
  clearSelectedTag: PropTypes.func.isRequired,
  closeInputModal: PropTypes.func.isRequired,
};

export default TagsInputModal;
