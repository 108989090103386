import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgActions from '../../../modules/organizations/actions/organization';
import VibesActions from '../../../modules/vibes/actions/vibes';
import VibesPublicActions from '../../../modules/vibes/actions/vibesPublic';
import TagsActions from '../../../modules/tags/actions/tags';
import FetchDiscussionActions from '../../../modules/discussions/actions/fetchDiscussion';
import Vibes from '../components/vibes';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    vibes: state.vibes,
    vibesPublic: state.vibesPublic,
    tags: state.tags,
    activeVibeFilter: state.activeVibeFilter,
    discussion: state.discussion,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    OrgActions, VibesActions, VibesPublicActions, TagsActions, FetchDiscussionActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Vibes);
