import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserDetailsActions from '../../../modules/users/actions/userDetails';
import UserEditActions from '../../../modules/auth/actions/editUser';
import SendGroupEmailActions from '../../../modules/users/actions/sendGroupEmail';
import Profile from '../components/profile.jsx';

var mapStateToProps = function(state){
    return {
        auth: state.auth,
        organization: state.organization,
        user: state.user,
        vibes: state.vibes,
        tags: state.tags
    };
};

var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, UserDetailsActions, UserEditActions, SendGroupEmailActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
