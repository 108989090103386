import ReactSelect from 'react-select';

const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const _ = require('lodash');
const Alert = require('../../alert.jsx').default;
const utils = require('../../../../../common/utils').default;

const formatOptionLabel = ({ firstName, lastName, photoUrl, orgs }, { context }) => {
  if (context === 'value') {
    return <div>{`${firstName} ${lastName}`}</div>;
  }

  // context === 'menu'
  return (
    <div className="clearfix">
      <img
        src={utils.formatImage(photoUrl, 'userThumb')}
        className="thumb-selectlist float-left"
        style={{ marginRight: '10px' }}
        alt="Profile"
        onError={utils.showDefaultImage} />
      <strong>{`${firstName} ${lastName}`}</strong><br />
      {(orgs) && orgs[0].title}
    </div>
  );
};

formatOptionLabel.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  orgs: PropTypes.object.isRequired,
};

class ListItem extends React.PureComponent {
  render() {
    const user = this.props.item;

    return (
      <div className="clearfix">
        <img src={utils.formatImage(user.photoUrl, 'userThumb')} className="thumb-selectlist float-left" style={{ marginRight: '10px' }} alt="Profile" onError={utils.showDefaultImage} />
        <strong>{`${user.firstName} ${user.lastName}`}</strong><br />
        {(user.orgs) && user.orgs[0].title}
        <br />
      </div>
    );
  }
}

class TagItem extends React.PureComponent {
  render() {
    const user = this.props.item;

    return (
      <span>
        { `${user.firstName} ${user.lastName}` }
      </span>
    );
  }
}

class GroupItem extends React.PureComponent {
  render() {
    let header = _.startCase(_.toLower(this.props.item));
    header = (header === 'Team') ? <span>Team <small style={{ fontWeight: 'normal' }}>(this will add all users in the team)</small></span> : header;

    return (
      <span>
        {header}
      </span>
    );
  }
}

class VibeInviteModal extends React.Component {
  state = {
    updateAlert: false,
    calloutMessage: '',
    selectedList: {},
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  showUpdateAlert = (message) => {
    this.setState({
      updateAlert: true,
      calloutMessage: message,
    });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  updateSelectedList = (selections) => {
    this.setState({ selectedList: selections, openSelect: false });
  };

  handleAddUsers = (event) => {
    const { selectedList } = this.state;
    if (selectedList.length > 0) {
      const teamList = _.filter(selectedList, { itemType: 'team' });
      const userList = _.filter(selectedList, { itemType: 'user' });

      const notify = this.refs.notify.checked;
      const adminFullName = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;

      if (teamList.length > 0) {
        //this.props.addVibeTeam();
        console.log('TEAMS');
        console.log(teamList);
      }

      if (userList.length > 0) {
        this.props.addVibeUsers(
          this.props.selectedVibe.data, this.props.organization.data, userList,
          adminFullName, notify, this.props.auth.token,
        );
      }

      this.props.closeVibeModal();
    } else {
      this.showUpdateAlert('No users or teams selected. Please select something from the list.');
    }
    // var Indicative = new indicative;
    // For when email vibe invites are addded
    // var valid = true;
    // var emails = this.refs.email_list.value.trim().split(/[\s,\n\t]+/);
    // for(var i = 0;i < emails.length;i++) {
    //     if(!Indicative.is.email(emails[i])) {
    //         valid = false;
    //         break;
    //     }
    // }
    // if(valid) {
    //     //this.props.createInvites(this.props.auth.user.firstName, this.props.auth.user.lastName, emails, this.props.organization.data, this.props.auth.token);
    //     this.props.closeModal();
    // } else {
    //     this.showUpdateAlert("Invalid email format. Please check your emails and try again.");
    // }
  };

  render() {
    const { organization, auth, tags, selectedVibe } = this.props;
    const { user } = auth;
    const { members: { data: orgMembers }, data: { _id: orgId } } = organization;
    const { data: orgTags } = tags;

    const { members: { direct: vibeMembers, message: memberAlert = '' } } = selectedVibe;

    const teamTags = _.filter(orgTags, { tagType: 'team' });

    // Build multi-select list from orgMembers and orgTeams
    // Add teams to combined array
/*    const teamsList = _.map(teamTags, (team) => {
      const newTeam = {
        _id: team._id,
        firstName: team.displayName,
        lastName: '',
        photoUrl: '',
        itemType: 'team',
      };
      return newTeam;
    }); */
    const teamsList = {};

    // Filter members list if not admin
    const filteredMembers = utils.filterMembersByAdmin(user, orgMembers, orgId, orgTags) || [];

    // Add users to combined array
    const userList = _.map(filteredMembers, (member) => {
      const newMember = _.pick(member, ['_id', 'firstName', 'lastName', 'photoUrl', 'orgs']);
      newMember.itemType = 'user';
      return newMember;
    });

    const userTeamList = _.sortBy(_.union(userList, teamsList), ['itemType']);

    return (
      <div id="user-invite">
        <Alert
          type="success callout text-center"
          showAlert={Object.keys(memberAlert).length !== 0}
          handleClose={this.props.clearSelectedVibeMessage}>
          <h5>These users have been added to your vibe:<br /> {memberAlert}</h5>
        </Alert>
        <Modal
          isOpen={this.props.vibeModalIsOpen}
          onRequestClose={this.props.closeVibeModal}
          className="modal large"
          overlayClassName="modal-overlay"
          contentLabel="Invite users modal">
          <div id="user-invite">
            <Alert
              type="alert callout text-center"
              message={this.state.calloutMessage}
              showAlert={this.state.updateAlert}
              handleClose={this.hideUpdateAlert} />
            <br />
            <div className="row small-12 medium-9 large-7 columns">
              <h3 className="text-center"><i className="fa fa-users" style={{ color: '#8a8a8a' }} />&nbsp;&nbsp;Add board members</h3>
              <p className="text-center">Select users below and they will be automatically added to this board.</p>
              <br />

              <ReactSelect
                classNamePrefix="react-select"
                menuPosition="fixed"
                placeholder="Start typing first or last name to filter users"
                isMulti
                options={userTeamList}
                isOptionDisabled={option => vibeMembers.find(el => el._id === option._id)}
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                noOptionsMessage={() => 'You do not have admin rights for any users'}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={option => option._id}
                onChange={option => this.updateSelectedList(option)}
              />

              <div><input className="margin-top-15" type="checkbox" ref="notify" defaultChecked /> Send notifications to new users added to this board</div>
              {/*
              <label><strong>Enter email(s)</strong>
                  <textarea
                    ref="email_list" id="email_list" rows="3" aria-describedby="emailHelpText"
                    placeholder="janedoe@company.com, johndoe@company.com, juniordoe@company.com" />
              </label>
              <p className="help-text" id="emailHelpText">
                Paste one or many email addresses but make sure each email is seperated by a comma
              </p>
              */}
              <br />
              <button className="expanded button" onClick={this.handleAddUsers}>Add users to board</button>
              <br />
            </div>

            <button className="close-button" onClick={this.props.closeVibeModal} data-close="" aria-label="Close reveal" type="button">
              <span aria-hidden="true"><small>&times;</small></span>
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

VibeInviteModal.propTypes = {
  addVibeUsers: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  closeVibeModal: PropTypes.func.isRequired,
};

export default VibeInviteModal;
