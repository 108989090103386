const React = require('react');
const PropTypes = require('prop-types');
const { Helmet } = require('react-helmet');

const PlatformSettings = require('./platform-settings.jsx').default;
// const Invitations = require('./invitations.jsx').default;
// const ManageVibes = require('./manage-vibes.jsx').default;
// const UserList = require('../../components/users/containers/userList.jsx').default;
// const Dashboard = require('./dashboard.jsx').default;
// const Billing = require('./billing.jsx').default;

const jquery = require('jquery');
const $ = (typeof window !== 'undefined') ? window.$ = window.jQuery = jquery : {};

class Admin extends React.Component {
  componentDidMount() {
    new window.Foundation.Tabs($('.tabs'));
    const linkTab = window.location.hash.substr(1);
    if (linkTab) {
      $('[data-tabs]').eq(0).foundation('selectTab', $('#' + linkTab));
    }
    $('[data-tabs] a').on('click', function () {
      const myhash = this.hash;
      window.location.hash = myhash;
    });
  }

  render() {
    return (
      <div>
        <Helmet title="Admin" />
        <div className="row expanded tabs-header border-bottom">
          <div className="row submenu">
            <ul className="tabs" data-tabs id="settings-tabs">
              {/* <li className="tabs-title"><a href="#admin-dashboard">Dashboard</a></li> */}
              {/* <li className="tabs-title hide"><a href="#admin-boards">Manage boards</a></li> */}
              <li className="tabs-title is-active"><a href="#admin-settings">Platform settings</a></li>
              {/* <li className="tabs-title"><a href="#invitations">Invitations</a></li> */}
              {/* <li className="tabs-title hide"><a href="#admin-billing">Billing</a></li> */}
            </ul>
          </div>
        </div>
        <div className="tabs-content" data-tabs-content="settings-tabs">
          {/* <div className="tabs-panel" id="admin-dashboard">
          <Dashboard />
          </div> */}
          {/* <div className="tabs-panel" id="admin-boards">
          <ManageVibes />
          </div> */}
          <div className="tabs-panel is-active" id="admin-settings">
            <PlatformSettings
              tags={this.props.tags}
              organization={this.props.organization}
              auth={this.props.auth}
              fetchTagsIfNeeded={this.props.fetchTagsIfNeeded}
              editOrganization={this.props.editOrganization}
              addTag={this.props.addTag}
              saveTags={this.props.saveTags}
              sortTags={this.props.sortTags}
              cleanTags={this.props.cleanTags}
              setSelectedTag={this.props.setSelectedTag}
              clearSelectedTag={this.props.clearSelectedTag} />
          </div>
          <div className="tabs-panel" id="invitations">
            {/* <Invitations
              auth={this.props.auth}
              organization={this.props.organization}
              invitations={this.props.invitations}
              fetchInvitationsIfNeeded={this.props.fetchInvitationsIfNeeded}
              deleteInvitation={this.props.deleteInvitation} />  */}
          </div>
          {/* <div className="tabs-panel" id="admin-billing">
          <Billing />
          </div> */}
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  fetchTagsIfNeeded: PropTypes.func.isRequired,
  fetchOrganizationMembers: PropTypes.func.isRequired,
  editOrganization: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  invitations: PropTypes.object.isRequired,
  addTag: PropTypes.func.isRequired,
  saveTags: PropTypes.func.isRequired,
  sortTags: PropTypes.func.isRequired,
  fetchInvitationsIfNeeded: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  invitation: PropTypes.object,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default Admin;
