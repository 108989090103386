import constants from '../constants';
import api from '../../../utils/api';

import { alertOperations } from '../../alerts';

const { triggerAlert } = alertOperations;

const UpdateTag = (function () {
  const _updateTagRequest = function () {
    return {
      type: constants.UPDATE_TAG_REQUEST,
    };
  };

  const _updateTagSuccess = function (data) {
    return {
      type: constants.UPDATE_TAG_SUCCESS,
      tag: data.tag,
    };
  };

  const _updateTagError = function (error) {
    return {
      type: constants.UPDATE_TAG_ERROR,
      errors: error.data,
    };
  };

    // var _validationError = function(errors) {
    //     return {
    //         type: constants.UPDATE_TAG_ERROR,
    //         errors: errors
    //     };
    // };

  const _updateTag = function (tag, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_updateTagRequest());
      api.put(`/proxy/v1/organizations/${orgId}/tag/${tag.data._id}`, {
        tag: tag.data,
      }, accessToken)
        .then((res) => {
          const { tag: { displayName } } = res;

          dispatch(_updateTagSuccess(res));
          dispatch(triggerAlert(displayName));
        })
        .catch((res) => {
          dispatch(_updateTagError(res));
        });
    };
  };

  return {
    updateTag: _updateTag,
  };
}());

export default UpdateTag;
