var React = require('react');
var PropTypes = require('prop-types');
var utils = require('../../../../common/utils').default;

var jquery = require('jquery');
var $ = (typeof window !== 'undefined') ? window.$ = window.jQuery = jquery : {};

class ImageUploadComponent extends React.Component {
    componentDidMount() {
        $(".upload-img").mouseenter(function(){
            $(this).addClass("hover");
        })
        .mouseleave(function(){
            $(this).removeClass("hover");
        });
    }

    handleUploadPhotoClick = (event) => {
        event.preventDefault();

        var options = {
            uploadPreset: 'tv_profile',
            folder: 'users',
            publicId: this.props.user._id,
            maxFileSize: 500000,
            minImageWidth: 200,
            maxImageWidth: 500,
            aspectRatio: 1
        };

        utils.uploadImage(options, function(results, error) {
            if(results) {
                this.props.changeProfilePictureUrl(results.secure_url);
                this.props.handleOnImageUpload(results.secure_url);
            }
        }.bind(this));
    };

    render() {
        var fullName = this.props.user.firstName+" "+this.props.user.lastName;
        return (
            <div>
                <div className="upload-img">
                    <img src={this.props.user.photoUrl} alt={fullName} className="profile-image" onError={utils.showDefaultImage}/>
                    <div className="overlay">
                        <a href={null} className="expand" onClick={this.handleUploadPhotoClick}>Upload Photo <i className="fi-upload"></i></a>
                    </div>
                </div>
            </div>
        );
    }
}

ImageUploadComponent.propTypes = {
    changeProfilePictureUrl: PropTypes.func.isRequired,
    handleOnImageUpload: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

export default ImageUploadComponent;
