const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const moment = require('moment');
const _ = require('lodash');

const utils = require('../../../../utils').default;
// const CleanMarkdown = require('../../../components/cleanMarkdown.jsx').default;
const DraftViewer = require('../../discussions/components/draftEditor.jsx').default;
const PostFiles = require('./postFiles').default;

class PostItem extends React.Component {
  userIsItemAuthor = () => {
    const { item = {} } = this.props;
    const { author } = item;
    if (author && (author._id === this.props.auth.user._id)) {
      return true;
    }
    return false;
  };

  handleEditClick = (type, post) => {
    this.props.openDiscussionInputModal('edit', type, post);
  };

  handleReactClick = () => {
    const fullName = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;
    const type = 'like';
    this.props.reactPost(this.props.item._id, type, fullName, this.props.organization.data._id, this.props.auth.token);
  };

  render() {
    const { organization, item = {}, modalLocation } = this.props;
    const { userIsOrgAdmin, userIsTagAdmin, userIsVibeChampion } = this.props;
    const { vibe: boardId, author, content, reactions } = item;

    const postType = ('postType' in item) ? item.postType : 'discussion';

    let authorId;
    let authorPhotoUrl;
    let authorFirstName;
    let authorLastName;
    let orgTitle = '';
    let authorOrgName = '';
    // let orgName;
    // let location;
    if (author) {
      const { orgs: authorOrgs = {} } = author;
      const authorCurrentOrg = _.find(authorOrgs, { id: organization.data._id }) || {};
      const authorExternalOrg = authorCurrentOrg.externalOrg || {};
      authorOrgName = authorExternalOrg.orgName || '';

      authorId = author._id;
      authorPhotoUrl = author.photoUrl;
      authorFirstName = author.firstName;
      authorLastName = author.lastName;

      orgTitle = authorCurrentOrg.title || '';

      // For posts from external orgs
      // if (this.props.item.orgId !== this.props.organization.data._id) {
      //   orgName = (orgTitle && authorCurrentOrg.orgName) ? ', ' : '';
      //   orgName = authorCurrentOrg.orgName ? orgName + authorCurrentOrg.orgName : '';
      // }
      // location = org[0].location ? ', '+org[0].location.city+', '+org[0].location.state : '';
    } else {
      // In case user is removed from org. Their posts remain as 'Unknown User'
      authorId = null;
      authorPhotoUrl = 'https://res.cloudinary.com/teamvibe/image/upload/product/blank-profile-picture.png';
      authorFirstName = 'Unknown';
      authorLastName = 'User';
    }

    // Author name, title, and externalOrg
    const authorFullName = `${authorFirstName} ${authorLastName}`;

    let externalOrg = '';
    if (this.props.organization.data.preferences &&
      this.props.organization.data.preferences.showExternalOrg) {
      if (orgTitle.length > 0 && authorOrgName.length > 0) { externalOrg = ', '; }
      externalOrg = `${externalOrg}${authorOrgName}`;
    }

    const reactedPost = (reactions) ?
      utils.userReactedPost(this.props.auth.user._id, reactions) : false;

    return (
      <article className="post-container discussion">
        {(postType === 'promo' && this.props.item.featuredImage) &&
        <img className="post-promo-image" src={this.props.item.featuredImage} alt="" />
        }

        {(postType !== 'promo') &&
          <header className="post-header">
            <div className="post-meta right">{moment(this.props.item.createdAt).fromNow()}</div>
            <div className="media-object">
              <div className="media-object-section-tight">
                {(authorId) ?
                  <Link to={`/profile/${authorId}`}>
                    <img
                      className="image-thumb"
                      src={utils.formatImage(authorPhotoUrl, 'userThumb')}
                      onError={utils.showDefaultImage}
                      alt={authorFullName} />
                  </Link>
                  :
                  <img
                    className="image-thumb"
                    src={utils.formatImage(authorPhotoUrl, 'userThumb')}
                    onError={utils.showDefaultImage}
                    alt={authorFullName} />
                }
              </div>
              <div className="media-object-section">
                <span>
                  <strong>{`${authorFullName} `}</strong>
                </span>
                <br />
                {/* <span className="post-meta">{orgTitle}{externalOrg}{location}</span> */}
                <span className="post-meta">{orgTitle}{externalOrg}</span>
              </div>
            </div>
          </header>
        }

        {(this.props.item.callToAction && this.props.item.callToAction.ctaType !== 'None') &&
        <div className="row margin-top-15 margin-bottom-15">
          <div className="small-8 columns small-centered">
            <a
              href={this.props.item.callToAction.url}
              target="promo"
              className="button large expanded no-margin">
              {this.props.item.callToAction.ctaType}
            </a>
          </div>
        </div>
        }

        {(postType !== 'reply') && (<h4 className="post-title">{this.props.item.title}</h4>)}

        <div className="post-body">
          {(content) &&
            <DraftViewer className={`${(!content.raw && content.raw.length === 0) && 'hide'}`} content={content} readOnly />
          }
          <PostFiles post={this.props.item} mode="view" />
        </div>
        <aside className="post-actions">
          <ul className="menu">
            {(userIsVibeChampion || this.userIsItemAuthor() ||
              userIsOrgAdmin || userIsTagAdmin)
              ?
              <li>
                {modalLocation === 'home' ?
                  <Link to={`/board/${boardId}#board-settings`} title="Admin">Admin</Link>
                  :
                  <button
                    type="button"
                    className="link"
                    title="Edit post"
                    onClick={this.handleEditClick.bind(this, postType, this.props.item)}>
                    Edit
                  </button>
                }
              </li> : null}

            {(reactedPost
              ? <li><button type="button" className="link" title="Remove post reaction" onClick={this.handleReactClick}>Unlike</button></li>
              : <li><button type="button" className="link" title="React to post" onClick={this.handleReactClick}>Like</button></li>)
            }
          </ul>
        </aside>
        <footer className="post-footer margin-top-5">
          <ul>
            {this.props.item.reactions
              ? (<li>{utils.humanizeReactCounter(this.props.item.reactions)}</li>)
              : <li>&nbsp;</li>}
          </ul>
        </footer>
      </article>
    );
  }
}

PostItem.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  openDiscussionInputModal: PropTypes.func.isRequired,
  reactPost: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  modalLocation: PropTypes.string,
  userIsVibeChampion: PropTypes.bool.isRequired,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  userIsTagAdmin: PropTypes.bool.isRequired,
};

PostItem.defaultProps = {
  modalLocation: '',
};

export default PostItem;
