const React = require('react');
const PropTypes = require('prop-types');

const UserList = require('../../components/users/containers/userList.jsx').default;

class VibeMembers extends React.Component {
  componentDidMount() {
    const { organization, vibes, auth } = this.props;
    const { fetchVibeByIdIfNeeded, params } = this.props;

    fetchVibeByIdIfNeeded(
      params.boardId, organization,
      vibes, auth, auth.token,
    );
  }

  render() {
    const organization = this.props.organization.data;
    const selectedVibe = this.props.selectedVibe.data;
    const { isFetching } = selectedVibe;

    // Loading
    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    const headerImage = (!organization.preferences.hasOwnProperty('showVibeHeaderImage') || organization.preferences.showVibeHeaderImage)
      ? `url(${selectedVibe.imageUrl})`
      : 'none';

    return (
      <div>
        <div className="vibe-header-panel" style={{ backgroundImage: headerImage }} />
        <div className="row column">
          <UserList
            mode="vibe"
            page={true} />
        </div>
      </div>
    );
  }
}

VibeMembers.propTypes = {
  fetchVibeByIdIfNeeded: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default VibeMembers;
