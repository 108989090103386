const initialState = {
  organization: {
    isFetching: true,
    data: {},
    error: {},
    members: {
      isFetching: true,
      data: {},
      error: {},
    },
    viewMode: null,
  },
  registration: {
    error: {},
  },
  invitation: {
    isFetching: true,
    data: {},
    error: {},
  },
  invitations: {
    isFetching: true,
    data: {},
    error: {},
  },
  auth: {
    isFetching: true,
    error: {},
    token: null,
    user: {},
  },
  vibes: {
    isFetching: false,
    callout: {},
    data: {},
    error: {},
  },
  vibesPublic: {
    isFetching: false,
    data: {},
    error: {},
  },
  tags: {
    isFetching: true,
    data: {},
    error: {},
  },
  selectedTag: {
    isFetching: true,
    data: {
      digest: {
        digestEnabled: true,
        digestTitle: 'The Daily Vibe',
        digestSendDays: [1, 2, 3, 4, 5],
        digestSendTime: '09:00',
        digestTimeZone: 'America/Los_Angeles',
      },
    },
    error: {},
  },
  discussions: {
    isFetching: true,
    data: {},
    error: {},
  },
  discussion: {
    isFetching: true,
    data: {},
    error: {},
  },
  post: {
    isFetching: true,
    unsaved: false,
    data: {},
    error: {},
  },
  events: {
    isFetching: true,
    data: {},
    error: {},
  },
  subscriptions: {
    isFetching: true,
    data: {},
    error: {},
  },
  selectedEvent: {
    isFetching: true,
    data: {},
    error: {},
  },
  user: {
    isFetching: true,
    emailSent: false,
    data: {},
    error: {},
  },
  userFilter: {
    tags: [],
    filterType: 'ANY',
  },
  selectedVibe: {
    isFetching: false,
    members: {
      isFetching: false,
      vibe: null,
      direct: [],
      inherited: [],
      parent: null,
      error: {},
      message: {},
    },
    updated: false,
    approved: false,
    children: [],
    data: {},
    error: {},
  },
  activeVibeFilter: ['*'],
  activeEventFilter: {
    intervalStart: null,
    intervalEnd: null,
    filterType: 'all',
  },
  previousRoute: null,
  mentions: [],
};

export default initialState;
