const React = require('react');
const Link = require('react-router').Link;
const PropTypes = require('prop-types');
const moment = require('moment');
const _ = require('lodash');
const Tooltip = require('react-tippy').Tooltip;
const utils = require('../../../../../common/utils').default;

class Tile extends React.PureComponent {
    truncateUrl = (value) => {
      return value.length > 30 ? value.slice(0, 30) + '…' : value;
    };

    render() {
      const { vibe, tags, isOrgAdmin } = this.props;
      const { isActive } = vibe.orgOptions || true;
      const { isApproved } = vibe.orgOptions || true;
      const { isPinned } = vibe.orgOptions || false;

      const disabledClass = (isActive === false) ? ' card-disabled' : '';
      // var champions = null;
      // if(vibe.champion) {
      //     champions = vibe.champion.map(function (champ, i) {
      //         return (
      //             <span key={i}>{champ.firstName} {champ.lastName}{i + 1 < (vibe.champion.length) ? <span>, </span> : null}</span>
      //         );
      //     });
      // }

      // Main vibe content vars
      let vibeTitle = vibe.title;
      let vibeDescription = vibe.description;
      let vibeImage = vibe.imageUrl;
      let tileUrl = `/board/${vibe._id}`;

      // Build location list for vibe
      const vibeTags = utils.getVibeTags(vibe, tags);
      const vibeTagsList = vibeTags.map(tag => tag.displayName).join('/');
      const vibeLocationList = _.filter(vibeTags, { tagType: 'location' }).map(tag => tag.displayName).join('/');

      // Check if user is tag admin of vibe
      const vibeTagIds = _.map(vibeTags, '_id');
      const viewerIsTagAdmin = utils.userIsTagAdmin(this.props.auth.user._id, vibeTagIds, tags);

      // If single post vibe, set vars from featuredPostId
      if ((vibe.vibeLayout === 'single') && vibe.featuredPostId) {
        vibeTitle = vibe.featuredPostId.title;
        const content = utils.formatPreview(vibe.featuredPostId.content.rich);

        // Get featuredImage
        if (vibe.featuredPostId.featuredImage) {
          vibeImage = vibe.featuredPostId.featuredImage;
        } else if (content.featuredImage) {
          vibeImage = content.featuredImage;
        }

        vibeDescription = content.preview;
        tileUrl = '/post/' + vibe.featuredPostId._id + '?vibe=' + vibe.friendlyUrl;
        //var sponsorName = (vibe.featuredPostId.sponsor) ? vibe.featuredPostId.sponsor.sponsorName : null;
        var sponsorLogo = (vibe.featuredPostId.sponsor) ? vibe.featuredPostId.sponsor.sponsorLogo : null;
      }

      // New content since last visit
      if (vibe.lastActivity) {
        var newVibeContent;
        var newVibeContentType = vibe.lastActivity.activityType;
        let lastVibeVisit = null;

        if (this.props.auth.user.vibes) {
          lastVibeVisit = utils.userGetLastVibeVisit(this.props.auth.user.vibes, vibe._id);
        }

        if (lastVibeVisit) newVibeContent = (moment(vibe.lastActivity.activityDate).isAfter(moment(lastVibeVisit)));
      }

      let baseClass = 'vibe-tile item ';
      baseClass = (vibe.type === 'public') ? baseClass + "public " : baseClass;
      baseClass = (vibe.isFeatured) ? baseClass + "size-2x " : baseClass;
      return (

        <div className={baseClass + vibe.tags.toString().replace(',', ' ') + disabledClass}>
          {isApproved === false &&
          <div className="ribbon-wrapper"><div className="ribbon-green">Pending</div></div>}
          {isActive === false &&
          <div className="ribbon-wrapper"><div className="ribbon-green">Disabled</div></div>}
          {newVibeContent &&
          <div className="ribbon-wrapper"><div className="ribbon-green">New {newVibeContentType}</div></div>}
          {vibe.isFeatured &&
          <div className="ribbon-wrapper"><div className="ribbon-green">Featured</div></div>}

          <Link to={tileUrl} className="common-link">
            {vibeImage &&
              <img src={utils.formatImage(vibeImage, 'vibePhoto')} alt="Vibe" />
            }
            <div className="card-body border-top">
              {((isOrgAdmin || viewerIsTagAdmin) && vibeTagsList.length > 0) &&
              <Tooltip
                style={{ float: 'right' }}
                title={vibeTagsList}
                position="right-end"
                trigger="mouseenter"
                arrow="true">
                <i className="fa fa-tag icon-card" />
              </Tooltip>
              }
              {isPinned && <i className="fa fa-thumb-tack icon-card" title="Pinned to top" />}
              {vibe.orgOptions.accessLevel === 'hidden' && <i className="fa fa-lock icon-card" title="Private board" />}
              {sponsorLogo && <img className="float-center logo-sponsor" src={sponsorLogo} alt="Sponsor" />}
              <h3 className="lead"><strong>{vibeTitle}</strong></h3>
              <p className="" dangerouslySetInnerHTML={{ __html: vibeDescription }} />
              {/*<p><Link to={vibe._id} className="button small hollow expanded">{!this.props.buttonText ? "Explore board" : this.props.buttonText}</Link></p>*/}
            </div>
          </Link>
        </div>
      );
    }
}

Tile.propTypes = {
  auth: PropTypes.object.isRequired,
  vibe: PropTypes.object.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
};

export default Tile;
