const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const moment = require('moment');
const DropdownList = require('react-widgets/lib/DropdownList');
const DateTimePicker = require('react-widgets/lib/DateTimePicker');
const momentLocalizer = require('react-widgets-moment');
moment.locale('en');
momentLocalizer(moment);

const utils = require('../../../../../common/utils').default;
const Alert = require('../../../components/alert.jsx').default;

class ListItem extends React.PureComponent {
  render() {
    const vibe = this.props.item;

    return (
      <div style={{ marginBottom: '10px' }}>
        <h5 className="no-margin"><strong>{vibe.title}</strong></h5>
        <span>{vibe.description}</span>
      </div>
    );
  }
}

class EventInputModal extends React.Component {
  state = {
    showEndPicker: false,
    showAlert: false,
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  showEndDatePicker = () => {
    this.setState({ showEndPicker: true });
  };

  hideEndDatePicker = () => {
    const eventItem = this.props.selectedEvent;
    eventItem.data.endDateOnly = eventItem.data.startDateOnly;
    eventItem.data.endTimeOnly = eventItem.data.startTimeOnly;
    this.props.setSelectedEvent(eventItem.data);
    this.setState({ showEndPicker: false });
  };

  afterOpenModal = () => {
    const eventItem = this.props.selectedEvent;
    if (moment(moment(eventItem.data.endDate)).isAfter(moment(eventItem.data.startDate), 'minute')) {
      this.setState({ showEndPicker: true });
    }

    this.title.focus();
  };

  handleChange = (event, name, date, dateStr) => {
    const eventItem = this.props.selectedEvent;

    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
      eventItem.data[event.target.name] = inputValue;
      delete eventItem.error[event.target.name];
    } else if (name && date) {
      const formattedDate = moment(date, 'MM-DD-YYYY hh:mm a').toISOString();
      eventItem.data[name] = formattedDate;
      delete eventItem.error[name];

      // If endDate = startDate and picker is closed, update both when startDate or startTime changed
      if ((name === 'startDateOnly' || name === 'startTimeOnly') &&
        (eventItem.data.endDate === eventItem.data.startDate) &&
        this.state.showEndPicker === false) {
        eventItem.data.endDateOnly = eventItem.data.startDateOnly;
        eventItem.data.endTimeOnly = eventItem.data.startTimeOnly;
      }
    }
    this.props.setSelectedEvent(eventItem.data);
  };

  handleVibeChange = (selectedValue) => {
    const eventItem = this.props.selectedEvent;
    eventItem.data.vibe = selectedValue;
    delete eventItem.error.vibe;

    this.props.setSelectedEvent(eventItem.data);
  };

  handleClose = () => {
    this.setState({ showEndPicker: false, showAlert: false });
    this.props.closeInputModal();
  };

  handleDeleteClick = () => {
    this.setState({ showAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ showAlert: false });
  };

  handleCreateEvent = (selectedEvent) => {
    this.props.createEvent(
      selectedEvent.data, this.props.auth.user._id, this.props.organization.data._id,
      this.props.auth.token,
    );
  };

  handleEditEvent = (selectedEvent) => {
    this.props.editEvent(
      selectedEvent.data, this.props.auth.user._id,
      selectedEvent.data.vibe._id, this.props.organization.data._id,
      this.props.auth.token,
    );
  };

  handleDeleteEvent = (selectedEvent) => {
    this.handleCloseAlert();
    this.props.deleteEvent(
      selectedEvent.data, this.props.auth.user._id, this.props.organization.data._id,
      this.props.auth.token,
    );
  };

  handleSubmit = (mode) => {
    const { selectedEvent } = this.props;

    if (!('vibe' in selectedEvent.data)) { selectedEvent.data.vibe = { _id: '' }; }

    if (selectedEvent.data.title == null) { selectedEvent.data.title = ''; }
    if (selectedEvent.data.location == null) { selectedEvent.data.location = ''; }
    if (selectedEvent.data.description == null) { selectedEvent.data.description = ''; }

    const formattedStartDate = moment(selectedEvent.data.startDateOnly).format('MM-DD-YYYY');
    const formattedStartTime = moment(selectedEvent.data.startTimeOnly).format('hh:mm:ss a');
    const formattedEndDate = moment(selectedEvent.data.endDateOnly).format('MM-DD-YYYY');
    const formattedEndTime = moment(selectedEvent.data.endTimeOnly).format('hh:mm:ss a');

    selectedEvent.data.startDate = moment(`${formattedStartDate} ${formattedStartTime}`, 'MM-DD-YYYY hh:mm a').toISOString();
    selectedEvent.data.endDate = moment(`${formattedEndDate} ${formattedEndTime}`, 'MM-DD-YYYY hh:mm a').toISOString();

    selectedEvent.data.showAttendees = ('showAttendees' in selectedEvent.data) ? selectedEvent.data.showAttendees : true;
    selectedEvent.data.allowRSVP = ('allowRSVP' in selectedEvent.data) ? selectedEvent.data.allowRSVP : true;

    selectedEvent.data.visibility = 'public';
    selectedEvent.data.isRecurring = false;

    if (mode === 'edit') {
      this.handleEditEvent(selectedEvent);
    } else if (mode === 'delete') {
      this.handleDeleteEvent(selectedEvent);
    } else {
      this.handleCreateEvent(selectedEvent);
    }
  };

  render() {
    const { mode } = this.props;
    const buttonLabel = (mode === 'edit') ? 'Update event' : 'Create new event';

    const event = this.props.selectedEvent.data;

    const { startDate, endDate } = event;

    // Error handling
    const eventError = this.props.selectedEvent.error;
    const vibeHelp = ((Object.keys(eventError).length !== 0) && ('vibe' in eventError)) ? eventError.vibe.message : null;
    const titleHelp = ((Object.keys(eventError).length !== 0) && ('title' in eventError)) ? eventError.title.message : null;
    const locationHelp = ((Object.keys(eventError).length !== 0) && ('location' in eventError)) ? eventError.location.message : null;
    const startDateHelp = ((Object.keys(eventError).length !== 0) && ('startDate' in eventError)) ? eventError.startDate.message : null;

    // Populate vars
    const currentVibe = event.vibe;
    const eventTitle = (event.title || '');
    const eventLocation = (event.location || '');
    const eventDescription = event.description;

    const eventShowAttendees = ('showAttendees' in event) ? event.showAttendees : true;
    const eventAllowRSVP = ('allowRSVP' in event) ? event.allowRSVP : true;

    // Populate dates
    const eventStartDateOnly = (event.startDateOnly) ? new Date(event.startDateOnly) : null;
    const eventEndDateOnly = (event.endDateOnly) ? new Date(event.endDateOnly) : null;

    // Set default times to noon (date doesn't matter)
    const eventStartTimeOnly = (event.startTimeOnly) ? new Date(event.startTimeOnly) : new Date('7/17/07 12:00 PM');
    const eventEndTimeOnly = (event.endTimeOnly) ? new Date(event.endTimeOnly) : new Date('7/17/07 12:00 PM');

    // allDay flag
    const eventAllDay = event.allDay || false;

    // Build vibe list
    const vibeList = this.props.auth.user.vibes ? utils.getUserVibes(this.props.auth.user.vibes, this.props.vibes.data) : [];

    return (
      <Modal
        isOpen={this.props.inputModalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.handleClose}
        className="modal"
        overlayClassName="modal-overlay"
        contentLabel="Event input modal">

        {/* 'Are you sure' alert */}
        <Alert
          name="alert_pending"
          type="alert callout text-center"
          autoClose={false}
          handleClose={this.handleCloseAlert}
          showAlert={this.state.showAlert}>
          <h4>Are you sure?</h4>
          <p>Once deleted, the event can not be recovered.</p>
          <div className="margin-top-15">
            <button
              type="button"
              className="alert button"
              onClick={this.handleSubmit.bind(this, 'delete')}>Confirm deletion
            </button>&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="success button"
              onClick={this.handleCloseAlert}>Keep event
            </button>
          </div>
        </Alert>

        {/* Close buttons */}
        <i className="fi-arrow-left back-arrow" onClick={this.handleClose} />
        <button className="close-button" onClick={this.handleClose} aria-label="Close reveal" type="button">
          <span aria-hidden="true"><small>&times;</small></span>
        </button>

        {/* Main input area */}
        <div className="post-container event no-border">
          <h5>Create new event in:</h5>
          {currentVibe && currentVibe._id &&
          <div className="vibe-simple-header">
            <div className="media-object no-margin">
              <div className="media-object-section-tight">
                <img src={currentVibe.imageUrl} alt="" />
              </div>
              <div className="media-object-section middle">
                <h5 className="no-margin"><strong>{currentVibe.title}</strong></h5>
                <span>{currentVibe.description}</span>
              </div>
            </div>
          </div>}
          {(vibeList && mode === 'create') &&
          <DropdownList
            className={this.props.selectedVibe ? 'hide' : 'no-margin'}
            data={vibeList}
            valueField="_id"
            textField="title"
            itemComponent={ListItem}
            placeholder="Select/switch board"
            onChange={this.handleVibeChange}
          />}
          <span className="input-error">{vibeHelp}</span>

          <div className="row collapse margin-top-15">
            <div className="small-8 columns">
              <div className="margin-top-5">
                <label>
                  <h5>Event title</h5>
                  <input
                    className="input"
                    type="text"
                    name="title"
                    ref={(element) => { this.title = element; }}
                    value={eventTitle}
                    onChange={this.handleChange}
                    placeholder="Add a descriptive name" />
                  <span className="input-error">{titleHelp}</span>
                </label>
              </div>
              <div className="margin-top-5">
                <label>
                  <h5>Event location</h5>
                  <input
                    className="input"
                    type="text"
                    name="location"
                    value={eventLocation}
                    onChange={this.handleChange}
                    placeholder="Provide a place or address (optional)" />
                  <span className="input-error">{locationHelp}</span>
                </label>
              </div>
            </div>
            <div className="small-4 columns">
              <br />
              <div className="row">
                <div className="small-9 columns text-right input-height">
                  <p>Show Attendees:</p>
                </div>
                <div className="small-3 columns text-right">
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="showAttendees"
                      checked={eventShowAttendees}
                      onChange={this.handleChange}
                      className="switch-input"
                      id="switch-showAttendees" />
                    <label className="switch-paddle" htmlFor="switch-showAttendees">
                      <span className="switch-active" aria-hidden="true">On</span>
                      <span className="switch-inactive" aria-hidden="true">Off</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="small-9 columns text-right input-height">
                  <p>Allow RSVPs:</p>
                </div>
                <div className="small-3 columns text-right">
                  <div className="switch">
                    <input
                      type="checkbox"
                      name="allowRSVP"
                      checked={eventAllowRSVP}
                      onChange={this.handleChange}
                      className="switch-input"
                      id="switch-allowRSVP" />
                    <label className="switch-paddle" htmlFor="switch-allowRSVP">
                      <span className="switch-active" aria-hidden="true">On</span>
                      <span className="switch-inactive" aria-hidden="true">Off</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row collapse margin-top-15">
            <div className="small-12 columns">
              <h5>Date/time
                <small>
                  <input
                    className="margin-left-15 no-margin no-height"
                    name="allDay"
                    type="checkbox"
                    defaultChecked={eventAllDay}
                    onChange={this.handleChange} />
                  <label>&nbsp;
                    <span style={{ color: 'black', textSize: '0.2rem' }}>&nbsp;All-day event</span>
                  </label>
                </small>
              </h5>
            </div>
          </div>

          <div className="row" id="sd">
            <div className="small-12 medium-3 columns" ref="sd">
              <DateTimePicker
                name="startDateOnly"
                value={eventStartDateOnly}
                onChange={this.handleChange.bind(null, '0', 'startDateOnly')}
                time={false}
                footer={false}
                format="M/D/YYYY"
                dateFormat="D"
                placeholder="Pick a date" />
              <span className="input-error">{startDateHelp}</span>
            </div>
            <div className={`small-12 medium-3 columns ${(eventAllDay === false) ? '' : 'hide'}`}>
              <DateTimePicker
                name="startTimeOnly"
                value={eventStartTimeOnly}
                onChange={this.handleChange.bind(null, '0', 'startTimeOnly')}
                date={false}
                placeholder="Time" />
            </div>
            <div className={`${(eventAllDay === false) ? 'small-12 medium-6 columns' : 'small-12 medium-9 columns'}`}>
              {!this.state.showEndPicker
                ?
                <p className="input-height">
                  <a href={null} id="aed" onClick={this.showEndDatePicker}><i className="fi-plus" /> add end date</a>
                </p>
                : null}
            </div>
          </div>
          {this.state.showEndPicker &&
              <div className="row margin-top-15" id="ed">
                <div className="small-12 medium-3 columns">
                  <DateTimePicker
                    name="endDateOnly"
                    value={eventEndDateOnly}
                    onChange={this.handleChange.bind(null, '0', 'endDateOnly')}
                    time={false}
                    footer={false}
                    format="M/D/YYYY"
                    dateFormat="D"
                    min={eventStartDateOnly}
                    placeholder="Pick end date" />
                </div>
                <div className={`small-12 medium-3 columns ${(eventAllDay === false) ? '' : 'hide'}`}>
                  <DateTimePicker
                    name="endTimeOnly"
                    value={eventEndTimeOnly}
                    onChange={this.handleChange.bind(null, '0', 'endTimeOnly')}
                    date={false}
                    placeholder="End time" />
                </div>
                <div className={`${(eventAllDay === false) ? 'small-12 medium-6 columns' : 'small-12 medium-9 columns'}`}>
                  <p className="input-height">
                    <a href={null} id="ned" onClick={this.hideEndDatePicker}><i className="fi-minus" /> remove end date</a>
                  </p>
                </div>
              </div>
          }

          <label className="margin-top-5"><h5>Describe your event</h5>
            <textarea
              className="textarea"
              name="description"
              value={eventDescription}
              onChange={this.handleChange}
              rows="5"
              placeholder="Let people know more about your event" />
          </label>
          <br />
          <div className="clearfix">
            {(mode === 'edit') &&
              <button type="button" className="alert button" onClick={this.handleDeleteClick}>Delete event</button>
            }
            <button type="button" className="button float-right" onClick={this.handleSubmit.bind(this, mode)}>{buttonLabel}</button>
          </div>
        </div>
      </Modal>
    );
  }
}

EventInputModal.propTypes = {
  createEvent: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  closeInputModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  selectedVibe: PropTypes.object,
  vibes: PropTypes.object.isRequired,
};

export default EventInputModal;
