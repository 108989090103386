const React = require('react');
const { Helmet } = require('react-helmet');
const { withRouter, Link } = require('react-router');
const PropTypes = require('prop-types');
const ReactTimeout = require('react-timeout');
const _ = require('lodash');
const Alert = require('../../components/alert.jsx').default;
const Tabs = require('../../components/tabs.jsx').default;
const utils = require('../../../../common/utils').default;
const VibeHomepage = require('./vibeHomepage.jsx').default;
const VibeUsers = require('../../components/users/containers/userList.jsx').default;
const VibeSettings = require('../../vibes/components/create/customizeVibe.jsx').default;

const tabList = [
  {
    name: 'Board home',
  }, {
    name: 'Board settings',
  }, {
    name: 'Manage members',
  },
];

class Vibe extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Board home',
      dismissAlert: false,
      dismissVibe: false,
      updateAlert: false,
    };
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  componentDidMount() {
    const { params, auth, organization, vibes } = this.props;
    const { boardId, name } = params;
    const { token } = auth;

    // Allow both boardId and name for backwards compatibility
    if (boardId) {
      this.props.fetchVibeByIdIfNeeded(
        boardId, organization,
        vibes, auth, token,
      );
    } else {
      this.props.fetchVibeIfNeeded(
        name, organization,
        vibes, auth, token,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { selectedVibe: { isFetching, error = {}, data: { _id: selectedBoardId, friendlyUrl } = {} } } = this.props;
      const boardFetchError = (Object.keys(error).length !== 0) || false;

      // Re-fetch on url change
      const { params, auth, organization, vibes } = this.props;
      const { boardId, name } = params;
      const { token } = auth;

      if ((name) && !isFetching && !boardFetchError && params.name !== friendlyUrl) {
        this.props.fetchVibeIfNeeded(
          name, organization,
          vibes, auth, token,
        );
      }

      if (boardId && !isFetching && !boardFetchError && (boardId !== selectedBoardId)) {
        this.props.fetchVibeByIdIfNeeded(
          boardId, organization,
          vibes, auth, token,
        );
      }

      if (this.props.selectedVibe.data && !this.props.selectedVibe.isFetching) {
        const userIsVibeMember = utils.userIsVibeMember(this.props.auth.user.vibes, this.props.selectedVibe.data._id);

        // Auto-join vibe if ?join=auto
        if (this.props.location.query.join === 'auto' && !userIsVibeMember) {
          this.props.joinVibe(
            this.props.auth.user._id, this.props.selectedVibe.data._id, false,
            this.props.organization.data._id, this.props.auth.token,
          );
          this.props.router.push(this.props.location.pathname);
          this.props.setTimeout(this.showUpdateAlert, 500);
        }
      }
    }
  }

  componentWillUnmount() {
    // Added this to clear selectedVibe when editing, but caused issues with members and events
    // Adding back to clear
    this.props.clearSelectedVibe();
  }

  getParentVibe = (vibes, parentId) => {
    const parentVibe = _.find(vibes, { _id: parentId });
    return parentVibe;
  };

  showUpdateAlert = () => {
    this.setState({ updateAlert: true });
  };

  hideAlerts = () => {
    this.setState({ updateAlert: false, dismissAlert: true });
    this.props.removeVibeAlerts();
  };

  handleApproveVibe = () => {
    this.props.approveOrgVibe(
      this.props.selectedVibe.data, this.props.organization.data._id,
      this.props.auth.token,
    );
  };

  handleRejectVibe = () => {
    this.setState(prevState => ({ dismissVibe: !prevState.dismissVibe }));
  };

  rejectVibe = () => {
    this.props.rejectOrgVibe(
      this.props.selectedVibe.data, this.props.organization.data,
      this.reject_reason.value, this.props.auth.token,
    );
  };

  changeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const { organization, selectedVibe, tags } = this.props;
    const { data: { _id: boardId } = {} } = selectedVibe;
    const vibeIsFetching = selectedVibe.isFetching;
    const tagsIsFetching = tags.isFetching;

    // Loading
    if (vibeIsFetching || tagsIsFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    // Loading
    if (!boardId) {
      return (
        <div>
          <br /><br />
          <h5 className="text-center">Unable to load board</h5>
          <br />
          <h5 className="text-center"><Link to="/">Click here to return to the main menu</Link></h5>
          <br /><br />
        </div>
      );
    }

    const { data: { orgOptions = {} } } = selectedVibe;
    const { isApproved = false, isActive = false } = orgOptions;
    const { tags: orgTags } = orgOptions || [];
    const parentVibeId = (orgOptions && orgOptions.parent) || undefined;
    const parentVibe = this.getParentVibe(this.props.vibes.data, parentVibeId);

    const userIsVibeChampion = utils.userIsVibeChampion(this.props.auth.user.vibes, this.props.selectedVibe.data._id);
    const userIsOrgAdmin = utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs);
    const userIsTagAdmin = utils.userIsTagAdmin(this.props.auth.user._id, orgTags, tags.data);
    const vibeIsInOrg = utils.getOrgVibe(this.props.organization.data.vibes, this.props.selectedVibe.data._id) || false;

    const { approved } = this.props.selectedVibe;
    const dismissAlert = this.state.dismissAlert || false;

    // Get top level board ID for member fetching via inheritence
    const { data: { vibes } } = organization;
    const boardList = vibes.map((o) => (
      {
        _id: o.vibe,
        accessLevel: o.accessLevel,
        isChild: o.isChild || false,
        parent: o.parent || null,
        tags: o.tags || [],
      }
    ));

    const currentBoard = {
      _id: selectedVibe.data._id,
      accessLevel: orgOptions.accessLevel,
      isChild: orgOptions.isChild || false,
      parent: orgOptions.parent || null,
      tags: orgOptions.tags || [],
    };
    const topParentBoard = utils.findTopParent(boardList, currentBoard);

    return (
      <div>
        <Helmet title={this.props.selectedVibe.data.title} />
        <Alert
          name="alert_success"
          type="success callout text-center"
          message="You are now following this board"
          showAlert={this.state.updateAlert}
          handleClose={this.hideAlerts} />

        <Alert
          name="alert_pending"
          type="alert callout"
          autoClose={false}
          handleClose={this.hideAlerts}
          showAlert={(!dismissAlert && !isApproved && isActive)}>
          <h5 className="text-center">This board is pending and needs approval</h5>
          {(userIsOrgAdmin) &&
            <span>
              <p>Approving will make it live and notify the champion. Rejecting will allow you to send a reason.</p>
              <div className="text-center">
                <button type="button" className="alert button dropdown" onClick={this.handleRejectVibe}>Reject board</button>&nbsp;&nbsp;
                <button type="button" className="success button" onClick={this.handleApproveVibe}>Approve this board</button>
              </div>
              <div className={!this.state.dismissVibe ? 'hide' : 'show'}>
                <p>The message below will be sent explaining why the board was rejected. If the user wants to re-submit, the new board will go through the same approval process.</p>
                <label>
                  <strong>Rejection reason</strong> <small>(max 300 characters)</small>
                  <textarea id="reject-reason" className="textarea" ref={(c) => { this.reject_reason = c; }} rows="4" />
                </label>
                <br />
                <button type="button" className="button alert" onClick={this.rejectVibe}>Reject board &amp; send message</button>
              </div>
            </span>
          }
        </Alert>

        <Alert
          name="alert_approved"
          type="success callout text-center"
          message="This board has been approved and the champion has been notified"
          showAlert={(approved && isApproved)}
          handleClose={this.hideAlerts} />

        {/* This alert is shown based on state change, which causes some UI confusion if settings not saved */}
        <Alert
          name="alert_disabled"
          type="alert callout text-center"
          message="This board is currently disabled and can't be seen by normal users"
          autoClose={false}
          showAlert={(this.state.activeTab !== 'Board settings') && !isActive} />

        {/* Admin/champion tabs for board management */}
        {(userIsVibeChampion || (vibeIsInOrg && userIsOrgAdmin) || userIsTagAdmin) && (
          <div>
            <div className="row expanded tabs-header border-bottom">
              <div className="row submenu">
                <Tabs
                  tabList={tabList}
                  activeTab={this.state.activeTab}
                  changeActiveTab={this.changeActiveTab} />
              </div>
            </div>
          </div>
        )}

        <div className="tabs-content" data-tabs-content="settings-tabs">
          <div className={`tabs-panel ${this.state.activeTab === 'Board home' && 'is-active'}`} id="admin-vibe-homepage">
            <VibeHomepage
              selectedVibe={this.props.selectedVibe}
              organization={this.props.organization}
              auth={this.props.auth}
              discussions={this.props.discussions}
              mentions={this.props.mentions}
              parentVibe={parentVibe}
              joinVibe={this.props.joinVibe}
              leaveVibe={this.props.leaveVibe}
              post={this.props.post}
              postId={this.props.params.id}
              setPost={this.props.setPost}
              clearPost={this.props.clearPost}
              updateVibeOverview={this.props.updateVibeOverview}
              userIsVibeChampion={userIsVibeChampion}
              userIsOrgAdmin={userIsOrgAdmin}
              userIsTagAdmin={userIsTagAdmin} />
          </div>
          <div className={`tabs-panel ${this.state.activeTab === 'Board settings' && 'is-active'}`} id="admin-settings">
            <VibeSettings
              mode="update"
              selectedVibe={this.props.selectedVibe}
              tags={tags.data}
              organization={this.props.organization}
              auth={this.props.auth}
              discussions={this.props.discussions}
              vibes={this.props.vibes}
              fetchTagsIfNeeded={this.props.fetchTagsIfNeeded}
              fetchDiscussionsIfNeeded={this.props.fetchDiscussionsIfNeeded}
              updateVibe={this.props.updateVibe}
              deleteVibe={this.props.deleteVibe}
              setSelectedVibe={this.props.setSelectedVibe}
              clearSelectedVibe={this.props.clearSelectedVibe}
              validateVibe={this.props.validateVibe}
              validateVibeUniqueTitle={this.props.validateVibeUniqueTitle}
              removeVibeAlerts={this.hideAlerts}
              userIsVibeChampion={userIsVibeChampion}
              userIsOrgAdmin={userIsOrgAdmin}
              userIsTagAdmin={userIsTagAdmin} />
          </div>
          <div className={`tabs-panel ${this.state.activeTab === 'Manage members' && 'is-active'}`} id="admin-users">
            <VibeUsers
              mode="vibe"
              userIsOrgAdmin={userIsOrgAdmin}
              userIsTagAdmin={userIsTagAdmin}
              userIsVibeChampion={userIsVibeChampion}
              topParentBoard={topParentBoard}
              selectedVibe={this.props.selectedVibe}
              organization={this.props.organization}
              auth={this.props.auth}
              fetchMembers={this.props.fetchMembers}
              editVibeUser={this.props.editVibeUser}
              leaveVibe={this.props.leaveVibe}
              removeVibeUser={this.props.removeVibeUser} />
          </div>
        </div>
      </div>
    );
  }
}

Vibe.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  tags: PropTypes.object,
  params: PropTypes.object.isRequired,
  fetchVibeIfNeeded: PropTypes.func.isRequired,
  fetchVibeByIdIfNeeded: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  joinVibe: PropTypes.func.isRequired,
  leaveVibe: PropTypes.func.isRequired,
  approveOrgVibe: PropTypes.func.isRequired,
  rejectOrgVibe: PropTypes.func.isRequired,
  updateVibe: PropTypes.func.isRequired,
  editVibeUser: PropTypes.func.isRequired,
  removeVibeUser: PropTypes.func.isRequired,
  removeVibeAlerts: PropTypes.func,
  validateVibe: PropTypes.func.isRequired,
  validateVibeUniqueTitle: PropTypes.func.isRequired,
  mentions: PropTypes.array.isRequired,
};

export default withRouter(ReactTimeout(Vibe));
