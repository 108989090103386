import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const Vibes = (function () {
  const _requestVibes = function () {
    return {
      type: constants.REQUEST_VIBES,
    };
  };

  const _receiveVibes = function (vibes) {
    return {
      type: constants.RECEIVE_VIBES,
      vibes,
    };
  };

  const _fetchVibesError = function (error) {
    return {
      type: constants.REQUEST_VIBES_ERROR,
      errors: error.data,
    };
  };

  const _requestRemoveVibesCallout = function () {
    return {
      type: constants.REQUEST_CALLOUT_REMOVE,
    };
  };

  const _fetchVibes = function (organization, accessToken) {
    return function (dispatch) {
      dispatch(_requestVibes());
      api.get(`/proxy/v1/organizations/${organization._id}/vibes`, {
        orgId: organization._id,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveVibes(res.vibes));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchVibesError(res));
          }
        });
    };
  };

  const _shouldFetchVibes = function (state) {
    // var organization = state.organization.data;
    const { vibes } = state;
    if (Object.keys(vibes.data).length === 0) {
      // CASE: Vibes state is empty
      return true;
    } else if (vibes.activity === true) {
      // CASE: New activity (post, reply, event) will trigger re-fetch
      // Will also re-fetch vibes on org fetch
      return true;
    }
    return false;
  };

  const _setActivityFlag = function (flag) {
    return {
      type: constants.SET_VIBES_ACTIVITY,
      flag,
    };
  };

  const _fetchVibesIfNeeded = function (organization, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchVibes(getState())) {
        dispatch(_fetchVibes(organization, accessToken));
        dispatch(_setActivityFlag(false));
      }
    };
  };

  const _setActiveVibeFilter = function (filter) {
    return {
      type: constants.SET_ACTIVE_VIBE_FILTER,
      filter,
    };
  };

  const _removeVibeCallout = function () {
    return function (dispatch) {
      dispatch(_requestRemoveVibesCallout());
    };
  };

  return {
    fetchVibes: _fetchVibes,
    fetchVibesIfNeeded: _fetchVibesIfNeeded,
    setActiveVibeFilter: _setActiveVibeFilter,
    removeVibeCallout: _removeVibeCallout,
    setActivityFlag: _setActivityFlag,
  };
}());

export default Vibes;
