import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const UserItem = (props) => {
  const { user } = props;
  const { firstName, lastName } = user;

  return (
    <tr>
      <td>{`${firstName} ${lastName}`}</td>
      <td className="text-center"></td>
      <td className="text-center"></td>
      <td className="text-center"></td>
    </tr>
  );
};


const PendingUsers = () => {
  // Import state vars
  const { organization, tags } = useSelector(
    (state) => ({
      organization: state.organization,
      tags: state.tags,
    }),
  );

  const { data: { _id: orgId } } = organization;
  const { members: { data: orgUsers } } = organization;
  const { data: tagsData } = tags;
  const locationTags = tagsData.filter((tag) => tag.tagType === 'location') || [];
  const sortedLocations = _.orderBy(locationTags, 'displayName');



  const testTag = '5e78e2fd01c5b36984d30548';
  const pendingUsersList = orgUsers.filter((user) => {

    // const userOrg = (user.orgs).find((org) => org.id === orgId);
return user.isPending;
    // return (userOrg.tags).find((userOrgTag) => userOrgTag === testTag._id) && user.isPending;
  }).map((item) => (<UserItem key={item._id} user={item} />));
console.log(orgUsers);
console.log(pendingUsersList);




  return (
    <div className="row columns">
      <br />
      <h3>User report</h3>
      <table>
        <thead>
          <tr>
            <th width="200">Location</th>
            <th className="text-center" width="100">Total users</th>
            <th className="text-center" width="100">Active</th>
            <th className="text-center" width="100">Pending</th>
          </tr>
        </thead>
        <tbody>
        {pendingUsersList}
    
          <tr>
            <td width="200"><strong>Total:</strong></td>
            <td className="text-center"><strong>{}</strong></td>
            <td className="text-center"><strong>{}</strong></td>
            <td className="text-center"><strong>{}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

PendingUsers.propTypes = {
  // tag: PropTypes.object.isRequired,
};

export default PendingUsers;
