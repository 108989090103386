import constants from '../constants';
import initialState from '../../initialState';

const activeVibeFilter = function (state, action) {
  switch (action.type) {
  case constants.SET_ACTIVE_VIBE_FILTER:
    return action.filter;
  default: return state || initialState.activeVibeFilter;
  }
};

export default activeVibeFilter;
