const React = require('react');
const PropTypes = require('prop-types');
const { Link } = require('react-router');

const BoardPreview = (props) => {
  const { title, imageUrl, isApproved } = props;
  let thumbnail = <i className="fa fa-file thumb-folder font" />;
  if (imageUrl) {
    thumbnail = imageUrl;
    // Using background image instead of img tag
    // thumbnail = <img className="thumb-folder" src={imageUrl} alt={title} />;
  }

  const mediaLeftStyle = {
    backgroundImage: `url(${thumbnail})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  };

  return (
    <div className="box sub-folder has-background-white is-rounded is-shadowless">
      <article className="media">
        <figure className="media-left" style={mediaLeftStyle} />
        <div className="media-content">
          <div className="content">
            {(!isApproved)
              ? <i><strong>{title}</strong> (pending)</i>
              : <strong>{title}</strong> }
          </div>
        </div>
      </article>
    </div>
  );
};

const VibeFolder = (props) => {
  const { boardId = '', title, imageUrl, isApproved } = props;

  return (
    <div className="column">
      {(boardId) ?
        <Link to={`/board/${boardId}`}>
          <BoardPreview title={title} imageUrl={imageUrl} isApproved={isApproved} />
        </Link>
        : <BoardPreview title={title} imageUrl={imageUrl} isApproved={isApproved} />
      }
    </div>
  );
};

BoardPreview.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isApproved: PropTypes.bool.isRequired,
};

VibeFolder.propTypes = {
  boardId: PropTypes.string,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isApproved: PropTypes.bool.isRequired,
};

VibeFolder.defaultProps = {
  boardId: '',
};

export default VibeFolder;
