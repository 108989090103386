import constants from '../constants';
import api from '../../../utils/api';

const UpdateSubscription = (function () {
  const _updateSubscriptionRequest = function () {
    return {
      type: constants.UPDATE_SUBSCRIPTION_REQUEST,
    };
  };

  const _updateSubscriptionSuccess = function (data) {
    return {
      type: constants.UPDATE_SUBSCRIPTION_SUCCESS,
      subscription: data.subscription,
    };
  };

  const _updateSubscriptionError = function (error) {
    return {
      type: constants.UPDATE_SUBSCRIPTION_ERROR,
      errors: error.data,
    };
  };

  const _updateSubscription = function (userId, orgId, type, ref, accessToken) {
    return function (dispatch) {
      dispatch(_updateSubscriptionRequest());
      api.put(`/proxy/v1/subscriptions/${userId}`, {
        orgId,
        type,
        ref,
      }, accessToken)
        .then((res) => {
          dispatch(_updateSubscriptionSuccess(res));
        })
        .catch((res) => {
          dispatch(_updateSubscriptionError(res));
        });
    };
  };

  return {
    updateSubscription: _updateSubscription,
  };
}());

export default UpdateSubscription;
