import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import loginActions from './auth-login';
import loginUtils from '../utils/LoginUtils';

const SignupLogin = (function () {
  const _loginRequest = function () {
    return {
      type: constants.AUTH_LOGIN_REQUEST,
    };
  };

  const _loginSuccess = function (data) {
    const now = new Date();
    const expires = now.setSeconds(now.getSeconds() + data.expires_in);

    return {
      type: constants.AUTH_LOGIN_SUCCESS,
      data: data.access_token,
      expires,
    };
  };

  const _loginError = function (error) {
    return {
      type: constants.AUTH_LOGIN_FAILURE,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.AUTH_LOGIN_FAILURE,
      errors,
    };
  };

  const _clearAuthError = function () {
    return {
      type: constants.CLEAR_AUTH_LOGIN_ERROR,
    };
  };

  const _loginWithInvitation = function (email, password, invitation, teamDomain) {
    const { token, inviteType } = invitation;
    return function (dispatch) {
      loginUtils.validatePassword(password)
        .then((result) => {
          if (result === true) {
            dispatch(_loginRequest());
            api.post('/proxy/oauth/token', {
              username: email,
              password,
              invite_token: token,
              grant_type: 'password',
            })
              .then((res) => {
                dispatch(loginActions.setCookie(res));
                dispatch(_loginSuccess(res));

                if (inviteType === 'joinOrg' || inviteType === 'selfJoin' || inviteType === 'magicLink') {
                  dispatch(routerActions.push(`/profile-details?invite=${token}`));
                } else if (inviteType === 'createOrg') {
                  dispatch(routerActions.push(`/org-create?invite=${token}`));
                }
              })
              .catch((res) => {
                dispatch(_loginError(res.response));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    loginWithInvitation: _loginWithInvitation,
    clearAuthError: _clearAuthError,
  };
}());

export default SignupLogin;
