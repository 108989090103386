import constants from '../constants';
import api from '../../../utils/api';

const UserDetails = (function () {
  const _requestUserDetails = function () {
    return {
      type: constants.REQUEST_USER_DETAILS,
    };
  };

  const _receiveUserDetails = function (data) {
    return {
      type: constants.RECEIVE_USER_DETAILS,
      user: data.user,
    };
  };

  const _fetchUserDetailsError = function (error) {
    return {
      type: constants.REQUEST_USER_DETAILS_ERROR,
      errors: error.data,
    };
  };

  const _fetchUserDetails = function (userId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestUserDetails());
      api.get(`/proxy/v1/users/${userId}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveUserDetails(res));
        })
        .catch((res) => {
          dispatch(_fetchUserDetailsError(res));
        });
    };
  };

  const _shouldFetchUserDetails = function (userId, state) {
    const userDetails = state.user.data;
    if (Object.keys(userDetails).length === 0) {
      return true;
    } else if (userDetails._id !== userId) {
      if (userDetails._id !== userId) {
        return true;
      }
    }
    return false;
  };

  const _fetchUserDetailsIfNeeded = function (userId, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchUserDetails(userId, getState())) {
        return dispatch(_fetchUserDetails(userId, orgId, accessToken));
      }
      return false;
    };
  };

  const _setUser = function (user) {
    return {
      type: constants.SET_USER,
      user,
    };
  };

  return {
    fetchUserDetailsIfNeeded: _fetchUserDetailsIfNeeded,
    setUser: _setUser,
  };
}());

export default UserDetails;
