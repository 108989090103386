const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');

const PostFiles = require('../../posts/components/postFiles').default;
const utils = require('../../../../../common/utils').default;

class PostTileItem extends React.PureComponent {
    handleEditDiscussion = (postItem) => {
      const { organization, auth, selectedVibe } = this.props;
      const { data: { _id: orgId } } = organization;
      const { token } = auth;
      const { data: { _id: boardId } = {} } = selectedVibe;

      this.props.editDiscussion(
        postItem, orgId, boardId, token,
      );
    };

    handleExpandClick = (event) => {
      event.preventDefault();
      const { discussion } = this.props;
      const postItem = { data: discussion };
      postItem.data.isFeatured = !postItem.data.isFeatured;

      this.handleEditDiscussion(postItem);
    };

    handlePinClick = (event) => {
      event.preventDefault();
      const { discussion } = this.props;
      const postItem = { data: discussion };
      postItem.data.isPinned = !postItem.data.isPinned;

      this.handleEditDiscussion(postItem);
    };

    render() {
      const {
        discussion, selectedVibe, alert, userIsOrgAdmin, userIsVibeChampion,
      } = this.props;
      const { data: { _id: boardId } = {} } = selectedVibe;
      const {
        _id: postId, title, content,
        meta, isPinned, isFeatured,
      } = discussion;

      const formattedContent = (content)
        ? utils.formatPreview(content.rich)
        : { preview: '', imageCount: 0, featuredImage: '' };

      const contentPreview = formattedContent.preview || '';
      const imageCount = formattedContent.imageCount || 0;

      // Get featuredImage
      let { featuredImage } = discussion;

      // For older posts that don't have a stored featured image
      featuredImage = (featuredImage && featuredImage.length > 0) ? featuredImage : formattedContent.featuredImage;

      const numReplies = meta.replyCount;

      let baseClass = 'post-tile item';
      baseClass = (isFeatured) ? `${baseClass} size-2x` : baseClass;
      baseClass = (!featuredImage) ? `${baseClass} no-image` : baseClass;

      const imageStyle = { backgroundImage: `url(${featuredImage})` };

      return (
        <div>
          <div className={baseClass}>
            {alert &&
            <div className="ribbon-wrapper"><div className="ribbon-green">{alert}</div></div>}

            <Link to={`/board/${boardId}/post/${postId}`} className="common-link">
              {featuredImage &&
                <div className="post-feature-image" style={imageStyle} />
              }

              <div className="post-tile-content">
                {isPinned && <i className="fa fa-thumb-tack icon-card" title="Pinned to top" />}
                <h5 className="post-title">{title}</h5>
                <p className="" dangerouslySetInnerHTML={{ __html: contentPreview }} />
                <PostFiles post={discussion} mode="preview" />

                <div className="row post-meta">
                  <div className="small-4 columns menu-expand">
                    {(userIsOrgAdmin || userIsVibeChampion) &&
                      <span>
                        <button className="fa fa-expand icon" onClick={this.handleExpandClick} />&nbsp;&nbsp;&nbsp;
                        <button className="fa fa-thumb-tack icon" onClick={this.handlePinClick} />
                      </span>
                    }
                  </div>
                  <div className="small-8 columns text-right">
                    {(imageCount > 2) && `${imageCount} images`}
                    {(imageCount > 2 && numReplies > 0) && ' / '}
                    {(numReplies > 0) && `${numReplies + 1} posts`}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
}

PostTileItem.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  discussion: PropTypes.object.isRequired,
  alert: PropTypes.string,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  userIsVibeChampion: PropTypes.bool.isRequired,
  editDiscussion: PropTypes.func.isRequired,
};

PostTileItem.defaultProps = {
  alert: '',
};

export default PostTileItem;
