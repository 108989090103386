const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const indicative = require('indicative');
const DropdownList = require('react-widgets/lib/DropdownList');

const Alert = require('../../components/alert.jsx').default;

const Indicative = new indicative();

class SignUp extends React.PureComponent {
  constructor(props) {
    super(props);
    const organization = props.organization.data;
    const emailDomains = organization.emailDomains || [];

    const initialEmailDomain = (emailDomains.length < 1)
      ? `${organization.teamDomain}.com`
      : emailDomains[0];

    const inviteType = (props.location.pathname === '/get-started') ? 'createOrg' : 'selfJoin';

    this.state = {
      updateAlert: false,
      inviteType,
      emailDomain: initialEmailDomain,
    };
  }

  componentWillUnmount() {
    this.props.clearInvite();
  }

  showUpdateAlert = () => {
    this.setState({
      updateAlert: true,
    });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  handleChange = (event) => {
    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
      this.setState({ [event.target.name]: inputValue });
    }
  };

  handleDomainChange = (selectedValue) => {
    this.setState({ emailDomain: selectedValue });
  };

  handleRequestInvite = () => {
    let emailAddress;
    if (this.state.emailName) {
      const emailName = this.state.emailName.toLowerCase();
      const { emailDomain } = this.state;

      if (this.state.inviteType === 'selfJoin') {
        emailAddress = (this.props.organization.data.preferences.allowAnyEmail) ? emailName : `${emailName}@${emailDomain}`;
      } else {
        emailAddress = emailName;
      }
    }

    if (Indicative.is.email(emailAddress)) {
      this.props.createInvite(emailAddress, this.state.inviteType, this.props.organization.data);
    } else {
      this.showUpdateAlert('Invalid email format. Please check your emails and try again.');
    }
  };

  render() {
    const organization = this.props.organization.data;
    const invitation = this.props.invitation ? this.props.invitation : '';

    const emailDomainText = (!organization.preferences.allowAnyEmail) && `@${this.state.emailDomain}`;
    const numEmailDomains = organization.emailDomains.length;

    return (
      <div>
        {(this.state.inviteType === 'selfJoin')
          ? <Helmet title="Sign Up" />
          : <Helmet title="Get Started" />}
        <Alert
          name="alert_invalid"
          type="alert callout text-center"
          message="Invalid email format. Please check your emails and try again."
          showAlert={this.state.updateAlert}
          handleClose={this.hideUpdateAlert} />
        <Alert
          name="alert_error"
          type="alert callout text-center"
          autoClose={false}
          closeButton={true}
          showAlert={Object.keys(invitation.error).length !== 0}
          handleClose={this.props.clearInvite}>
          <h5>{invitation.error.message}</h5>
          <p>If you have trouble logging in, <a href="mailto:support@teamvibe.com?subject=Login Help">email us for help</a></p>
        </Alert>
        <Alert
          name="alert_success"
          type="success callout text-center"
          message={`An invitation has been sent to: ${invitation.data.validEmails}`}
          showAlert={Object.keys(invitation.data).length !== 0}
          handleClose={this.props.clearInvite} />
        <br />
        <br className="is-hidden-mobile" />
        <div className="row">
          <div className="medium-10 medium-centered large-7 large-centered columns">
            {organization &&
            <div>
              <div className="login-card">
                {(this.state.inviteType === 'selfJoin')
                  ? <h3 className="text-center"><strong>Sign up for {organization.name}</strong></h3>
                  : <h3 className="text-center"><strong>Get Started on Teamvibe</strong></h3>}
                {(this.state.inviteType === 'selfJoin')
                  ? <p className="text-center">Enter your {(!organization.preferences.allowAnyEmail) && 'work'} email address to get started:</p>
                  : <p className="text-center">Enter your email address to get started:</p>}
                <div className="text-center">
                  <div className="row collapse">

                    {(this.state.inviteType === 'selfJoin') && (
                      (!organization.preferences.allowAnyEmail) ?
                        <div className="small-6 columns text-right end">
                          <input
                            type="text"
                            className="input text-right margin-right-5"
                            placeholder="email"
                            name="emailName"
                            onChange={this.handleChange} />
                        </div> :
                        <div className="small-12 columns small-centered">
                          <input
                            type="text"
                            className="input wide"
                            placeholder="Your full email address"
                            name="emailName"
                            onChange={this.handleChange} />
                        </div>
                    )}

                    {(this.state.inviteType === 'createOrg') && (
                      <div className="small-12 columns small-centered">
                        <input
                          type="text"
                          className="input wide text-right"
                          placeholder="your email"
                          name="emailName"
                          onChange={this.handleChange} />
                      </div>
                    )}
                    {(!organization.preferences.allowAnyEmail) &&
                    <div className="small-6 columns text-left">
                      {(this.state.inviteType === 'selfJoin') &&
                      (numEmailDomains > 1)
                        ? <DropdownList
                          className={numEmailDomains > 1 ? 'input-inline-dropdown' : 'hide'}
                          data={organization.emailDomains}
                          defaultValue={this.state.emailDomain}
                          textField={item => `@${item}`}
                          onSelect={this.handleDomainChange} />
                        : <div className="input-height">&nbsp;{emailDomainText}</div>
                      }
                    </div>
                    }
                  </div>
                  <br />
                  {(this.state.inviteType === 'selfJoin')
                    ?
                    <p>
                      <button
                        type="button"
                        className="button expanded"
                        onClick={this.handleRequestInvite}>Create account
                      </button>
                    </p>
                    :
                    <p>
                      <button
                        type="button"
                        className="button expanded"
                        onClick={this.handleRequestInvite}>Get invite
                      </button>
                    </p>}
                </div>
              </div>
              {(this.state.inviteType === 'selfJoin') && (
                (numEmailDomains > 1)
                  ? <p className="text-center">If you don&#39;t have an email address listed above, contact your admin for an invite.</p>
                  : <p className="text-center">If you don&#39;t have an {emailDomainText} email address, contact your admin for an invite.</p>
              )}
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  location: PropTypes.object.isRequired,
  createInvite: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  clearInvite: PropTypes.func.isRequired,
};

export default SignUp;
