var React = require('react');
var Link = require('react-router').Link;
var PropTypes = require('prop-types');
var moment = require('moment');
var _ = require('lodash');
var EventsList = require('./eventsList.jsx').default;
var EventInputModal = require('../../components/events/containers/eventInputContainer.jsx').default;
var Calendar = require('../../components/events/components/calendarWidget.jsx').default;
var utils = require('../../../../common/utils').default;

class VibeCalendar extends React.PureComponent {
  state = {modalIsOpen: false};

  componentDidMount() {
    const { params, auth, organization, vibes } = this.props;
    const { boardId } = params;
    const { token } = auth;
    const getEvents = true;
    this.props.fetchVibeByIdIfNeeded(
      boardId, organization,
      vibes, auth, token,
      getEvents,
    );
    // this.props.fetchVibeIfNeeded(this.props.params.name, this.props.organization, this.props.vibes, this.props.auth, this.props.auth.token, getEvents);
  }

  componentDidUpdate() {
    const { events: { closeInputModal = false } } = this.props;
    if (closeInputModal === true) {
      this.closeInputModal();
    }
  }

    openEventInputModal = (mode) => {
        this.setState({inputModalIsOpen: true, eventInputMode: mode});
    };

    closeInputModal = () => {
        this.props.clearSelectedEvent();
        this.setState({inputModalIsOpen: false});
    };

    handleMonthChange = (title, intervalStart, intervalEnd) => {
        this.props.setActiveEventFilter(intervalStart, intervalEnd);
    };

    handleChangeRSVP = (eventId, rsvpStatus) => {
        this.props.changeRSVP(eventId, this.props.auth.user._id, rsvpStatus, this.props.organization.data._id, this.props.auth.token);
    };

    render() {
        var auth = this.props.auth;
        var organization = this.props.organization.data;
    	var events = this.props.events.data;
    	var filteredEvents = events;
        var isFetchingVibe = this.props.selectedVibe.isFetching;
    	var isFetchingEvents = this.props.events.isFetching;

        var selectedVibe = this.props.selectedVibe.data;

    	var activeEventFilter = this.props.activeEventFilter;

        var isVibeMember = utils.userIsVibeMember(auth.user.vibes, selectedVibe._id);

        var headerImage = (!organization.preferences.hasOwnProperty("showVibeHeaderImage") || organization.preferences.showVibeHeaderImage) 
            ? "url(" + selectedVibe.imageUrl + ")" 
            : "none";

        if(!isFetchingVibe && !isFetchingEvents) {
            filteredEvents = _.filter(events, function(event) { 
                return moment(event.startDate).isBetween(activeEventFilter.intervalStart,activeEventFilter.intervalEnd);
            });
        }

        return (
            <div>
                <div className="vibe-header-panel" style={{backgroundImage: headerImage}}></div>
                {/* ## BEGIN content area */}
                {/*Object.keys(events).length !== 0 && !isFetching &&*/}
                {/* Removed record check above - instead showing blank calendar */}
                {!isFetchingVibe && !isFetchingEvents &&
                    <div className="row column">
                        <div className="callout vibe-canvas">
                            <div className="row">
                                <div className="large-12 small-12 columns">
                                    {/* Close buttons */}
                                    <Link to={`/board/${selectedVibe._id}`}><i className="fi-arrow-left back-arrow" /></Link>
                                    <Link className="close-button" to={`/board/${selectedVibe._id}`}><span aria-hidden="true"><small>&times;</small></span></Link>                        

                                    <div className="post-container event no-border">
                                        <Calendar 
                                            events={events}
                                            selectedEvent={this.props.selectedEvent}
                                            vibe={selectedVibe}
                                            auth={auth}                                            
                                            organization={this.props.organization} 
                                            joinVibe={this.props.joinVibe}                                            
                                            RSVPclick={this.handleChangeRSVP}
                                            handleMonthChange={this.handleMonthChange}
                                            setSelectedEvent={this.props.setSelectedEvent}
                                            openInputModal={this.openInputModal}
                                            clearSelectedEvent={this.props.clearSelectedEvent}
                                            openEventInputModal={this.openEventInputModal} />
                                    </div>
                                    <EventsList 
                                        mode={'vibe'}
                                        events={filteredEvents} 
                                        auth={auth}
                                        organization={this.props.organization} 
                                        joinVibe={this.props.joinVibe}                                        
                                        RSVPclick={this.handleChangeRSVP}
                                        setSelectedEvent={this.props.setSelectedEvent}
                                        openEventInputModal={this.openEventInputModal} />
                                    {(isVibeMember) &&
                                    <EventInputModal 
                                        mode={this.state.eventInputMode}
                                        inputModalIsOpen={this.state.inputModalIsOpen}
                                        closeInputModal={this.closeInputModal} />}
                                </div>
                                <div className="medium-2 columns hide-for-small-only hide">
                                    {/* ## Future sidebar */}
                                    <button className="button expanded hollow">Action 1</button>
                                    <button className="button expanded">Action 2</button>
                                </div>
                            </div>
                        </div>
                    </div>                  
                }
                {(isFetchingVibe || isFetchingEvents) &&
                    <h1 className="loading">Loading...</h1>
                }
            </div>
        )
    }
}

VibeCalendar.propTypes = {
  fetchEventsIfNeeded: PropTypes.func.isRequired,
  changeRSVP: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  joinVibe: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object,
  activeEventFilter: PropTypes.object.isRequired,
};

export default VibeCalendar;
