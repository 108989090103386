var React = require('react');
var PropTypes = require('prop-types');
var Link = require('react-router').Link;
var Modal = require('react-modal');
var utils = require('../../../../common/utils').default;

class UserVibesSettings extends React.Component {
    state = {
        modalIsOpen: false,
        selectedVibe: null
    };

    openModal = (vibe) => {
        this.setState({
            modalIsOpen: true,
            selectedVibe: vibe
        });
    };

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            selectedVibe: null
        });
    };

    handleVibeButtonClick = () => {
        this.props.leaveVibe(this.props.auth.user._id, this.state.selectedVibe,
                             this.props.organization.data._id, this.props.auth.token);
        this.closeModal();
    };

    render() {
        var that = this;
        var vibes = utils.getUserVibes(this.props.auth.user.vibes, this.props.vibes.data);

        var vibeList = vibes.map(function (vibe, i) {
            return (         
                <div className="column" key={vibe._id}>
                    <div className="row">
                        <div className="medium-4 hide-for-small-only columns"> 
                            <img src={utils.formatImage(vibe.imageUrl,'vibeThumb')} className="belongs-to-vibe-thumb" alt={vibe.title} title={vibe.title}/>                        
                        </div>
                        <div className="small-8 medium-6 columns">
                            <Link to={`/board/${vibe._id}`}><h5 className="settings-vibe-name">{vibe.title}</h5></Link>
                            {vibe.isChampion && <span className="settings-vibe-detail"><i className="fi-trophy"></i> Board Champion</span>}
                            <p>{vibe.description}</p>
                        </div>
                        <div className="small-4 medium-2 columns text-right"><button type="button" className="alert button small" onClick={that.openModal.bind(that, vibe._id)}>Unfollow board</button></div>
                    </div>
                    <hr />
                </div>
            );
        });

        return (
            <div className="callout">
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="modal"
                    overlayClassName="modal-overlay"
                    contentLabel="Leave vibe modal">
                        <div className="text-center" id="leave-vibe" data-reveal>
                            <h2 className="text-left">Unfollow board?</h2>
                            <p>You will no longer get notfications related to this board&#39;s activity.</p>
                            <p className="text-center"><button className="button wide" onClick={this.handleVibeButtonClick}>Unfollow board</button></p>
                            <button className="close-button" onClick={this.closeModal} aria-label="Close reveal" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                </Modal>

                <h4><strong>Your boards</strong></h4>
                <br/>
                <div className="row small-up-1 medium-up-2">
                    {vibeList}
                </div>
            </div>
        );
    }
}

UserVibesSettings.propTypes = {
    leaveVibe: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default UserVibesSettings;
