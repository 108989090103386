import { routerActions } from 'react-router-redux';
import constants from '../constants';
import api from '../../../utils/api';
import vibesActions from '../../vibes/actions/vibes';

const Organization = (function () {
  const _requestOrganization = function () {
    return {
      type: constants.REQUEST_ORGANIZATION,
    };
  };

  const _receiveOrganization = function (organization, viewMode) {
    return {
      type: constants.RECEIVE_ORGANIZATION,
      data: organization,
      viewMode,
    };
  };

  const _fetchOrganizationError = function (error) {
    return {
      type: constants.REQUEST_ORGANIZATION_ERROR,
      errors: error.data,
    };
  };

  const _fetchOrganization = function (teamDomain, accessToken) {
    const mode = (accessToken) ? 'nonPublic' : 'public';
    return function (dispatch) {
      dispatch(_requestOrganization());
      api.get(`/proxy/v1/organizations?teamdomain=${teamDomain}`, {}, accessToken)
        .then((res) => {
          dispatch(_receiveOrganization(res.organization, mode));

          // Trigger vibe refresh when org is fetched
          dispatch(vibesActions.setActivityFlag(true));
        })
        .catch((error) => {
          if (error.response) {
            const { response: { status, data } } = error;
            if (status === 500 && data.message === 'Not connected to MongoDB') {
              dispatch(routerActions.push('/outage'));
            } else if (status === 404) {
              window.location.assign('http://teamvibe.com/no-org');
            } else {
              dispatch(_fetchOrganizationError(data));
            }
          }
        });
    };
  };

  // Depreciated after moving checkToken before checkOrg
  const _fetchOrganizationLoggedIn = function (teamDomain, accessToken) {
    return function (dispatch) {
      dispatch(_requestOrganization());
      api.get(`/proxy/v1/organizations?teamdomain=${teamDomain}`, {}, accessToken)
        .then((res) => {
          if (res.organization !== null) {
            dispatch(_receiveOrganization(res.organization, 'nonPublic'));
          } else {
            window.location.assign('http://teamvibe.com/no-org');
          }
        })
        .catch((res) => {
          dispatch(_fetchOrganizationError(res));
        });
    };
  };

  const _shouldFetchOrganization = function (state, teamDomain, accessToken) {
    const organization = state.organization.data;
    const { viewMode } = state.organization;
    if (Object.keys(organization).length === 0) {
      return true;
    }
    if (accessToken && viewMode === 'public') {
      return true;
      // }  else if (organization.teamDomain !== teamDomain) {
      //    return false;
    }
    return false;
  };

    // var _isOrgSameDomain = function(state, teamDomain) {
    //     var organization = state.organization.data;
    //     if(organization.teamDomain !== teamDomain) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };

  const _fetchOrganizationIfNeeded = function (teamDomain, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchOrganization(getState(), teamDomain, accessToken)) {
        return dispatch(_fetchOrganization(teamDomain, accessToken));
        // if (accessToken) {
        //    return dispatch(_fetchOrganizationLoggedIn(teamDomain, accessToken))
        // } else {
        //    return dispatch(_fetchOrganization(teamDomain))
        // }        return dispatch(_fetchOrganization(teamDomain, accessToken))
        // if (accessToken) {
        //    return dispatch(_fetchOrganizationLoggedIn(teamDomain, accessToken))
        // } else {
        //    return dispatch(_fetchOrganization(teamDomain))
        // }

        // } else {
        // if (!_isOrgSameDomain(getState(), teamDomain) && teamDomain !== 'start') {
        // return dispatch(routerActions.push('/login'));
        // }
      }
      return false;
    };
  };

  const _updateOrganizationState = function (organization, viewMode) {
    return function (dispatch) {
      dispatch(_receiveOrganization(organization, viewMode));
    };
  };

  return {
    fetchOrganizationIfNeeded: _fetchOrganizationIfNeeded,
    fetchOrganization: _fetchOrganization,
    fetchOrganizationLoggedIn: _fetchOrganizationLoggedIn,
    updateOrganizationState: _updateOrganizationState,
  };
}());

export default Organization;
