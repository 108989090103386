const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const _ = require('lodash');
const TagsContainer = require('../../../components/tagsContainer.jsx').default;
const utils = require('../../../../../common/utils').default;

class TagsSelectModal extends React.Component {
  state = {
    userTags: this.props.userTags,
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  handleSubmit = () => {
    const { userCurrentOrg } = this.props;
    let { userTags } = this.state;

    userTags = _.map(userTags, '_id');
    userCurrentOrg.tags = userTags;

    this.props.updateUserOrg(
      this.props.user.data._id,
      this.props.organization.data._id,
      userCurrentOrg,
      this.props.auth.token,
    );
    this.props.closeTagsModal();
  };

  handleTagClick = (tag) => {
    let { userTags } = this.props;
    const tagInUserTags = _.some(userTags, { _id: tag._id });
    userTags = tagInUserTags ? utils.remove(userTags, tag._id) : utils.unshift(userTags, tag);
    this.setState({ userTags });
  };

  render() {
    const orgTags = this.props.tags.data;
    const { user, userTags, viewingUserId, viewingUserIsOrgAdmin } = this.props;

    // Filter tags based on isActive, tagAdmin
    const filteredTags = utils.filterTagsByAdmin(orgTags, viewingUserId, viewingUserIsOrgAdmin);
    const hasIndustryTags = (filteredTags.filter((tag) => tag.isActive && tag.tagType === 'industry')).length > 0 || false;

    return (
      <Modal
        isOpen={this.props.tagsModalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.props.closeTagsModal}
        className="modal small"
        overlayClassName="modal-overlay"
        contentLabel="Tags select modal">
        <div id="tags-select">
          <br />
          <div className="row">
            <div className="small-11 columns end">
              <h3 className="text-center"><i className="fa fa-tags" style={{ color: '#8a8a8a' }} />&nbsp;Manage tags</h3>
              <p className="text-center" style={{ marginBottom: '1rem' }}>Select the tags you would like to assign to this user</p>
              <TagsContainer
                tags={filteredTags}
                userTags={userTags}
                tagType="team"
                mode="select"
                allowDnd={false}
                handleTagClick={this.handleTagClick}
                setSelectedTag={this.props.setSelectedTag}
              />
              {(hasIndustryTags) &&
              <div>
                <hr />
                <TagsContainer
                  tags={filteredTags}
                  userTags={userTags}
                  tagType="industry"
                  mode="select"
                  allowDnd={false}
                  handleTagClick={this.handleTagClick}
                  setSelectedTag={this.props.setSelectedTag}
                />
              </div>}
            </div>
          </div>
          <div className="text-right">
            <button type="button" className="button" onClick={this.handleSubmit}>Save tags</button>
          </div>

          <button className="close-button" onClick={this.props.closeTagsModal} data-close="" aria-label="Close reveal" type="button">
            <span aria-hidden="true"><small>&times;</small></span>
          </button>
        </div>
      </Modal>
    );
  }
}


TagsSelectModal.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userTags: PropTypes.array.isRequired,
  userCurrentOrg: PropTypes.object.isRequired,
  updateUserOrg: PropTypes.func.isRequired,
  setSelectedTag: PropTypes.func.isRequired,
  closeTagsModal: PropTypes.func.isRequired,
  tagsModalIsOpen: PropTypes.bool.isRequired,
};

export default TagsSelectModal;
