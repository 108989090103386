const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const Draft = require('draft-js');
const StateToHTML = require('draft-js-export-html');
const DateTimePicker = require('react-widgets/lib/DateTimePicker');
const videoUtils = require('draft-js-video-plugin/lib/video/utils').default;
const DropdownList = require('react-widgets/lib/DropdownList');
const _ = require('lodash');

const Editor = require('./draftEditor.jsx').default;
const Alert = require('../../../components/alert.jsx').default;
const PostFiles = require('../../posts/components/postFiles').default;
const utils = require('../../../../../common/utils').default;

class DiscussionInputModal extends React.Component {
  state = {
    showAlert: false,
    showUnsavedAlert: false,
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  setFeaturedImage = (src) => {
    const postItem = this.props.post;

    // Only set the first time a video or image src occurs
    if (!postItem.data.newFeaturedImage) {
      postItem.data.newFeaturedImage = src;
      this.props.setPost(postItem.data, false);
    }
  };

  // Should consolidate changeContent and handleChange into 1 function (maybe)
  changeContent = (contentState) => {
    const postItem = this.props.post;

    postItem.data.contentState = contentState;
    if (postItem.error) { delete postItem.error.content; }

    this.props.setPost(postItem.data, true);
  };

  handleChange = (event) => {
    const postItem = this.props.post;

    if (event.target.name === 'callToActionType') {
      const callToAction = postItem.data.callToAction || [];
      callToAction.ctaType = event.target.value;
      postItem.data.callToAction = callToAction;
    } else if (event.target.name === 'callToActionUrl') {
      postItem.data.callToAction.url = event.target.value;
    } else {
      postItem.data[event.target.name] = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    }
    delete postItem.error[event.target.name];

    this.props.setPost(postItem.data, true);
  };

  handleVibeChange = (selectedValue) => {
    const postItem = this.props.post;
    postItem.data.vibe = selectedValue._id;

    this.props.setPost(postItem.data, true);
  };

  handleUploadFeaturedImage = (event) => {
    event.preventDefault();
    const postItem = this.props.post;

    const options = {
      folder: `${this.props.organization.data.teamDomain}/uploads`,
    };

    utils.uploadImage(options, (results, error) => {
      if (results) {
        postItem.data.featuredImage = results.secure_url;
        this.props.setPost(postItem.data, true);
      }
    });
  };

  addAttachment = (upload) => {
    const postItem = this.props.post;
    const attachments = postItem.data.attachments || [];

    const fileType = upload.secure_url.split('.').pop();

    if (fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'pdf' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'xls' ||
      fileType === 'xlsx') {
      const uploadItem = {
        fileType,
        fileName: upload.original_filename,
        path: upload.path,
        secureUrl: upload.secure_url,
        pages: upload.pages,
        width: upload.width,
        height: upload.height,
      };

      attachments.push(uploadItem);
      postItem.data.attachments = attachments;

      this.props.setPost(postItem.data, true);
    }
  };

  handleOnAfterOpenModal = () => {
    // Triggers function in child (draftEditor) to focus
    this.child.focusTextInput();
  };

  handleDeleteClick = () => {
    this.setState({ showAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ showAlert: false, showUnsavedAlert: false });
  };

  handleCreateDiscussion = (postItem) => {
    const { selectedVibe } = this.props;
    const mentions = utils.getMentionsFromContent(postItem.data.content.raw);
    this.props.createDiscussion(
      postItem, this.props.auth.user._id, this.props.organization.data._id,
      selectedVibe.data._id, mentions, this.props.auth.token,
    );
  };

  handleEditDiscussion = (postItem) => {
    const { selectedVibe } = this.props;
    const { data: { _id: boardId } = {} } = selectedVibe;
    this.props.editDiscussion(
      postItem, this.props.organization.data._id,
      boardId, this.props.auth.token,
    );
    // Need this due to the double modal coming from postModal/postItem
    this.handleClose();
  };

  handleDeleteDiscussion = (postItem) => {
    this.handleCloseAlert();
    this.props.deleteDiscussion(
      postItem, this.props.organization.data._id,
      this.props.selectedVibe.data._id, this.props.auth.token,
    );
    this.handleClose();
  };

  handleEditReply = (postItem) => {
    this.props.editReply(postItem, this.props.organization.data._id, this.props.auth.token);
    this.handleClose();
  };

  handleDeleteReply = (postItem) => {
    this.handleCloseAlert();
    this.props.deleteReply(postItem, this.props.organization.data._id, this.props.auth.token);
    this.handleClose();
  };

  handleSubmit = (mode, postType) => {
    const postItem = this.props.post;
    const { selectedVibe } = this.props;
    const errorMessage = {};

    // Set featured image (image or video)
    const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
    const VIMEO_PREFIX = 'https://player.vimeo.com/video/';
    const draftConvertOptions = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'image') {
          this.setFeaturedImage(entity.getData().src);
        }

        if (entityType === 'draft-js-video-plugin-video') {
          const data = entity.getData();
          let url = data.src;
          if (videoUtils.isYoutube(data.src)) {
            const videoID = videoUtils.getYoutubeSrc(data.src).srcID;
            url = `${YOUTUBE_PREFIX}${videoID}`;
            this.setFeaturedImage(`https://img.youtube.com/vi/${videoID}/0.jpg`);
          }
          if (videoUtils.isVimeo(data.src)) {
            const videoID = videoUtils.getVimeoSrc(data.src).srcID;
            url = `${VIMEO_PREFIX}${videoID}`;
            this.setFeaturedImage(`http://res.cloudinary.com/teamvibe/image/vimeo/${videoID}.jpg`);
          }

          return {
            element: 'iframe',
            attributes: {
              src: url,
              width: '560',
              height: '315',
            },
            style: {
              // Put styles here...
            },
          };
        }
        return false;
      },
    };

    // If no changes in edit mode, close and return (fixes issue with no contentState)
    if (mode === 'edit' && postItem.unsaved === false) { this.handleClose(); return; }

    // Send editing state to actions
    postItem.data.content = {
      raw: '',
      rich: '',
    };

    // Post types are 'discussion', 'reply' or 'promo'
    postItem.data.postType = postType;

    // Set vibe if not provided
    postItem.data.vibe = ('vibe' in postItem.data) ? postItem.data.vibe : selectedVibe.data._id;

    // Set blank post title if not provided
    postItem.data.title = postItem.data.title || '';

    // Validate there is some content (either title, text, or image)
    const hasContent = (postItem.data.contentState) ? postItem.data.contentState.hasText() : false;
    const hasTitle = (postItem.data.title) ? postItem.data.title.length >= 0 : false;

    if ((mode === 'create' || mode === 'edit') && !hasContent && !hasTitle) {
      errorMessage.content = { message: 'You must type some content or upload an image before submitting' };
      this.props.setPostError(errorMessage);
      return;
    }

    if (postItem.data.title && (postItem.data.title).length >= 200) {
      errorMessage.content = { message: 'Your title must be less than 200 characters' };
      this.props.setPostError(errorMessage);
      return;
    }

    // Transform content into raw and rich
    if (postItem.data.contentState) {
      postItem.data.content.raw = JSON.stringify(Draft.convertToRaw(postItem.data.contentState));
      postItem.data.content.rich = StateToHTML.stateToHTML(postItem.data.contentState, draftConvertOptions);
    }

    // Swap newFeaturedImage for featuredImage
    postItem.data.featuredImage = (postItem.data.newFeaturedImage) ? postItem.data.newFeaturedImage : '';

    if (mode === 'create' && (postType === 'discussion' || postType === 'promo')) {
      this.handleCreateDiscussion(postItem);
    } else if (mode === 'edit' && (postType === 'discussion' || postType === 'promo')) {
      this.handleEditDiscussion(postItem);
    } else if (mode === 'edit' && postType === 'reply') {
      this.handleEditReply(postItem);
    } else if (mode === 'delete' && (postType === 'discussion' || postType === 'promo')) {
      this.handleDeleteDiscussion(postItem);
    } else if (mode === 'delete' && postType === 'reply') {
      this.handleDeleteReply(postItem);
    }
  };

  handleVerifyClose = () => {
    if (this.props.post.unsaved === true) {
      this.setState({ showUnsavedAlert: true });
    } else {
      this.handleClose();
    }
  };

  handleClose = () => {
    // Post is cleared on delete in actions
    // this.props.clearPost();
    this.setState({ showAlert: false, showUnsavedAlert: false });
    this.props.closeModal();
  };

  render() {
    const { selectedVibe } = this.props;
    const { data: { _id: boardId } = {} } = selectedVibe;
    const userIsOrgAdmin = utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs);

    // Types are 'discussion', 'reply' or 'promo'
    const postType = this.props.type;
    const titleClass = (postType === 'discussion' || postType === 'promo') ? 'input-title' : 'input-title hide';

    // Modes are 'create', 'edit' or 'delete' (delete set by button)
    const { mode } = this.props;
    const buttonLabel = (mode === 'edit') ? '\xa0Publish changes\xa0' : '\xa0\xa0Publish\xa0\xa0';

    const postItem = this.props.post; // Either edit obj or blank discussion
    const post = postItem.data;

    // Error handling
    const postError = postItem.error || {};
    const titleHelp = ((Object.keys(postError).length !== 0) && ('title' in postError)) ? postError.title.message : null;
    const contentHelp = ((Object.keys(postError).length !== 0) && ('content' in postError)) ? postError.content.message : null;
    const urlHelp = ((Object.keys(postError).length !== 0) && ('callToActionUrl' in postError)) ? postError.callToActionUrl.message : null;

    // Populate vars
    // const currentVibe = event.vibe;
    let postAuthor;
    let postAuthorThumb;
    // let postAuthorTitle;
    // let postAuthorLocation;
    // let postAuthorOrg;
    if (post.author) {
      postAuthor = `${post.author.firstName} ${post.author.lastName}`;
      postAuthorThumb = utils.formatImage(post.author.photoUrl, 'userThumb');

      // Get post author user/org data (title, location)
      // postAuthorOrg = post.author.orgs.filter(function (org) {
      //   return org.id === this.props.organization.data._id;
      // }.bind(this));

      // postAuthorTitle = postAuthorOrg[0].title;
      // postAuthorLocation = postAuthorOrg[0].location
      //  ? `, ${postAuthorOrg[0].location.city}, ${postAuthorOrg[0].location.state}`
      //  : '';
    } else {
      postAuthor = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;
      postAuthorThumb = utils.formatImage(this.props.auth.user.photoUrl, 'userThumb');

      // Get logged in user/org data (title, location)
      // const userOrg = this.props.auth.user.orgs.filter(function (org) {
      //   return org.id === this.props.organization.data._id;
      // }.bind(this));

      // postAuthorTitle = userOrg[0].title;
      // postAuthorLocation = userOrg[0].location
      //  ? `, ${userOrg[0].location.city}, ${userOrg[0].location.state}`
      //  : '';
    }

    // Build vibe list
    let vibeList = (this.props.auth.user.vibes)
      ? utils.getUserVibesCanPost(this.props.auth.user.vibes, this.props.vibes.data, userIsOrgAdmin)
      : [];
    vibeList = _.sortBy(vibeList, ['title']);
    const postVibe = (post.vibe) ? post.vibe : boardId;

    const headerCopy = (mode === 'create') ? 'Create offer/promotion' : null;
    const postTitle = post.title;
    const postTitlePlaceholder = (postType === 'promo')
      ? 'Type your promo headline here'
      : 'Title (optional)';
    let postContent = null;
    if (Object.keys(post).length !== 0) {
      postContent = post.content;
    }
    const postContentPlaceholder = (postType === 'promo')
      ? 'Type the details of your promo here. You can add links and images too.'
      : 'Write your post here or paste a link to an article, video, or tweet.';

    const ctaType = (post.callToAction) ? post.callToAction.ctaType : 'None';

    const modalClass = (postType === 'promo') ? 'modal small' : 'modal';

    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onAfterOpen={this.handleOnAfterOpenModal}
        onRequestClose={this.handleVerifyClose}
        className={modalClass}
        overlayClassName="modal-overlay"
        contentLabel="Discussion input modal">

        {/* 'Are you sure' alert */}
        <Alert
          name="alert_pending"
          type="alert callout text-center"
          autoClose={false}
          handleClose={this.handleCloseAlert}
          showAlert={this.state.showAlert}>
          <h4>Are you sure?</h4>
          <p>Once deleted, the post can not be recovered.</p>
          <div className="margin-top-15">
            <button
              type="button"
              className="alert button"
              onClick={this.handleSubmit.bind(this, 'delete', postType)}>Confirm deletion
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button
              type="button"
              className="success button"
              onClick={this.handleCloseAlert}>Keep post
            </button>
          </div>
        </Alert>

        {/* Confirm if unsaved content */}
        <Alert
          name="alert_pending"
          type="alert callout text-center"
          autoClose={false}
          handleClose={this.hideUpdateAlert}
          showAlert={this.state.showUnsavedAlert}>
          <h4>You have unsaved content in your post.</h4>
          <p>Would you like to discard or continue editing?</p>
          <div className="margin-top-15">
            <button type="button" className="alert button" onClick={this.handleClose}>Discard changes</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="success button" onClick={this.handleCloseAlert}>Continue editing</button>
          </div>
        </Alert>

        <div>
          {/* Close buttons */}
          <i className="fi-arrow-left back-arrow" onClick={this.handleVerifyClose} />
          <button className="close-button" onClick={this.handleVerifyClose} aria-label="Close reveal" type="button">
            <span aria-hidden="true"><small>&times;</small></span>
          </button>

          {/* Main input area */}
          <article className="post-container discussion no-border margin-top-5">

            {/* Action buttons */}
            <div className="clearfix">
              <div className="float-right">
                {mode === 'edit' ?
                  <span>
                    <button
                      type="button"
                      className="button alert"
                      onClick={this.handleDeleteClick}>Delete post
                    </button>&nbsp;&nbsp;&nbsp;
                  </span>
                  :
                  <span>
                    {/* <button
                          className="button hollow"
                          onClick={this.handleSubmit.bind(this, 'draft', postType)}>Save as draft
                        </button>&nbsp;&nbsp;&nbsp; */}
                  </span>
                }
                <button
                  type="button"
                  className="button wide"
                  onClick={this.handleSubmit.bind(this, mode, postType)}>{buttonLabel}
                </button>
              </div>
              {postType === 'promo'
                ?
                <h5><strong>{headerCopy}</strong></h5>
                :
                <div className="media-object">
                  <div className="media-object-section-tight">
                    <img
                      className="image-thumb"
                      src={postAuthorThumb}
                      onError={utils.showDefaultImage}
                      alt={postAuthor} />
                  </div>
                  <div className="media-object-section">
                    <span><strong>{postAuthor}</strong> in</span><br />
                    <span className="post-meta">
                      {(vibeList) &&
                    <DropdownList
                      className="no-margin input-selector"
                      data={vibeList}
                      value={postVibe}
                      valueField="_id"
                      textField="title"
                      onChange={this.handleVibeChange}
                    />}
                    </span>
                    {/* <span className="post-meta">{postAuthorTitle}{postAuthorLocation}</span> */}
                  </div>
                </div>
              }
            </div>

            {/* Featured image (promo only) */}
            {(postType === 'promo') &&
              <div>
                {(post.featuredImage) ?
                  <img id="imageOutput" className="post-promo-image" src={post.featuredImage} alt="Vibe" />
                  :
                  <div style={{ width: '100%', background: '#dadada', height: '200px', paddingTop: '70px' }}>
                    <button
                      type="button"
                      className="button secondary float-center"
                      onClick={this.handleUploadFeaturedImage}>Upload featured image
                    </button>
                  </div>
                }
                <br />
              </div>
            }

            {/* Post title */}
            <div id="discussion-add">
              <input
                type="text"
                className={titleClass}
                name="title"
                defaultValue={postTitle}
                onChange={this.handleChange}
                placeholder={postTitlePlaceholder} />
            </div>

            {/* Call to action (promo posts only */}
            {(postType === 'promo') &&
            <div className="margin-top-15">
              <hr />
              {(post.callToAction && (ctaType !== 'None')) &&
              <div className="row">
                <div className="small-8 columns small-centered">
                  <a
                    href={post.callToAction.url}
                    target="promo"
                    className="button large expanded no-margin">{ctaType}
                  </a>
                </div>
              </div>
              }
              <div className="row margin-top-15">
                <div className="small-4 columns">
                  <label><p><strong>Call to action</strong> <small>(optional)</small></p>
                    <select name="callToActionType" value={ctaType} onChange={this.handleChange}>
                      <option value="None">None</option>
                      <option value="Learn more">Learn more button</option>
                      <option value="Download">Download</option>
                      {/* <option value="Code">Coupon/discount code</option> */}
                    </select>
                  </label>
                </div>
                <div className="small-8 columns">
                  {(ctaType !== 'None') &&
                  <label><p><strong>{ctaType} URL</strong></p>
                    <input
                      type="text"
                      name="callToActionUrl"
                      placeholder="https://www.myoffer.com"
                      onChange={this.handleChange} />
                    <span className="input-error large">{urlHelp}</span>
                  </label>
                  }
                </div>
              </div>
              {(ctaType !== 'None' && ctaType === 'Disable expiration date for now') &&
                <div className="row">
                  <div className="small-4 columns">
                    <label><p><strong>Expires on</strong></p>
                      <DateTimePicker
                        name="startDateOnly"
                        // value={eventStartDateOnly}
                        // onChange={this.handleChange.bind(null, '0', 'startDateOnly')}
                        time={false}
                        footer={false}
                        format="M/D/YYYY"
                        dateFormat="D"
                        placeholder="Pick a date" />
                    </label>
                  </div>
                  <div className="small-4 columns">
                    <label><p>&nbsp;</p>
                      <DateTimePicker
                        name="startTimeOnly"
                        // value={eventStartTimeOnly}
                        // onChange={this.handleChange.bind(null, '0', 'startTimeOnly')}
                        calendar={false}
                        placeholder="Time" />
                    </label>
                  </div>
                  <div className="small-4 columns">
                    <h5>&nbsp;</h5>
                  </div>
                </div>
              }
              <hr />
            </div>
            }

            {/* Main content body */}
            <div>

              {/* <textarea
                  className="input-textarea"
                  name="content"
                  defaultValue={postContent}
                  onChange={this.handleChange}
                  placeholder="Write your post here or paste in a link to an article, video or tweet."
                  rows="21"
                  ref="topic_content" ></textarea> */}
              <Editor
                ref={(instance) => { this.child = instance; }}
                className="post-create-edit-box"
                placeholder={postContentPlaceholder}
                changeContent={this.changeContent}
                content={postContent}
                organization={this.props.organization}
                addAttachment={this.addAttachment}
                users={this.props.organization.members.data}
              />
            </div>

            <ul className="no-bullet no-margin">
              <li><span className="input-error large">{titleHelp}</span></li>
              <li><span className="input-error large">{contentHelp}</span></li>
            </ul>

            <PostFiles post={post} setPost={this.props.setPost} mode="edit" />
          </article>
        </div>
      </Modal>
    );
  }
}

DiscussionInputModal.propTypes = {
  type: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  createDiscussion: PropTypes.func.isRequired,
  editDiscussion: PropTypes.func.isRequired,
  deleteDiscussion: PropTypes.func.isRequired,
  editReply: PropTypes.func.isRequired,
  deleteReply: PropTypes.func.isRequired,
  setPost: PropTypes.func.isRequired,
  setPostError: PropTypes.func.isRequired,
  clearPost: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DiscussionInputModal;
