import { routerActions } from 'react-router-redux';
import constants from '../constants';
import api from '../../../utils/api';
import authLogout from './auth-logout';

const VisitVibe = (function () {
  const _visitVibeRequest = function () {
    return {
      type: constants.VISIT_VIBE_REQUEST,
    };
  };

  const _visitVibeSuccess = function (data) {
    return {
      type: constants.VISIT_VIBE_SUCCESS,
      user: data.user,
    };
  };

  const _visitVibeError = function (error) {
    return {
      type: constants.VISIT_VIBE_ERROR,
      errors: error.data,
    };
  };

  const _visitVibe = function (vibeId, userId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_visitVibeRequest());
      api.put(`/proxy/v1/vibes/${vibeId}/visit`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_visitVibeSuccess(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_visitVibeError(res));
          }
        });
    };
  };

  return {
    visitVibe: _visitVibe,
  };
}());

export default VisitVibe;
