var React = require('react');
var PropTypes = require('prop-types');

var EventsWidget = require('../../../components/events/containers/eventsContainer.jsx').default;
var VibeMembers = require('../../../components/users/containers/userWidget.jsx').default;
var VibeInfo = require('../layout/vibeInfo.jsx').default;

class LayoutCalendar extends React.PureComponent {
    render() {
		var selectedVibe = this.props.selectedVibe.data;

		var leftSectionClass = "large-8 small-12 columns";
		var rightSectionClass = "large-4 small-12 columns";

		return (
			<div>
				<div className="row">
					<div className={leftSectionClass}>
						<EventsWidget
						organization={this.props.organization} 
						selectedVibe={this.props.selectedVibe}
						auth={this.props.auth}
						view="full" />
						
					</div>
					<div className={rightSectionClass}>
						<VibeInfo 
						organization={this.props.organization} 
						selectedVibe={this.props.selectedVibe} 
						auth={this.props.auth}
						post={this.props.post}
						setPost={this.props.setPost}
						clearPost={this.props.clearPost}
						updateVibeOverview={this.props.updateVibeOverview}
						userIsVibeChampion={this.props.userIsVibeChampion}
						userIsOrgAdmin={this.props.userIsOrgAdmin}/>	 

						<VibeMembers 
						className={(!selectedVibe.showMemberList || selectedVibe.allUser) && 'hide'}
						organization={this.props.organization} 
						selectedVibe={this.props.selectedVibe} 
						auth={this.props.auth}/>
					</div>
				</div>
			</div>
		)
	}
}

LayoutCalendar.propTypes = {
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    selectedVibe: PropTypes.object.isRequired
};

export default LayoutCalendar;
