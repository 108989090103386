import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import loginUtils from '../../auth/utils/LoginUtils';
import loginActions from '../../auth/actions/auth-login';

const Registration = (function () {
  const _signUpError = function (error) {
    return {
      type: constants.REGISTRATION_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.REGISTRATION_ERROR,
      errors,
    };
  };

  const _clearRegError = function () {
    return {
      type: constants.CLEAR_REGISTRATION_ERROR,
    };
  };

  const _redirect = (token, inviteType) => (dispatch) => {
    if (inviteType === 'joinOrg' || inviteType === 'selfJoin' || inviteType === 'magicLink') {
      dispatch(routerActions.push(`/profile-details?invite=${token}`));
    } else if (inviteType === 'createOrg') {
      dispatch(routerActions.push(`/org-create?invite=${token}`));
    }
  };

  const _loginOrRegister = (username, password, invitation, org) => async (dispatch) => {
    const { token, sender, inviteType } = invitation;
    const { teamDomain } = org;

    try {
      const validate = await loginUtils.validate(username, password);
      if (validate !== true) {
        dispatch(_validationError(validate));
        return;
      }

      // First check for exisiting account
      const currentAccount = await api.get('/proxy/v1/users/validate', { username });
      const { accountExists = false, isPending = false } = currentAccount;

      // If no account, create one
      // If account isPending, activate it
      // Backend function creates new or overwrites pending accounts
      if (!accountExists ||
          (accountExists && isPending)) {
        await api.post('/proxy/v1/users', {
          username,
          password,
          org,
          token,
          sender,
        });
      }

      // Login user
      const tokenData = await api.post('/proxy/oauth/token', {
        username,
        password,
        grant_type: 'password',
      });

      dispatch(loginActions.setCookie(tokenData));
      dispatch(loginActions.loginSuccess(tokenData));
      dispatch(_redirect(token, inviteType));
    } catch (err) {
      dispatch(loginActions.loginError(err.response));
      return;
    }
  };

  // DEPRECIATED
  const _signUp = function (email, password, invitation, org) {
    const { token, inviteType } = invitation;
    return function (dispatch) {
      loginUtils.validatePassword(password)
        .then((result) => {
          if (result === true) {
            api.post('/authproxy', {
              username: email,
              password,
              token,
              org: {
                id: org._id,
                teamDomain: org.teamDomain,
                orgName: org.name,
              },
            })
              .then((res) => {
                dispatch(loginActions.setCookie(res));
                dispatch(loginActions.loginSuccess(res));
                if (inviteType === 'joinOrg' || inviteType === 'selfJoin' || inviteType === 'magicLink') {
                  dispatch(routerActions.push(`/profile-details?invite=${token}`));
                } else if (inviteType === 'createOrg') {
                  dispatch(routerActions.push(`/org-create?invite=${token}`));
                }
              })
              .catch((res) => {
                dispatch(_signUpError(res.response));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    loginOrRegister: _loginOrRegister,
    signUp: _signUp,
    clearRegError: _clearRegError,
  };
}());

export default Registration;
