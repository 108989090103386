import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TagsActions from '../../../../modules/tags/actions/tags';
import AddTagActions from '../../../../modules/tags/actions/addTag';
import CreateTagActions from '../../../../modules/tags/actions/createTag';
import EditTagActions from '../../../../modules/tags/actions/editTag';
import tagsInput from '../components/tagsInputModal.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    tags: state.tags,
    selectedTag: state.selectedTag,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({}, TagsActions, AddTagActions, CreateTagActions, EditTagActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(tagsInput);
