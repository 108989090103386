const React = require('react');
const PropTypes = require('prop-types');
const { ReactiveComponent } = require('@appbaseio/reactivesearch');
const _ = require('lodash');

const utils = require('../../../common/utils').default;

class SearchSentry extends React.Component {
  customQuery = () => {
    // const { auth: { user: { vibes: userVibes } } } = this.props;
    const {
      auth: { user: authUser },
      organization: { data: organizationData },
      vibes: { data: vibesData },
      tags: { data: tagsData },
    } = this.props;

    // const userVibesArray = _.map(userVibes, 'vibe');
    const orgId = organizationData._id;
    let allowedVibes = vibesData;

    // Filter vibe list for user location
    // Show vibes if no tags selected, user location matches, or isOrgAdmin
    const userCurrentOrg = _.find(authUser.orgs, { id: organizationData._id });
    const userTags = utils.getUserTags(userCurrentOrg, tagsData);
    const userlocationTag = _.find(userTags, { tagType: 'location' });
    const isOrgAdmin = utils.userIsOrgAdmin(organizationData._id, authUser.orgs);

    if (userlocationTag) {
      allowedVibes = _.filter(allowedVibes, (vibe) => {
        const vibeTags = utils.getVibeTags(vibe, tagsData);
        if ((_.filter(vibeTags, { tagType: 'location' })).length === 0 ||
          _.some(vibeTags, { _id: userlocationTag._id, orgId: organizationData._id }) ||
          isOrgAdmin) {
          return vibe;
        }
        return false;
      });
    }

    const allowedVibesArray = _.map(allowedVibes, '_id');

    return {
      query: {
        constant_score: {
          filter: {
            bool: {
              should: [
                {
                  bool: {
                    must: [
                      { term: { searchType: 'user' } },
                      { match: { 'orgs.id': orgId } },
                    ],
                  },
                },
                {
                  bool: {
                    must: [
                      { term: { searchType: 'discussion' } },
                      { terms: { vibe: allowedVibesArray } },
                      { match: { isActive: true } },
                    ],
                  },
                },
                {
                  bool: {
                    must: [
                      { term: { searchType: 'vibe' } },
                      { terms: { id: allowedVibesArray } },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    };
  };

  render() {
    const { componentId } = this.props;

    return (
      <ReactiveComponent
        componentId={componentId}
        customQuery={this.customQuery}
      />
    );
  }
}

SearchSentry.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  vibes: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  componentId: PropTypes.string.isRequired,
};

export default SearchSentry;
