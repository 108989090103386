import constants from '../constants';
import api from '../../../utils/api';

const CreateTag = (function () {
  const _createTagRequest = function () {
    return {
      type: constants.CREATE_TAG_REQUEST,
    };
  };

  const _createTagSuccess = function (data) {
    return {
      type: constants.CREATE_TAG_SUCCESS,
      tag: data.tag,
    };
  };

  const _createTagError = function (error) {
    return {
      type: constants.CREATE_TAG_ERROR,
      errors: error.data,
    };
  };

    // var _validationError = function(errors) {
    //     return {
    //         type: constants.CREATE_TAG_ERROR,
    //         errors: errors
    //     };
    // };

  const _createTag = function (tag, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_createTagRequest());
      api.post(`/proxy/v1/organizations/${orgId}/tag/`, {
        tag: tag.data,
      }, accessToken)
        .then((res) => {
          dispatch(_createTagSuccess(res));
        })
        .catch((res) => {
          dispatch(_createTagError(res));
        });
    };
  };

  return {
    createTag: _createTag,
  };
}());

export default CreateTag;
