import ReactSelect from 'react-select';

const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');
const indicative = require('indicative');
const utils = require('../../utils').default;

const Indicative = new indicative();

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    const { authUser } = props;

    this.state = {
      firstName: authUser.firstName || '',
      lastName: authUser.lastName || '',
      location: null,
      locationHelp: '',
      industries: [],
      industryHelp: '',
    };
  }

  handleUploadPhotoClick = (event) => {
    event.preventDefault();

    const options = {
      folder: 'users',
    };

    utils.uploadImage(options, (results, error) => {
      if (results) {
        this.profilePhoto.src = utils.formatImage(results.secure_url, 'userThumb');
        this.photoUrl.value = results.secure_url;
      }
    });
  };

  handleLocationChange = (selectedValue) => {
    const { _id: locationId } = selectedValue;
    this.setState({ location: locationId, locationHelp: '' });
  };

  handleIndustryChange = (selections) => {
    const industryIds = _.map(selections, '_id');
    this.setState({ industries: industryIds, industryHelp: '' });
  };

  userDataChange = (event) => {
    const currentState = this.state;
    const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    currentState[event.target.name] = inputValue;
    delete currentState[`${event.target.name}Help`];
    this.setState(currentState);
  }

  clearError = (event) => {
    const currentState = this.state;
    delete currentState[`${event.target.name}Help`];
    this.setState(currentState);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      authUser, organizationData,
      tags, presetLocations,
    } = this.props;
    const userOrgData = {
      tags: [],
    };

    // Filter tags by type
    const locationTags = tags.filter(tag => tag.tagType === 'location' && tag.isActive);
    const industryTags = tags.filter(tag => tag.tagType === 'industry' && tag.isActive);

    const { firstName, lastName } = this.state;
    let error = false;

    // Validate firstName, lastName, title
    if (!firstName || firstName.length < 1 || firstName.length > 30) {
      this.setState({ firstNameHelp: 'Must be between 1-30 characters' });
      error = true;
    }

    if (!lastName || lastName.length < 1 || lastName.length > 30) {
      this.setState({ lastNameHelp: 'Must be between 1-30 characters' });
      error = true;
    }

    // If locations dropdown shows, make sure user selects an option before continuing
    if (locationTags && locationTags.length > 1 && presetLocations.length === 0 && this.state.location === null) {
      this.setState({ locationHelp: 'Please select your primary location' });
      error = true;
    }

    const title = this.title ? this.title.value : '';
    if (organizationData.type === 'company' && (title.length < 1 || title.length > 60)) {
      this.setState({ titleHelp: 'Must be between 1-60 characters' });
      error = true;
    }

    if (organizationData.preferences && organizationData.preferences.showExternalOrg) {
      const externalOrgName = this.externalOrg ? this.externalOrg.value : '';
      const externalOrgUrl = this.externalOrgUrl ? this.externalOrgUrl.value : '';
      if (externalOrgName.length > 60) {
        this.setState({ externalOrgNameHelp: 'Max of 60 characters' });
        error = true;
      }
      if (externalOrgUrl && !Indicative.is.url(externalOrgUrl)) {
        this.setState({ externalOrgUrlHelp: 'Please enter a valid Url format (ex. http://www.google.com)' });
        error = true;
      }
    }

    // Validate termsAccepted (if customTOS.requireConsent)
    const termsAccepted = this.termsAccepted ? this.termsAccepted.checked : false;
    if (organizationData.customTOS && organizationData.customTOS.isActive && organizationData.customTOS.requireConsent && !termsAccepted) {
      this.setState({ termsAcceptedHelp: 'You must accept the terms to continue' });
      error = true;
    }

    // Escape if any validation errors. Must occur before assigning vars below.
    if (error) { return false; }

    // Populate user top-level data
    if (firstName && lastName) {
      authUser.firstName = _.upperFirst(firstName);
      authUser.lastName = _.upperFirst(lastName);
    }
    authUser.photoUrl = this.photoUrl ? this.photoUrl.value : '';

    if (presetLocations.length > 0) {
      // Preset locations hides input and auto-populates
      // Note: Locations is an array even if there is only 1 location (future support for multiple)
      userOrgData.tags = _.union(userOrgData.tags, presetLocations);
    } else if (locationTags && locationTags.length === 1) {
      // If only 1 location, don't show dropdown and populate single location
      userOrgData.tags.push(locationTags[0]);
    } else if (this.state.location) {
      // else populate with user selection
      userOrgData.tags.push(this.state.location);
    }

    if (industryTags && industryTags.length > 0) {
      const { industries = [] } = this.state;
      userOrgData.tags.push(...industries);
    }

    userOrgData.title = this.title ? this.title.value : '';

    // If terms have been explictly accepted, log date of acceptance
    if (termsAccepted) {
      userOrgData.termsAcceptedOn = new Date();
    }

    if (organizationData.preferences && organizationData.preferences.showExternalOrg) {
      const externalOrg = {};
      externalOrg.orgName = this.externalOrg ? this.externalOrg.value : '';
      externalOrg.orgUrl = this.externalOrgUrl ? this.externalOrgUrl.value : '';
      userOrgData.externalOrg = externalOrg;
    }

    // Otherwise send back to orgJoin
    return this.props.createProfile(authUser, userOrgData);
  };

  render() {
    const {
      authUser, organizationData,
      tags, presetLocations = [],
    } = this.props;
    const photoUrl = authUser.photoUrl || 'https://res.cloudinary.com/teamvibe/image/upload/product/blank-profile-picture.png';

    const {
      firstName, lastName,
      firstNameHelp, lastNameHelp,
    } = this.state;
    // const { firstName: initialFirstName, lastName: initialLastName, orgs = [] } = authUser;

    // Check for existing user/org
    const { orgs = [] } = authUser;
    const currentOrg = orgs.find((userOrg) => userOrg.id === organizationData._id) || {};
    const { externalOrg: { orgName = '', orgUrl = '' } = {} } = currentOrg;

    // Filter tags by type
    const locationTags = tags.filter(tag => tag.tagType === 'location' && tag.isActive);
    const industryTags = tags.filter(tag => tag.tagType === 'industry' && tag.isActive);

    return (
      <form onSubmit={this.handleSubmit}>
        <p>{this.props.formLabel}</p>
        {/* {((firstNameHelp || lastNameHelp) || (!initialFirstName && !initialLastName)) && */}
        <div className="row">
          <div className="small-6 columns">
            <label><p><strong>Your name</strong></p>
              <input
                className="input"
                type="text"
                name="firstName"
                placeholder="First name"
                onChange={this.userDataChange}
                value={firstName} />
              <span className="input-error">{firstNameHelp}</span>
            </label>
          </div>
          <div className="small-6 columns">
            <label><p>&nbsp;</p>
              <input
                className="input"
                type="text"
                name="lastName"
                placeholder="Last name"
                onChange={this.userDataChange}
                value={lastName} />
              <span className="input-error">{lastNameHelp}</span>
            </label>
          </div>
        </div>
        {/* } */}
        <br />

        {(locationTags && locationTags.length > 1 && presetLocations.length === 0) &&
            <div>
              <label><p><strong>Location</strong></p></label>
              <ReactSelect
                ref={(e) => { this.location = e; }}
                classNamePrefix="react-select"
                menuPosition="fixed"
                placeholder="Select your location"
                options={locationTags}
                getOptionLabel={option => option.displayName}
                getOptionValue={option => option._id}
                onChange={option => this.handleLocationChange(option)}
              />
              <span className="input-error">{this.state.locationHelp}</span>
              <br />
            </div>
        }

        {organizationData.type === 'company' &&
            <label><p><strong>Job title</strong></p>
              <input
                type="text"
                className="input"
                name="title"
                id="user-title"
                placeholder="Enter your job title"
                ref={(e) => { this.title = e; }}
                onChange={this.clearError}
                defaultValue={this.props.socialProfile ? this.props.socialProfile.title : null} />
              <span className="input-error">{this.state.titleHelp}</span>
              <br />
            </label>
        }

        {(industryTags && industryTags.length > 0) &&
            <div>
              <label><p><strong>Industries</strong></p></label>
              <ReactSelect
                ref={(e) => { this.industries = e; }}
                classNamePrefix="react-select"
                menuPosition="fixed"
                placeholder="Select all industries that apply"
                isMulti={true}
                options={industryTags}
                getOptionLabel={option => option.displayName}
                getOptionValue={option => option._id}
                onChange={option => this.handleIndustryChange(option)}
              />
              <span className="input-error">{this.state.industryHelp}</span>
              <br />
            </div>
        }

        {(organizationData.preferences && organizationData.preferences.showExternalOrg) &&
            <div className="row">
              <div className="small-6 columns">
                <label><p><strong>Company/Organization</strong></p>
                  <input
                    type="text"
                    className="input"
                    name="externalOrgName"
                    placeholder="Dunder Mifflen, Inc."
                    defaultValue={orgName}
                    ref={(e) => { this.externalOrg = e; }}
                    onChange={this.clearError} />
                  <span className="input-error">{this.state.externalOrgNameHelp}</span>
                </label>
                <br />
              </div>
              <div className="small-6 columns">
                <label><p><strong>URL</strong> <small>(optional)</small></p>
                  <input
                    type="text"
                    className="input"
                    name="externalOrgUrl"
                    placeholder="https://dundermifflen.com"
                    defaultValue={orgUrl}
                    onChange={this.clearError}
                    ref={(e) => { this.externalOrgUrl = e; }} />
                  <span className="input-error">{this.state.externalOrgUrlHelp}</span>
                </label>
              </div>
            </div>
        }

        <div className="row columns">
          <label><p><strong>Your photo</strong></p>
            <div id="thumb" className="float-left">
              <div>
                <img
                  className="image-thumb large"
                  ref={(e) => { this.profilePhoto = e; }}
                  src={utils.formatImage(photoUrl, 'userThumb')}
                  alt="Vibe" />
              </div>
            </div>
            <div className="float-left">
              <span>&nbsp;&nbsp;</span>
              <button type="button" id="opener" className="button hollow" onClick={this.handleUploadPhotoClick}>Upload/change photo</button>
            </div>
            <input
              type="hidden"
              name="photoUrl"
              ref={(e) => { this.photoUrl = e; }}
              defaultValue={photoUrl} />
          </label>
        </div>

        {(organizationData.customTOS && organizationData.customTOS.isActive) &&
          <div className="row columns">
            <br />
            <div>
              {organizationData.customTOS.requireConsent &&
                <span>
                  <input
                    type="checkbox"
                    name="termsAccepted"
                    ref={(e) => { this.termsAccepted = e; }}
                    onClick={this.userDataChange}
                    className="no-margin" />&nbsp;&nbsp;
                </span>
              }
              <span dangerouslySetInnerHTML={{ __html: organizationData.customTOS.copy }} />
            </div>
            <div className="input-error" style={{ top: 0 }}>{this.state.termsAcceptedHelp}</div>
          </div>
        }

        <div className="row columns">
          <br />
          <div className="text-center">
            <button type="submit" className="button large">{this.props.buttonText}</button>
          </div>
        </div>
      </form>
    );
  }
}

ProfileForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  organizationData: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  socialProfile: PropTypes.object,
  formLabel: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  createProfile: PropTypes.func.isRequired,
  locationTags: PropTypes.array,
  presetLocations: PropTypes.array.isRequired,
};

export default ProfileForm;
