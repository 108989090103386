import constants from '../constants';

const AddTag = (function () {
  const _addTag = function (tag) {
    return {
      type: constants.ADD_TAG,
      tag,
    };
  };

  return {
    addTag: _addTag,
  };
}());

export default AddTag;
