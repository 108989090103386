import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const TagItem = (props) => {
  const { tag: { displayName, tagAdmin, _id: tagId }, active, pending } = props;

  return (
    <tr>
      <td>{displayName}</td>
      <td className="text-center">{active + pending}</td>
      <td className="text-center">{active}</td>
      <td className="text-center">{pending}</td>
    </tr>
  );
};


const UserCounts = () => {
  // Import state vars
  const { organization, tags } = useSelector(
    (state) => ({
      organization: state.organization,
      tags: state.tags,
    }),
  );

  const { data: { _id: orgId } } = organization;
  const { members: { data: orgUsers } } = organization;
  const { data: tagsData } = tags;
  const locationTags = tagsData.filter((tag) => tag.tagType === 'location') || [];
  const sortedLocations = _.orderBy(locationTags, 'displayName');

  let userCount = 0;
  let activeCount = 0;
  let pendingCount = 0;

  const tagList = sortedLocations.map((tag) => {
    const locationActive = orgUsers.filter((user) => {
      const userOrg = (user.orgs).find((org) => org.id === orgId);

      return (userOrg.tags).find((userOrgTag) => userOrgTag === tag._id) && !user.isPending;
    }) || [];
    const locationPending = orgUsers.filter((user) => {
      const userOrg = (user.orgs).find((org) => org.id === orgId);

      return (userOrg.tags).find((userOrgTag) => userOrgTag === tag._id) && user.isPending;
    }) || [];

    activeCount += locationActive.length;
    pendingCount += locationPending.length;
    userCount = (activeCount + pendingCount);
    return (
      <TagItem
        tag={tag}
        key={tag._id}
        active={locationActive.length}
        pending={locationPending.length} />
    );
  });

  return (
    <div className="row columns">
      <br />
      <h3>User report</h3>
      <table>
        <thead>
          <tr>
            <th width="200">Location</th>
            <th className="text-center" width="100">Total users</th>
            <th className="text-center" width="100">Active</th>
            <th className="text-center" width="100">Pending</th>
          </tr>
        </thead>
        <tbody>
          {tagList}
          <tr>
            <td width="200"><strong>Total:</strong></td>
            <td className="text-center"><strong>{userCount}</strong></td>
            <td className="text-center"><strong>{activeCount}</strong></td>
            <td className="text-center"><strong>{pendingCount}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TagItem.propTypes = {
  tag: PropTypes.object.isRequired,
};

UserCounts.propTypes = {

};

export default UserCounts;
