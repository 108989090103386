import constants from '../constants';
import api from '../../../utils/api';

const SendGroupEmail = (function () {
  const _requestGroupEmail = function () {
    return {
      type: constants.REQUEST_GROUP_EMAIL,
    };
  };

  const _groupEmailSuccess = function (data) {
    return {
      type: constants.GROUP_EMAIL_SUCCESS,
      tag: data.tag,
    };
  };

  const _groupEmailError = function (error) {
    return {
      type: constants.GROUP_EMAIL_ERROR,
      errors: error.data,
    };
  };

  const _sendGroupEmail = function (recipients, email, organization, accessToken) {
    return function (dispatch) {
      dispatch(_requestGroupEmail());
      api.post('/proxy/v1/users/email/', {
        recipients, email, orgId: organization._id,
      }, accessToken)
        .then((res) => {
          dispatch(_groupEmailSuccess(res));
        })
        .catch((res) => {
          dispatch(_groupEmailError(res));
        });
    };
  };

  const _clearGroupEmail = function () {
    return {
      type: constants.CLEAR_GROUP_EMAIL,
    };
  };

  return {
    sendGroupEmail: _sendGroupEmail,
    clearGroupEmail: _clearGroupEmail,
  };
}());

export default SendGroupEmail;
