import constants from '../constants';
import api from '../../../utils/api';

const Subscriptions = (function () {
  const _requestSubscriptions = function () {
    return {
      type: constants.REQUEST_SUBSCRIPTIONS,
    };
  };

  const _receiveSubscriptions = function (data) {
    return {
      type: constants.RECEIVE_SUBSCRIPTIONS,
      subscriptions: data.subscriptions,
    };
  };

  const _fetchSubscriptionsError = function (error) {
    return {
      type: constants.REQUEST_SUBSCRIPTIONS_ERROR,
      errors: error.data,
    };
  };

  const _fetchSubscriptions = function (userId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestSubscriptions());
      api.get(`/proxy/v1/subscriptions/${userId}/${orgId}`, {}, accessToken)
        .then((res) => {
          dispatch(_receiveSubscriptions(res));
        })
        .catch((res) => {
          dispatch(_fetchSubscriptionsError(res));
        });
    };
  };

  const _shouldFetchSubscriptions = function (state) {
    const subscriptions = state.subscriptions.data;
    if (Object.keys(subscriptions).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchSubscriptionsIfNeeded = function (userId, orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchSubscriptions(getState())) {
        return dispatch(_fetchSubscriptions(userId, orgId, accessToken));
      }
      return false;
    };
  };

  return {
    fetchSubscriptionsIfNeeded: _fetchSubscriptionsIfNeeded,
    fetchSubscriptions: _fetchSubscriptions,
  };
}());

export default Subscriptions;
