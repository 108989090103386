const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const PropTypes = require('prop-types');
const RouterActions = require('react-router-redux').routerActions;
const PreviousRouteActions = require('../../../modules/auth/actions/previousRoute').default;

const requiresAuth = function (Component) {
  class AuthenticatedComponent extends React.Component {
    componentDidMount() {
      this.checkAndRedirect();
    }

    checkAndRedirect = () => {
      const { auth } = this.props;

      if (!auth.token) {
        this.props.push('/login?err=6');
      }
    };

    render() {
      return (
        <div className="authenticated">
          { (Object.keys(this.props.auth.user).length !== 0 && this.props.auth.token)
            ? <Component {...this.props} />
            : null }
        </div>
      );
    }
  }

  AuthenticatedComponent.propTypes = {
    auth: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
      previousRoute: state.previousRoute,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, RouterActions, PreviousRouteActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
};

export default requiresAuth;
