var React = require( 'react' );
var marked = require('marked');

class CleanMarkdown extends React.PureComponent {
    rawMarkup = () => {
        var rawMarkup = marked(this.props.payload.toString(), {sanitize: true});
        return { __html: rawMarkup };
    };

    render() {

        // console.log("MARKDOWN:" + JSON.stringify(this.props));
        return (<span dangerouslySetInnerHTML={this.rawMarkup()}/>);
    }
}

export default CleanMarkdown;