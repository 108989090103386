const React = require('react');
const ReactRedux = require('react-redux');
const { bindActionCreators } = require('redux');
const RouterActions = require('react-router-redux').routerActions;
const PropTypes = require('prop-types');
const cookie = require('react-cookie');
const tldjs = require('tldjs');
const AuthLogin = require('../../../modules/auth/actions/auth-login').default;
const AuthCurrentUser = require('../../../modules/auth/actions/auth-current-user').default;

const tld = tldjs.fromUserSettings({
  validHosts: ['localhost'],
});

const checkToken = function (Component) {
  class CheckTokenComponent extends React.Component {
      state = { allowRender: false };

      componentDidMount() {
        this.checkTokenStatus();
      }

      componentWillReceiveProps(nextProps) {
        if (!nextProps.auth.isFetching && nextProps.auth.token &&
            (nextProps.auth !== this.props.auth)) {
          // Once auth is complete, fetchCurrentUser and then let them through
          if (Object.keys(nextProps.auth.user).length !== 0) {
            this.setState({ allowRender: true });
          } else {
            this.props.fetchCurrentUserIfNeeded(nextProps.auth.token);
          }
        } else if (!nextProps.auth.isFetching && !nextProps.auth.token) {
          // Fetch complete but no token (likely expired refresh token)
          this.setState({ allowRender: true });
        }
      }

      checkTokenStatus = () => {
        let domain = '';
        if (typeof document !== 'undefined') {
          domain = tld.getDomain(document.location.hostname);
        }

        const tvCookieAt = cookie.load('tv_at');
        const tvCookieRt = cookie.load('tv_rt');
        const accessToken = (tvCookieAt) ? tvCookieAt.accessToken : undefined;
        const refreshToken = (tvCookieRt) ? tvCookieRt.refreshToken : undefined;

        // const previousRoute = this.props.location.search ? this.props.location.pathname + this.props.location.search : this.props.location.pathname;

        if (this.props.location.query.accessToken && this.props.location.query.refreshToken) {
          // refreshToken from query params
          // console.log("QUERY");
          this.props.refreshToken(this.props.location.query.refreshToken);

          if (!this.props.location.query.invite) {
            // Clean token params from url (use this.props.location to edit query before redirect)
            delete this.props.location.query['accessToken'];
            delete this.props.location.query['refreshToken'];
            delete this.props.location.query['expires'];
            this.props.push(this.props.location);
          }
        } else if (accessToken) {
          // Use cookie/accessToken to login
          // console.log("COOKIE");

          // This transfers the refreshToken from the old cookie to a new one
          // This should only run one time and can be removed down the road
          if (refreshToken === undefined && tvCookieAt.refreshToken) {
            const rtExpiration = 31536000; // 1 year
            if (domain === 'localhost') {
              cookie.save('tv_rt', { refreshToken: tvCookieAt.refreshToken },
                { path: '/', maxAge: rtExpiration });
            } else {
              cookie.save('tv_rt', { refreshToken: tvCookieAt.refreshToken },
                { domain: '.teamvibe.com', path: '/', maxAge: rtExpiration });
            }
          }

          this.props.loginViaCookie(accessToken);
        } else if (refreshToken) {
          // Use refreshToken to get new accessToken and login
          // console.log("REFRESH")

          this.props.refreshToken(refreshToken);
        } else {
          // If no token or cookie, let them through (public view)
          // console.log("ELSE");

          this.setState({ allowRender: true });
        }
      };

      render() {
        return (
          <div className="authenticated">
            {
              (this.state.allowRender === true)
                ? <Component {...this.props} />
                : null
            }

          </div>
        );
      }
  }

  CheckTokenComponent.propTypes = {
    auth: PropTypes.object,
    refreshToken: PropTypes.func.isRequired,
    fetchCurrentUserIfNeeded: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  const mapStateToProps = function (state) {
    return {
      auth: state.auth,
    };
  };

  const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(Object.assign({}, AuthLogin, AuthCurrentUser, RouterActions), dispatch);
  };

  return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(CheckTokenComponent);
};

export default checkToken;
