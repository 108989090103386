import constants from '../constants';
import api from '../../../utils/api';
import authLogout from './auth-logout';

const User = (function () {
  const _requestCurrentUser = function () {
    return {
      type: constants.REQUEST_CURRENT_USER,
    };
  };

  const _receiveCurrentUser = function (data) {
    return {
      type: constants.RECEIVE_CURRENT_USER,
      data: data.user,
    };
  };

  const _fetchCurrentUserError = function (error) {
    return {
      type: constants.REQUEST_CURRENT_USER_ERROR,
      errors: error.data,
    };
  };

  const _fetchCurrentUser = function (accessToken) {
    return function (dispatch) {
      dispatch(_requestCurrentUser());
      api.get('/proxy/v1/users', {}, accessToken)
        .then((res) => {
          dispatch(_receiveCurrentUser(res));
        })
        .catch((res) => {
          dispatch(_fetchCurrentUserError(res));
          dispatch(authLogout.logout());
        });
    };
  };

  const _shouldFetchCurrentUser = function (state) {
    const { user } = state.auth;
    if (Object.keys(user).length === 0) {
      return true;
    }

    return false;
  };

  const _fetchCurrentUserIfNeeded = function (accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchCurrentUser(getState())) {
        return dispatch(_fetchCurrentUser(accessToken));
      }

      return false;
    };
  };

  return {
    fetchCurrentUserIfNeeded: _fetchCurrentUserIfNeeded,
  };
}());

export default User;
