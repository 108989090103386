var React = require('react');
var PropTypes = require('prop-types');
var Modal = require('react-modal');

class GoogleSyncModal extends React.Component {
    state = {
        showAlert: false,
        calendarTitle: "Teamvibe"
    };

    changeTitle = () => {
        this.setState({ calendarTitle: encodeURIComponent(this.refs.calendarTitle.value) });
    };

    handleOnAfterOpenModal = () => {
        //this.refs.calendarTitle.focus();
    };

    handleClose = () => {
        this.props.closeGoogleModal();
    };

    handleUnsyncGoogleClick = () => {
        this.props.removeSocialIdentity(this.props.auth.user._id, this.props.organization.data._id, 'google', this.props.auth.token);
        this.props.showUnsyncAlert();
        this.handleClose();
    };

    render() {

        return (
            <Modal
                isOpen={this.props.googleModalIsOpen}
                onAfterOpen={this.handleOnAfterOpenModal}
                onRequestClose={this.handleClose}
                className="modal tiny"
                overlayClassName="modal-overlay"
                contentLabel="Google sync modal">

                <div>
                    {/* Close buttons */}
                    <button className="close-button" onClick={this.handleClose} aria-label="Close reveal" type="button">
                        <span aria-hidden="true"><small>&times;</small></span>
                    </button>

                    {/* Main input area */}
                    <div className="media-object stack-for-small">
                        <div className="media-object-section">
                            <img style={{ maxWidth: '120px' }} src="https://res.cloudinary.com/teamvibe/image/upload/v1488040383/product/icon-google-calendar.png" alt="Google Calendar" />
                        </div>
                        <div className="media-object-section">
                            <h4><strong>Sync to Google calendar</strong></h4>
                            {(!this.props.isGoogleSynced) ?
                                <div>
                                    <p>Connect your Google calendar account to automatically sync your event RSVPs. You can customize the name of your calendar within Google.</p>
                                    <p>Calendar name: <input className="input" ref="calendarTitle" defaultValue="Teamvibe" type="text" onChange={this.changeTitle} /></p>
                                    <div><a className="button round" href={'/oauth/google?teamdomain='+this.props.organization.data.teamDomain+'&orgId='+this.props.organization.data._id+'&username='+this.props.auth.user.username+'&calendarTitle='+this.state.calendarTitle}>Sync to Google Calendar<i/></a></div>
                                </div>
                            :
                                <div>
                                    <p>Your Google account is already connected</p>
                                    <p>If you would like to un-sync your account, <a onClick={this.handleUnsyncGoogleClick}>click here</a></p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

GoogleSyncModal.propTypes = {
    type: PropTypes.string
};

export default GoogleSyncModal;
