import constants from '../constants';
import initialState from '../../initialState';

const invitation = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.CREATE_INVITATION_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.CREATE_INVITATION_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.error = {};
    return newstate;
  case constants.CREATE_INVITATIONS_SUCCESS:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.error = {};
    return newstate;
  case constants.CREATE_INVITATION_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_INVITATIONS_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.REQUEST_INVITATION:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_INVITATION:
    newstate.isFetching = false;
    newstate.data = action.data;
    newstate.error = {};
    return newstate;
  case constants.REQUEST_INVITATION_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_INVITE:
    newstate.data = {};
    newstate.error = {};
    return newstate;
  case constants.CLEAR_GET_INVITE_ERROR:
    newstate.error = {};
    return newstate;
  default:
    return state || initialState.invitation;
  }
};

export default invitation;
