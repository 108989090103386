import constants from '../constants';
import initialState from '../../initialState';

const UserFilter = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.SET_USER_FILTER:
    newstate.tags = action.tags;
    newstate.filterType = action.filterType;
    return newstate;
  case constants.CLEAR_USER_FILTER:
    newstate.tags = [];
    newstate.filterType = 'ANY';
    return newstate;
  default: return state || initialState.userFilter;
  }
};

export default UserFilter;
