const React = require('react');
const PropTypes = require('prop-types');

class SurveyChoices extends React.Component {
  state = {

  };

  render() {
    const { questionChoices } = this.props;

    return (
      <div className="row">
        <div className="small-12 columns">
          <p id="questionChoice">{questionChoices}</p>
        </div>
      </div>
    );
  }
}

SurveyChoices.propTypes = {

};

export default SurveyChoices;
