import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const UpdateUserOrganization = (function () {
  const _updateUserOrgRequest = function () {
    return {
      type: constants.UPDATE_USER_ORG_REQUEST,
    };
  };

  const _updateUserOrgSuccess = function (data) {
    return {
      type: constants.UPDATE_USER_ORG_SUCCESS,
      user: data.user,
    };
  };

  const _updateUserOrgError = function (error) {
    return {
      type: constants.UPDATE_USER_ORG_ERROR,
      errors: error.data,
    };
  };

  const _updateUserOrg = function (userId, orgId, userOrg, accessToken) {
    return function (dispatch) {
      dispatch(_updateUserOrgRequest());
      api.put(`/proxy/v1/users/${userId}/org/${orgId}`, {
        orgId,
        userOrg,
      }, accessToken)
        .then((res) => {
          dispatch(_updateUserOrgSuccess(res));
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_updateUserOrgError(res.response));
          }
        });
    };
  };

  return {
    updateUserOrg: _updateUserOrg,
  };
}());

export default UpdateUserOrganization;
