import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toaster from 'toasted-notes';

const Toaster = (props) => {
  const {
    showAlert = false, message, children,
    position = 'top', duration = 4000,
  } = props;

  const toasterMessage = children || <h5>{message}</h5>;

  // Trigger toaster on component load or props change
  useEffect(() => {
    if (showAlert) {
      toaster.notify(({ onClose }) => (
        <button type="button" onClick={onClose}>
          <div className="notification toaster is-success is-light">
            <i className="delete" />
            {toasterMessage}
          </div>
        </button>
      ), {
        position,
        duration,
      });
    }
  }, [showAlert, toasterMessage, position, duration]);

  return (null);
};


Toaster.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Toaster;
