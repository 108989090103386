import constants from '../constants';
import api from '../../../utils/api';

const EditOrganization = (function () {
  const _editOrganizationRequest = function () {
    return {
      type: constants.EDIT_ORGANIZATION_REQUEST,
    };
  };

  const _editOrganizationSuccess = function (data) {
    return {
      type: constants.EDIT_ORGANIZATION_SUCCESS,
      organization: data,
    };
  };

  const _editOrganizationError = function (error) {
    return {
      type: constants.EDIT_ORGANIZATION_ERROR,
      errors: error.data,
    };
  };

  const _editOrganization = function (organization, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_editOrganizationRequest());
      api.put(`/proxy/v1/organizations/${orgId}`, {
        organization,
      }, accessToken)
        .then((res) => {
          dispatch(_editOrganizationSuccess(res));
        })
        .catch((res) => {
          dispatch(_editOrganizationError(res));
        });
    };
  };

  return {
    editOrganization: _editOrganization,
  };
}());

export default EditOrganization;
