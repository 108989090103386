const React = require('react');
const PropTypes = require('prop-types');

class Auth extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

Auth.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Auth;
