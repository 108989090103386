const indicative = require('indicative');

const Indicative = new indicative();

const loginUtils = (function () {
  const _validate = function (email, password) {
    const data = {
      email,
      password,
    };

    const rules = {
      email: 'required|email',
      password: 'required|min:8|max:30',
    };

    const messages = {
      'email.required': 'Email address is required',
      'email.email': 'Email address is not vaild',
      'password.required': 'Password is required',
      'password.min': 'Password must have a minimum length of 8 characters',
      'password.max': 'Password must have a maximum length of 30 characters',
    };

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => true)
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }

        return errorMessage;
      });
  };

  const _validatePassword = function (password) {
    const data = {
      password,
    };

    const rules = {
      password: 'required|min:8|max:30',
    };

    const messages = {
      'password.required': 'Password is required',
      'password.min': 'Password must have a minimum length of 8 characters',
      'password.max': 'Password must have a maximum length of 30 characters',
    };

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => true)
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }

        return errorMessage;
      });
  };

  // Removed from createUserOrg action - instead doing manual validations in profileForm.jsx
  const _validateCreateProfile = function (firstName, lastName, title, externalOrg) {
    const data = {
      firstName,
      lastName,
      title,
    };

    const rules = {
      firstName: 'required|max:30',
      lastName: 'required|max:30',
      title: 'max:60',
    };

    if (externalOrg) {
      rules.externalOrgName = 'max:50';
      rules.externalOrgUrl = 'url';
    }

    const messages = {
      'firstName.required': 'First name is required',
      'firstName.max': 'Maximum of 30 characters',
      'lastName.required': 'Last name is required',
      'lastName.max': 'Maximum of 30 characters',
      'title.required': 'Job title is required',
      'title.max': 'Job title must have a maximum length of 60 characters',
      'externalOrgName.max': 'Maximum of 50 characters',
      'externalOrgUrl.url': 'Please enter a valid Url',
    };

    if (externalOrg) {
      data.externalOrgName = externalOrg.orgName;
      data.externalOrgUrl = externalOrg.orgUrl;
    }

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => true)
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }

        return errorMessage;
      });
  };

  const _validateEditProfile = function (user) {
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
    };

    const rules = {
      firstName: 'required|max:30',
      lastName: 'required|max:30',
      title: 'max:30',
    };

    if (user.externalOrg) {
      rules.externalOrgName = 'max:50';
      rules.externalOrgUrl = 'url';
    }

    const messages = {
      'firstName.required': 'First name is required',
      'firstName.max': 'First name must have a maximum length of 30 characters',
      'lastName.required': 'Last name is required',
      'lastName.max': 'Last name must have a maximum length of 30 characters',
      'title.required': 'Job title is required',
      'title.max': 'Job title must have a maximum length of 30 characters',
      'externalOrgName.max': 'Company/Org has a max length of 50 characters',
      'externalOrgUrl.url': 'Please enter a valid Url',
    };

    if (user.externalOrg) {
      data.externalOrgName = user.externalOrg.orgName;
      data.externalOrgUrl = user.externalOrg.orgUrl;
    }

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => true)
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }

        return errorMessage;
      });
  };

  return {
    validate: _validate,
    validatePassword: _validatePassword,
    validateCreateProfile: _validateCreateProfile,
    validateEditProfile: _validateEditProfile,
  };
}());

export default loginUtils;
