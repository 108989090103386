import constants from '../constants';
import initialState from '../../initialState';

const activeEventFilter = function (state, action) {
  const newstate = Object.assign({},state);
  switch (action.type) {
  case constants.SET_ACTIVE_EVENT_FILTER:
    newstate.intervalStart = action.intervalStart;
    newstate.intervalEnd = action.intervalEnd;
    return newstate;
  case constants.SET_ACTIVE_EVENT_TYPE_FILTER:
    newstate.filterType = action.filterType;
    return newstate;

  default: return state || initialState.activeEventFilter;
  }
};

export default activeEventFilter;
