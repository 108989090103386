import constants from '../constants';
import initialState from '../../initialState';

const registration = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REGISTRATION_ERROR:
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_REGISTRATION_ERROR:
    newstate.error = {};
    return newstate;
  case constants.REQUEST_PASSWORD_RESET_REQUEST:
    return newstate;
  case constants.REQUEST_PASSWORD_RESET_SUCCESS:
    return newstate;
  case constants.REQUEST_PASSWORD_RESET_ERROR:
    return newstate;
  case constants.PASSWORD_RESET_VALIDATION_REQUEST:
    return newstate;
  case constants.PASSWORD_RESET_VALIDATION_SUCCESS:
    return newstate;
  case constants.PASSWORD_RESET_VALIDATION_ERROR:
    return newstate;
  case constants.PASSWORD_RESET_SUCCESS:
    return newstate;
  case constants.PASSWORD_RESET_ERROR:
    newstate.error = action.errors;
    return newstate;
  default:
    return state || initialState.registration;
  }
};

export default registration;
