import constants from '../constants';
import api from '../../../utils/api';
import vibeChampionActions from '../../vibes/actions/vibe-champions';

const EditVibeUser = (function () {
  const _editVibeUserRequest = function () {
    return {
      type: constants.EDIT_VIBE_USER_REQUEST,
    };
  };

  const _editVibeUserSuccess = function (res) {
    return {
      type: constants.EDIT_VIBE_USER_SUCCESS,
      user: res.user,
    };
  };

  const _editVibeUserError = function (error) {
    return {
      type: constants.EDIT_VIBE_USER_ERROR,
      errors: error.response.data,
    };
  };

  const _editVibeUser = function (userId, vibe, orgId, isChampion, isActive, organizationData, adminFullName, accessToken) {
    const vibeId = vibe._id;
    return function (dispatch) {
      dispatch(_editVibeUserRequest());
      api.put(`/proxy/v1/users/${userId}/vibe`, {
        vibeId,
        vibeTags: (('orgOptions' in vibe) ? vibe.orgOptions.tags : []),
        isChampion,
        isActive,
        orgTeamDomain: organizationData.teamDomain,
        orgName: organizationData.name,
        orgLogo: organizationData.logo,
        vibeTitle: vibe.title,
        adminFullName,
        orgId,
      }, accessToken)
        .then((res) => {
          if (isChampion === true) {
            dispatch(vibeChampionActions.addChampionToVibe(res, userId, vibeId));
          } else {
            dispatch(vibeChampionActions.removeChampionFromVibe(userId, vibeId));
          }

          dispatch(_editVibeUserSuccess(res));
        })
        .catch((err) => {
          dispatch(_editVibeUserError(err));
        });
    };
  };

  return {
    editVibeUser: _editVibeUser,
  };
}());

export default EditVibeUser;
