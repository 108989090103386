import React from 'react';

const Outage = () => (
  <div className="row columns small-9 small-centered has-text-centered margin-top-15">
    <img
      src="http://res.cloudinary.com/teamvibe/image/upload/v1485804786/email/Teamvibe-logo-wide.png"
      width="200"
      alt="Teamvibe service outage" />
    <br />
    <br />
    <h2>Temporary service outage</h2>
    <br />
    <p>We are currently experiencing a service outage with one of our providers. We are working to resolve the issue and restore service as fast as possible.</p>
    <p>
      If you have any questions in the meantime, please email us at&nbsp;
      <a href="mailto:support@teamvibe.com?subject=Service%20Outage">support@teamvibe.com</a>
    </p>
  </div>
);


export default Outage;
