import _ from 'lodash';

import constants from '../constants';
import api from '../../../utils/api';
import visitVibeActions from '../../auth/actions/visitVibe';
import vibesActions from '../../vibes/actions/vibes';
import eventUtils from '../utils/eventUtils';

const CreateEvent = (function () {
  const _createEventRequest = function () {
    return {
      type: constants.CREATE_EVENT_REQUEST,
    };
  };

  const _createEventSuccess = function (data, vibe, state) {
    let events = state.events.data;

    // Add the vibe object since it returns from Maple as just vibeId
    const { event } = data;
    event.vibe = vibe;

    if (state.selectedVibe.data._id) {
      if (state.selectedVibe.data._id === vibe._id) {
        events = events.concat(event);
      }
    } else {
      events = events.concat(event);
    }
    events = _.sortBy(events, 'startDate');

    return {
      type: constants.CREATE_EVENT_SUCCESS,
      events,
    };
  };

  const _createEventError = function (error) {
    return {
      type: constants.CREATE_EVENT_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.CREATE_EVENT_ERROR,
      errors,
    };
  };

    // var _clearError = function() {
    //    return {
    //        type: constants.CLEAR_EVENT_ERROR
    //    };
    // };

  const _createEvent = function (event, userId, orgId, accessToken) {
    return function (dispatch, getState) {
      eventUtils.validateEvent(event, event.vibe._id)
        .then((result) => {
          if (result === true) {
            const eventItem = event;
            eventItem.organizer = userId;
            dispatch(_createEventRequest());
            api.post('/proxy/v1/events/', {
              event: eventItem,
              vibeId: eventItem.vibe._id,
              orgId,
            }, accessToken)
              .then((res) => {
                dispatch(_createEventSuccess(res, eventItem.vibe, getState()));
                dispatch(visitVibeActions.visitVibe(eventItem.vibe._id, userId, orgId, accessToken));
                dispatch(vibesActions.setActivityFlag(true));
              })
              .catch((res) => {
                dispatch(_createEventError(res));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    createEvent: _createEvent,
  };
}());

export default CreateEvent;
