const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');

class rsvpButtons extends React.Component {
  handleRSVPClick = (event) => {
    const rsvpStatus = event.target.value;
    this.props.RSVPclick(this.props.eventId, rsvpStatus);
  };

  render() {
    return (
      <div className="button-group toggle small" data-toggle="buttons-radio">
        <input
          type="radio"
          id={`${this.props.eventId}-yes`}
          data-toggle="button" value="going"
          onChange={this.handleRSVPClick}
          checked={_.includes(this.props.rsvp, 'going')} />
        <label className="button" htmlFor={`${this.props.eventId}-yes`}>Attending</label>

        <input
          type="radio"
          id={`${this.props.eventId}-maybe`}
          data-toggle="button"
          value="maybe"
          onChange={this.handleRSVPClick}
          checked={_.includes(this.props.rsvp, 'maybe')} />
        <label className="button" htmlFor={`${this.props.eventId}-maybe`}>Maybe</label>

        <input
          type="radio"
          id={`${this.props.eventId}-no`}
          data-toggle="button"
          value="declined"
          onChange={this.handleRSVPClick}
          checked={_.includes(this.props.rsvp, 'declined')} />
        <label className="button" htmlFor={`${this.props.eventId}-no`}>Not attending</label>
      </div>
    );
  }
}

rsvpButtons.propTypes = {
  // event: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  rsvp: PropTypes.object,
  RSVPclick: PropTypes.func.isRequired,
};

export default rsvpButtons;
