const React = require('react');
const PropTypes = require('prop-types');
const { withRouter } = require('react-router');

const DiscussionsListItem = require('./discussionsListItem.jsx').default;
const DiscussionInputModal = require('../containers/discussionInputContainer.jsx').default;
const PostModal = require('../../posts/containers/postModalContainer.jsx').default;
const utils = require('../../../../../common/utils').default;
// const Editor = require('./draftEditor.jsx').default;

class DiscussionsList extends React.PureComponent {
  state = {
    inputModalIsOpen: false,
    postModalIsOpen: false,
    inputMode: 'create',
    inputType: 'discussion',
  };

  componentDidMount() {
    this.props.fetchDiscussionsIfNeeded(
      this.props.selectedVibe.data._id, this.props.organization.data._id,
      this.props.auth.token,
    );

    if (this.props.postId) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedVibe.data._id !== this.props.selectedVibe.data._id) {
      nextProps.fetchDiscussionsIfNeeded(nextProps.selectedVibe.data._id, nextProps.organization.data._id, nextProps.auth.token);
    }

    if (nextProps.postId) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }
  }

  componentDidUpdate() {
    // Closes input modal after create or edit
    if (this.props.discussions.closeModal === true) {
      this.props.discussions.closeModal = false;
      this.closeInputModal();
    }

    // Closes input modal after edit (discussion)
    if (this.props.discussion.closeModal === true) {
      this.props.discussion.closeModal = false;
      this.closeInputModal();
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  openInputModal = () => {
    this.setState({ inputModalIsOpen: true });
  };

  closeInputModal = () => {
    // this.props.clearPost();
    this.setState({ inputModalIsOpen: false });
  };

  openPostModal = () => {
    this.setState({ postModalIsOpen: true });
  };

  closePostModal = () => {
    const { selectedVibe: { data: { _id: boardId } } } = this.props;
    this.setState({ postModalIsOpen: false });
    this.props.router.push(`/board/${boardId}`);
  };

  openInputModal = (inputMode, inputType) => {
    this.setState({
      floatMenuIsOpen: false,
      inputModalIsOpen: true,
      inputMode,
      inputType,
    });
  };

  closeInputModal = () => {
    this.setState({ inputModalIsOpen: false });
  };

  render() {
    const selectedVibe = this.props.selectedVibe.data;
    const { isFetching, data: discussions } = this.props.discussions;

    // Loading
    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    const isVibeMember = utils.userIsVibeMember(this.props.auth.user.vibes, this.props.selectedVibe.data._id);
    const isVibeChampion = utils.userIsVibeChampion(this.props.auth.user.vibes, this.props.selectedVibe.data._id);
    const isOrgAdmin = utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs);

    const discussionList = this.props.discussions.data.map(discussion => (
      <DiscussionsListItem
        id={discussion._id}
        boardId={selectedVibe._id}
        key={discussion._id}
        discussion={discussion}
        createdAt={discussion.meta.latestPost ? discussion.meta.latestPost : discussion.createdAt}
        lastPosterId={discussion.meta.authorId}
        lastPosterName={discussion.meta.authorName}
        lastPosterPhotoUrl={discussion.meta.authorPhotoUrl}
        editDiscussion={this.props.editDiscussion} />));

    return (
      <div>
        <PostModal
          postId={this.props.postId}
          postModalIsOpen={this.state.postModalIsOpen}
          closePostModal={this.closePostModal}
          openInputModal={this.openInputModal}
          closeInputModal={this.closeInputModal} />
        {/* <Editor /> */}
{/*        {(isVibeMember && ((isVibeChampion || isOrgAdmin) || (selectedVibe.hasOwnProperty('memberPosting')
          ? selectedVibe.memberPosting
          : true)))
          ? (
            <div className="">
              <button type="button" className="button new-post" onClick={this.openInputModal.bind(null, 'create', 'discussion')}>
                <i className="fi-plus" /> New discussion
              </button>
              <DiscussionInputModal
                type={this.state.inputType}
                mode={this.state.inputMode}
                auth={this.props.auth}
                organization={this.props.organization}
                selectedVibe={this.props.selectedVibe}
                modalIsOpen={this.state.inputModalIsOpen}
                closeModal={this.closeInputModal}
                cleanMentions={this.props.cleanMentions} />
            </div>)
          : null}
*/}
        <DiscussionInputModal
          type={this.state.inputType}
          mode={this.state.inputMode}
          auth={this.props.auth}
          organization={this.props.organization}
          selectedVibe={this.props.selectedVibe}
          modalIsOpen={this.state.inputModalIsOpen}
          closeModal={this.closeInputModal}
          cleanMentions={this.props.cleanMentions} />
        {(Object.keys(discussions).length !== 0)
          ? discussionList
          : (
            <div className="callout">
              <br /><br />
              <p className="text-center">No current posts<br />Why don&#39;t you follow this board and start one now?</p>
              <br /><br />
            </div>)
        }
      </div>
    );
  }
}

DiscussionsList.propTypes = {
  router: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  discussions: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  fetchDiscussionsIfNeeded: PropTypes.func.isRequired,
  editDiscussion: PropTypes.func,
};

export default withRouter(DiscussionsList);
