var React = require('react');
var PropTypes = require('prop-types');

var PostItem = require('./postItem.jsx').default;

class PostReplies extends React.Component {
    state = {
        reply: {},
        modalIsOpen: false
    };

    openModal = (item) => {
        this.setState({
            reply: item,
            modalIsOpen: true
        });
    };

    closeModal = () => {
        this.setState({modalIsOpen: false});
    };

    render() {
        var auth = this.props.auth;
        var organization = this.props.organization;
        var modalLocation = this.props.modalLocation;
        var openModal = this.openModal;
        var reactPost = this.props.reactPost;
        var userIsVibeMember = this.props.userIsVibeMember;
        var userIsVibeChampion = this.props.userIsVibeChampion;
        var userIsOrgAdmin = this.props.userIsOrgAdmin;
        var userIsTagAdmin = this.props.userIsTagAdmin;
        var openDiscussionInputModal = this.props.openDiscussionInputModal;        

        var itemList = this.props.items.map(function (item, i) {
        	item.postType = "reply"
            return (
                <PostItem 
                    key={item._id}
                    item={item} 
                    modalLocation={modalLocation}
                    auth={auth}
                    organization={organization}
                    userIsVibeMember={userIsVibeMember}
                    userIsVibeChampion={userIsVibeChampion}
                    userIsOrgAdmin={userIsOrgAdmin}
                    userIsTagAdmin={userIsTagAdmin}
                    openDiscussionInputModal={openDiscussionInputModal}
                    openModal={openModal} 
                    reactPost={reactPost}
                />
            );
        }.bind(this));
        return (
            <span>
                {itemList}
            </span>
        )
    }
}

PostReplies.propTypes = {
    reactPost: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    vibe: PropTypes.string,
    organization: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

export default PostReplies;
