import _ from 'lodash';

import constants from '../constants';
import initialState from '../../initialState';
import utils from '../../../utils';

const discussions = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_DISCUSSIONS:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_DISCUSSIONS:
    newstate.isFetching = false;
    newstate.data = action.discussions;
    return newstate;
  case constants.APPEND_DISCUSSIONS:
    newstate.isFetching = false;
    newstate.data.push.apply(...action.discussions);
    return newstate;
  case constants.REQUEST_DISCUSSIONS_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.CREATE_POST_REQUEST:
    newstate.isFetching = true;
    return newstate;
  case constants.CREATE_POST_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.unshift(newstate.data, action.post);
    newstate.data = _.orderBy(
      newstate.data, [
        function (o) { return o.isPinned || false; },
        function (o) { return o.meta.latestPost || false; },
      ],
      ['desc', 'desc'],
    );
    newstate.closeModal = true;
    return newstate;
  case constants.CREATE_CROSS_POST_SUCCESS:
    newstate.closeModal = true;
    newstate.isFetching = false;
    return newstate;
  case constants.CREATE_POST_ERROR:
    newstate.isFetching = false;
    newstate.error = action.errors;
    return newstate;
  case constants.CLEAR_DISCUSSION_ERROR:
    newstate.isFetching = false;
    newstate.error = {};
    return newstate;
  case constants.EDIT_POST_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.upsert(state.data, action.post._id, action.post);
    newstate.data = _.orderBy(
      newstate.data, [
        function (o) { return o.isPinned || false; },
        function (o) { return o.meta.latestPost || false; },
      ],
      ['desc', 'desc'],
    );
    return newstate;
  case constants.MOVE_POST_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.post._id);
    newstate.closeModal = true;
    return newstate;
  case constants.DELETE_DISCUSSION_SUCCESS:
    newstate.isFetching = false;
    newstate.data = utils.remove(state.data, action.discussion);
    newstate.closeModal = true;
    return newstate;
  default: return state || initialState.discussions;
  }
};

export default discussions;
