import constants from '../constants';

const UserDetails = (function () {
  const _setUserFilter = function (tags, filterType) {
    const initialFilter = filterType || 'ANY';
    return {
      type: constants.SET_USER_FILTER,
      tags,
      filterType: initialFilter,
    };
  };

  const _clearUserFilter = function () {
    return {
      type: constants.CLEAR_USER_FILTER,
    };
  };

  return {
    setUserFilter: _setUserFilter,
    clearUserFilter: _clearUserFilter,
  };
}());

export default UserDetails;
