import constants from '../constants';
import api from '../../../utils/api';

const SocialIdentity = (function () {
  const _addSocialIdentityRequest = function () {
    return {
      type: constants.ADD_SOCIAL_IDENTITY_REQUEST,
    };
  };

  const _removeSocialIdentityRequest = function () {
    return {
      type: constants.REMOVE_SOCIAL_IDENTITY_REQUEST,
    };
  };

  const _addSocialIdentitySuccess = function (data) {
    return {
      type: constants.ADD_SOCIAL_IDENTITY_SUCCESS,
      data: data.user,
    };
  };

  const _removeSocialIdentitySuccess = function (data) {
    return {
      type: constants.REMOVE_SOCIAL_IDENTITY_SUCCESS,
      data: data.user,
    };
  };

  const _addSocialIdentityError = function (error) {
    return {
      type: constants.ADD_SOCIAL_IDENTITY_FAILURE,
      errors: error.data,
    };
  };

  const _removeSocialIdentityError = function (error) {
    return {
      type: constants.REMOVE_SOCIAL_IDENTITY_FAILURE,
      errors: error.data,
    };
  };

  const _addSocialIdentity = function (username, orgId, provider, tokens, accessToken) {
    return function (dispatch) {
      dispatch(_addSocialIdentityRequest());
      const identity = {
        provider,
        tokens,
      };
      api.post('/proxy/v1/oauth/identity', {
        username,
        orgId,
        identity,
      }, accessToken)
        .then((res) => {
          dispatch(_addSocialIdentitySuccess(res));
        })
        .catch((res) => {
          dispatch(_addSocialIdentityError(res));
        });
    };
  };

  const _removeSocialIdentity = function (userId, orgId, provider, accessToken) {
    return function (dispatch) {
      dispatch(_removeSocialIdentityRequest());
      api.post('/proxy/v1/oauth/identity/remove', {
        orgId,
        provider,
      }, accessToken)
        .then((res) => {
          dispatch(_removeSocialIdentitySuccess(res));
        })
        .catch((res) => {
          dispatch(_removeSocialIdentityError(res));
        });
    };
  };

  return {
    addSocialIdentity: _addSocialIdentity,
    removeSocialIdentity: _removeSocialIdentity,
  };
}());

export default SocialIdentity;
