var React = require('react');
var PropTypes = require('prop-types');

var EventsItem = require('../../components/events/components/eventsItem.jsx').default;

class EventsList extends React.Component {
    render() {
        var mode = this.props.mode;
        var auth = this.props.auth;
        var organization = this.props.organization;
        var joinVibe = this.props.joinVibe;
        var RSVPclick = this.props.RSVPclick;
        var selectedEvent = this.props.selectedEvent;
        var setSelectedEvent = this.props.setSelectedEvent;
        var openEventInputModal = this.props.openEventInputModal;
        var eventsList = this.props.events.map(function (event, i) {

            return (
                <EventsItem 
                    key={i}
                    mode={mode}
                    view={'full'}
                    eventItem={event}
                    selectedEvent={selectedEvent} 
                    auth={auth}
                    organization={organization}
                    joinVibe={joinVibe}
                    RSVPclick={RSVPclick}
                    setSelectedEvent={setSelectedEvent}
                    openEventInputModal={openEventInputModal} />
            );
        });

        return (
            <div>
                <div className="row column">
                    <div className="clearfix">
                        {Object.keys(eventsList).length !== 0 ? eventsList : <div className="text-center"><br />No events scheduled for this month<br /><br /><br /></div>}
                    </div>
                </div>
            </div>
        );
    }
}

EventsList.propTypes = {
    RSVPclick: PropTypes.func.isRequired,
    setSelectedEvent: PropTypes.func.isRequired,
    openEventInputModal: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
        PropTypes.object.isRequired
    ).isRequired,
    auth: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired
};

export default EventsList;
