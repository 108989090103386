import { routerActions } from 'react-router-redux';
import _ from 'lodash';

import constants from '../constants';
import api from '../../../utils/api';
import VibeChildrenActions from './vibeChildren';
import events from '../../events/actions/events';
import authLogout from '../../auth/actions/auth-logout';
import visitVibeActions from '../../auth/actions/visitVibe';
import utils from '../../../utils';

const Vibe = (function () {
  const _requestVibe = function () {
    return {
      type: constants.REQUEST_VIBE,
    };
  };

  const _receiveVibe = function (data) {
    return {
      type: constants.RECEIVE_VIBE,
      data,
    };
  };

  // var _receiveOrgVibe = function(data) {
  //     return {
  //         type: constants.RECEIVE_ORG_VIBE,
  //         data: data
  //     };
  // }

  const _receiveVibeById = function (data) {
    return {
      type: constants.RECEIVE_VIBE,
      data: data.vibe,
    };
  };

  const _receiveBoardById = function (data) {
    return {
      type: constants.RECEIVE_BOARD_BY_ID,
      data: data.board,
    };
  };

  const _receiveBoardFromState = function (board) {
    return {
      type: constants.RECEIVE_BOARD_FROM_STATE,
      data: board,
    };
  };

  const _fetchVibeError = function (error) {
    return {
      type: constants.REQUEST_VIBE_ERROR,
      errors: error,
    };
  };

  // We are no longer fetching by friendlyUrl but this remains for backwards compatability
  const _fetchVibe = function (friendlyUrl, organization, vibes, auth, accessToken, getEvents) {
    return function (dispatch) {
      dispatch(_requestVibe());

      const vibe = _.find(vibes.data, vibe => (vibe.friendlyUrl === friendlyUrl));

      if (vibe) {
        dispatch(_receiveVibe(vibe));

        // Get child vibes
        dispatch(VibeChildrenActions.fetchVibeChildren(vibes.data, vibe._id));

        // Get vibe events
        if (getEvents) {
          dispatch(events.fetchEventsIfNeeded(vibe._id, organization.data._id, accessToken));
        }

        // Log vibe visit
        if (utils.userIsVibeMember(auth.user.vibes, vibe._id)) {
          dispatch(visitVibeActions.visitVibe(vibe._id, auth.user._id, organization.data._id, accessToken));
        }
      } else {
        api.get(`/proxy/v1/vibe?friendlyUrl=${friendlyUrl}`, {
          orgId: organization.data._id,
        }, accessToken)
          .then((res) => {
            dispatch(_receiveVibe(res.vibe));

            // Get child vibes
            dispatch(VibeChildrenActions.fetchVibeChildren(vibes.data, res.vibe._id));

            // Get vibe events
            if (getEvents) {
              dispatch(events.fetchEventsIfNeeded(res.vibe._id, organization.data._id, accessToken));
            }

            // Log vibe visit
            if (utils.userIsVibeMember(auth.user.vibes, res.vibe._id)) {
              dispatch(visitVibeActions.visitVibe(res.vibe._id, auth.user._id, organization.data._id, accessToken));
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              dispatch(authLogout.logout());
              dispatch(routerActions.push('/login?err=3'));
            } else if (res.response.status === 403) {
              dispatch(routerActions.push('/login?err=6'));
            } else {
              dispatch(_fetchVibeError(res));
              dispatch(routerActions.push('/'));
            }
          });
      }
    };
  };

  const _validateVibeByFriendlyUrl = function (friendlyUrl, orgId, accessToken, callback) {
    api.get(`/proxy/v1/vibe?friendlyUrl=${friendlyUrl}&validate=true`, {
      orgId,
    }, accessToken)
      .then((res) => {
        const doesVibeExisit = res.results;
        return callback(!doesVibeExisit);
      })
      .catch((res) => {
        return callback(res);
      });
  };

  const _fetchVibeByIdOld = function (vibeId, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestVibe());
      api.get(`/proxy/v1/vibe?vibeId=${vibeId}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveVibeById(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(routerActions.push('/login'));
          } else {
            dispatch(_fetchVibeError(res));
          }
        });
    };
  };

  const _fetchVibeById = function (vibeId, organization, vibes, auth, accessToken, getEvents) {
    const { _id: orgId } = organization;
    const { user: { _id: userId, vibes: userVibes } } = auth;

    return function (dispatch) {
      dispatch(_requestVibe());
      const vibe = _.find(vibes.data, vibe => (vibe._id === vibeId));

      if (vibe) {
        dispatch(_receiveBoardFromState(vibe));

        // Get child vibes
        dispatch(VibeChildrenActions.fetchVibeChildren(vibes.data, vibe._id));

        // Get vibe events
        if (getEvents) {
          dispatch(events.fetchEventsIfNeeded(vibe._id, organization.data._id, accessToken));
        }

        // Log vibe visit
        if (utils.userIsVibeMember(auth.user.vibes, vibe._id)) {
          dispatch(visitVibeActions.visitVibe(vibe._id, auth.user._id, organization.data._id, accessToken));
        }
      } else {
        api.get(`/proxy/v1/board/${vibeId}`, {
          orgId,
        }, accessToken)
          .then((res) => {
            const { _id: boardId } = res;
            dispatch(_receiveBoardById(res));

            // Get child vibes
            dispatch(VibeChildrenActions.fetchVibeChildren(vibes.data, boardId));

            // Get vibe events
            if (getEvents) {
              dispatch(events.fetchEventsIfNeeded(boardId, orgId, accessToken));
            }

            // Log vibe visit
            if (utils.userIsVibeMember(userVibes, boardId)) {
              dispatch(visitVibeActions.visitVibe(boardId, userId, orgId, accessToken));
            }
          })
          .catch((res) => {
            if (res.status === 401) {
              dispatch(routerActions.push('/login'));
            } else {
              dispatch(_fetchVibeError(res.response));
            }
          });
      }
    };
  };

  const _shouldFetchVibe = function (state, friendlyUrl) {
    const vibe = state.selectedVibe;
    if (Object.keys(vibe.data).length === 0) {
      // No vibe data
      return true;
    } else if (!vibe.data._id) {
      // Vibe created with no id
      return true;
    } else if (vibe.data.friendlyUrl !== friendlyUrl) {
      // User switched vibe, re-fetch
      return true;
    } else if (state.selectedVibe.updated === true) {
      return true;
    }
    return false;
  };

  const _shouldFetchVibeById = function (state, vibeId) {
    const vibe = state.selectedVibe;
    if (Object.keys(vibe).length === 0) {
      return true;
    } else if (vibe.data._id !== vibeId) {
      return true;
    }
    return false;
  };

  const _fetchVibeIfNeeded = function (friendlyUrl, organization, vibes, auth, accessToken, getEvents) {
    return function (dispatch, getState) {
      const state = getState();
      if (_shouldFetchVibe(state, friendlyUrl)) {
        return dispatch(_fetchVibe(friendlyUrl, organization, vibes, auth, accessToken, getEvents));
      }

      if (utils.userIsVibeMember(auth.user.vibes, state.selectedVibe.data._id)) {
        dispatch(visitVibeActions
          .visitVibe(state.selectedVibe.data._id, auth.user._id, organization.data._id, accessToken));
      }
      return false;
    };
  };

  const _fetchVibeByIdIfNeeded = function (vibeId, organization, vibes, auth, accessToken, getEvents) {
    return function (dispatch, getState) {
      const state = getState();
      if (_shouldFetchVibe(state, vibeId)) {
        return dispatch(_fetchVibeById(vibeId, organization, vibes, auth, accessToken, getEvents));
      }

      if (utils.userIsVibeMember(auth.user.vibes, state.selectedVibe.data._id)) {
        dispatch(visitVibeActions
          .visitVibe(state.selectedVibe.data._id, auth.user._id, organization.data._id, accessToken));
      }
      return false;
    };
  };

  const _removeVibeAlerts = function () {
    return {
      type: constants.CLEAR_VIBE_ALERT,
    };
  };

  const _setSelectedVibe = function (vibe) {
    return {
      type: constants.SET_SELECTED_VIBE,
      vibeItem: vibe,
    };
  };

  const _clearSelectedVibe = function () {
    return {
      type: constants.CLEAR_SELECTED_VIBE,
    };
  };

  const _approveVibe = function (selectedVibe) {
    return {
      type: constants.APPROVE_ORG_VIBE,
      selectedVibe,
    };
  };

  const _clearSelectedVibeMessage = function () {
    return {
      type: constants.CLEAR_SELECTED_VIBE_MESSAGE,
    };
  };

  return {
    fetchVibeIfNeeded: _fetchVibeIfNeeded,
    fetchVibeByIdIfNeeded: _fetchVibeByIdIfNeeded,
    removeVibeAlerts: _removeVibeAlerts,
    validateVibeByFriendlyUrl: _validateVibeByFriendlyUrl,
    setSelectedVibe: _setSelectedVibe,
    clearSelectedVibe: _clearSelectedVibe,
    approveVibe: _approveVibe,
    clearSelectedVibeMessage: _clearSelectedVibeMessage,
  };
}());

export default Vibe;
