import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VibeMembers from '../components/vibeMembers.jsx';
import VibeActions from '../../../modules/vibes/actions/vibe';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    selectedVibe: state.selectedVibe,
    vibes: state.vibes,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({}, VibeActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VibeMembers);
