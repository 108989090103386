import constants from '../constants';
import api from '../../../utils/api';

const UpdateVibeOverview = (function () {
  const _updateVibeOverviewRequest = function () {
    return {
      type: constants.UPDATE_VIBE_OVERVIEW_REQUEST,
    };
  };

  const _updateVibeOverviewSuccess = function (data) {
    return {
      type: constants.UPDATE_VIBE_OVERVIEW_SUCCESS,
      vibe: data,
    };
  };

  const _updateVibeOverviewError = function (error) {
    return {
      type: constants.UPDATE_VIBE_OVERVIEW_ERROR,
      errors: error.data,
    };
  };

    // var _validationError = function(errors) {
    //     return {
    //         type: constants.UPDATE_VIBE_OVERVIEW_ERROR,
    //         errors: errors
    //     };
    // };

  const _updateVibeOverview = function (vibeId, vibeOverview, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_updateVibeOverviewRequest());
      api.put(`/proxy/v1/vibes/${vibeId}/overview`, {
        vibeOverview,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_updateVibeOverviewSuccess(res));
        })
        .catch((res) => {
          dispatch(_updateVibeOverviewError(res));
        });
    };
  };

  return {
    updateVibeOverview: _updateVibeOverview,
  };
}());

export default UpdateVibeOverview;
