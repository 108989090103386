import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import organizationVibesActions from '../../organizations/actions/organization-vibes';

const DeleteVibe = (function () {
  const _deleteVibeRequest = function () {
    return {
      type: constants.DELETE_VIBE_REQUEST,
    };
  };

  const _deleteVibeSuccess = function (data) {
    return {
      type: constants.DELETE_VIBE_SUCCESS,
      vibe: data,
      callout: {
        type: 'alert callout text-center',
        message: 'The board has been deleted and all content purged',
      },
    };
  };

  const _deleteVibeError = function (error) {
    return {
      type: constants.DELETE_VIBE_ERROR,
      errors: error.data,
    };
  };

  const _deleteVibe = function (vibe, organization, accessToken) {
    const vibeId = vibe._id;
    return function (dispatch) {
      dispatch(_deleteVibeRequest());
      api.delete(`/proxy/v1/vibes/${vibeId}`, {
        vibe,
        orgId: organization._id,
        orgVibes: organization.vibes,
      }, accessToken)
        .then((res) => {
          // Correctly removes vibe from state (should be in org or org-vibes though)
          dispatch(organizationVibesActions.removeOrgVibe(vibeId));

          // Need to redirect before action fires so vibe page doesn't try to fetch
          dispatch(routerActions.push('/'));
          dispatch(_deleteVibeSuccess(res));
        })
        .catch((res) => {
          dispatch(_deleteVibeError(res));
        });
    };
  };

  return {
    deleteVibe: _deleteVibe,
  };
}());

export default DeleteVibe;
