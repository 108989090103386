import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const OrganizationMembers = (function () {
  const _requestOrganizationMembers = function () {
    return {
      type: constants.REQUEST_ORGANIZATION_MEMBERS,
    };
  };

  const _receiveOrganizationMembers = function (data) {
    return {
      type: constants.RECEIVE_ORGANIZATION_MEMBERS,
      data: data.users,
    };
  };

  const _fetchOrganizationMembersError = function (error) {
    return {
      type: constants.REQUEST_ORGANIZATION_MEMBERS_ERROR,
      errors: error.data,
    };
  };

  const _fetchOrganizationMembers = function (orgId, accessToken) {
    return function (dispatch) {
      dispatch(_requestOrganizationMembers());
      api.get(`/proxy/v1/organizations/${orgId}/users`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveOrganizationMembers(res));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_fetchOrganizationMembersError(res));
          }
        });
    };
  };

  const _shouldFetchOrganizationMembers = function (state) {
    const members = state.organization.members.data;
    if (Object.keys(members).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchOrganizationMembersIfNeeded = function (orgId, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchOrganizationMembers(getState())) {
        return dispatch(_fetchOrganizationMembers(orgId, accessToken));
      }
      return false;
    };
  };

  return {
    fetchOrganizationMembersIfNeeded: _fetchOrganizationMembersIfNeeded,
    fetchOrganizationMembers: _fetchOrganizationMembers,
  };
}());

export default OrganizationMembers;
