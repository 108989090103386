const React = require('react');
const PropTypes = require('prop-types');
const { Link } = require('react-router');
const VibeOverview = require('./vibeOverview.jsx').default;
const LayoutSocial = require('./templates/layoutSocial.jsx').default;
const LayoutCalendar = require('./templates/layoutCalendar.jsx').default;
const LayoutTile = require('./templates/layoutTile.jsx').default;

class VibeHomepage extends React.PureComponent {
  renderTemplate = (vibeLayout) => {
    const components = {
      calendar: LayoutCalendar,
      feed: LayoutSocial,
      tile: LayoutTile,
      single: LayoutTile,
      default: LayoutCalendar,
    };

    const ItemComponent = components[vibeLayout];
    return (<ItemComponent
      organization={this.props.organization}
      selectedVibe={this.props.selectedVibe}
      auth={this.props.auth}
      post={this.props.post}
      postId={this.props.postId}
      setPost={this.props.setPost}
      clearPost={this.props.clearPost}
      updateVibeOverview={this.props.updateVibeOverview}
      userIsChampion={this.props.userIsVibeChampion}
      userIsOrgAdmin={this.props.userIsOrgAdmin}
      userIsTagAdmin={this.props.userIsTagAdmin}
      mentions={this.props.mentions} />);
  };

  render() {
    const { selectedVibe, parentVibe } = this.props;
    const { data: selectedVibeData } = selectedVibe;
    const { orgOptions } = selectedVibeData || {};
    const { isChild } = orgOptions || false;
    const { vibeLayout } = selectedVibeData;

    return (
      <div>
        {isChild && parentVibe &&
          <div className="row column margin-top-15">
            <Link to={`/board/${parentVibe._id}`}>&lt;&lt; back to {parentVibe.title}</Link>
          </div>
        }

        <VibeOverview
          selectedVibe={this.props.selectedVibe}
          auth={this.props.auth}
          organization={this.props.organization}
          joinVibe={this.props.joinVibe}
          leaveVibe={this.props.leaveVibe} />
        <br />
        {/* ## Spacer */}
        {/* <div className="spacer" style={{"height":"2rem"}}></div>
        <div className="show-for-medium-only" style={{"height":"3rem"}}></div>
        <div className="show-for-large" style={{"height":"3rem"}}></div> */}
        {(vibeLayout === 'notInUse' && (this.props.userIsOrgAdmin || this.props.userIsVibeChampion)) &&
        <div className="row columns">
          <div className="callout alert">
            <h5 className="text-center">This board is set to feature a single post. Select your featured post from the list below or create a new post.</h5>
            <p className="text-center">(Regular users won&#39;t see this page or message)</p>
          </div>
        </div>
        }

        {/* Load vibe template */}
        {vibeLayout ? this.renderTemplate(vibeLayout) : this.renderTemplate('feed')}
      </div>
    );
  }
}

VibeHomepage.propTypes = {
  joinVibe: PropTypes.func.isRequired,
  leaveVibe: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  parentVibe: PropTypes.object,
  mentions: PropTypes.array.isRequired,
};

VibeHomepage.defaultProps = {
  parentVibe: null,
};

export default VibeHomepage;
