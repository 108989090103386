import ReactSelect from 'react-select';
import BoardVisibility from '../../boardVisibility';

const React = require('react');
const { Link, withRouter } = require('react-router');
const PropTypes = require('prop-types');
const { DropdownList } = require('react-widgets');
const Geosuggest = require('react-geosuggest').default;
const _ = require('lodash');
const Alert = require('../../../components/alert.jsx').default;
const utils = require('../../../../../common/utils').default;
const VibeFolder = require('../layout/vibeFolder.jsx').default;

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onTagClick(this.props.item._id);
  }

  render() {
    const classes = (this.props.enabled) ? 'button round tag-on' : 'button round tag-off';

    return (
      <li>
        <button
          type="button"
          onClick={this.handleClick}
          className={classes}>
          {this.props.item.displayName} <i className="fi-check" />
        </button>
      </li>
    );
  }
}

class CustomizeVibe extends React.Component {
  state = {
    errors: {},
    previewTitle: 'Board name',
    previewDescription: 'Your board\'s short description will show here',
    previewImageUrl: 'https://res.cloudinary.com/teamvibe/image/upload/product/placeholder-image.png',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { selectedVibe: { data: vibeItem } } = this.props;
    if (this.props.mode === 'update') {
      this.setPreview(vibeItem);
    } else {
      this.props.clearSelectedVibe();

      this.setState({
        previewImageUrl: 'https://res.cloudinary.com/teamvibe/image/upload/product/placeholder-image.png',
      });

      // Pass selectedVibe._id when creating sub-vibe
      if (this.props.params.vibeId) {
        const parentVibeId = this.props.params.vibeId;
        const { vibes: { data: vibes } } = this.props || {};
        const parentName = _.find(vibes, { _id: parentVibeId }).title || '';

        const selectedVibe = { orgOptions: { isChild: true, parent: parentVibeId, parentName } };
        this.props.setSelectedVibe(selectedVibe);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.selectedVibe.data._id !== this.props.selectedVibe.data._id) && nextProps.mode === 'update') {
      const { selectedVibe: { data: vibeItem } } = nextProps;
      this.setPreview(vibeItem);
    }
  }

  onSuggestSelect = (suggest) => {
    const { selectedVibe } = this.props;
    const location = {
      label: suggest.label,
      googlePlaceId: suggest.placeId,
      loc: {
        type: 'Point',
        coordinates: [suggest.location.lng, suggest.location.lat],
      },
    };
    selectedVibe.data.location = location;

    this.props.setSelectedVibe(selectedVibe.data);
  };

  setPreview = (vibeItem) => {
    let previewImageUrl;
    let previewTitle;
    let previewDescription;

    if (vibeItem.vibeLayout && vibeItem.vibeLayout === 'single' && vibeItem.featuredPostId) {
      const previewData = utils.formatPreview(vibeItem.featuredPostId.content.rich);

      // Get featuredImage
      if (vibeItem.featuredPostId.featuredImage) {
        previewImageUrl = vibeItem.featuredPostId.featuredImage;
      } else if (previewData.featuredImage) {
        previewImageUrl = previewData.featuredImage;
      } else {
        previewImageUrl = vibeItem.imageUrl;
      }

      previewTitle = vibeItem.featuredPostId.title;
      previewDescription = previewData.preview;
    } else {
      previewImageUrl = (vibeItem.imageUrl) ?
        vibeItem.imageUrl :
        'https://res.cloudinary.com/teamvibe/image/upload/product/placeholder-image.png';
      previewTitle = (vibeItem.title) ? vibeItem.title : 'Board name';
      previewDescription = (vibeItem.description) ? vibeItem.description : 'Your board\'s short description will show here';
    }

    this.setState({
      previewImageUrl,
      previewTitle,
      previewDescription,
    });
  };

  showErrorAlert = (errors) => {
    window.scrollTo(0, 0);
    this.setState({ errors, errorAlert: true });
  };

  handleDeleteClick = () => {
    this.setState({ deleteAlert: true });
  };

  handleDeleteVibe = () => {
    this.hideAlerts();
    this.props.deleteVibe(this.props.selectedVibe.data, this.props.organization.data, this.props.auth.token);
  };

  hideAlerts = () => {
    this.setState({ errorAlert: false, deleteAlert: false });
    this.props.removeVibeAlerts();
  };

  handleTitleChange = (event) => {
    this.setState({ previewTitle: event.target.value });
    this.handleChange(event);
  };

  handleDescriptionChange = (event) => {
    this.setState({ previewDescription: event.target.value });
    this.handleChange(event);
  };

  handleFeaturedPostChange = (selectedValue) => {
    const { selectedVibe } = this.props;
    const featuredPost = {
      _id: selectedValue._id,
      featuredImage: selectedValue.featuredImage || null,
      title: selectedValue.title,
      content: {
        rich: selectedValue.content.rich,
      },
    };
    selectedVibe.data.featuredPostId = featuredPost;
    this.props.setSelectedVibe(selectedVibe.data);
    this.setPreview(selectedVibe.data);
  };

  handleVibeLayoutChange = (selectedValue) => {
    const { selectedVibe } = this.props;
    selectedVibe.data.vibeLayout = selectedValue.value;

    this.props.setSelectedVibe(selectedVibe.data);
    this.setPreview(selectedVibe.data);
  };

  handleChange = (event) => {
    const { selectedVibe } = this.props;
    if (this.state.errors[event.target.name]) {
      const currentState = this.state;
      delete currentState.errors[event.target.name];
      this.setState(currentState);
    }

    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
      selectedVibe.data[event.target.name] = inputValue;
      delete selectedVibe.error[event.target.name];
    }
    this.props.setSelectedVibe(selectedVibe.data);
    this.setPreview(selectedVibe.data);
  };

  handleOrgOptionsChange = (event) => {
    const { selectedVibe } = this.props;
    const orgOptions = (selectedVibe.data.orgOptions) ? selectedVibe.data.orgOptions : {};

    if (this.state.errors[event.target.name]) {
      const currentState = this.state;
      delete currentState.errors[event.target.name];
      this.setState(currentState);
    }

    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
      orgOptions[event.target.name] = inputValue;
      delete selectedVibe.error[event.target.name];
    }

    selectedVibe.data.orgOptions = orgOptions;
    this.props.setSelectedVibe(selectedVibe.data);
    this.setPreview(selectedVibe.data);
  };

  handleVibeTagsChange = (tagId) => {
    const { selectedVibe } = this.props;
    const orgOptions = (selectedVibe.data.orgOptions) ? selectedVibe.data.orgOptions : {};
    let vibeTags = orgOptions.tags || [];

    const tagInVibeTags = _.includes(vibeTags, tagId);
    vibeTags = tagInVibeTags ? _.pull(vibeTags, tagId) : utils.unshift(vibeTags, tagId);

    orgOptions.tags = vibeTags;
    selectedVibe.data.orgOptions = orgOptions;
    this.props.setSelectedVibe(selectedVibe.data);
  };

  handleRemoveTag = (tagId) => {
    const { selectedVibe: { data: boardData } } = this.props;
    const { orgOptions = {} } = boardData;
    const { tags = [] } = orgOptions;

    const updatedTags = _.pull(tags, tagId);
    orgOptions.tags = updatedTags;
    boardData.orgOptions = orgOptions;
    this.props.setSelectedVibe(boardData);
  };

  handleAddTag = (tagId) => {
    const { selectedVibe: { data: boardData } } = this.props;
    const { orgOptions = {} } = boardData;
    const { tags = [] } = orgOptions;

    const updatedTags = utils.unshift(tags, tagId);
    orgOptions.tags = updatedTags;
    boardData.orgOptions = orgOptions;
    this.props.setSelectedVibe(boardData);
  };

  handleClick = () => {
    const { selectedVibe: { data: vibeItem } } = this.props;

    // Get admin permissions
    const { userIsVibeChampion, userIsOrgAdmin, userIsTagAdmin } = this.props;

    this.props.validateVibe(vibeItem.title, vibeItem.description, vibeItem.vibeLayout, (result) => {
      if (result === true) {
        // Check for values, set defauls
        vibeItem.imageUrl = ('imageUrl' in vibeItem) ? vibeItem.imageUrl : this.state.previewImageUrl;
        vibeItem.vibeLayout = ('vibeLayout' in vibeItem) ? vibeItem.vibeLayout : 'tile';
        vibeItem.memberPosting = ('memberPosting' in vibeItem) ? vibeItem.memberPosting : true;

        // Org options
        const orgOptions = {};
        orgOptions.tags = (vibeItem.orgOptions && 'tags' in vibeItem.orgOptions) ? vibeItem.orgOptions.tags : [];
        orgOptions.isActive = (vibeItem.orgOptions && 'isActive' in vibeItem.orgOptions) ? vibeItem.orgOptions.isActive : true;
        orgOptions.isPinned = (vibeItem.orgOptions && 'isPinned' in vibeItem.orgOptions) ? vibeItem.orgOptions.isPinned : false;
        orgOptions.allUser = (vibeItem.orgOptions && 'allUser' in vibeItem.orgOptions) ? vibeItem.orgOptions.allUser : false;

        const defaultAccessLevel = (!userIsOrgAdmin) ? 'hidden' : 'members';
        orgOptions.accessLevel = (vibeItem.orgOptions && 'accessLevel' in vibeItem.orgOptions) ? vibeItem.orgOptions.accessLevel : defaultAccessLevel;

        orgOptions.isChild = (vibeItem.orgOptions && 'isChild' in vibeItem.orgOptions) ? vibeItem.orgOptions.isChild : false;
        orgOptions.parent = (vibeItem.orgOptions && 'parent' in vibeItem.orgOptions) ? vibeItem.orgOptions.parent : 0;
        // Clear parent field if top level is selected
        if (orgOptions.parent === 0) { delete orgOptions.parent; }

        orgOptions.showExternalUsers = (vibeItem.orgOptions && 'showExternalUsers' in vibeItem.orgOptions) ?
          vibeItem.orgOptions.showExternalUsers : true;
        orgOptions.showExternalPosts = (vibeItem.orgOptions && 'showExternalPosts' in vibeItem.orgOptions) ?
          vibeItem.orgOptions.showExternalPosts : true;

        vibeItem.orgOptions = orgOptions;
        this.props.setSelectedVibe(vibeItem);

        if (this.props.mode === 'create') {
          if (userIsVibeChampion || userIsOrgAdmin || userIsTagAdmin) {
            const redirect = true;
            const autoApprove = true;
            this.props.handleActivateVibe(redirect, autoApprove);
          } else {
            this.props.setCreateVibeStep('2');
          }
        } else if (this.props.mode === 'update') {
          this.props.updateVibe(
            vibeItem, this.props.selectedVibe.data._id, this.props.auth.user._id,
            this.props.organization.data, this.props.auth.token,
          );
        }
      } else {
        this.showErrorAlert(result);
      }
    });
  };

  handleUploadImageClick = () => {
    const options = {
      folder: this.props.organization.data.teamDomain,
      maxFileSize: 100000,
      minImageWidth: 150,
      maxImageWidth: 400,
    };

    utils.uploadImage(options, (results) => {
      if (results) {
        const { selectedVibe } = this.props;
        selectedVibe.data.imageUrl = results.secure_url;
        this.props.setSelectedVibe(selectedVibe.data);
        this.setPreview(selectedVibe.data);
      }
    });
  };

  updateParentVibe = (selectedValue) => {
    const { selectedVibe } = this.props;
    const orgOptions = (selectedVibe.data.orgOptions) ? selectedVibe.data.orgOptions : {};
    const selectedId = selectedValue._id;

    orgOptions.isChild = (selectedId !== 0);
    orgOptions.parent = selectedId;
    orgOptions.parentName = selectedValue.title;

    selectedVibe.data.orgOptions = orgOptions;
    this.props.setSelectedVibe(selectedVibe.data);
  };

  render() {
    // Get parentId in params if available
    const { mode, params: { parentId } } = this.props;

    const { organization: { data: organization } } = this.props;
    const { auth: { user } } = this.props;
    const { selectedVibe: { data: vibeItem } } = this.props;
    const { discussions: { data: discussions } } = this.props || [];
    // const discussions = (this.props.discussions.data) ? _.values(this.props.discussions.data) : [];
    const { vibes: { data: vibes } } = this.props || {};

    const orgId = organization._id;
    const { orgOptions = {} } = vibeItem;
    const boardTags = (orgOptions && 'tags' in orgOptions) ? orgOptions.tags : [];

    const parentBoard = _.find(vibes, { _id: orgOptions.parent }) || {};
    const { title: parentName = '' } = parentBoard;

    const userIsOrgAdmin = utils.userIsOrgAdmin(this.props.organization.data._id, this.props.auth.user.orgs);

    // List of boards filtered by admin & champion
    const boardList = _.orderBy(utils.filterBoardsByAdmin(vibes, user, organization, this.props.tags), 'title') || [];
    const noParentOption = { _id: 0, title: 'Homepage / top level' };

    // Remove current board (can't live under itself)
    const boardParentList = (mode === 'update') ? utils.remove(boardList, vibeItem._id) : boardList;

    // Top level only for admins
    if (userIsOrgAdmin) { boardParentList.unshift(noParentOption); }

    const categories = this.props.tags.filter((tag) => {
      return tag.tagType === 'category' && tag.isActive;
    }).map((tag, i) => {
      const result = _.some(boardTags, orgTag => orgTag === tag._id);
      const classes = (result) ? 'button round tag-on' : 'button round tag-off';
      return (
        <li key={tag._id}><a href={tag._id} className={classes}>{tag.displayName} <i className="fi-check" /></a></li>
      );
    });

    // Populate vars
    const vibeTitle = (vibeItem.title || '');
    const vibeDescription = (vibeItem.description || '');

    // If vibeLayout doesn't exisit, default to 'feed' (legacy). If new vibe, default to 'tile'
    const vibeLayoutDefault = (this.props.mode === 'create') ? 'tile' : 'feed';
    const vibeLayout = ('vibeLayout' in vibeItem) ? vibeItem.vibeLayout : vibeLayoutDefault;

    const { featuredPostId } = vibeItem;
    const boardLayoutOptions = [
      { value: 'tile', label: 'Tile layout - multiple posts will display in tile format' },
      { value: 'feed', label: 'Social feed - feed based listing display' },
    ];
    // if (this.props.mode === 'update') {
    //   vibeLayoutOptions.push({ value: 'single', text: 'Pop-up window - users will see a single featured post' });
    // }

    const vibeMemberPosting = ('memberPosting' in vibeItem) ? vibeItem.memberPosting : true;

    // Org options
    const vibeIsActive = (vibeItem.orgOptions && 'isActive' in vibeItem.orgOptions) ? vibeItem.orgOptions.isActive : true;
    const vibeIsPinned = (vibeItem.orgOptions && 'isPinned' in vibeItem.orgOptions) ? vibeItem.orgOptions.isPinned : false;
    const vibeAllUser = (vibeItem.orgOptions && 'allUser' in vibeItem.orgOptions) ? vibeItem.orgOptions.allUser : false;

    // Set parent from orgOptions first, then by param, then default to 0 (string)
    let { orgOptions: { parent: selectedParent = null } = {} } = vibeItem;
    if (!selectedParent) {
      selectedParent = parentId || 0;
    }

    // Location
    const locationLabel = (vibeItem.location && vibeItem.location.label) ? vibeItem.location.label : undefined;
    const maxRadius = ('maxRadius' in vibeItem) ? vibeItem.maxRadius : undefined;
    const maxRadiusType = ('maxRadiusType' in vibeItem) ? vibeItem.maxRadiusType : undefined;

    return (
      <div className="row column">
        <Alert
          type="success callout text-center"
          message="Board settings updated successfully"
          closeButton={false}
          showAlert={this.props.selectedVibe.updated}
          handleClose={this.hideAlerts} />
        <Alert
          type="alert callout text-center"
          message="You have validation errors. Please fix before submitting."
          closeButton={false}
          showAlert={this.state.errorAlert}
          handleClose={this.hideAlerts} />

        {/* 'Are you sure' alert */}
        <Alert
          name="alert_pending"
          type="alert callout text-center"
          autoClose={false}
          showAlert={this.state.deleteAlert}
          handleClose={this.hideAlerts}>
          <h4 className="alert">Warning: Permanent action</h4>
          <p>Deleting this board will also delete all posts and events contained in this board. Once deleted, the data can not be recovered.<br /><br />
            If you would prefer to disable this board, you can do so from the previous screen.
          </p>
          <div className="margin-top-15">
            <button type="button" className="alert button" onClick={this.handleDeleteVibe}>Confirm deletion</button>&nbsp;&nbsp;&nbsp;
            <button type="button" className="success button" onClick={this.hideAlerts}>Keep board</button>
          </div>
        </Alert>

        {/* Board name, image and description */}
        <div className="callout">
          <div className="row">
            <div className="large-8 columns">
              <div className="clearfix">
                {(this.props.mode === 'create') && <div className="float-left"><h4><strong>Add new board</strong></h4></div> }
                {(this.props.mode !== 'create') && <label><strong>Board status</strong><br /></label> }
                <div className={`switch margin-bottom-15 ${(this.props.mode === 'create') && 'hide'}`}>
                  <input
                    type="checkbox"
                    name="isActive"
                    checked={vibeIsActive}
                    onChange={this.handleOrgOptionsChange}
                    className="switch-input"
                    id="switch-vibe" />
                  {/* React.createElement('input', {
                    className:'switch-input', id:'switch-vibe', ref:'switch_vibe', type: 'checkbox',
                    defaultChecked: (orgVibe  && orgVibe.isActive) ? true : false}) */}
                  <label className="switch-paddle" htmlFor="switch-vibe">
                    <span className="switch-active" aria-hidden="true">On</span>
                    <span className="switch-inactive" aria-hidden="true">Off</span>
                  </label>
                </div>
              </div>
              <label><strong>Board type</strong></label>
              <ReactSelect
                classNamePrefix="react-select"
                menuPosition="fixed"
                options={boardLayoutOptions}
                value={boardLayoutOptions.find(option => option.value === vibeLayout)}
                onChange={option => this.handleVibeLayoutChange(option)}
              />
              <br />
              {(vibeLayout === 'single') ?
                <div>
                  <DropdownList
                    data={discussions}
                    valueField="_id"
                    defaultValue={featuredPostId}
                    textField="title"
                    placeholder="Select post to feature"
                    onChange={this.handleFeaturedPostChange}
                  />
                  <input name="title" type="hidden" value={vibeTitle} />
                  <input name="description" type="hidden" value={vibeDescription} />
                  <br />
                  <p className="help-text">Your selected post will be featured via a pop-up window on the board homepage</p>
                </div>
                :
                <div>
                  <label>
                    <strong>Board name</strong> <small>(max 100 characters)</small>
                    <input
                      className="input"
                      name="title"
                      type="text"
                      value={vibeTitle}
                      onChange={this.handleTitleChange}
                      placeholder="Vibe name"
                      aria-describedby="titleHelpText" />
                    {(Object.keys(this.state.errors).length !== 0 && ('title' in this.state.errors)) &&
                      <span className="input-error">{this.state.errors.title.message}</span>}
                    {/* (Object.keys(createVibeError).length !== 0 && 'string' === typeof createVibeError) &&
                      <span className="input-error">{createVibeError}</span> */}
                  </label>
                  <p className="help-text" id="titleHelpText" />
                  <label>
                    <strong>Select header image</strong>
                  </label>
                  <div className="row">
                    <div className="small-12 medium-6 columns">
                      <button type="button" className="button hollow" onClick={this.handleUploadImageClick}>Upload custom image</button>
                    </div>
                  </div>
                  <label><strong>Short description</strong> <small>(max 300 characters)</small>
                    <textarea
                      className="textarea"
                      name="description"
                      rows="3"
                      style={{ color: 'black' }}
                      value={vibeDescription}
                      onChange={this.handleDescriptionChange}
                      placeholder="Describe your vibe in a few sentences" />
                    {(Object.keys(this.state.errors).length !== 0 && ('description' in this.state.errors)) &&
                      <span className="input-error">{this.state.errors.description.message}</span>}
                  </label>
                  <br />
                  <label><strong>Board appears under</strong>
                    <ReactSelect
                      classNamePrefix="react-select"
                      menuPosition="fixed"
                      options={boardParentList}
                      value={boardParentList.find(option => option._id === selectedParent)}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option._id}
                      onChange={option => this.updateParentVibe(option)}
                    />
                  </label>
                </div>
              }
              <br />
            </div>
            <div className="large-4 columns">
              {(orgOptions && orgOptions.isChild) ?
                <div className="float-center" style={{ maxWidth: '80%' }}>
                  <br />
                  <VibeFolder
                    title={this.state.previewTitle}
                    imageUrl={this.state.previewImageUrl}
                    isApproved={true} />
                  <p className="text-center">This sub-board lives under<br /><strong>{parentName}</strong></p>
                </div>
                :
                <div className="vibe-tile preview float-center">
                  {this.state.previewImageUrl &&
                    <img src={this.state.previewImageUrl} alt="Vibe" />
                  }
                  <div className="card-body">
                    <h3 className="lead"><strong>{this.state.previewTitle}</strong></h3>
                    {/* Remove champion from vibe preview card
                    {!vibeItem && <p className="vibe-champions">Champion: {this.props.auth.user.firstName} {this.props.auth.user.lastName}</p>}
                    {(vibeItem && vibeItem.champion) &&
                      <p className="vibe-champions">Champion:
                        {vibeItem.champion.map(function (champ, i) {
                          return(<span key={i}>{champ.firstName} {champ.lastName}{i + 1 < (vibeItem.champion.length) ? <span>, </span> : null}</span>);
                        })}
                      </p>} */}
                    <p className="" dangerouslySetInnerHTML={{ __html: this.state.previewDescription }} />
                  </div>
                </div>
              }

              {(this.props.mode !== 'create') &&
                <div className="text-center"><br /><br />
                  <button type="button" className="alert button" onClick={this.handleDeleteClick}>{`Delete ${vibeTitle}`}</button>
                </div>
              }
            </div>
          </div>
        </div>

        {/* Org vibe settings */}
        <div className="row">
          <div className="large-12 columns">
            {/* Vibe category tags */}
            {(orgId === vibeItem.orgId) &&
              <div className="callout hide">
                <div className="row column">
                  <h4><strong>Categories</strong></h4>
                  <p className="subheader">Select the categories that apply to this board (multiple categories allowed)</p>
                  <ul className="vibe-tags">
                    {categories}
                  </ul>
                </div>
              </div>
            }
            {vibeItem.isSearchable &&
            <div className="callout">
              <h4><strong>Public search</strong></h4>
              <p className="subheader">Enter the primary location and max radius this board will be visible to outside users.</p>
              <div className="row">

                <div className="small-8 columns">
                  <label>
                    <strong>Location</strong>
                    <Geosuggest
                      onSuggestSelect={this.onSuggestSelect}
                      placeholder="Enter a business address, city, or region"
                      types={['geocode']}
                      initialValue={locationLabel}
                      // location={new google.maps.LatLng(32.715738, -117.161084)}
                      // radius="2000"
                    />
                  </label>
                </div>
                <div className="small-4 columns">
                  <label><strong>Max distance</strong>
                    <div className="row collapse">
                      <div className="small-5 columns">
                        <input type="number" name="maxRadius" value={maxRadius} onChange={this.handleChange} />
                      </div>
                      <div className="small-7 columns" style={{ paddingLeft: '0.5rem' }}>
                        <select name="maxRadiusType" value={maxRadiusType || 'mi'} onChange={this.handleChange}>
                          <option value="mi">Miles</option>
                          <option value="km">Kilometers</option>
                        </select>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="large-8 columns">
            <BoardVisibility
              mode={this.props.mode}
              orgOptionsChange={this.handleOrgOptionsChange}
              userIsOrgAdmin={userIsOrgAdmin}
              addTag={this.handleAddTag}
              removeTag={this.handleRemoveTag}
              isChild={orgOptions.isChild || false}
              parentBoard={parentBoard} />
          </div>
          <div className="large-4 columns">
            {/* Vibe options */}
            <div className="callout">
              <div className="row column">
                <h4><strong>Options</strong></h4>
                <table>
                  <tbody>
                    <tr>
                      <td>Anyone can start posts:</td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            type="checkbox"
                            name="memberPosting"
                            checked={vibeMemberPosting}
                            onChange={this.handleChange}
                            className="switch-input"
                            id="switch-memberpost" />
                          {/* React.createElement('input', {
                            className:'switch-input', id:'switch-memberpost', ref:'switch_memberpost', type: 'checkbox',
                            defaultChecked: (vibeItem && (vibeItem.hasOwnProperty("memberPosting"))) ? vibeItem.memberPosting : true})) */}
                          <label className="switch-paddle" htmlFor="switch-memberpost">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    {(userIsOrgAdmin && (orgId === vibeItem.orgId || this.props.mode === 'create')) &&
                    <tr>
                      <td>Pin to top of homepage:</td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            type="checkbox"
                            name="isPinned"
                            checked={vibeIsPinned}
                            onChange={this.handleOrgOptionsChange}
                            className="switch-input"
                            id="switch-pinned" />
                          {/* React.createElement('input', {
                            className:'switch-input', id:'switch-pinned', ref:'switch_pinned', type: 'checkbox',
                            defaultChecked: (orgVibe && (orgVibe.hasOwnProperty("isPinned"))) ? orgVibe.isPinned : false}) */}
                          <label className="switch-paddle" htmlFor="switch-pinned">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    }
                    {userIsOrgAdmin &&
                    <tr>
                      <td>Auto follow:</td>
                      <td className="text-right">
                        <div className="switch">
                          <input
                            type="checkbox"
                            name="allUser"
                            checked={vibeAllUser}
                            onChange={this.handleOrgOptionsChange}
                            className="switch-input"
                            id="switch-alluser" />
                          {/* React.createElement('input', {
                            className:'switch-input', id:'switch-alluser', ref:'switch_alluser', type: 'checkbox',
                            defaultChecked: (vibeItem && (vibeItem.hasOwnProperty("allUser"))) ? vibeItem.allUser : false}) */}
                          <label className="switch-paddle" htmlFor="switch-alluser">
                            <span className="switch-active" aria-hidden="true">On</span>
                            <span className="switch-inactive" aria-hidden="true">Off</span>
                          </label>
                        </div>
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
                <p className="subheader">Some options are only available to platform admins.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Vibe feature control */}
        {/* !!!!! HIDDEN UNTIL SUPPORTING FEATURES ARE BUILT !!!!! */}
        {/* !!!!! DEFAULT SETTINGS STILL FLOW THROUGH WHILE HIDDEN !!!!! */}
        <div className="callout hide">
          <div className="row column">
            <h4><strong>Feature Control</strong></h4>
            <p className="subheader">These controls determine which features appear in your board</p>
            <table>
              <tbody>
                <tr>
                  <td width="200">Discussions:</td>
                  <td>
                    <div className="switch">
                      {React.createElement('input', {
                        className: 'switch-input',
                        id: 'switch-discussions',
                        ref: 'switch_discussions',
                        type: 'checkbox',
                        defaultChecked: (vibeItem && !vibeItem.showDiscussions) ? false : true,
                      })}
                      <label className="switch-paddle" htmlFor="switch-discussions">
                        <span className="switch-active" aria-hidden="true">On</span>
                        <span className="switch-inactive" aria-hidden="true">Off</span>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Event calendar:</td>
                  <td>
                    <div className="switch">
                      {React.createElement('input', {
                        className: 'switch-input',
                        id: 'switch-calendar',
                        ref: 'switch_calendar',
                        type: 'checkbox',
                        defaultChecked: (vibeItem && !vibeItem.showCalendar) ? false : true,
                      })}
                      <label className="switch-paddle" htmlFor="switch-calendar">
                        <span className="switch-active" aria-hidden="true">On</span>
                        <span className="switch-inactive" aria-hidden="true">Off</span>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Member list:</td>
                  <td>
                    <div className="switch">
                      {React.createElement('input', {
                        className: 'switch-input',
                        id: 'switch-members',
                        ref: 'switch_members',
                        type: 'checkbox',
                        defaultChecked: (vibeItem && !vibeItem.showMemberList) ? false : true,
                      })}
                      <label className="switch-paddle" htmlFor="switch-members">
                        <span className="switch-active" aria-hidden="true">On</span>
                        <span className="switch-inactive" aria-hidden="true">Off</span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Update vibe button */}
        <div className="row columns">
          <br />
          <div className="text-center">
            {(this.props.mode === 'create') && <Link to="/" className="alert button large margin-right-15">Cancel and return</Link>}
            <button type="button" onClick={this.handleClick} className="button large">Save settings and continue</button>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

CustomizeVibe.propTypes = {
  mode: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  clearSelectedVibe: PropTypes.func.isRequired,
  setSelectedVibe: PropTypes.func.isRequired,
  removeVibeAlerts: PropTypes.func.isRequired,
  setCreateVibeStep: PropTypes.func,
  handleActivateVibe: PropTypes.func,
  updateVibe: PropTypes.func,
  deleteVibe: PropTypes.func,
  validateVibe: PropTypes.func.isRequired,
  userIsVibeChampion: PropTypes.bool.isRequired,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  userIsTagAdmin: PropTypes.bool.isRequired,
};

CustomizeVibe.defaultProps = {
  setCreateVibeStep: () => {},
  handleActivateVibe: () => {},
  updateVibe: () => {},
  deleteVibe: () => {},
};

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  onTagClick: PropTypes.func.isRequired,
};

export default withRouter(CustomizeVibe);
