var React = require('react');
var Link = require('react-router').Link;
var PropTypes = require('prop-types');
var Callout = require('../../../components/callout.jsx').default;
var utils = require('../../../../../common/utils').default;
var linkifyHtml = require('linkifyjs/html');

class CallToAction extends React.Component {
    parseTo = (to) => {
        var parser = document.createElement('a');
        parser.href = to;
        return parser;
    };

    isInternal = (toLocation) => {
        return window.location.host === toLocation.host;
    };

    render() {
        var toLocation = this.parseTo(this.props.linkUrl);
        var isInternal = this.isInternal(toLocation);
        return (
            <Callout type="clearfix" closeButton={false} >
                {(this.props.image) ? 
                <img className="image-feature hide-for-small-only" src={utils.formatImage(this.props.image,'ctaPhoto')} alt="Get involved, start a board." />
                : null}                
                {this.props.title && <h3>{this.props.title}</h3>}
                {this.props.message && <p className="" dangerouslySetInnerHTML={{__html: linkifyHtml(this.props.message)}}></p>}
                {/*this.props.message && <p>{linkifyHtml(this.props.message)}</p>*/}
                <h4>
                    {isInternal ? <Link to={toLocation.pathname}>{this.props.linkText}</Link> : <a href={this.props.linkUrl} target="_blank">{this.props.linkText}</a>}
                </h4>
            </Callout>
       )
    }
}

CallToAction.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  image: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string
};

export default CallToAction;
