import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import loginUtils from '../../auth/utils/LoginUtils';

const PasswordReset = (function () {
  const _requestPasswordResetRequest = function () {
    return {
      type: constants.REQUEST_PASSWORD_RESET_REQUEST,
    };
  };

  const _passwordResetRequestSuccess = function (data) {
    return {
      type: constants.REQUEST_PASSWORD_RESET_SUCCESS,
      data,
    };
  };

  const _passwordResetSuccess = function (data) {
    return {
      type: constants.PASSWORD_RESET_SUCCESS,
      data,
    };
  };

  const _passwordResetRequestError = function (error) {
    return {
      type: constants.REQUEST_PASSWORD_RESET_ERROR,
      errors: error.data,
    };
  };

  const _passwordResetError = function (error) {
    return {
      type: constants.PASSWORD_RESET_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.PASSWORD_RESET_ERROR,
      errors,
    };
  };

  const _passwordResetRequest = function (email, organization) {
    return function (dispatch) {
      dispatch(_requestPasswordResetRequest());
      api.post('/proxy/v1/users/password-reset/', {
        email,
        orgTeamDomain: organization.teamDomain,
        orgName: organization.name,
      })
        .then((res) => {
          dispatch(_passwordResetRequestSuccess(res));
        })
        .catch((res) => {
          dispatch(_passwordResetRequestError(res));
        });
    };
  };

  const _passwordReset = function (userId, password, token, organization) {
    return function (dispatch) {
      loginUtils.validatePassword(password)
        .then((result) => {
          if (result === true) {
            dispatch(_requestPasswordResetRequest());
            api.post(`/proxy/v1/users/password-reset/${token}`, {
              userId,
              password,
              orgTeamDomain: organization.teamDomain,
              orgName: organization.name,
            })
              .then((res) => {
                dispatch(_passwordResetSuccess(res));
                dispatch(routerActions.push('/login?err=5'));
              })
              .catch((res) => {
                dispatch(_passwordResetError(res));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    passwordResetRequest: _passwordResetRequest,
    passwordReset: _passwordReset,
  };
}());

export default PasswordReset;
