import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgMembersActions from '../../../../modules/organizations/actions/organization-members';
import UpdateUserOrganizationActions from '../../../../modules/organizations/actions/updateUserOrganization';
import RemoveOrganizationUserActions from '../../../../modules/organizations/actions/removeOrganizationUser';
import VibeActions from '../../../../modules/vibes/actions/vibe';
import VibeMembersActions from '../../../../modules/vibes/actions/vibe-members';
import FilterUsersActions from '../../../../modules/users/actions/filterUsers';
import userList from '../components/userList.jsx';

import { boardOperations } from '../../../../modules/boards';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    board: state.board,
    organization: state.organization,
    selectedVibe: state.selectedVibe,
    tags: state.tags,
    vibes: state.vibes,
    userFilter: state.userFilter,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {},
    OrgMembersActions, UpdateUserOrganizationActions, RemoveOrganizationUserActions,
    VibeMembersActions, VibeActions, boardOperations, FilterUsersActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(userList);
