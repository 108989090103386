import { routerActions } from 'react-router-redux';
import _ from 'lodash';

import constants from '../constants';
import api from '../../../utils/api';
import authLogout from '../../auth/actions/auth-logout';

const AddVibeUsers = (function () {
  const _addVibeUsersRequest = function () {
    return {
      type: constants.ADD_VIBE_USERS_REQUEST,
    };
  };

  const _addVibeUsersSuccess = function (data, state) {
    const { usersAdded } = data;
    const { members: vibeMembers = {} } = state.selectedVibe;

    const mergedVibeMembers = _.orderBy(_.union(vibeMembers.direct, usersAdded), ['firstName'], ['asc']);

    // Get full names of new users for alert
    let newUserFullNames = _.map(usersAdded, user => `${user.firstName} ${user.lastName}`);
    newUserFullNames = _.values(newUserFullNames).join(', ');

    return {
      type: constants.ADD_VIBE_USERS_SUCCESS,
      mergedVibeMembers,
      newUserFullNames,
    };
  };

  const _addVibeUsersError = function (error) {
    return {
      type: constants.ADD_VIBE_USERS_ERROR,
      errors: error.data,
    };
  };

  const _addVibeUsers = function (vibe, organization, userList, adminFullName, notify, accessToken) {
    return function (dispatch, getState) {
      dispatch(_addVibeUsersRequest());
      api.put(`/proxy/v1/vibes/${vibe._id}/users`, {
        orgId: organization._id,
        orgTeamDomain: organization.teamDomain,
        orgName: organization.name,
        orgLogo: organization.logo,
        vibeTitle: vibe.title,
        vibeTags: (('orgOptions' in vibe) ? vibe.orgOptions.tags : []),
        userList,
        adminFullName,
        notify,
      }, accessToken)
        .then((res) => {
          dispatch(_addVibeUsersSuccess(res, getState()));
        })
        .catch((res) => {
          if (res.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_addVibeUsersError(res));
          }
        });
    };
  };

  return {
    addVibeUsers: _addVibeUsers,
  };
}());

export default AddVibeUsers;
