import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import RadioSet from '../components/radioSet';
import TagsList from '../components/tags/tagsList';
import TagsSelector from '../components/tags/tagsSelector';

const BoardVisibility = (props) => {
  // Create REFs for menus
  // const filterMenu = useRef(null);
  // const [tagType, setTagType] = useState('');

  const {
    orgOptionsChange, userIsOrgAdmin, addTag, removeTag,
    isChild, parentBoard,
  } = props;

  // Import state vars
  const { organization, selectedVibe } = useSelector(
    state => ({
      organization: state.organization,
      selectedVibe: state.selectedVibe,
    }),
  );

  const { data: { _id: orgId, name: orgName, preferences: orgPreferences = {} } } = organization;
  const { data: selectedVibeData } = selectedVibe;
  const { orgOptions = {} } = selectedVibeData;

  const vibeAccessLevel = (orgOptions && 'accessLevel' in orgOptions) ? orgOptions.accessLevel : 'private';

  // Visibility options
  const visibilityOptions = [];
  const optionPublic = {
    label: 'Public',
    text: 'Visible to anyone on the internet',
    value: 'public',
  };
  optionPublic.checked = (vibeAccessLevel === 'public') || false;

  const optionMembers = {
    label: `All ${orgName} members`,
    text: 'Visible to all members on the platform',
    value: 'members',
  };
  optionMembers.checked = (vibeAccessLevel === 'private' || vibeAccessLevel === 'members') || false;

  const optionInherit = {
    label: 'Inherit from parent board',
    text: 'Members will be inherited from parent',
    value: 'inherit',
  };
  optionInherit.checked = (vibeAccessLevel === 'inherit') || false;

  const optionPrivate = {
    label: 'Only people or tags you select',
    text: 'Private board will not be visible to others',
    value: 'hidden',
  };
  optionPrivate.checked = (vibeAccessLevel === 'hidden') || false;

  if (orgPreferences.publicView === true || vibeAccessLevel === 'public') {
    visibilityOptions.push(optionPublic);
  }

  if (userIsOrgAdmin) {
    visibilityOptions.push(optionMembers);
  }

  // If child then offer inherit option
  if (isChild) { visibilityOptions.push(optionInherit); }

  // Push private option
  visibilityOptions.push(optionPrivate);

  return (
    <div>
      {/* Vibe access */}
      {(orgId === selectedVibeData.orgId || props.mode === 'create') &&
        <div className="callout">
          <div className="row column">
            <h4><strong>Who can see this board?</strong></h4>
            <RadioSet
              group="accessLevel"
              subText={true}
              boldHeaders={true}
              onChange={orgOptionsChange}
              radios={visibilityOptions}
            />
            {/* Vibe location tags */}
            <TagsList removeTag={removeTag} />
            {/* (orgId === selectedVibeData.orgId || this.props.mode === 'create') && (locations.length > 1) &&
              <div className={(locations.length > 0 && vibeAccessLevel === 'private') ? '' : 'hide'}>
                <p className="subheader">Optional: Limit board to specific locations (multiple locations allowed)</p>
                <div className="tags">
                  {locations}
                </div>
              </div>
            */}
            <TagsSelector
              placeholder="Select or type to add tags"
              userIsOrgAdmin={userIsOrgAdmin}
              addTag={addTag} />
          </div>
        </div>
      }
    </div>
  );
};

BoardVisibility.propTypes = {
  addTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  isChild: PropTypes.bool.isRequired,
  parentBoard: PropTypes.object,
  mode: PropTypes.string.isRequired,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  orgOptionsChange: PropTypes.func.isRequired,
};

BoardVisibility.defaultProps = {
  parentBoard: {},
};

export default BoardVisibility;
