import constants from '../constants';
import api from '../../../utils/api';

const DeleteInvitation = (function () {
  const _deleteInvitationRequest = function () {
    return {
      type: constants.DELETE_INVITATION_REQUEST,
    };
  };

  const _deleteInvitationSuccess = function (data) {
    return {
      type: constants.DELETE_INVITATION_SUCCESS,
      invitationId: data._id,
    };
  };

  const _deleteInvitationError = function (error) {
    return {
      type: constants.DELETE_INVITATION_ERROR,
      errors: error.data,
    };
  };

  const _deleteInvitation = function (orgId, invitationId, accessToken) {
    return function (dispatch) {
      dispatch(_deleteInvitationRequest());
      api.delete(`/proxy/v1/invitations/${invitationId}`, {
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_deleteInvitationSuccess(res.invitation));
        })
        .catch((res) => {
          dispatch(_deleteInvitationError(res));
        });
    };
  };

  return {
    deleteInvitation: _deleteInvitation,
  };
}());

export default DeleteInvitation;
