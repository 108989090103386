const React = require('react');
const { Helmet } = require('react-helmet');
const { Link } = require('react-router');
const { withRouter } = require('react-router');
const PropTypes = require('prop-types');
const _ = require('lodash');
// const SubHeader = require('./layout/subHeader.jsx').default;
const CallToAction = require('./layout/callToAction.jsx').default;
const TileList = require('./layout/tileList.jsx').default;
const utils = require('../../../../common/utils').default;
const Alert = require('../../components/alert.jsx').default;

const PostModal = require('../../components/posts/containers/postModalContainer.jsx').default;

class Vibes extends React.PureComponent {
  state = {
    inputModalIsOpen: false,
    postModalIsOpen: false,
    floatMenuIsOpen: false,
    vibeView: 'org',
    locationTag: [],
  };

  componentDidMount() {
    // Get current user's location tag
    // let locationTag = [];
    // const userOrg = _.find(this.props.auth.user.orgs, {'id': this.props.organization.data._id});
    // locationTag = _.find(this.props.tags.data, function(tag){
    //     return tag.tagType === 'location' && _.findIndex(userOrg.tags, tag._id) == -1;
    // });

    // Should move this to auth/checkVibes
    // (locationTag) && this.setState({locationName: locationTag.displayName})

    // if (this.props.auth.user.orgs && this.props.organization.data.preferences.showPublicVibes) {
    //     (locationTag) && this.props.fetchVibesPublic(this.props.organization.data._id, locationTag, this.props.auth.token);
    // }

    // -----------
    // Not sure if this is needed since /post/:id opens modal
    // Causes second render when active
    // UPDATE: This is needed for direct load /post/:id, but need to fix double visit
    if (this.props.params.id || this.props.route.showModal) {
      this.setState({ postModalIsOpen: true });
    } else {
      this.setState({ postModalIsOpen: false });
    }

    // TODO:  Use tutorial query param to initialize tutorial
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    // Not sure if this is needed since /post/:id opens modal
    // Causes second render when active
    // UPDATE: This is needed for direct load /post/:id, but need to fix double visit
    if (nextProps.params !== this.props.params) {
      if (nextProps.params.id || nextProps.route.showModal) {
        this.setState({ postModalIsOpen: true });
      } else {
        this.setState({ postModalIsOpen: false });
      }
    }

    // Clear error alert
    if ((nextProps !== this.props) && nextProps.location.query.err) {
      nextProps.router.push(nextProps.location.pathname);
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  toggleFloatMenu = () => {
    const { floatMenuIsOpen } = this.state;
    this.setState({ floatMenuIsOpen: !floatMenuIsOpen });
  };

  handleClickOutside = (event) => {
    if (this.state.floatMenuIsOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ floatMenuIsOpen: false });
    }
  };

  toggleVibes = (selection) => {
    this.setState({ vibeView: selection });
  };

  openPostModal = () => {
    this.setState({ postModalIsOpen: true });
  };

  closePostModal = () => {
    this.setState({ postModalIsOpen: false });
    this.props.router.push('/');
  };

  handleFilterVibes = (tags) => {
    this.props.setActiveVibeFilter(tags);
  };

  handleCloseCallout = () => {
    this.props.removeVibeCallout();
  };

  handleCloseAlert = () => {
    this.props.clearDiscussion();
  };

  render() {
    const { auth, activeVibeFilter } = this.props;
    const { organization: { data: organization } } = this.props;
    const { preferences: { allowBoardCreate = true } } = organization;
    const { tags: { data: tags } } = this.props;
    const { vibes: { isFetching, callout } } = this.props;
    let vibes = this.props.vibes.data;
    let vibesPublic = this.props.vibesPublic.data;

    const isOrgAdmin = utils.userIsOrgAdmin(organization._id, this.props.auth.user.orgs);

    // General error handling (for homepage)
    const generalError = {};
    const alertOptions = {
      type: 'alert callout text-center',
      autoClose: true,
      closeButton: false,
    };

    if (this.props.location.query.err) {
      switch (this.props.location.query.err) {
      case 'p1':
        generalError.message = 'Post not found';
        break;
      case 'p2':
        generalError.message = 'There was a problem loading your post. Please try again or contact support@teamvibe.com.';
        break;
      default:
        generalError.message = 'An error ocurred in your request. Please try again.';
        break;
      }
    }

    // Loading
    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    // Build public vibes list for org
    const orgVibeIds = _.map(vibes, '_id');
    vibesPublic = _.filter(vibesPublic, vibe => _.indexOf(orgVibeIds, vibe._id) === -1);

    // Filter out child vibes
    vibes = _.reject(vibes, ['orgOptions.isChild', true]);

    // Filter vibe list for user location
    // Show vibes if no tags selected, user location matches, or isOrgAdmin
    // Most vibe filtering happens on the backend, but location filtering on FE
    const userCurrentOrg = _.find(auth.user.orgs, { id: organization._id });
    const userTags = utils.getUserTags(userCurrentOrg, tags);
    // const userlocationTag = _.find(userTags, { tagType: 'location' });
    const userVibesList = auth.user.vibes;

/*      vibes = _.filter(vibes, (vibe) => {
      const vibeTags = utils.getVibeTags(vibe, tags);
      const userTagMatch = _.intersectionBy(vibeTags, userTags, '_id');

      if (vibeTags.length === 0 ||
        userTagMatch.length > 0 ||
        _.some(userVibesList, { vibe: vibe._id }) ||
        isOrgAdmin) {
        return vibe;
      }
    });*/


/*      if (userlocationTag) {
      vibes = _.filter(vibes, (vibe) => {
        const vibeTags = utils.getVibeTags(vibe, tags);
        if ((_.filter(vibeTags, { tagType: 'location' })).length === 0 ||
          _.some(vibeTags, { _id: userlocationTag._id, orgId: organization._id }) ||
          _.some(userVibesList, { vibe: vibe._id }) ||
          isOrgAdmin) {
          return vibe;
        }
        return false;
      });
    } */

    return (
      <div>
        <Helmet title="Boards" />
        <PostModal
          postId={this.props.params.id}
          modalLocation="home"
          postModalIsOpen={this.state.postModalIsOpen}
          closePostModal={this.closePostModal} />

        <Alert
          type={callout.type}
          message={callout.message}
          showAlert={(callout && Object.keys(callout).length > 0)}
          handleClose={this.handleCloseCallout} />

        <Alert
          name="alert_error"
          type={alertOptions.type}
          message={generalError.message}
          showAlert={(Object.keys(generalError).length !== 0)}
          handleClose={this.handleCloseAlert}
          closeButton={alertOptions.closeButton}
          autoClose={alertOptions.autoClose}>
          {generalError.html && generalError.html}
        </Alert>

        <br />

        {/* Public vibe toggle menu */}
        {/* (this.props.auth.user.orgs
          && organization.preferences.showPublicVibes
          && vibesPublic.length > 0) &&
           <div className="row columns text-center">
           <div className="button-group toggle" data-toggle="buttons-radio">
            <input type="radio" id="org" data-toggle="button" value="going" onChange={this.toggleVibes.bind(null, 'org')} checked={this.state.vibeView === 'org'}/>
            <label className="button wide" htmlFor="org">{organization.name} vibes</label>
            <input type="radio" id="public" data-toggle="button" value="going" onChange={this.toggleVibes.bind(null, 'public')} checked={this.state.vibeView === 'public'}/>
            <label className="button wide" htmlFor="public">Vibes near {this.state.locationName}</label>
          </div>
        </div>
        */}

        {/* Org vibes */}
        {this.state.vibeView === 'org' &&
          <div className="row columns vibe-list">
            {/* <SubHeader tags={tags} filterVibes={this.handleFilterVibes} /> */}
            <TileList
              vibes={vibes}
              auth={auth}
              tags={tags}
              isOrgAdmin={isOrgAdmin}
              type="org" />
          </div>
        }

        {/* Public vibes */}
        {this.state.vibeView === 'public' &&
          <div className="row columns vibe-list">
            <TileList
              vibes={vibesPublic}
              auth={auth}
              tags={tags}
              type="public" />
          </div>
        }

        {/* Float menu */}
        {(this.props.auth.token && (allowBoardCreate || isOrgAdmin)) &&
          <div ref={this.setWrapperRef}>
            <div id="float-menu" className={!this.state.floatMenuIsOpen ? 'hide' : null}>
              <ul className="menu vertical text-left">
                <li>
                  <Link className="common-link" to="board/create/1">
                    <i className="fa fa-file-o" />
                    <span>Add board</span>
                  </Link>
                </li>
              </ul>
            </div>
            <button
              id="float-menu-toggle"
              className="button large"
              onClick={this.toggleFloatMenu}><i className="fi-plus" />
            </button>
          </div>
        }

        {organization.callToAction && organization.callToAction.isActive &&
        <div className="row columns">
          <CallToAction
            title={organization.callToAction.title}
            orgName={organization.name}
            message={organization.callToAction.message}
            linkText={organization.callToAction.linkText}
            linkUrl={organization.callToAction.linkUrl}
            image={organization.callToAction.image} />
        </div>
        }
      </div>
    );
  }
}

Vibes.propTypes = {
  fetchVibes: PropTypes.func.isRequired,
  fetchVibesPublic: PropTypes.func.isRequired,
  fetchTagsIfNeeded: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setActiveVibeFilter: PropTypes.func.isRequired,
  vibes: PropTypes.object.isRequired,
  vibesPublic: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  activeVibeFilter: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Vibes);
