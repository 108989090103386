const React = require('react');
const PropTypes = require('prop-types');

const SocialButton = require('./socialButton.jsx').default;

class SocialButtonList extends React.Component {
  getMessage = (name) => {
    const type = (this.props.type === 'login') ? 'Sign In with' : 'Sign Up with';
    return `${type} ${name}`;
  };

  buildRedirect = (name) => {
    const { org: { _id: orgId, teamDomain }, inviteType, previousRoute, token } = this.props;
    let base = `/oauth/${name}`;
    let query;
    if (this.props.type === 'signup') {
      base = `${base}/${token}`;
      query = `?org=${orgId}_${teamDomain}_${inviteType}`;
    } else {
      query = `?org=${orgId}_${teamDomain}_${previousRoute}_${inviteType}`;
    }
    return base + query;
  };

  render() {
    return (
      <div>
        <SocialButton text={this.getMessage('Linkedin')} name="Linkedin" redirect={this.buildRedirect('linkedin')} />
        <SocialButton text={this.getMessage('Facebook')} name="Facebook" visibility="hide" />
        <SocialButton text={this.getMessage('Twitter')} name="Twitter" visibility="hide" />
      </div>
    );
  }
}

SocialButtonList.propTypes = {
  org: PropTypes.object.isRequired,
  inviteType: PropTypes.string.isRequired,
  token: PropTypes.string,
  type: PropTypes.string,
  linkedInAction: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default SocialButtonList;
