import ReactSelect from 'react-select';

const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('react-modal');
const indicative = require('indicative');
const _ = require('lodash');

const Alert = require('../../alert.jsx').default;

const Indicative = new indicative();

class UserInvite extends React.Component {
  state = {
    updateAlert: false,
    calloutMessage: '',
  };

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    this.props.clearInvite();
  }

  showUpdateAlert = (message) => {
    this.setState({
      updateAlert: true,
      calloutMessage: message,
    });
  };

  hideUpdateAlert = () => {
    this.setState({ updateAlert: false });
  };

  updateSelectedTeams = (selections) => {
    this.setState({ selectedTeams: selections });
  };

  updateSelectedVibes = (selections) => {
    this.setState({ selectedVibes: selections });
  };

  handleLocationChange = (selections) => {
    this.setState({ selectedLocations: selections });
  }

  handleCreateInvites = () => {
    const { locationsList } = this.props;
    const emails = this.emailList.value.trim().split(/[\s,\n\t]+/);

    // Handle presets
    const { selectedLocations, selectedTeams, selectedVibes } = this.state;

    const presetLocations = [];
    if (locationsList) {
      // Only using a single location (array can handle multiple locations)
      if (locationsList.length > 1 && selectedLocations) {
        presetLocations.push(selectedLocations._id);
      } else if (locationsList.length === 1) {
        presetLocations.push(locationsList[0]._id);
      }
    }

    // Map teams and vibes to _id arrays; Set isAdmin
    const presetTeams = _.map(selectedTeams, '_id');
    const presetVibes = _.map(selectedVibes, '_id');

    // Need to check existence of isAdmin field for tagAdmins
    const isAdmin = (this.isAdmin) ? this.isAdmin.checked : false;

    let valid = true;
    for (let i = 0; i < emails.length; i += 1) {
      if (!Indicative.is.email(emails[i])) {
        valid = false;
        break;
      }
    }
    if (valid) {
      this.props.createInvites(
        this.props.auth.user.firstName, this.props.auth.user.lastName,
        emails, this.props.organization.data,
        presetLocations, presetTeams, presetVibes, isAdmin,
        this.props.auth.token,
      );
      // Clear local state vars
      this.setState({ selectedLocations: null, selectedTeams: null, selectedVibes: null });
      this.props.closeModal();
    } else {
      this.showUpdateAlert('Invalid email format. Please check your emails and try again.');
    }
  };

  render() {
    const {
      invitation,
      locationsList, teamsList, vibesList,
      viewerIsOrgAdmin,
    } = this.props;

    return (
      <div id="user-invite">
        <Alert
          type="success callout text-center"
          showAlert={Object.keys(invitation.data).length !== 0}
          handleClose={this.props.clearInvite}
          autoClose={!invitation.data.keepOpen}
          closeButton={invitation.data.keepOpen}>
          {invitation.data.validEmails && <h5>Your invites have been delivered to:<br />{invitation.data.validEmails}</h5>}
          {invitation.data.invalidEmails && <h5>These users already exisit and won&#39;t be sent invites:<br />{invitation.data.invalidEmails}</h5>}
        </Alert>
        <Alert
          type="alert callout text-center"
          autoClose={false}
          closeButton={true}
          showAlert={Object.keys(invitation.error).length !== 0}
          handleClose={this.props.clearInvite}>
          <h5>{invitation.error.message}</h5>
        </Alert>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          className="modal large"
          overlayClassName="modal-overlay"
          contentLabel="Invite users modal">
          <div id="user-invite">
            <Alert
              type="alert callout text-center"
              message={this.state.calloutMessage}
              showAlert={this.state.updateAlert}
              handleClose={this.hideUpdateAlert} />
            <br />
            <div className="row small-12 medium-6 large-6 columns">
              <h3 className="text-center"><i className="fi-mail" style={{ color: '#8a8a8a' }} />&nbsp;&nbsp;Invite people</h3>
              <p className="text-center">You can send invites to people inside and outside your organization</p>

              <label><strong>Enter email(s)</strong>
                <textarea
                  className="textarea"
                  ref={(e) => { this.emailList = e; }}
                  rows="3"
                  aria-describedby="emailHelpText"
                  placeholder="janedoe@company.com, johndoe@company.com, juniordoe@company.com" />
              </label>
              <p className="help-text text-center" id="emailHelpText">
                Paste one or many email addresses but make sure each email is seperated by a comma
              </p>
              <hr />
              <p className="text-center">Optionally pre-assign users to a location, teams, or boards</p>

              {/* Preset location(s) */}
              <label><strong>Assign users to a location</strong></label>
              {locationsList && locationsList.length > 1 ?
                <div>
                  <ReactSelect
                    classNamePrefix="react-select"
                    menuPosition="fixed"
                    placeholder="Select a location"
                    options={locationsList}
                    getOptionLabel={option => option.displayName}
                    getOptionValue={option => option._id}
                    onChange={option => this.handleLocationChange(option)}
                  />
                  <br />
                </div>
                :
                <p>-&gt; Users will be automatically added to <strong>{locationsList[0] && locationsList[0].displayName}</strong></p>
              }

              {/* Preset teams */}
              <label><strong>Assign users to teams</strong></label>
              <ReactSelect
                classNamePrefix="react-select"
                menuPosition="fixed"
                placeholder="Select one or more teams"
                isMulti={true}
                options={teamsList}
                getOptionLabel={option => option.displayName}
                getOptionValue={option => option._id}
                noOptionsMessage={() => 'Your organization does not have any teams setup yet'}
                onChange={option => this.updateSelectedTeams(option)}
              />
              <br />

              {/* Preset boards */}
              <label><strong>Assign users to boards you manage</strong></label>
              <ReactSelect
                classNamePrefix="react-select"
                menuPosition="fixed"
                placeholder="Select one or more boards"
                isMulti={true}
                options={vibesList}
                getOptionLabel={option => option.title}
                getOptionValue={option => option._id}
                noOptionsMessage={() => 'We couldn\'t find any boards that you manage'}
                onChange={option => this.updateSelectedVibes(option)}
              />
              <br />

              {/* Global admin */}
              {viewerIsOrgAdmin &&
                <label><strong>Assign admin privileges</strong><br />
                  <input type="checkbox" ref={(e) => { this.isAdmin = e; }} />&nbsp;Grant invited users global admin privileges
                </label>
              }

              <button type="button" className="expanded button margin-top-15" onClick={this.handleCreateInvites}>Send invites</button>
              <br />
            </div>

            <button className="close-button" onClick={this.props.closeModal} data-close="" aria-label="Close reveal" type="button">
              <span aria-hidden="true"><small>&times;</small></span>
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}


UserInvite.propTypes = {
  auth: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  locationsList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  teamsList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  vibesList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  invitation: PropTypes.object.isRequired,
  createInvites: PropTypes.func.isRequired,
  clearInvite: PropTypes.func.isRequired,
  viewerIsOrgAdmin: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UserInvite;
