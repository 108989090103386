const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const moment = require('moment');

const utils = require('../../../../../common/utils').default;

class DiscussionsListItem extends React.PureComponent {
  render() {
    const { discussion, boardId } = this.props;
    const content = (discussion.content)
      ? utils.formatPreview(discussion.content.rich)
      : { preview: '', imageCount: 0, featuredImage: '' };

    const contentPreview = content.preview || '';
    // const imageCount = content.imageCount || 0;

    // Get featuredImage
    let { featuredImage } = discussion;

    // For older posts that don't have a stored featured image
    featuredImage = (featuredImage && featuredImage.length > 0) ? featuredImage : content.featuredImage;

    const numReplies = discussion.meta.replyCount;

    return (
      <div className="callout vibe-card">
        {(this.props.lastPosterId && this.props.lastPosterPhotoUrl) && (
          <div className="media-object vibe-card-user-head">
            <div className="media-object-section-tight">
              <span className="post-meta text-right">{moment(this.props.createdAt).fromNow()} by</span>
            </div>
            <div className="media-object-section">
              <Link to={`/profile/${this.props.lastPosterId}`}>
                <img
                  className="image-thumb"
                  src={utils.formatImage(this.props.lastPosterPhotoUrl, 'userThumb')}
                  alt={this.props.lastPosterName}
                  onError={utils.showDefaultImage}
                  title={this.props.lastPosterName} />
              </Link>
            </div>
          </div>)
        }
        <Link to={`/board/${boardId}/post/${this.props.id}`}>
          <p className="vibe-card-title">{discussion.title}</p>
          {featuredImage && (
            <div className="vibe-card-image hide-for-small-only">
              <img src={featuredImage} alt="" />
            </div>)
          }

          <p className="vibe-card-body" dangerouslySetInnerHTML={{ __html: contentPreview }} />

          <div className="row post-meta">
            <div className="small-6 columns">Read more...</div>
            <div className="small-6 columns text-right">
              {(numReplies > 0) && numReplies + ((numReplies > 1) ? ' replies' : ' reply')}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

DiscussionsListItem.propTypes = {
  discussion: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lastPosterId: PropTypes.string.isRequired,
  lastPosterName: PropTypes.string.isRequired,
  lastPosterPhotoUrl: PropTypes.string.isRequired,
};

export default DiscussionsListItem;
