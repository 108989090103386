const React = require('react');
const PropTypes = require('prop-types');

class LoginForm extends React.Component {
    handleSubmit = (event) => {
      event.preventDefault();
      const email = this.email.value.toLowerCase();
      const password = this.password.value;
      this.props.loginUser(email, password);
    };

    render() {
      const { emailValue = '', emailPlaceholder, emailHelp } = this.props;
      return (
        <div>
          <p><strong>{this.props.emailLabel}</strong></p>
          {(this.props.disabledEmail) ?
            <div>
              <h4><strong>{this.props.emailValue}</strong></h4>
              <input
                className="input"
                type="hidden"
                id="user-email"
                defaultValue={emailValue}
                placeholder={emailPlaceholder}
                ref={(e) => { this.email = e; }}
              />
            </div>
            :
            <input
              className="input"
              type="email"
              id="user-email"
              defaultValue={emailValue}
              placeholder={emailPlaceholder}
              ref={(e) => { this.email = e; }}
            />}
          <span className="input-error">{emailHelp}</span>

          <p className="margin-top-15"><strong>{this.props.passwordLabel}</strong></p>
          {(this.props.disabledEmail) ?
            <input
              className="input"
              type="password"
              id="user-password"
              placeholder={this.props.passwordPlaceholder}
              ref={(e) => { this.password = e; }}
              autoComplete="user-password" />
            :
            <input
              className="input"
              type="password"
              id="user-password"
              placeholder={this.props.passwordPlaceholder}
              ref={(e) => { this.password = e; }} />}
          <br />
          <span className="input-error">{this.props.passwordHelp}</span>

          <input id="show-password" className={this.props.rememberMe} type="checkbox" />
          <label htmlFor="show-password" className={this.props.rememberMe}>Remember me</label>
          <br />
          <div className="text-center">
            <button type="button" onClick={this.handleSubmit} className="button large">{this.props.buttonText}</button>
          </div>
        </div>
      );
    }
}

LoginForm.propTypes = {
  emailValue: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  emailHelp: PropTypes.string,
  disabledEmail: PropTypes.bool,
  passwordLabel: PropTypes.string,
  passwordPlaceholder: PropTypes.string,
  passwordHelp: PropTypes.string,
  rememberMe: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  loginUser: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  emailValue: '',
  emailLabel: 'Your email',
  emailPlaceholder: 'you@example.com',
  emailHelp: '',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Choose a password (at least 8 characters)',
  passwordHelp: '',
  disabledEmail: false,
};

export default LoginForm;
