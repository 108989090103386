import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import loginUtils from '../utils/LoginUtils';

const Profile = (function () {
  const _createProfileRequest = function () {
    return {
      type: constants.AUTH_CREATE_PROFILE_REQUEST,
    };
  };

  const _createProfileSuccess = function (data) {
    return {
      type: constants.AUTH_CREATE_PROFILE_SUCCESS,
      data: data.user,
    };
  };

  const _createProfileError = function (error) {
    return {
      type: constants.AUTH_CREATE_PROFILE_ERROR,
      errors: error.data,
    };
  };

  const _validationError = function (errors) {
    return {
      type: constants.AUTH_CREATE_PROFILE_ERROR,
      errors,
    };
  };

  const _clearAuthError = function () {
    return {
      type: constants.CLEAR_CREATE_PROFILE_ERROR,
    };
  };

  const _createProfile = function (newUser, accessToken, org, externalOrg, inviteType, inviteToken) {
    return function (dispatch) {
      loginUtils.validateCreateProfile(newUser.firstName, newUser.lastName, newUser.title, externalOrg)
        .then((result) => {
          if (result === true) {
            dispatch(_createProfileRequest());
            const user = {
              title: newUser.title,
              photoUrl: newUser.photoUrl,
              tags: newUser.tags,
              orgId: org._id,
              org: {
                id: org._id,
                teamDomain: org.teamDomain,
                orgName: org.name,
              },
              invite_token: inviteToken,
              inviteType,
            };
            if (newUser.firstName && newUser.lastName) {
              user.firstName = newUser.firstName;
              user.lastName = newUser.lastName;
            }
            if (externalOrg) {
              user.externalOrg = externalOrg;
            }
            if (newUser.timeZone) {
              user.timeZone = newUser.timeZone;
            }
            api.put('/proxy/v1/users', user, accessToken)
              .then((res) => {
                dispatch(_createProfileSuccess(res));
                if (inviteType === 'joinOrg' || inviteType === 'selfJoin') {
                  dispatch(routerActions.push('/'));
                } else if (inviteType === 'createOrg') {
                  dispatch(routerActions.push('/org-confirmation'));
                }
                // dispatch(routerActions.push('/?teamdomain='+org.teamDomain+'&invite='+inviteToken));
              })
              .catch((res) => {
                dispatch(_createProfileError(res));
              });
          } else {
            dispatch(_validationError(result));
          }
        });
    };
  };

  return {
    createProfile: _createProfile,
    clearAuthError: _clearAuthError,
  };
}());

export default Profile;
