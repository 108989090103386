import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserActions from '../../../modules/auth/actions/auth-current-user';
import JoinOrgActions from '../../../modules/auth/actions/joinOrg';
import TagsActions from '../../../modules/tags/actions/tags';
import InviteActions from '../../../modules/invitations/actions/get-invitation';
import ProfileDetails from '../components/orgJoin.jsx';

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    organization: state.organization,
    tags: state.tags,
    invitation: state.invitation,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign(
    {}, UserActions, JoinOrgActions, TagsActions, InviteActions,
  ), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
