import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import initialState from './initialState';
import rootReducer from './reducers';

const nodeEnv = process.env.NODE_ENV || 'development';
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

function configureStore(preloadedState, routerState) {
  const state = preloadedState || initialState;

  if (nodeEnv === 'development') {
    const logger = createLogger({
      collapsed: true,
    });

    return createStore(
      rootReducer,
      state,
      composeEnhancers(applyMiddleware(
        thunk,
        routerMiddleware(routerState),
        logger,
      )),
    );
  }

  return createStore(
    rootReducer,
    state,
    applyMiddleware(
      thunk,
      routerMiddleware(routerState),
    ),
  );
}

export { configureStore };
