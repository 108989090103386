const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const moment = require('moment');

const EventsWidgetItem = require('./eventsWidgetItem.jsx').default;
const EventInputModal = require('../containers/eventInputContainer.jsx').default;
const utils = require('../../../../../common/utils').default;

class EventsWidget extends React.PureComponent {
  state = { inputModalIsOpen: false, eventInputMode: 'create' };

  componentDidMount() {
    this.props.fetchUpcomingEventsIfNeeded(this.props.selectedVibe.data._id, 5, this.props.organization.data._id, this.props.auth.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedVibe.data._id !== this.props.selectedVibe.data._id) {
      nextProps.fetchUpcomingEventsIfNeeded(nextProps.selectedVibe.data._id, 5, nextProps.organization.data._id, nextProps.auth.token);
    }
  }

  componentDidUpdate() {
    if (this.props.events.closeInputModal === true) {
      this.props.events.closeInputModal = false;
      this.closeEventInputModal();
    }
  }

  openEventInputModal = (mode) => {
    if (mode === 'create') { this.props.clearSelectedEvent(); }
    const eventItem = this.props.selectedEvent;

    // Set vibe to current vibe
    eventItem.data.vibe = this.props.selectedVibe.data;

    this.props.setSelectedEvent(eventItem.data);
    this.setState({ inputModalIsOpen: true, eventInputMode: mode });
  };

  closeEventInputModal = () => {
    this.props.clearSelectedEvent();
    this.setState({ inputModalIsOpen: false });
  };

  render() {
    const selectedVibe = this.props.selectedVibe.data;
    const isFetching = this.props.events.isFetching;

    if (!isFetching) {
      let pastEvent = null;
      const eventsList = this.props.events.data.filter((event) => {
        return moment().isSameOrBefore(moment(event.startDate), 'day');
      }).map((event, i) => {
        let sameDate = false;
        const startDate = moment(event.startDate).format('DD/MM/YYYY');
        if (pastEvent === startDate) {
          sameDate = true;
        }
        pastEvent = moment(event.startDate).format('DD/MM/YYYY');

        return (<EventsWidgetItem
          key={event._id}
          itemKey={i}
          eventItem={event}
          selectedEvent={this.props.selectedEvent}
          sameDate={sameDate}
          vibe={this.props.vibe}
          auth={this.props.auth}
          organization={this.props.organization}
          joinVibe={this.props.joinVibe}
          changeRSVP={this.props.changeRSVP}
          setSelectedEvent={this.props.setSelectedEvent}
          clearSelectedEvent={this.props.clearSelectedEvent}
          openEventInputModal={this.openEventInputModal} />);
      });

      return (
        <div>
          <div className="callout">
            <div className="clearfix border-bottom">
              <div className="float-left">
                <h4 className="post-title">Upcoming events</h4>
              </div>
            </div>
            <div className="clearfix margin-top-15">
              {eventsList.length > 0 ? eventsList : <div className="text-center"><br />No upcoming events scheduled<br /><br /><br /></div>}
            </div>
            <p><Link className="button expanded hollow round" to={`/board/${selectedVibe._id}/calendar`}>View full calendar</Link></p>
          </div>
          <EventInputModal
            mode={this.state.eventInputMode}
            inputModalIsOpen={this.state.inputModalIsOpen}
            closeInputModal={this.closeEventInputModal}
            selectedVibe={this.props.selectedVibe} />
        </div>
      );
    }

    return (
      <div>
        <h1 className="loading">Loading...</h1>
      </div>
    );
  }
}

EventsWidget.propTypes = {
  selectedVibe: PropTypes.object.isRequired,
};

export default EventsWidget;
