import constants from '../constants';
import initialState from '../../initialState';

const user = function (state, action) {
  const newstate = Object.assign({}, state);
  switch (action.type) {
  case constants.REQUEST_USER_DETAILS:
    newstate.isFetching = true;
    return newstate;
  case constants.RECEIVE_USER_DETAILS:
    newstate.isFetching = false;
    newstate.data = action.user;
    return newstate;
  case constants.REQUEST_USER_DETAILS_ERROR:
    newstate.isFetching = false;
    newstate.data = {};
    newstate.error = action.errors;
    return newstate;
  case constants.SET_USER:
    newstate.isFetching = false;
    newstate.data = action.user;
    return newstate;
  case constants.GROUP_EMAIL_SUCCESS:
    newstate.isFetching = false;
    newstate.emailSent = true;
    return newstate;
  case constants.CLEAR_GROUP_EMAIL:
    newstate.isFetching = false;
    newstate.emailSent = false;
    return newstate;
  default: return state || initialState.user;
  }
};

export default user;
