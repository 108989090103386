const React = require('react');
const PropTypes = require('prop-types');
const { withRouter, Link } = require('react-router');
const CSSTransitionGroup = require('react-transition-group/CSSTransitionGroup');

const SearchBox = require('./searchBox.jsx').default;
const utils = require('../../../common/utils').default;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.burger = React.createRef();
    this.menu = React.createRef();

    this.state = {
      showMenu: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  toggleHamburger = () => {
    if (this.menu.current && this.burger.current) {
      if (this.menu.current.classList.contains('is-active')) {
        this.menu.current.classList.remove('is-active');
      } else {
        this.menu.current.classList.add('is-active');
      }

      if (this.burger.current.classList.contains('is-active')) {
        this.burger.current.classList.remove('is-active');
      } else {
        this.burger.current.classList.add('is-active');
      }
    }
  };

  closeHamburger = () => {
    if (this.menu.current && this.burger.current) {
      this.menu.current.classList.remove('is-active');
      this.burger.current.classList.remove('is-active');
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  }

  handleClickOutside = (event) => {
    if (this.state.showMenu && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showMenu: false });
    }
  };

  handleLogoutClick = () => {
    this.setState({ showMenu: false });
    this.props.logout();
  };

  render() {
    const organization = this.props.organization.data;
    const userFullName = `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`;

    if (this.props.auth.token && this.props.auth.user.orgs) {
      const userIsOrgAdmin = utils.userIsOrgAdmin(organization._id, this.props.auth.user.orgs);
      return (
        <header className="header">
          {/* Logged in navbar */}
          <nav className="navbar is-fixed-top-mobile is-transparent" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              { organization.hasOwnProperty('logo')
                ? <div className="logo-container"><Link to="/" id="logo" onClick={this.closeHamburger} title="Back to home"><img src={organization.logo} alt={organization.name} /></Link></div>
                : <h3><Link to="/" id="logo" className="common-link" onClick={this.closeHamburger} title="Back to home">{organization.name}</Link></h3>
              }

              <button
                onClick={this.toggleHamburger}
                className="navbar-burger burger"
                type="button"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                ref={this.burger}>
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>

            <div className="navbar-menu" ref={this.menu}>
              {/* Hamburger menu (mobile & tablet) */}
              <div className="navbar-start is-hidden-desktop">
                <Link to="/" className="navbar-item" onClick={this.toggleHamburger}>Boards</Link>
                <Link to="/calendar" className="navbar-item" onClick={this.toggleHamburger}>Calendar</Link>
                <Link to="/members" className="navbar-item" onClick={this.toggleHamburger}>Members</Link>
                <Link to={`/profile/${this.props.auth.user._id}`} className="navbar-item" onClick={this.toggleHamburger}>View/edit profile</Link>
                <a className="navbar-item" onClick={this.handleLogoutClick}>Log out</a>
                <SearchBox
                  className="navbar-item"
                  router={this.props.router}
                  auth={this.props.auth}
                  organization={this.props.organization}
                  vibes={this.props.vibes}
                  autoRedirect={false}
                  toggleHamburger={this.toggleHamburger} />
              </div>

              {/* Middle search - desktop */}
{/*               <div className="navbar-start is-hidden-mobile is-hidden-tablet-only margin-top-15" style={{ flexGrow: 1, justifyContent: 'center' }}>
                <SearchBox
                  router={this.props.router}
                  auth={this.props.auth}
                  organization={this.props.organization}
                  vibes={this.props.vibes}
                  autoRedirect={true} />
              </div> */}

              {/* Right icon menu - desktop */}
              <div className="navbar-end is-hidden-mobile is-hidden-tablet-only">
                <div className="navbar-item badge-container">
                  <Link to="/"><i className="fi-home notification-icon" /></Link>
                </div>
                <div className="navbar-item badge-container">
                  <Link to="/calendar"><i className="fi-calendar notification-icon" /></Link>
                </div>
                <div className="navbar-item badge-container">
                  <Link to="/members"><i className="fi-torsos notification-icon" /></Link>
                </div>
                <div className="navbar-item" ref={this.setWrapperRef}>
                  <button type="button" onClick={this.toggleMenu}>
                    <img
                      className="image-thumb"
                      src={utils.formatImage(this.props.auth.user.photoUrl, 'userThumb')}
                      alt={userFullName}
                      onError={utils.showDefaultImage} />
                  </button>
                  <CSSTransitionGroup
                    transitionName="fade"
                    transitionEnter={true}
                    transitionEnterTimeout={0}
                    transitionLeave={false}>
                    {this.state.showMenu &&
                      <div id="user-settings-menu">
                        <div className="menu-header">{userFullName}</div>
                        <div className="menu-body">
                          <ul className="menu vertical">
                            <li>
                              <Link to={`/profile/${this.props.auth.user._id}`} onClick={this.toggleMenu}>View/edit profile</Link>
                            </li>
                            <li>
                              <Link to="/settings" onClick={this.toggleMenu}>User settings</Link>
                            </li>
                            {userIsOrgAdmin &&
                            <li role="menuitem">
                              <Link to="/admin" onClick={this.toggleMenu}>Admin panel</Link>
                            </li>
                            }
                            <li role="menuitem">
                              <button type="button" className="link" onClick={this.handleLogoutClick}>Log out</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    }
                  </CSSTransitionGroup>
                </div>
              </div>
            </div>
          </nav>
        </header>
      );
    }
    return (
      <header className="header">
        {/* Logged out navbar */}
        <nav className="navbar is-fixed-top-mobile" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            { organization.hasOwnProperty('logo')
              ? <div className="logo-container"><Link to="/" id="logo" onClick={this.closeHamburger} title="Back to home"><img src={organization.logo} alt={organization.name} /></Link></div>
              : <h3><Link to="/" id="logo" className="common-link" onClick={this.closeHamburger} title="Back to home">{organization.name}</Link></h3>
            }

            {/* Puts link at right for mobile and tablet */}
            <Link to="/login" className="navbar-item is-hidden-desktop">Login</Link>
            <Link to="/sign-up" className="navbar-item is-hidden-desktop">Sign up</Link>
          </div>

          {/* Puts link at right for desktop */}
          <div className="navbar-menu">
            <div className="navbar-end">
              <Link to="/login" className="navbar-item">Login</Link>
              <Link to="/sign-up" className="navbar-item">Sign up</Link>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object,
  router: PropTypes.object.isRequired,
  vibes: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

export default withRouter(Header);
