import constants from '../constants';
import api from '../../../utils/api';

const CreateInvitation = (function () {
  const _requestInvite = function () {
    return {
      type: constants.CREATE_INVITATION_REQUEST,
    };
  };

  const _createInviteSuccess = function (data) {
    return {
      type: constants.CREATE_INVITATION_SUCCESS,
      data,
    };
  };

  const _createInviteError = function (error) {
    return {
      type: constants.CREATE_INVITATION_ERROR,
      errors: error.response.data,
    };
  };

  const _clearInvite = function () {
    return {
      type: constants.CLEAR_INVITE,
    };
  };

  const _createInvite = function (email, inviteType, organization) {
    return function (dispatch) {
      dispatch(_requestInvite());
      api.post('/proxy/v1/invitations', {
        email,
        inviteType,
        orgId: organization._id,
        orgTeamDomain: organization.teamDomain,
        orgName: organization.name,
        orgLogo: organization.logo,
      })
        .then((res) => {
          dispatch(_createInviteSuccess(res));
        })
        .catch((res) => {
          dispatch(_createInviteError(res));
        });
    };
  };

  return {
    createInvite: _createInvite,
    clearInvite: _clearInvite,
  };
}());

export default CreateInvitation;
