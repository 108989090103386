const indicative = require("indicative");

const Indicative = new indicative();

const VibeValidations = (function () {
  const _validateVibe = function (title, description, vibeLayout) {

    const data = {
      title,
      description,
    };

    const rules = {};
    if (vibeLayout !== 'single') {
      rules.title = 'required|min:3|max:100';
      rules.description = 'required|max:300';
    }

    const messages = {
      'title.required': 'Title is required',
      'title.min': 'Your title must be at least 3 characters',
      'title.max': 'Title has a maximum length of 100 characters',
      'description.required': 'Description is required',
      'description.max': 'Descrption has a maximum length of 300 characters',
    };

    return Indicative
      .validateAll(rules, data, messages)
      .then(() => {
        return true;
      })
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }
        return errorMessage;
      });
  };

  return {
    validateVibe: _validateVibe,
  };
}());

export default VibeValidations;
