import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrgActions from '../../../modules/organizations/actions/organization';
import AuthActions from '../../../modules/auth/actions/auth-profile';
import LogoutActions from '../../../modules/auth/actions/auth-logout';
import Main from '../components/main.jsx';

  var mapStateToProps = function(state){
	  return {
	    auth: state.auth,
	    organization: state.organization,
	  };
  }

  var mapDispatchToProps = function(dispatch) {
    return bindActionCreators(Object.assign({}, OrgActions, AuthActions, LogoutActions), dispatch);
  }

export default connect(mapStateToProps, mapDispatchToProps)(Main);
