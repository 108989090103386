export default {
  REQUEST_TAGS: 'REQUEST_TAGS',
  RECEIVE_TAGS: 'RECEIVE_TAGS',
  REQUEST_TAGS_ERROR: 'REQUEST_TAGS_ERROR',
  ADD_TAG: 'ADD_TAG',
  SAVE_TAGS_REQUEST: 'SAVE_TAGS_REQUEST',
  SAVE_TAGS_SUCCESS: 'SAVE_TAGS_SUCCESS',
  SAVE_TAGS_ERROR: 'SAVE_TAGS_ERROR',
  SORT_TAGS: 'SORT_TAGS',
  CLEAN_TAGS: 'CLEAN_TAGS',
  CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',
  UPDATE_TAG_REQUEST: 'UPDATE_TAG_REQUEST',
  UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
  UPDATE_TAG_ERROR: 'UPDATE_TAG_ERROR',
  SET_SELECTED_TAG: 'SET_SELECTED_TAG',
  CLEAR_SELECTED_TAG: 'CLEAR_SELECTED_TAG',
  CLEAR_TAG_ERROR: 'CLEAR_TAG_ERROR',
};
