import constants from '../constants';
import api from '../../../utils/api';

const ReactPost = (function () {
  const _reactDiscussionRequest = function () {
    return {
      type: constants.REACT_DISCUSSION_REQUEST,
    };
  };

  const _reactDiscussionSuccess = function (data) {
    return {
      type: constants.REACT_DISCUSSION_SUCCESS,
      discussion: data,
    };
  };

  const _reactDiscussionError = function (error) {
    return {
      type: constants.REACT_DISCUSSION_ERROR,
      errors: error.data,
    };
  };

  const _reactReplyError = function (error) {
    return {
      type: constants.REACT_REPLY_ERROR,
      errors: error.data,
    };
  };

  const _reactReplyRequest = function () {
    return {
      type: constants.REACT_REPLY_REQUEST,
    };
  };

  const _reactReplySuccess = function (data) {
    return {
      type: constants.REACT_REPLY_SUCCESS,
      reply: data.reply,
    };
  };

  const _reactDiscussion = function (discussionId, reactionType, fullName, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_reactDiscussionRequest());
      api.put(`/proxy/v1/discussions/${discussionId}/react`, {
        reactionType,
        fullName,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_reactDiscussionSuccess(res));
        })
        .catch((res) => {
          dispatch(_reactDiscussionError(res));
        });
    };
  };

  const _reactReply = function (replyId, reactionType, fullName, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_reactReplyRequest());
      api.put(`/proxy/v1/reply/${replyId}/react`, {
        reactionType,
        fullName,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(_reactReplySuccess(res));
        })
        .catch((res) => {
          dispatch(_reactReplyError(res));
        });
    };
  };

  return {
    reactDiscussion: _reactDiscussion,
    reactReply: _reactReply,
  };
}());

export default ReactPost;
