import constants from '../constants';
import api from '../../../utils/api';

const VibesPublic = (function () {
  const _requestVibesPublic = function () {
    return {
      type: constants.REQUEST_VIBES_PUBLIC,
    };
  };

  const _receiveVibesPublic = function (data) {
    return {
      type: constants.RECEIVE_VIBES_PUBLIC,
      vibesPublic: data.vibes,
    };
  };

  const _fetchVibesPublicError = function (error) {
    return {
      type: constants.REQUEST_VIBES_PUBLIC_ERROR,
      errors: error.data,
    };
  };

  const _fetchVibesPublic = function (orgId, locationTag, accessToken) {
    return function (dispatch) {
      dispatch(_requestVibesPublic());
      api.get('/proxy/v1/vibes/public', {
        orgId,
        location: locationTag.location,
      }, accessToken)
        .then((res) => {
          dispatch(_receiveVibesPublic(res));
        })
        .catch((res) => {
          dispatch(_fetchVibesPublicError(res));
        });
    };
  };

  const _shouldFetchVibesPublic = function (state) {
    const vibesPublic = state.vibesPublic.data;
    if (Object.keys(vibesPublic).length === 0) {
      return true;
    }
    return false;
  };

  const _fetchVibesPublicIfNeeded = function (orgId, locationTag, accessToken) {
    return function (dispatch, getState) {
      if (_shouldFetchVibesPublic(getState())) {
        return dispatch(_fetchVibesPublic(orgId, locationTag, accessToken));
      }
      return false;
    };
  };

  return {
    fetchVibesPublic: _fetchVibesPublic,
    fetchVibesPublicIfNeeded: _fetchVibesPublicIfNeeded,
  };
}());

export default VibesPublic;
