const React = require('react');
const PropTypes = require('prop-types');

const Footer = function (props) {
  const { footer } = props;

  return (
    <footer className="footer">
      {(footer) &&
        <div className="row full-width text-center subheader">
          <div><span dangerouslySetInnerHTML={{ __html: footer }} /><br /><br /></div>
        </div>
      }
      <div className="row full-width hide">
        <ul className="menu">
          <li>&copy; {new Date().getFullYear()} Teamvibe</li>
        </ul>
      </div>
    </footer>);
};

Footer.propTypes = {
  footer: PropTypes.string,
};

Footer.defaultProps = {
  footer: '',
};

export default Footer;
