const React = require('react');
const PropTypes = require('prop-types');

const Tab = (props) => {
  const { name } = props.tab;
  const { activeTab, changeActiveTab } = props;

  return (
    <li
      className={name === activeTab ? 'tabs-title is-active' : 'tabs-title'}
      onClick={() => changeActiveTab(name)}>
      <a>
        { /* <span className="icon is-small"><i className="fa fa-image"></i></span> */ }
        <span>{name}</span>
      </a>
    </li>
  );
};

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
};

class Tabs extends React.PureComponent {
  static propTypes = {
    tabList: PropTypes.array.isRequired,
    activeTab: PropTypes.string,
    changeActiveTab: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTab: '',
  };

  render() {
    return (
      <div>
        <ul className="tabs">
          { this.props.tabList.map(tab => (
            <Tab
              tab={tab}
              key={tab.name}
              activeTab={this.props.activeTab}
              changeActiveTab={this.props.changeActiveTab}
            />
          ))
          }
        </ul>
      </div>
    );
  }
}

export default Tabs;
