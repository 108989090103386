import ReactSelect from 'react-select';

const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');
// const CleanMarkdown = require('../../components/cleanMarkdown.jsx').default;
const utils = require('../../../utils').default;
const ImageUploadComponent = require('../../settings/components/imageUploadComponent.jsx').default;

class ProfileHeaderEditor extends React.Component {
  state = {
    errors: {},
  };

  changeProfilePictureUrl = () => {
    // Legacy function used in userBioSettings to store photoUrl for refs
    // this.refs.photoUrl.defaultValue = value;
  };

  handleOnImageUpload = (photoUrl) => {
    const { user } = this.props;
    user.data.photoUrl = photoUrl;
    this.props.setUser(user.data);
  };

  handleChange = (event) => {
    const { user } = this.props;

    if (this.state.errors[event.target.name]) {
      const currentState = this.state;
      delete currentState.errors[event.target.name];
      this.setState(currentState);
    }

    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;

      if (event.target.name === 'phoneSingle') {
        const phoneNumber = {
          type: 'office',
          number: inputValue,
          isActive: true,
        };
        const phoneNumbers = [];
        phoneNumbers.push(phoneNumber);
        user.data.phoneNumbers = phoneNumbers;
      } else {
        user.data[event.target.name] = inputValue;
      }
      delete user.error[event.target.name];
    }

    this.props.setUser(user.data);
  };

  handleUserOrgChange = (event) => {
    const organization = this.props.organization.data;
    const { user } = this.props;
    const userCurrentOrg = _.find(user.data.orgs, { id: organization._id });
    const externalOrg = userCurrentOrg.externalOrg || {};

    if (this.state.errors[event.target.name]) {
      const currentState = this.state;
      delete currentState.errors[event.target.name];
      this.setState(currentState);
    }

    if (event.target) {
      const inputValue = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;

      if (event.target.name === 'externalOrgName') {
        externalOrg.orgName = inputValue;
        userCurrentOrg.externalOrg = externalOrg;
      } else if (event.target.name === 'externalOrgUrl') {
        externalOrg.orgUrl = inputValue;
        userCurrentOrg.externalOrg = externalOrg;
      } else {
        userCurrentOrg[event.target.name] = inputValue;
      }
      delete user.error[event.target.name];
    }

    // Upsert userOrg into user object and update state
    user.data.orgs = utils.upsertByField(user.data.orgs, 'id', organization._id, userCurrentOrg);
    this.props.setUser(user.data);
  };

  handleLocationChange = (selectedValue) => {
    const organization = this.props.organization.data;
    const { user } = this.props;
    const tags = this.props.tags.data;
    const userCurrentOrg = _.find(user.data.orgs, { id: organization._id });

    // Filter out any location tags and add selected location
    // let userTags = utils.getUserTags(userCurrentOrg, tags);
    var userTags = utils.getUserTags(userCurrentOrg, tags);
    userTags = _.reject(userTags, { tagType: 'location' });
    userTags.push(selectedValue);
    userCurrentOrg.tags = _.map(userTags, '_id');

    // Upsert userOrg into user object and update state
    user.data.orgs = utils.upsertByField(user.data.orgs, 'id', organization._id, userCurrentOrg);
    this.props.setUser(user.data);
  };

  handleSaveProfile = () => {
    const { user } = this.props;
    this.props.editUserFull(user.data, this.props.organization.data._id, this.props.auth.token);
    this.props.toggleEditProfile(false);
  };

  handleCancelEdit = () => {
    this.props.toggleEditProfile(false);
  };

  render() {
    const organization = this.props.organization.data;
    const user = this.props.user.data;
    const userTags = this.props.userTags;
    const { userCurrentOrg, phoneSingle } = this.props;

    // Get user location tag (there should only be one - will use first instance)
    const userlocationTag = _.find(userTags, { tagType: 'location' });

    const tags = this.props.tags.data;
    // Full location list for org
    const locationTags = tags.filter(tag => tag.tagType === 'location' && tag.isActive);

    // let externalOrgName;
    // let externalOrgUrl;
    var externalOrgName;
    var externalOrgUrl;

    if (organization.preferences.showExternalOrg && userCurrentOrg.externalOrg) {
      externalOrgName = userCurrentOrg.externalOrg.orgName;
      externalOrgUrl = userCurrentOrg.externalOrg.orgUrl;
    }

    return (
      <div className="media-object expanded stack-for-small">
        <div className="media-object-section">
          <ImageUploadComponent
            user={user}
            organization={organization}
            changeProfilePictureUrl={this.changeProfilePictureUrl}
            handleOnImageUpload={this.handleOnImageUpload} />
        </div>
        <div className="media-object-section profile-header-text">
          <button
            type="button"
            className="button margin-left-15 float-right"
            title="Save profile"
            onClick={this.handleSaveProfile}><i className="fa fa-edit" /> Save profile
          </button>
          {/* <button className="button float-right" title="Cancel" onClick={this.handleCancelEdit}><i className="fa fa-times" /> Cancel</button> */}
          <div className="row margin-top-15">
            <div className="small-6 columns end">
              <div className="field-body">
                <div className="field">
                  <input className="input" defaultValue={user.firstName} name="firstName" onChange={this.handleChange} type="text" placeholder="First name" />
                </div>
                <div className="field">
                  <input className="input" defaultValue={user.lastName} name="lastName" onChange={this.handleChange} type="text" placeholder="Last name" />
                </div>
              </div>
            </div>
          </div>
          <br />
          {(organization.preferences && organization.preferences.showExternalOrg) ?
            <div className="row">
              <div className="small-3 columns">
                <input
                  className="input"
                  defaultValue={userCurrentOrg.title}
                  name="title"
                  onChange={this.handleUserOrgChange}
                  type="text"
                  placeholder="Job title" />
              </div>
              <div className="small-3 margin-left-15 columns">
                <input
                  className="input"
                  defaultValue={externalOrgName}
                  name="externalOrgName"
                  onChange={this.handleUserOrgChange}
                  type="text"
                  placeholder="Organization" />
              </div>
              <div className="small-3 margin-left-15 columns end">
                <input
                  className="input"
                  defaultValue={externalOrgUrl}
                  name="externalOrgUrl"
                  onChange={this.handleUserOrgChange}
                  type="text"
                  placeholder="Org URL (optional)" />
              </div>
            </div>
            :
            <div className="row">
              <div className="small-6 columns end">
                <input
                  className="input"
                  defaultValue={userCurrentOrg.title}
                  name="title"
                  onChange={this.handleUserOrgChange}
                  type="text"
                  placeholder="Job title" />
              </div>
            </div>
          }
          <br />
          {(locationTags && locationTags.length > 1) &&
            <div className="row">
              <div className="small-6 columns end">
                <ReactSelect
                  placeholder="Select your location"
                  classNamePrefix="react-select"
                  menuPosition="fixed"
                  options={locationTags}
                  value={userlocationTag}
                  getOptionLabel={option => option.displayName}
                  getOptionValue={option => option._id}
                  onChange={option => this.handleLocationChange(option)}
                />
              </div>
            </div>
          }
          <br />
          {(organization.preferences && organization.preferences.showPhoneNumbers) &&
          <div className="row">
            <div className="small-6 columns end">
              <input className="input" defaultValue={phoneSingle} name="phoneSingle" onChange={this.handleChange} type="tel" placeholder="Phone" />
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

ProfileHeaderEditor.propTypes = {
  organization: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  editUserFull: PropTypes.func.isRequired,
};

export default ProfileHeaderEditor;
