import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InviteActions from '../../../modules/invitations/actions/create-invitation';
import SignUp from '../components/signUp.jsx';

const mapStateToProps = function (state) {
  return {
    organization: state.organization,
    invitation: state.invitation,
  };
};

const mapDispatchToProps = function (dispatch) {
  return bindActionCreators(Object.assign({}, InviteActions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
