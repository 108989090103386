import FloatMenu from '../../../components/floatMenu';

const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');

const Discussions = require('../../../components/discussions/containers/discussionListContainer.jsx').default;
const Events = require('../../../components/events/containers/eventsContainer.jsx').default;
// const Members = require('../../../components/users/containers/userWidget.jsx').default;
const VibeFolder = require('../../../vibes/components/layout/vibeFolder.jsx').default;

class LayoutSocial extends React.PureComponent {
  render() {
    const {
      selectedVibe, userIsOrgAdmin, userIsChampion, userIsTagAdmin,
    } = this.props;
    const { data: { memberPosting }, children } = selectedVibe;
    const userCanAdmin = userIsChampion || userIsOrgAdmin || userIsTagAdmin;

    const leftSectionClass = 'large-8 small-12 columns';
    const rightSectionClass = 'large-4 small-12 columns';

    // Child list
    const childList = _.map(children, child => (
      <VibeFolder
        key={child._id}
        boardId={child._id}
        title={child.title}
        imageUrl={child.imageUrl}
        isApproved={child.orgOptions.isApproved} />));

    return (
      <div>
        {(childList.length > 0) &&
          <div>
            <div className="row small-up-1 medium-up-3 large-up-4">
              {childList}
            </div>

            <div className="row column">
              <hr />
            </div>
          </div>
        }
        <div className="row">
          {/* Float menu controled by permissions */}
          {(userCanAdmin || memberPosting) && <FloatMenu userCanAdmin={userCanAdmin} /> }

          <div className={leftSectionClass}>
            <Discussions
              organization={this.props.organization}
              selectedVibe={this.props.selectedVibe}
              auth={this.props.auth}
              mentions={this.props.mentions}
              postId={this.props.postId} />
          </div>
          <div className={rightSectionClass}>
            <Events
              organization={this.props.organization}
              selectedVibe={this.props.selectedVibe}
              auth={this.props.auth}
              view="sidebar" />

{/* Remove members widget from layoutSocial
            <Members
              className={(!selectedVibe.showMemberList || selectedVibe.allUser) && 'hide'}
              organization={this.props.organization}
              selectedVibe={this.props.selectedVibe}
              auth={this.props.auth} /> */}
          </div>
        </div>
      </div>
    );
  }
}

LayoutSocial.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  postId: PropTypes.string,
  userIsOrgAdmin: PropTypes.bool.isRequired,
  userIsChampion: PropTypes.bool.isRequired,
  userIsTagAdmin: PropTypes.bool.isRequired,
};

LayoutSocial.defaultProps = {
  postId: null,
};

export default LayoutSocial;
