const React = require('react');
const PropTypes = require('prop-types');
const _ = require('lodash');

const utils = require('../../../../../common/utils').default;

class PostFiles extends React.Component {
  removeAttachment = (index) => {
    const postItem = this.props.post;
    const attachments = postItem.attachments || [];

    _.pullAt(attachments, index);
    postItem.attachments = attachments;
    this.props.setPost(postItem, true);
  }

  render() {
    const { post, mode } = this.props;
    const fileCount = (post.attachments) ? post.attachments.length : 0;

    let listClass;
    if (mode === 'preview' && fileCount <= 1) {
      listClass = 'row small-up-1';
    } else if (mode === 'preview') {
      listClass = 'row small-up-1 medium-up-3';
    } else {
      listClass = 'row small-up-1 medium-up-1 large-up-5';
    }

    // Attachement list
    const attachmentList = _.map(post.attachments, (attachment, i) => {
      const thumbnail = utils.getFileThumbnail(attachment.fileType);

      return (
        <div className="column text-center post-file margin-top-15" key={i}>
          {(mode === 'view' || mode === 'edit') ?
            <a href={attachment.secureUrl} target="viewer" className="common-link">
              <img src={thumbnail} alt="" />
            </a>
            : <img src={thumbnail} alt="" />
          }
          {mode === 'edit' && <button className="close" onClick={() => this.removeAttachment(i)}>x</button>}
          <br />
          {(mode === 'view' || mode === 'edit') &&
          <span>
            <a href={attachment.secureUrl} target="viewer" className="common-link">
              {attachment.fileName}
              {(attachment.pages > 1) && <span className="subheader"><br />{attachment.pages} pages</span> }
            </a>
          </span>
          }
        </div>
      );
    });

    return (
      <div>
        {(attachmentList && attachmentList.length > 0) &&
          <div className={listClass}>
            {attachmentList}
          </div>
        }
      </div>
    );
  }
}

PostFiles.propTypes = {
  post: PropTypes.object.isRequired,
  setPost: PropTypes.func,
  mode: PropTypes.string,
};

PostFiles.defaultProps = {
  mode: 'view',
};

export default PostFiles;
