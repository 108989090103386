const React = require('react');
const { Link } = require('react-router');
const PropTypes = require('prop-types');
const _ = require('lodash');
const utils = require('../../../../../common/utils').default;

class UserWidget extends React.PureComponent {
  componentDidMount() {
    this.props.fetchMembersIfNeeded(this.props.selectedVibe.data._id, this.props.organization.data._id, this.props.auth.token);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedVibe.data._id !== this.props.selectedVibe.data._id) {
      nextProps.fetchMembersIfNeeded(nextProps.selectedVibe.data._id, nextProps.organization.data._id, nextProps.auth.token);
    }
  }

  render() {
    const { selectedVibe: { isFetching, members, data: selectedVibe } } = this.props;
    const { organization: { data: organization } } = this.props;
    const orgId = organization._id;
    let memberList = members.data || [];
    const baseClass = `callout ${this.props.className}`;

    // Loading
    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading members...</h1>
        </div>
      );
    }

    const internalMembers = [];
    const externalMembers = [];

    // Remove disabled users from user array
    if (memberList.length > 0) {
      memberList = _.filter(memberList, user => utils.userIsOrgActive(this.props.organization.data._id, user.orgs));
    }

    for (let i = 0; i < memberList.length; i += 1) {
      if (_.some(memberList[i].orgs, { id: orgId })) {
        internalMembers.push(memberList[i]);
      } else {
        externalMembers.push(memberList[i]);
      }
    }

    const internalMembersList = internalMembers.map((member) => {
      return (
        <Link to={`/profile/${member._id}`} key={member._id}>
          <img
            src={utils.formatImage(member.photoUrl, 'userThumb')}
            alt={`${member.firstName} ${member.lastName}`}
            onError={utils.showDefaultImage}
            title={`${member.firstName} ${member.lastName}`} />
        </Link>
      );
    });

    const externalMembersList = externalMembers.map((member) => {
      return (
        <Link to={`/profile/${member._id}`} key={member._id}>
          <img
            src={utils.formatImage(member.photoUrl, 'userThumb')}
            alt={`${member.firstName} ${member.lastName}`}
            onError={utils.showDefaultImage}
            title={`${member.firstName} ${member.lastName}`} />
        </Link>
      );
    });

    if (Object.keys(memberList).length > 0) {
      return (
        <div className={baseClass}>
          <h3 className="lead">Members</h3>
          <hr />
          <div className="member-thumbnails">
            {internalMembersList}
          </div>
          <div className="member-thumbnails">
            {externalMembersList}
          </div>
          <br />
          <p><Link to={`/board/${selectedVibe._id}/members`} className="button expanded hollow round">View all</Link></p>
        </div>
      );
    }

    return (
      <div className={baseClass}>
        <h3 className="lead">Members</h3>
        <hr />
        <div className="member-thumbnails">
          <br />
          <p className="text-center">No members yet</p>
          <br />
        </div>
      </div>
    );
  }
}

UserWidget.propTypes = {
  fetchMembersIfNeeded: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  selectedVibe: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  className: PropTypes.string,
};

UserWidget.defaultProps = {
  className: '',
};

export default UserWidget;
