const indicative = require("indicative");

const Indicative = new indicative();

const organizationUtils = (function () {
  const _validateOrg = function (orgName, teamDomain, initialLocation) {
    const data = {
      orgName,
      teamDomain,
      initialLocation: initialLocation.location,
    };

    const rules = {
      orgName: 'required|min:3|max:100',
      teamDomain: 'required|min:3|max:15|regex:^[a-zA-Z0-9\\-\\s]+$',
      initialLocation: 'required',
    };

    const messages = {
      'orgName.required': 'Org name is required',
      'orgName.min': 'Org name must be at least 3 characters',
      'orgName.max': 'Org name must be less than 100 characters',
      'teamDomain.required': 'Team domain is required',
      'teamDomain.min': 'Team domain must be at least 3 characters',
      'teamDomain.max': 'Team domain must be less than 15 characters',
      'teamDomain.regex': 'Only letters, numbers, and dashes allowed',
      'initialLocation.required': 'Primary location is required',
    };

    return Indicative
      .validateAll(rules, data, messages)
      .then((() => true))
      .catch((error) => {
        const errorMessage = {};
        for (let i = 0, errorLength = error.length; i < errorLength; i += 1) {
          errorMessage[error[i].field] = { message: error[i].message };
        }
        return errorMessage;
      });
  };

  return {
    validateOrg: _validateOrg,
  };
}());

export default organizationUtils;
