import constants from '../constants';
import utils from '../../../utils';

const VibeChampions = (function () {
  const _removeChampion = function (userId, vibeId, state) {
    const vibe = utils.get(state.vibes.data, vibeId);
    if (vibe.champion) {
      vibe.champion = utils.remove(vibe.champion, userId);
    }

    return {
      type: constants.REMOVE_VIBE_CHAMPION,
      vibe,
    };
  };

  const _addChampion = function (res, userId, vibeId, state) {
    const vibe = utils.get(state.vibes.data, vibeId);
    if (vibe.champion) {
      vibe.champion = utils.upsert(vibe.champion, userId, res.user);
    }

    return {
      type: constants.ADD_VIBE_CHAMPION,
      vibe,
    };
  };

  const _addChampionToVibe = function (res, userId, vibeId) {
    return function (dispatch, getState) {
      dispatch(_addChampion(res, userId, vibeId, getState()));
    };
  };

  const _removeChampionFromVibe = function (userId, vibeId) {
    return function (dispatch, getState) {
      dispatch(_removeChampion(userId, vibeId, getState()));
    };
  };

  return {
    addChampionToVibe: _addChampionToVibe,
    removeChampionFromVibe: _removeChampionFromVibe,
  };
}());

export default VibeChampions;
