const React = require('react');
const { Helmet } = require('react-helmet');
const PropTypes = require('prop-types');
const Header = require('../../components/header.jsx').default;
const Footer = require('../../components/footer.jsx').default;

// const whyDidYouUpdate = require('why-did-you-update').default;
if (process.env.NODE_ENV === 'development') {
  // whyDidYouUpdate(React);
}

class Main extends React.PureComponent {
  render() {
    const { isFetching, data: organization } = this.props.organization;
    const orgName = organization.name || 'Teamvibe';
    const { customTOS: { footer = '' } = {} } = organization;

    // Loading
    if (isFetching) {
      return (
        <div>
          <h1 className="loading">Loading...</h1>
        </div>
      );
    }

    return (
      <div className="wrapper">
        <Header
          auth={this.props.auth}
          organization={this.props.organization}
          logout={this.props.logout} />
        <section className="body">
          <Helmet title="Home" titleTemplate={`%s | ${orgName}`} />
          {this.props.children}
        </section>
        <Footer footer={footer} />
      </div>
    );
  }
}

Main.propTypes = {
  organization: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default Main;
