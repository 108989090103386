import { routerActions } from 'react-router-redux';

import constants from '../constants';
import api from '../../../utils/api';
import membersActions from '../../vibes/actions/vibe-members';
import authLogout from './auth-logout';

const JoinVibe = (function () {
  const _joinVibeRequest = function () {
    return {
      type: constants.JOIN_VIBE_REQUEST,
    };
  };

  const _joinVibeSuccess = function (data) {
    return {
      type: constants.JOIN_VIBE_SUCCESS,
      user: data.vibe,
    };
  };

  const _joinVibeError = function (error) {
    return {
      type: constants.JOIN_VIBE_ERROR,
      errors: error.data,
    };
  };

  const _joinVibe = function (userId, vibeId, isChampion, orgId, accessToken) {
    return function (dispatch) {
      dispatch(_joinVibeRequest());
      api.put(`/proxy/v1/users/${userId}/vibes`, {
        vibeId,
        isChampion,
        orgId,
      }, accessToken)
        .then((res) => {
          dispatch(membersActions.fetchMembers(vibeId, orgId, accessToken));
          dispatch(_joinVibeSuccess(res));
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(authLogout.logout());
            dispatch(routerActions.push('/login?err=3'));
          } else {
            dispatch(_joinVibeError(res.response));
          }
        });
    };
  };

  return {
    joinVibe: _joinVibe,
  };
}());

export default JoinVibe;
